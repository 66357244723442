import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import user from "../../assets/images/User.svg";
import { useGetRole } from "../../redux/selectors/Common";
import { Link } from "react-router-dom";
import resetReduxStore from "../../redux/action/resetReduxStore";
import { useAppDispatch } from "../../redux/store";

function DropdownButton() {
	const role = useGetRole();
	const dispatch = useAppDispatch();
	const handleLogout = () => {
		dispatch(resetReduxStore());
	};

	return (
		<Dropdown>
			<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
				<img src={user} alt="menu icon" width="30rem" height="40rem" />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item>
					<Link to={`/${role}/settings`}>Edit Profile</Link>
				</Dropdown.Item>
				<Dropdown.Item href="#/action-3">
					<Link
						to={"/home"}
						className="edit-link"
						onClick={handleLogout}
					>
						Logout
					</Link>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
}

function CustomToggle({ children, onClick }: any) {
	return (
		<Button
			variant="link"
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Button>
	);
}

export default DropdownButton;
