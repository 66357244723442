import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import NoImage from "../../../../assets/images/noImage.png";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TextArea from "../../../../components/TextArea";
import TextInput from "../../../../components/TextInput";
import { ArrowLeft } from "../../../../icons";
import { useCreateNewMediaMutation } from "../../../../redux/services/Firm/FirmLawyer";
import { Category, Locations, Webinars } from "../../../../utills/Const/const";
import JoditEditor from "jodit-react";
import "./AddNewMediaLibrary.scss";

interface AddFirmLawyer {
	active: boolean;
	setActive: any;
	item?: any;
	isUpdate: boolean;
	toUpdate?: string;
	setRefetch?: any;
}

export default function AddNewMediaLibrary(props: AddFirmLawyer) {
	const editor = useRef(null);
	const fileInput = useRef<HTMLInputElement>(null);
	const [formImage, setFormImage] = useState<string>(
		props?.isUpdate ? props?.item?.image : ""
	);
	const [dp, setDp] = useState<any>(
		props?.isUpdate ? props?.item?.image : ""
	);
	const [formImageError, setFormImageError] = useState<string>("");
	const [addMedia, { data: mediaData, isError, isLoading }] =
		useCreateNewMediaMutation();

	const handleAddMedia = (values: any) => {
		if (props.isUpdate) {
			addMedia({
				...values,
				id: props.item?.id,
				isUpdate: true,
				mediaLibrary: props.toUpdate,
				image: formImage,
			});
		} else {
			addMedia({
				...values,
				image: formImage,
				mediaLibrary: props.toUpdate,
			});
		}
	};

	useEffect(() => {
		if (
			mediaData?.message === "Podcast is created successfully." ||
			mediaData?.message === "Podcast is updated successfully." ||
			mediaData?.message === "Webinar is created successfully." ||
			mediaData?.message === "News is created successfully." ||
			mediaData?.message === "Event is created successfully." ||
			mediaData?.message === "Webinar is updated successfully." ||
			mediaData?.message === "News is updated successfully." ||
			mediaData?.message === "Event is updated successfully."
		) {
			setDp("");
			props.setRefetch();
			resetForm();
			handleClose();
			toast.success(mediaData.message);
		} else if (isError) {
			if (props.isUpdate) {
				toast.error(
					`Error! ${props.toUpdate} not updated Successfully, Please try Again`
				);
			} else {
				toast.error(
					`Error! ${props.toUpdate} not created Successfully, Please try Again`
				);
			}
		}
	}, [mediaData]);

	const handleClose = () => {
		props.setActive();
	};

	const initialValues = {
		name: props?.item?.name ? props?.item.name : "",
		location: props?.item?.location ? props.item.location : "",
		link: props.item?.link ? props.item?.link : "",
		description: props?.item?.description ? props?.item.description : "",
		image: props?.item?.image ? props?.item?.image : "",
		category: props.item?.category ? props.item?.category : "",
		date: props.item?.created_at
			? new Date(props.item?.created_at)
			: new Date(),
		webnar_type: props?.item?.webnar_type || "",
		featured: props?.item?.featured ? props?.item?.featured : false,
	};

	const {
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues,
		onSubmit: (values) => {
			handleAddMedia(values);
		},
	});

	const handleFileUpload = (e: any) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}

		const file = e.target.files[0];

		if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
			return;
		}

		setFormImage(file);
		setFormImageError("");

		const reader = new FileReader();

		reader.addEventListener(
			"load",
			(e: ProgressEvent<FileReader>) => {
				if (!e?.target?.result) {
					return;
				}
				const result = reader.result as string;
				setDp(result);
			},
			false
		);

		reader.readAsDataURL(file);
	};

	useEffect(() => {
		if (props?.item?.image !== "" && props.isUpdate === true) {
			setDp(props?.item?.image || "");
			setFormImage(props?.item?.image || "");
			setFormImageError("");
		}
	}, [props?.item]);

	const handleHeading = () => {
		if (props.toUpdate === "podcast" && !props.isUpdate) {
			return "Add New Podcast";
		} else if (props.toUpdate === "podcast" && props.isUpdate) {
			return "Update Podcast";
		} else if (props.toUpdate === "webinar" && !props.isUpdate) {
			return "Add New Webinar";
		} else if (props.toUpdate === "webinar" && props.isUpdate) {
			return "Update Webinar";
		} else if (props.toUpdate === "news" && !props.isUpdate) {
			return "Add New News";
		} else if (props.isUpdate && props.toUpdate === "news") {
			return "Update News";
		} else if (props.isUpdate && props.toUpdate === "event") {
			return "Update Event";
		} else {
			return "Add New Event";
		}
	};

	return (
		<section
			id="add_lawyer_popup"
			className={props.active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="inner_div">
									<button
										type="button"
										className="back_btn"
										onClick={props.setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">
										{handleHeading()}
									</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();
											if (formImage) {
												handleSubmit();
											} else {
												setFormImageError(
													"Image is Required"
												);
											}
										}}
									>
										<div className="ico fill round dp dp-image ">
											{dp != "" ? (
												<img src={dp} />
											) : (
												<img src={NoImage} />
											)}
											<div
												className="edit-icon"
												onClick={() =>
													fileInput?.current?.click()
												}
											>
												<CameraAltIcon className="dp-icon" />
											</div>
											<input
												ref={fileInput}
												type="file"
												hidden
												onChange={handleFileUpload}
											/>
										</div>
										<div className="text-center">
											<h5
												style={{
													color: "red",
												}}
											>
												{formImageError}
											</h5>
										</div>
										{/* ------------------------------------------------------------------------------------------------------------------
                                        Name And Location Row
------------------------------------------------------------------------------------------------------------------ */}
										<div
											className="row"
											style={{
												justifyContent: "center",
												marginTop: 25,
											}}
										>
											<div className="form_blk_container">
												<div className="input_field_div">
													<h5 className="fw_500 require">
														Name
													</h5>
													<div className="form_blk">
														<TextInput
															placeholder="eg: John Wick"
															type="text"
															name="name"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.name}
														/>
													</div>
												</div>
												<div className="input_field_div">
													<h5 className="fw_500 require">
														Location
													</h5>
													<Select
														value={values.location}
														onChange={(
															event: SelectChangeEvent
														) => {
															setFieldValue(
																"location",
																event.target
																	?.value
															);
														}}
														style={{
															width: "100%",
															lineHeight: "3rem",
															fontSize: "1rem",
														}}
														displayEmpty
														renderValue={
															values.location ===
															""
																? () =>
																		"Select Location"
																: () =>
																		values.location
														}
													>
														{Locations.map(
															(name) => (
																<MenuItem
																	key={
																		name.id
																	}
																	value={
																		name.name
																	}
																	style={{
																		fontSize: 17,
																		alignSelf:
																			"center",
																	}}
																>
																	{name.name}
																</MenuItem>
															)
														)}
													</Select>
												</div>
											</div>
											{/* ------------------------------------------------------------------------------------------------------------------
                                         Date
------------------------------------------------------------------------------------------------------------------ */}
											<div className="form_blk_container">
												<div className="input_field_div my-4">
													<h5 className="fw_500 require  ">
														{props.toUpdate ===
														"podcast"
															? "Podcast Date"
															: props.toUpdate ===
															  "news"
															? "News Date"
															: props.toUpdate ===
															  "webinar"
															? "Webinar Date"
															: "Event Date"}
													</h5>
													<div className="form_blk">
														<DatePicker
															format="yyyy-MM-dd"
															className="input"
															onChange={(
																value: any
															) => {
																setFieldValue(
																	"date",
																	new Date(
																		value
																	)
																);
															}}
															value={values.date}
														/>
													</div>
												</div>
												<div className="input_field_div my-4">
													<h5 className="fw_500 require  ">
														{props.toUpdate ===
														"podcast"
															? "Podcast Category"
															: props.toUpdate ===
															  "news"
															? "News Category"
															: props.toUpdate ===
															  "webinar"
															? "Webinar Category"
															: "Event Category"}
													</h5>
													<div className="form_blk">
														<Select
															value={
																values.category
															}
															onChange={(
																event: SelectChangeEvent
															) => {
																setFieldValue(
																	"category",
																	event.target
																		.value
																);
															}}
															style={{
																width: "100%",
																lineHeight:
																	"3rem",
															}}
															displayEmpty
															renderValue={
																values.category ===
																""
																	? () =>
																			"Select Category"
																	: () =>
																			values.category
															}
														>
															{Category.map(
																(name) => (
																	<MenuItem
																		key={
																			name.id
																		}
																		value={
																			name.name
																		}
																		style={{
																			fontSize: 17,
																			alignSelf:
																				"center",
																		}}
																	>
																		{
																			name.name
																		}
																	</MenuItem>
																)
															)}
														</Select>
													</div>
												</div>
											</div>
											{/* ------------------------------------------------------------------------------------------------------------------
                                         Date
------------------------------------------------------------------------------------------------------------------ */}

											{props.toUpdate === "webinar" && (
												<div className="form_blk_container">
													<div className="col-12">
														<h5 className="fw_500 require  ">
															Webinar Type
														</h5>
														<div className="form_blk">
															<Select
																id="Webinar_type"
																value={
																	values.webnar_type ||
																	""
																}
																onChange={(
																	event: SelectChangeEvent
																) => {
																	setFieldValue(
																		"webnar_type",
																		event
																			.target
																			.value
																	);
																}}
																style={{
																	width: "100%",
																	alignItems:
																		"center",
																	lineHeight:
																		"3rem",
																}}
																displayEmpty
																renderValue={
																	values.webnar_type ===
																	""
																		? () =>
																				"Select Webinar Type"
																		: () =>
																				values.webnar_type ===
																				"on_demand"
																					? "On Demand"
																					: "Up Coming"
																}
															>
																{Webinars.map(
																	(name) => (
																		<MenuItem
																			key={
																				name.id
																			}
																			value={
																				name.values
																			}
																			style={{
																				fontSize: 17,
																				alignSelf:
																					"center",
																			}}
																		>
																			{
																				name.name
																			}
																		</MenuItem>
																	)
																)}
															</Select>
														</div>
													</div>
												</div>
											)}

											{/* ------------------------------------------------------------------------------------------------------------------
                                           Link
------------------------------------------------------------------------------------------------------------------ */}

											<div className="form_blk_container my-4">
												<div className="col-12">
													<h5 className="fw_500 require">
														Link
													</h5>
													<div className="form_blk">
														<TextInput
															type="text"
															name="link"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.link}
															className="input"
															placeholder="https://"
														/>
													</div>
												</div>
											</div>
											<div className="form_blk_container">
												<div className="col-12">
													<h5 className="fw_500 require my-4">
														{props.toUpdate ===
														"podcast"
															? "Podcast Description"
															: props.toUpdate ===
															  "news"
															? "News Description"
															: props.toUpdate ===
															  "webinar"
															? "Webinar Description"
															: "Event Description"}
													</h5>
													<div className="form_blk">
														<JoditEditor
															ref={editor}
															value={
																values?.description ||
																""
															}
															className="editor-class"
															onChange={(
																newContent
															) => {
																setFieldValue(
																	"description",
																	newContent
																);
															}} // preferred to use only this option to update the content for performance reasons
														/>
													</div>
												</div>
											</div>
											{props.toUpdate !== "event" && (
												<div className="form_blk_container">
													<div className="col-12">
														<h5 className="fw_500 require my-4">
															Featured
														</h5>
														<div
															className="form_blk"
															style={{
																marginBottom: 0,
															}}
														>
															<div className="switch switch2">
																<input
																	type="checkbox"
																	name=""
																	checked={
																		values.featured
																	}
																	onChange={() => {
																		setFieldValue(
																			"featured",
																			!values.featured
																		);
																	}}
																/>
																<em></em>
																<span></span>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
										<div className="btn_blk mt-5 pt-3">
											<button
												className="site_btn w-100"
												type="submit"
											>
												{isLoading ? (
													<ScreenLoader
														show={isLoading}
													/>
												) : (
													handleHeading()
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
