import { ILawyer } from "../../../../interfaces/Lawyer";

export enum LawyerActionTypes {
	SET_LAWYER = "SET_LAWYER",
	RESET_LAWYER = "RESET_LAWYER",
}

export const setLawyerData = (payload: ILawyer) => {
	return {
		type: LawyerActionTypes.SET_LAWYER,
		payload,
	};
};

export const resetLawyerData = () => {
	return {
		type: LawyerActionTypes.RESET_LAWYER,
	};
};
