import Checkbox from "@mui/material/Checkbox";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ImportSvg from "../../../../assets/images/import.svg";
import search from "../../../../assets/images/search.svg";
import DeleteConfirmation from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import Layout from "../../../../components/Layout";
import MediaLibraryCard from "../../../../components/MediaLibraryCards/MediaLibraryCard";
import ImportFromCSV from "../../../../components/Popup/ImportFirmLawyers/ImportFromCsv";
import ReactPagination from "../../../../components/ReactPagination/ReactPagination";
import filters from "../../../../assets/images/filters.svg";
import filterClose from "../../../../assets/images/filterClose.png";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TopBlock from "../../../../components/TopBlock";
import { useGetPodcastsQuery } from "../../../../redux/services/Firm/MediaLibrary";
import AddNewMediaLibrary from "./AddNewMediaLibrary";
import {
	useDeleteMediaLibraryMutation,
	useGetMediaLibrarySearchQuery,
} from "../../../../redux/services/Firm/FirmLawyer";
import SuperAdminFilter from "../../../../components/Popup/SuperAdminFilter";
import { isEmptyArray } from "formik";

const MediaLibraryPodcasts = () => {
	const [addNewPodcast, setAddNewPodcast] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [deletePodcast, setDeletePodcast] = useState(false);
	const [importPodcast, setImportPodcast] = useState(false);
	const [rowsPerPageSearch, setRowsPerPageSearch] = useState(10);
	const [pageNumberSearch, setPageNumberSearch] = useState(1);
	const [searching, setSearching] = useState<any>(null);
	const [bulkDeletePodcast, setBulkDeletePodcast] = useState(false);
	const [refetchData, setRefetchData] = useState(false);
	const [searchText, setSearchText] = useState<any | null>("");
	const [podcast, setPodcast] = useState<any>(null);
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [filter, setFilter] = useState(false);
	const [filteredData, setFilteredData] = useState<any | null>(null);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [selectAll, setSelectAll] = useState(false);

	const {
		data: searchData,
		isLoading: searchLoading,
		isSuccess: isSearchSuccess,
		refetch: refetchSearch,
	} = useGetMediaLibrarySearchQuery(
		{
			...searching,
			rowsPerPage: rowsPerPageSearch,
			pageNumber: pageNumberSearch,
		},
		{
			skip: searchText === "" && searching === null,
		}
	);

	const [deleteMedia, { isSuccess, isError }] =
		useDeleteMediaLibraryMutation();

	const handleAddNewPodcast = () => {
		setAddNewPodcast(!addNewPodcast);
	};

	const { data, isLoading, refetch } = useGetPodcastsQuery({
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber,
	});

	useEffect(() => {
		if (searchText !== "" || searching !== null) {
			refetchSearch();
		} else {
			refetch();
			setDeleted(false);
			setRefetchData(false);
		}
	}, [
		deleted,
		refetchData,
		rowsPerPage,
		pageNumber,
		rowsPerPageSearch,
		pageNumberSearch,
		refetch,
		searchText,
		searching,
		refetchSearch,
	]);

	useEffect(() => {
		if (deleted || refetchData) {
			refetch();
			setDeleted(false);
			setRefetchData(false);
		}
	}, [deleted, refetchData]);

	const handleRefetch = () => {
		setRefetchData(!refetchData);
	};

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				setSearching({
					search: search,
					toSearch: "podcast",
				});
			} else {
				setFilteredData(null);
				setSearching(null);
				setPodcast(data?.podcasts);
			}
		}, 1000);
	};
	useEffect(() => {
		if (searchData?.podcasts) {
			setFilteredData(searchData?.podcasts);
		} else {
			setFilteredData(null);
			if (data?.podcasts?.length) {
				let podcastsArray: any = data?.podcasts;
				let sortedArray = [...podcastsArray];

				sortedArray?.sort(
					(a: any, b: any) =>
						new Date(b.created_at).getTime() -
						new Date(a.created_at).getTime()
				);

				setPodcast(sortedArray);
			}
		}
	}, [searchData, data]);

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(podcast?.map((item: { id: any }) => item.id));
		}
		setSelectAll(!selectAll);
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	const handleFilter = () => {
		setFilter(!filter);
		setSearchText("");
	};

	const handleFilterSearch = (filterObj: any) => {
		setSearching({
			filterObj,
			toSearch: "podcast",
		});
		refetchSearch();
	};

	const handleClearFilter = () => {
		setSearchText("");
		setFilteredData(null);
	};

	const handleImportPodcast = () => {
		setImportPodcast(!importPodcast);
	};

	const handleBulkDeletePodcast = () => {
		setBulkDeletePodcast(!bulkDeletePodcast);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	useEffect(() => {
		if (selectedItems?.length === podcast?.length) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [selectedItems]);

	useEffect(() => {
		if (deletePodcast) {
			deleteMedia({ toDelete: "podcast", selectedItems: selectedItems });
			setDeletePodcast(false);
		}
		if (isSuccess) {
			refetch();
			setSelectedItems([]);
			toast.success("Podcasts Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
			setDeletePopUp(false);
		}
		if (isError) {
			toast.error(
				"Could not delete podcast at the moment, Please try again"
			);
		}
	}, [deletePodcast, isSuccess, isError]);

	return (
		<>
			<Layout className="w-4/5">
				<section id="resources" className="sec_pad_btm">
					<div className="contain sm">
						<div className="Top_blk">
							<TopBlock title="Media Library" />
						</div>
						<ul>
							<li>
								<Link to="/firm/super-admin-media-library-handbooks">
									Handbook
								</Link>
							</li>
							<li>
								<Link to="/firm/super-admin-media-library-news">
									News
								</Link>
							</li>
							<li>
								<Link to="/firm/super-admin-media-library-webinars">
									Webinars
								</Link>
							</li>
							<li>
								<Link
									to="/firm/super-admin-media-library-podcasts"
									className="active"
								>
									Podcasts
								</Link>
							</li>
							<li>
								<Link to="/firm/super-admin-media-library-events">
									Events
								</Link>
							</li>
						</ul>
					</div>
				</section>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						marginBottom: "3rem",
						flexDirection: "column",
						margin: "auto",
						width: "80%",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: "2rem",
							marginBottom: "2rem",
						}}
					>
						<button
							className="site_btn"
							style={{
								width: "30rem",
								alignSelf: "flex-end",
								marginRight: "3rem",
							}}
							onClick={() => {
								handleBulkDeletePodcast();
							}}
						>
							{"Delete Via CSV"}{" "}
							<img
								style={{ fontWeight: "500", fontSize: 25 }}
								src={ImportSvg}
								alt="icon"
							/>
						</button>
						<button
							className="site_btn"
							style={{
								width: "30rem",
								alignSelf: "flex-end",
								marginRight: "3rem",
							}}
							onClick={() => {
								handleImportPodcast();
							}}
						>
							{"Import Podcasts"}{" "}
							<img
								style={{ fontWeight: "500", fontSize: 25 }}
								src={ImportSvg}
							/>
						</button>
						<button
							className="site_btn"
							style={{
								width: "30rem",
								alignSelf: "flex-end",
							}}
							onClick={handleAddNewPodcast}
						>
							{"Add New Podcasts"}{" "}
							<span style={{ fontWeight: "500", fontSize: 25 }}>
								+
							</span>
						</button>
					</div>
					<div
						className="search_container"
						style={{
							justifyContent: "space-between",
							backgroundColor: "#63a744",
							alignItems: "center",
							padding: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginLeft: "4rem",
								}}
							>
								<Checkbox
									checked={selectAll}
									classes={{ root: "custom-checkbox-root" }}
									onClick={() => {
										handleSelectAllChange();
									}}
								/>
								<p
									style={{
										fontSize: "2rem",
										lineHeight: "7rem",
									}}
								>
									Select All
								</p>
							</div>
							{selectedItems?.length > 0 && (
								<Button
									variant="danger"
									onClick={handleBulkDelete}
									style={{
										fontSize: "2rem",
										width: "15rem",
										height: "5rem",
										marginTop: "1rem",
										marginLeft: "2rem",
									}}
								>
									Delete
								</Button>
							)}
						</div>
						<div style={{ display: "flex" }}>
							<div className="left_blk">
								<div className="search_blk">
									<div className="img_blk">
										<img src={search} alt="" />
									</div>
									<input
										type="search"
										value={searchText}
										placeholder="Search"
										onChange={(e) => {
											handleSearch(e.target.value);
										}}
									/>
								</div>
								<div>
									{filteredData?.length > 0 &&
										searchText === "" && (
											<img
												onClick={() =>
													handleClearFilter()
												}
												style={{
													width: "3rem",
													height: "3rem",
													position: "absolute",
													marginTop: "-1rem",
													marginLeft: "0.5rem",
													cursor: "pointer",
												}}
												src={filterClose}
												alt=""
											/>
										)}
									<div
										className="filter_blk pop_btn"
										onClick={handleFilter}
									>
										<img src={filters} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						margin: "auto",
						width: "80%",
					}}
				>
					{(filteredData ? filteredData : podcast)?.map(
						(item: any) => {
							return (
								<MediaLibraryCard
									key={item?.id}
									item={item}
									isDeleted={setDeleted}
									setRefetch={handleRefetch}
									toDelete={"podcast"}
									isSelected={selectedItems.includes(item.id)}
									onSelect={handleItemSelect}
								/>
							);
						}
					)}
					{!isLoading && !searchLoading && filteredData === null && (
						<ReactPagination
							setRowsPerPage={setRowsPerPage}
							setPages={setPageNumber}
							totalRecords={data?.total_records}
						/>
					)}
					{!isLoading && !searchLoading && filteredData?.length && (
						<ReactPagination
							setRowsPerPage={setRowsPerPageSearch}
							setPages={setPageNumberSearch}
							totalRecords={searchData?.total_records}
						/>
					)}
				</div>
				{isEmptyArray(podcast) ? (
					<div>
						<p
							style={{
								textAlign: "center",
								marginTop: "30rem",
								verticalAlign: "middle",
								fontSize: "large",
								color: "#114D96",
							}}
						>
							No Podcast to show{" "}
						</p>
					</div>
				) : (
					isSearchSuccess &&
					filteredData?.length <= 0 && (
						<div>
							<p
								style={{
									textAlign: "center",
									marginTop: "30rem",
									verticalAlign: "middle",
									fontSize: "large",
									color: "#114D96",
								}}
							>
								No Record Found Against Your Search
							</p>
							<p
								onClick={handleClearFilter}
								style={{
									textAlign: "center",
									verticalAlign: "middle",
									fontSize: "large",
									color: "red",
									cursor: "pointer",
								}}
							>
								Clear Search
							</p>
						</div>
					)
				)}
				<ScreenLoader show={isLoading || searchLoading} />
				<AddNewMediaLibrary
					active={addNewPodcast}
					setActive={handleAddNewPodcast}
					isUpdate={false}
					toUpdate="podcast"
					setRefetch={handleRefetch}
				/>
				<DeleteConfirmation
					showModal={deletePopUp}
					message={
						"Are you sure you want to delete the selected Podcasts?"
					}
					handleDelete={setDeletePodcast}
					hideModal={handleBulkDelete}
				/>

				<ImportFromCSV
					active={importPodcast}
					setActive={handleImportPodcast}
					refetch={handleRefetch}
					toImport="podcast"
				/>
				<ImportFromCSV
					active={bulkDeletePodcast}
					setActive={handleBulkDeletePodcast}
					toDelete="podcast"
					refetch={handleRefetch}
				/>
				<SuperAdminFilter
					active={filter}
					setActive={handleFilter}
					searchingResources={handleFilterSearch}
					lawyer={false}
					mediaType="Podcast"
				/>
			</Layout>
		</>
	);
};

export default MediaLibraryPodcasts;
