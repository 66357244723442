import { FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { Formik } from "formik";
import Image from "material-ui-image";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import NoImage from "../../../assets/images/noImage.png";
import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInput";
import { EditIcon } from "../../../icons";
import resetReduxStore from "../../../redux/action/resetReduxStore";
import { GetLoginUserData, useGetRole } from "../../../redux/selectors/Common";
import { useAppDispatch } from "../../../redux/store";

const ProfileSetting = ({ data, handleSubmit }: any) => {
	let userData = GetLoginUserData();
	let user_data: any = userData;
	const role = useGetRole();
	const dispatch = useAppDispatch();
	const [preview, setPreview] = useState("");
	const [readOnly, setReadOnly] = useState(false);
	const [podcast, setPodcast] = useState(
		data.firm?.is_podcast ||
			data.lawyer?.is_podcast ||
			data.client?.is_podcast ||
			false
	);
	const [webinar, setWebinar] = useState(
		data.firm?.is_webinar ||
			data.lawyer?.is_webinar ||
			data.client?.is_webinar ||
			false
	);
	const [news, setNews] = useState(
		data.firm?.is_news ||
			data.lawyer?.is_news ||
			data.client?.is_news ||
			false
	);
	const [referral, setReferral] = useState(
		data.firm?.is_referral ||
			data.lawyer?.is_referral ||
			data.client?.is_referral ||
			false
	);

	const handleLogout = () => {
		dispatch(resetReduxStore());
	};

	useEffect(() => {
		if (data?.profile_image) {
			setPreview(data.profile_image);
		} else {
			setPreview("");
		}
	}, [data]);

	const handleFileUpload = (e: any) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}
		const file = e.target.files[0];

		const reader = new FileReader();

		reader.addEventListener(
			"load",
			(e: ProgressEvent<FileReader>) => {
				if (!e?.target?.result) {
					return;
				}
				const result = reader.result as string;
				setPreview(result);
			},
			false
		);

		reader.readAsDataURL(file);
	};

	const initialValues =
		role === "firm"
			? {
					email:
						data?.firm?.email ||
						data?.client?.email ||
						data?.lawyer?.email,
					phone:
						data?.firm?.phone ||
						data?.client?.phone ||
						data?.lawyer?.phone,
					description:
						data?.firm?.description ||
						data?.client?.description ||
						data?.lawyer?.description,
					info:
						data?.firm?.info ||
						data?.client?.info ||
						data?.lawyer?.info,

					is_news: news,

					is_podcast: podcast,

					is_webinar: webinar,

					userType: role,

					referral_email: data?.firm?.referral_email
			  }
			: {
					email:
						data?.firm?.email ||
						data?.client?.email ||
						data?.lawyer?.email,
					phone:
						data?.firm?.phone ||
						data?.client?.phone ||
						data?.lawyer?.phone,
					info:
						data?.firm?.info ||
						data?.client?.info ||
						data?.lawyer?.info,
					name: data?.client?.name || data?.lawyer?.name,

					is_news: news,
					is_podcast: podcast,
					is_webinar: webinar,
					is_referral: referral,
					userType: role,
			  };

	return (
		<Formik
			initialValues={initialValues}
			// validationSchema={settingSchema}
			onSubmit={(values) => {
				handleSubmit(values);
				setReadOnly(!readOnly);
			}}
		>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				errors,
				touched,
			}) => (
				<form onSubmit={handleSubmit} className='auth-form'>
					<div className='row'>
						<div className='col-md-12'>
							<div style={{ margin: "0 10px" }}>
								<Box
									sx={{
										position: "absolute",
										zIndex: "3",
										marginLeft: "12.5rem",
										marginTop: "18rem",
									}}
								>
									<label htmlFor='file-input'>
										{!readOnly && <EditIcon />}
									</label>
								</Box>

								<div style={{ display: "none" }}>
									<input
										placeholder='Please enter name here'
										id='file-input'
										type='file'
										name='profileImage'
										onChange={(event) => {
											const files: any =
												event.target.files;
											let myFile = Array.from(files);
											setFieldValue(
												"profile_image",
												myFile[0]
											);

											handleFileUpload(event);
										}}
									/>
								</div>
							</div>

							<div className='top_sec'>
								<div className='left_blk'>
									<div
										className='sec_logo'
										style={{ position: "relative" }}
									>
										<Image src={preview || NoImage} />
									</div>
									<h3 className='main-sect-title fw_600 mb-0'>
										{data?.firm?.name ||
											data?.client?.name ||
											data?.lawyer?.name}
									</h3>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='log_blk'>
								<div className='inner'>
									<div className='col-12'>
										<label className='input-label my-4'>
											<strong style={{ color: "black" }}>
												{role === "firm"
													? "Firm Info"
													: "First Name"}
											</strong>
										</label>
										{role === "firm" ? (
											<TextInput
												className='input-field'
												readOnly={readOnly}
												placeholder='Please enter firm info here'
												type='text'
												name='info'
												handleChange={handleChange}
												handleBlur={handleBlur}
												value={values.info}
												disabled={readOnly}
											/>
										) : (
											<TextInput
												className='input-field'
												readOnly={readOnly}
												placeholder='Please enter name here'
												type='text'
												name='name'
												handleChange={handleChange}
												handleBlur={handleBlur}
												disabled={readOnly}
											/>
										)}
									</div>
									<div className='col-12'>
										<label className='input-label my-4'>
											<strong style={{ color: "black" }}>
												Phone
											</strong>
										</label>
										<div className='row  border-2 border-black	'>
											<div className='col-10 my-2'>
												<PhoneInput
													containerStyle={{
														backgroundColor:
															"ButtonShadow",
														border: "1px solid grey",
													}}
													country={"us"}
													value={values.phone}
													onChange={(value) => {
														setFieldValue(
															"phone",
															value
														);
													}}
													disabled={readOnly}
												/>
											</div>
										</div>
									</div>
									<div className='col-12'>
										<label className='input-label my-4 '>
											<strong style={{ color: "black" }}>
												Email Address
											</strong>
										</label>
										<div className='form_blk'>
											<TextInput
												className='input-field'
												placeholder='eg: sample@gmail.com'
												type='email'
												name='email'
												readOnly={true}
												handleChange={handleChange}
												handleBlur={handleBlur}
												value={values.email}
												disabled={true}
											/>
										</div>
									</div>
									{role === "firm" &&
										user_data?.firm?.role !=
											"Super Admin" && (
											<div className='col-12'>
												<label className='input-label my-4 '>
													<strong
														style={{
															color: "black",
														}}
													>
														Referral Email
													</strong>
												</label>
												<div className='form_blk'>
													<TextInput
														className='input-field'
														placeholder='eg: sample@gmail.com'
														type='email'
														name='referral_email'
														readOnly={readOnly}
														handleChange={
															handleChange
														}
														handleBlur={handleBlur}
														value={
															values?.referral_email ||
															""
														}
														disabled={readOnly}
													/>
												</div>
											</div>
										)}
									<div className='col-12 mb-4'>
										<label className='input-label my-4'>
											<strong style={{ color: "black" }}>
												{role === "firm"
													? "Firm Description"
													: "About Me"}
											</strong>
										</label>
										{role === "firm" ? (
											<TextArea
												className='firm-info scrollbar'
												placeholder='Please add some details here'
												type='text'
												name='description'
												handleChange={handleChange}
												handleBlur={handleBlur}
												value={
													values?.description || ""
												}
												readOnly={readOnly}
												disabled={readOnly}
											/>
										) : (
											<TextArea
												className='firm-info scrollbar'
												placeholder='Please add some details here'
												type='text'
												name='info'
												handleChange={handleChange}
												handleBlur={handleBlur}
												value={values?.info || ""}
												readOnly={readOnly}
												disabled={readOnly}
											/>
										)}
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<label className='input-label my-4'>
											<strong style={{ color: "black" }}>
												Notifications
											</strong>
										</label>
										<p>Yes, I want to be notified about:</p>
										{role === "lawyer" && (
											<FormControlLabel
												control={
													<Checkbox
														disabled={readOnly}
														checked={referral}
														onChange={() => {
															setReferral(
																!referral
															);
															setFieldValue(
																"is_referral",
																!referral
															);
														}}
													/>
												}
												label={
													<span
														style={{
															fontSize: "2rem",
														}}
													>
														New ELA Referrals
													</span>
												}
												style={{ fontSize: "3rem" }}
											/>
										)}
										<FormControlLabel
											control={
												<Checkbox
													disabled={readOnly}
													checked={podcast}
													onChange={() => {
														setPodcast(!podcast);
														setFieldValue(
															"is_podcast",
															!podcast
														);
													}}
												/>
											}
											label={
												<span
													style={{ fontSize: "2rem" }}
												>
													New Podcast
												</span>
											}
											style={{ fontSize: "3rem" }}
										/>
										<FormControlLabel
											control={
												<Checkbox
													disabled={readOnly}
													checked={webinar}
													onChange={() => {
														setWebinar(!webinar);
														setFieldValue(
															"is_webinar",
															!webinar
														);
													}}
												/>
											}
											label={
												<span
													style={{ fontSize: "2rem" }}
												>
													Upcoming Webinars
												</span>
											}
											style={{ fontSize: "3rem" }}
										/>
										<FormControlLabel
											control={
												<Checkbox
													disabled={readOnly}
													checked={news}
													onChange={() => {
														setNews(!news);
														setFieldValue(
															"is_news",
															!news
														);
													}}
												/>
											}
											label={
												<span
													style={{ fontSize: "2rem" }}
												>
													ELA News & Events
												</span>
											}
											style={{ fontSize: "3rem" }}
										/>
									</div>
								</div>

								{!readOnly && (
									<div className='btn_blk justify-content-center mt-5 pt-3'>
										<button
											type='submit'
											className='site_btn w-100'
										>
											Update
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default ProfileSetting;
