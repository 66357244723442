import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_ENDPOINT,
	prepareHeaders: (headers) => {
		headers.set("cache-control", "no-cache");
		headers.set("Authorization", `Bearer ${localStorage.getItem("token")}` || "");
		return headers;
	},
	// credentials: 'include',
});

export const authApiSlice = createApi({
	reducerPath: "authApi",
	baseQuery,
	endpoints: () => ({}),
});
