import dayjs from "dayjs";
import React, { useEffect } from "react";
import { INotifications } from "../../interfaces/Common";
import CloseIcon from "@mui/icons-material/Close";
import "./notificationCard.scss";
import {
	useDeleteNotificationsMutation,
	useSeenNotificationsMutation,
} from "../../redux/services/Firm/MediaLibrary";
import { useNavigate } from "react-router-dom";
import { useGetRole } from "../../redux/selectors/Common";

type Props = {
	item: INotifications;
	setDeleted?: any;
	setCloseNotification?: any;
};

const getDateFormat = (date: string) => {
	const d = new Date(date);
	const year = d.toLocaleString("default", { year: "numeric" });
	const month = d.toLocaleString("default", { month: "long" });
	const day = d.toLocaleString("default", { day: "2-digit" });
	const hours = d.getHours();
	const minute = d.getMinutes();

	return `${day} ${month} ${year} (${hours} : ${minute})`;
};

const getCalculatedTime = (date: string) => {
	const startTime = dayjs().format(date);
	const endTime = dayjs();
	const days = endTime.diff(startTime, "days");
	return `${days}d`;
};

const NotificationsCard: React.FC<Props> = ({
	item,
	setDeleted,
	setCloseNotification,
}) => {
	const role = useGetRole();
	const currentUrl = window?.location?.href;
	const navigation = useNavigate();

	const [deleteNotification, { data }] = useDeleteNotificationsMutation();
	const [markAsSeenNotification] = useSeenNotificationsMutation();
	const handleDeleteNotification = (e: any) => {
		deleteNotification(item.id);
	};

	const handleClick = () => {
		markAsSeenNotification(item.id);

		if (!currentUrl?.includes("/notifications")) {
			setCloseNotification();
		}
		if (item?.notification_type === "podcast") {
			navigation(`/${role}/media-library`);
		} else if (item?.notification_type === "event") {
			navigation(`/${role}/events`);
		} else if (item?.notification_type === "webnar") {
			navigation(`/${role}/webinar`);
		} else if (item?.notification_type === "lawyer") {
			navigation(`/${role}/lawyers`);
		} else if (item?.notification_type === "news") {
			navigation(`/${role}/news`);
		} else if (item?.notification_type === "referral") {
			navigation(`/${role}/referals`);
		}
	};

	useEffect(() => {
		if (data?.message === "Deleted") {
			setDeleted();
		}
	}, [data]);

	return (
		<section
			id="notificationCard"
			style={{ backgroundColor: item?.seen ? "" : "#efefef" }}
		>
			<div className="notification_card">
				<div className="left_blk">
					<div style={{ width: "85%" }} onClick={handleClick}>
						<div className="date_container">
							<p className="notification_heading">{item?.body}</p>
							<span>{getDateFormat(item?.created_at)}</span>
						</div>
					</div>
					<div className="date_container">
						<div
							onClick={handleDeleteNotification}
							className="icon_container"
						>
							<CloseIcon className="icon_cross" />
						</div>
						<small>
							{getCalculatedTime(item?.created_at) === "0d"
								? "Today"
								: getCalculatedTime(item?.created_at)}
						</small>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NotificationsCard;
