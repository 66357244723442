import React from "react";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Type } from "typescript";

export default function Verification() {
	const location = useLocation();
	const state: any = location.state;
	const navigate = useNavigate();
	const handleVerification = (values: any) => {
		if (values.otp === state.otp) {
			setTimeout(() => {
				navigate("/reset-password", {
					state: {
						email: state.email,
						otp: state.otp,
						userType: state.userType
					}
				});
			}, 1000);
		} else {
			console.log('error')
		}
	};

	const {
		values,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		touched,
		resetForm,
	} = useFormik({
		initialValues: {
			otp: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			otp: Yup.string()
				.min(4, "Enter complete Otp")
				.required("Email is a required field"),
		}),
		onSubmit: (values) => {
			handleVerification(values);
		},
	});

	return (
		<>
			<section id="logon">
				<div className="contain">
					<div className="flex_blk">
						<form action="" method="POST" className="auth-form">
							<div className="log_blk">
								<div className="inner">
									<div className="txt text-center mb-5 pb-3">
										<h2>Enter verification code</h2>
										<p className="fs_5 opacity-50">
											Enter the verification code sent to
											your <br /> email address
										</p>
									</div>
									<div className="row verify_row justify-content-center">
										<div className="col-auto">
											<div className="form_blk">
												<OtpInput
													value={values.otp}
													onChange={(otp: any) =>
														setFieldValue(
															"otp",
															otp
														)
													}
													inputStyle={{
														width: "8rem",
														height: "9rem",
														margin: "0 1rem",
														fontSize: "4rem",
														borderRadius: 4,
														border: "1px solid rgba(0,0,0,0.3)",
													}}
													numInputs={4}
													separator={<span>-</span>}
												/>
											</div>
										</div>
										{/* <div className="col-auto">
											<div className="form_blk">
												<TextInput
													placeholder="0"
													type="text"
												/>
											</div>
										</div>
										<div className="col-auto">
											<div className="form_blk">
												<TextInput
													placeholder="0"
													type="text"
												/>
											</div>
										</div> */}
										{/* <div className="col-auto">
											<div className="form_blk">
												<TextInput
													placeholder="0"
													type="text"
												/>
											</div>
										</div>
										<div className="col-auto">
											<div className="form_blk">
												<TextInput
													placeholder="0"
													type="text"
												/>
											</div>
										</div> */}
									</div>
									<div className="btn_blk justify-content-center mt-5 pt-3">
										<Button
											label="Submit"
											type="submit"
											onClick={handleSubmit}
											className="site_btn w-100"
										/>
									</div>
									<div className="already_acc text-center">
										<p className="fs_6 opacity-50">
											Entered a wrong email address?
										</p>
										<button
											type="button"
											className="site_btn text"
										>
											Resend Verification Code Password
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	);
}
