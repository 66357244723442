import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TextInput from "../../../../components/TextInput";
import { ArrowLeft } from "../../../../icons";
import { useGetJurisdictionQuery } from "../../../../redux/services/Client/ClientDashboard";
import { useAddHandbookPdfMutation } from "../../../../redux/services/Firm/FirmLawyer";

interface AddJurisdiction {
	active: boolean;
	setActive: any;
	isUpdate: boolean;
	state?: any;
	setRefetch: any;
}

export default function AddNewHandbookLink(props: AddJurisdiction) {
	const [data, setData] = useState<any>(null);
	const [state, setState] = useState<any>(
		props?.state?.state_id
			? { id: props.state?.state_id, name: props.state?.state_name }
			: {}
	);

	const { data: jurisdiction, isLoading } = useGetJurisdictionQuery(null);
	useEffect(() => {
		let payload: any = jurisdiction;
		var sortedJurisdictions = _.sortBy(payload?.states, "name");
		setData({ states: sortedJurisdictions });
	}, [jurisdiction]);

	const [
		addHandbookPdf,
		{ data: addNewHandbookPdf, isSuccess, isError, isLoading: loading },
	] = useAddHandbookPdfMutation();

	const handleAddHandbookPdf = (values: any) => {
		if (props.isUpdate) {
			addHandbookPdf({ ...values, state_id: state.id });
		} else {
			addHandbookPdf({ ...values, state_id: state.id });
		}
	};

	useEffect(() => {
		if (isSuccess) {
			resetForm();
			handleClose();
			props.setRefetch();
			if (isSuccess && props.isUpdate) {
				toast.success("Handbook PDF Updated Successfully");
			} else if (isSuccess && !props.isUpdate) {
				toast.success("Handbook PDF created Successfully");
			} else {
				toast.success(addNewHandbookPdf?.message);
			}
		} else if (isError && !props.isUpdate) {
			toast.error(
				"Error! Handbook PDF not created Successfully, Please try Again"
			);
		} else if (isError && props.isUpdate) {
			toast.error(
				"Error! Could not update the Handbook PDF at the moment, Please try Again"
			);
		}
	}, [isSuccess, isError]);

	const handleClose = () => {
		props.setActive();
	};

	const initialValues = {
		pdfLink: props?.state?.pdf_link ? props?.state?.pdf_link : "",
		status: props?.state?.pdf_link_status
			? props?.state?.pdf_link_status
			: false,
	};

	const {
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
		setFieldValue,
	} = useFormik({
		initialValues,
		onSubmit: (values) => {
			handleAddHandbookPdf(values);
		},
	});

	const handleHeading = () => {
		if (props.isUpdate) {
			return "Update Handbook Link";
		} else {
			return "Create Handbook Link";
		}
	};

	return (
		<section
			id="add_lawyer_popup"
			className={props.active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="inner_div">
									<button
										type="button"
										className="back_btn"
										onClick={props.setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">
										{handleHeading()}
									</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
									>
										<div
											className="row"
											style={{
												justifyContent: "center",
												marginTop: 25,
											}}
										>
											<div className="col-11">
												<h5 className="fw_500 require my-4">
													State
												</h5>
												<Select
													value={
														props?.state?.state_name
															? props?.state
																	?.state_name
															: state || ""
													}
													onChange={(
														event: SelectChangeEvent
													) => {
														setState(
															event?.target?.value
														);
													}}
													style={{
														width: "100%",
														lineHeight: "3rem",
													}}
													displayEmpty
													renderValue={() =>
														state?.name
															? state.name
															: "Select State"
													}
												>
													{data?.states &&
														data?.states?.map(
															(item: any) => (
																<MenuItem
																	key={
																		item.id
																	}
																	value={item}
																	style={{
																		fontSize: 17,
																		alignSelf:
																			"center",
																	}}
																>
																	{item?.name}
																</MenuItem>
															)
														)}
												</Select>
											</div>
											<div className="form_blk_container">
												<div style={{ width: "100%" }}>
													<h5 className="fw_500 require">
														PDF Link
													</h5>
													<TextInput
														placeholder="eg: https://"
														type="text"
														name="pdfLink"
														handleChange={
															handleChange
														}
														handleBlur={handleBlur}
														value={values.pdfLink}
													/>
												</div>
											</div>
											<div className="col-11">
												<h5 className="fw_500 require my-4">
													Status
												</h5>
												<div
													className="form_blk"
													style={{ marginBottom: 0 }}
												>
													<div className="switch switch2">
														<input
															type="checkbox"
															name=""
															checked={
																values.status
															}
															onChange={() => {
																setFieldValue(
																	"status",
																	!values.status
																);
															}}
														/>
														<em></em>
														<span></span>
													</div>
												</div>
											</div>
										</div>
										<h4 style={{ marginTop: "2rem" }}>
											If disabled it will disable PDF
											LINK and automatically enable
											RICH TEXT PDF and vice versa
										</h4>
										<div className="btn_blk mt-5 pt-3">
											<button
												className="site_btn w-100"
												type="submit"
											>
												{isLoading ? (
													<ScreenLoader
														show={
															isLoading || loading
														}
													/>
												) : (
													handleHeading()
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
