import { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import NoImage from "../../../assets/images/noImage.png";
import { ArrowLeft, Lock } from "../../../icons";
import { IAddLawyer } from "../../../interfaces/Admin";
import { useGetJurisdictionQuery } from "../../../redux/services/Client/ClientDashboard";
import {
	useCreateFirmLawyersMutation,
	useGetAllFirmsQuery,
} from "../../../redux/services/Firm/FirmLawyer";
import { member_type } from "../../../utills/Const/const";
import { addFirmLawyerSchema } from "../../../utills/YupValidationSchemas";
import ErrorText from "../../Shared/ErrorText";
import ScreenLoader from "../../Shared/ScreenLoader";
import TextInput from "../../TextInput";
import "./AddFirmLawyer.scss";

const Avatar = lazy(() => import("../../Avatar"));

interface AddFirmLawyer {
	active: boolean;
	setActive: any;
	lawyer?: IAddLawyer;
	updateLawyer?: boolean;
	setUpdateLawyer?: any;
	newLawyer?: boolean;
	disable?: boolean;
}

export default function AddFirmLawyers(props: AddFirmLawyer) {
	let [data, setData] = useState<any>(null);
	const creationDate = new Date(
		props?.lawyer?.created_at || new Date()
	).toDateString();
	const updationDate = new Date(
		props?.lawyer?.updated_at || new Date()
	).toDateString();
	const [firmName, setFirmName] = useState<any>(
		props?.lawyer?.firm_name
			? { name: props?.lawyer?.firm_name, id: props.lawyer.firm_id }
			: null
	);
	const [firm, setFirm] = useState<any>(null);
	const fileInput = useRef<HTMLInputElement>(null);
	const item = localStorage.getItem("item");

	const [formImage, setFormImage] = useState<any>("");
	const [dp, setDp] = useState<any>(
		props?.updateLawyer ? props?.lawyer?.profile_image : ""
	);
	const [formImageError, setFormImageError] = useState<any>("");

	const [addFirmLawyer, { data: addFirmLawyerData, isSuccess, isLoading }] =
		useCreateFirmLawyersMutation();

	const { data: firms } = useGetAllFirmsQuery(null);

	const handleAddLawyer = (values: any) => {
		if (props.updateLawyer) {
			addFirmLawyer({
				...values,
				lawyer_id: props?.lawyer?.id,
				firm_id: props.newLawyer ? firmName?.id : firm.id,
				profile_image: formImage,
			});
		} else {
			addFirmLawyer({
				...values,
				profile_image: formImage,
				firm_id: props.newLawyer ? firmName?.id : props?.lawyer?.id,
			});
		}
	};
	useEffect(() => {
		if (
			addFirmLawyerData?.message === "Lawyer is updated successfully." ||
			addFirmLawyerData?.message === "Lawyer is created successfully."
		) {
			setDp(NoImage);
			resetForm();
			handleClose();
			toast.success(addFirmLawyerData?.message);
			props.setUpdateLawyer(true);
		} else if (addFirmLawyerData?.message === "Something wents wrong") {
			handleClose();
			toast.error("Something went wrong, Please try again");
		}
	}, [addFirmLawyerData]);

	const handleClose = () => {
		props.setActive();
	};

	const { data: jurisdiction } = useGetJurisdictionQuery(null);

	useEffect(() => {
		if (item) {
			setFirm(JSON?.parse(item || ""));
		}
		let payload: any = jurisdiction;
		var sortedJurisdictions = _.sortBy(payload?.states, "name");
		setData({ states: sortedJurisdictions });
	}, [jurisdiction, item]);

	const {
		values,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		touched,
		resetForm,
	} = useFormik({
		validationSchema: addFirmLawyerSchema,
		initialValues: {
			email: props.updateLawyer ? props?.lawyer?.email : "",
			firm_code: "",
			firm_id: props.lawyer?.id || "",
			password: "",
			password_confirmation: "",
			name: props.updateLawyer ? props?.lawyer?.name : "",
			profile_image: props.updateLawyer
				? props?.lawyer?.profile_image
				: "",
			member_type: props.updateLawyer ? props?.lawyer?.member_type : "",
			jurisdiction: props.updateLawyer ? props?.lawyer?.jurisdiction : "",
			phone: props.updateLawyer ? props?.lawyer?.phone : "",
			lawyer_url: props.updateLawyer ? props?.lawyer?.lawyer_url : "",
		},
		onSubmit: (values) => {
			handleAddLawyer(values);
		},
	});

	const handleFileUpload = (e: any) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}

		const file = e.target.files[0];

		if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
			return;
		}

		setFormImage(file);
		setFormImageError("");

		const reader = new FileReader();

		reader.addEventListener(
			"load",
			(e: ProgressEvent<FileReader>) => {
				if (!e?.target?.result) {
					return;
				}
				const result = reader.result as string;
				setDp(result);
			},
			false
		);

		reader.readAsDataURL(file);
	};

	useEffect(() => {
		if (
			props?.lawyer?.profile_image !== "" &&
			props?.updateLawyer === true
		) {
			setFormImage(props?.lawyer?.profile_image);
		}
	}, [props.lawyer]);

	const renderLoader = useCallback(() => <ScreenLoader show />, []);

	return (
		<section
			id="add_lawyer_popup"
			className={props.active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="inner_div">
									<button
										type="button"
										className="back_btn"
										onClick={props.setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">
										{!props.updateLawyer
											? "Add Lawyer"
											: props.disable
											? "Lawyer Details"
											: "Update Lawyer"}
									</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();

											if (formImage) {
												handleSubmit();
											} else {
												setFormImageError(
													"Image is Required"
												);
											}
										}}
									>
										<div className="ico fill round dp dp-image">
											<Suspense fallback={renderLoader()}>
												<Avatar
													url={
														props?.updateLawyer
															? props?.lawyer
																	?.profile_image
															: dp || NoImage
													}
												/>
											</Suspense>
											{!props.disable && (
												<div
													className="edit-icon"
													onClick={() =>
														fileInput?.current?.click()
													}
												>
													<CameraAltIcon className="dp-icon" />
												</div>
											)}
											<input
												ref={fileInput}
												type="file"
												hidden
												onChange={handleFileUpload}
											/>
										</div>
										<div className="text-center">
											<h5
												style={{
													color: "red",
												}}
											>
												{formImageError}
											</h5>
										</div>
										{/* ------------------------------------------------------------------------------------------------------------------
                                        Name And Email Row
------------------------------------------------------------------------------------------------------------------ */}

										<div
											className="row"
											style={{
												justifyContent: "center",
												marginTop: 25,
											}}
										>
											<div className="form_blk_container">
												<div className="input_field_div">
													<h5 className="fw_500 require">
														Name
													</h5>
													<div className="form_blk">
														<TextInput
															placeholder="eg: John Wick"
															disabled={
																props.disable
															}
															type="text"
															name="name"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.name}
														/>
													</div>
													<ErrorText
														text={errors.name}
														isTouched={touched.name}
													/>
												</div>
												<div className="input_field_div">
													<h5 className="fw_500 require">
														Email Address
													</h5>
													<div className="form_blk">
														<TextInput
															placeholder="eg: sample@gmail.com"
															disabled={
																props.disable
															}
															type="email"
															name="email"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.email}
														/>
													</div>
													<ErrorText
														text={errors.email}
														isTouched={
															touched.email
														}
													/>
												</div>
											</div>
											{/* ------------------------------------------------------------------------------------------------------------------
                                        Password And Confirm Password Row
------------------------------------------------------------------------------------------------------------------ */}
											<>
												{!props.disable ? (
													<div className="form_blk_container">
														<div className="input_field_div">
															<h5 className="fw_500 require ">
																Password
															</h5>
															<div className="form_blk">
																<Lock />
																<TextInput
																	disabled={
																		props.disable
																	}
																	type="password"
																	name="password"
																	handleChange={
																		handleChange
																	}
																	handleBlur={
																		handleBlur
																	}
																	value={
																		values.password
																	}
																	className="input"
																	placeholder="eg: PassLogin%7"
																/>
															</div>
															<ErrorText
																text={
																	errors.password
																}
																isTouched={
																	touched.password
																}
															/>
														</div>
														{!props.updateLawyer && (
															<div className="input_field_div">
																<h5 className="fw_500 require">
																	Confirm
																	Password
																</h5>
																<div className="form_blk">
																	<Lock />
																	<TextInput
																		type="password"
																		name="password_confirmation"
																		handleChange={
																			handleChange
																		}
																		handleBlur={
																			handleBlur
																		}
																		value={
																			values.password_confirmation
																		}
																		className="input"
																		placeholder="eg: PassLogin%7"
																	/>
																</div>
																<ErrorText
																	text={
																		errors.password_confirmation
																	}
																	isTouched={
																		touched.password_confirmation
																	}
																/>
															</div>
														)}
													</div>
												) : (
													<div className="form_blk_container">
														<div className="input_field_div">
															<h5 className="fw_500 require ">
																Created At
															</h5>
															<div className="form_blk">
																<TextInput
																	disabled={
																		props.disable
																	}
																	type="text"
																	value={
																		creationDate
																	}
																	className="input"
																	placeholder="22 Wed Feb 2023"
																/>
															</div>
														</div>
														<div className="input_field_div">
															<h5 className="fw_500 require">
																Updated At
															</h5>
															<div className="form_blk">
																<TextInput
																	disabled
																	type="text"
																	value={
																		updationDate
																	}
																	className="input"
																	placeholder="22 Wed Feb 2023"
																/>
															</div>
														</div>
													</div>
												)}
											</>

											{props.newLawyer && (
												<div
													style={{
														paddingLeft: "4.5rem",
														paddingRight: "4.5rem",
													}}
												>
													<h5 className="fw_500 require my-4">
														Firm
													</h5>
													<Select
														disabled={props.disable}
														value={
															firmName?.name
																? firmName?.name
																: ""
														}
														onChange={(
															event: SelectChangeEvent
														) => {
															setFirmName(
																event.target
																	?.value
															);
														}}
														style={{
															width: "100%",
															lineHeight: "3rem",
														}}
														displayEmpty
														renderValue={() =>
															firmName?.name
																? firmName?.name
																: "Select Firm"
														}
													>
														{firms &&
															firms.map(
																(item: any) => (
																	<MenuItem
																		key={
																			item.id
																		}
																		value={
																			item
																		}
																		style={{
																			fontSize: 17,
																			alignSelf:
																				"center",
																		}}
																	>
																		{
																			item.name
																		}
																	</MenuItem>
																)
															)}
													</Select>
													<ErrorText
														text={
															errors.jurisdiction
														}
														isTouched={
															touched.jurisdiction
														}
													/>
												</div>
											)}

											<div className="form_blk_container my-4">
												<div className="col-12">
													<h5 className="fw_500 require">
														Lawyer Url
													</h5>
													<div className="form_blk">
														<TextInput
															disabled={
																props.disable
															}
															type="text"
															name="lawyer_url"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={
																values.lawyer_url
															}
															className="input"
															placeholder="http://"
														/>
													</div>
												</div>
											</div>
											{/* ------------------------------------------------------------------------------------------------------------------
                                            Phone Number
------------------------------------------------------------------------------------------------------------------ */}
											<div className="form_blk_container">
												<div className="col-12">
													<h5 className="fw_500 require my-4">
														Phone
													</h5>
													<div className="form_blk">
														<PhoneInput
															disabled={
																props.disable
															}
															autoFormat={true}
															country={"us"}
															value={values.phone}
															onChange={(
																value
															) => {
																setFieldValue(
																	"phone",
																	value
																);
															}}
														/>
														<ErrorText
															text={errors.phone}
															isTouched={
																touched.phone
															}
														/>
													</div>
												</div>
											</div>

											{/* ------------------------------------------------------------------------------------------------------------------
                                            Jurisdiction
------------------------------------------------------------------------------------------------------------------ */}

											<div className="form_blk_container">
												<div className="input_field_div">
													<h5 className="fw_500 require my-4">
														Jurisdiction
													</h5>
													<Select
														disabled={props.disable}
														value={
															values.jurisdiction
														}
														onChange={(
															event: SelectChangeEvent
														) => {
															setFieldValue(
																"jurisdiction",
																event.target
																	.value
															);
														}}
														style={{
															width: "100%",
															lineHeight: "3rem",
														}}
														displayEmpty
														renderValue={() =>
															values?.jurisdiction
																? values.jurisdiction
																: "Select Jurisdiction"
														}
													>
														{data?.states &&
															data?.states?.map(
																(item: any) => (
																	<MenuItem
																		key={
																			item.id
																		}
																		value={
																			item.name
																		}
																		style={{
																			fontSize: 17,
																			alignSelf:
																				"center",
																		}}
																	>
																		{
																			item.name
																		}
																	</MenuItem>
																)
															)}
													</Select>
													<ErrorText
														text={
															errors.jurisdiction
														}
														isTouched={
															touched.jurisdiction
														}
													/>
												</div>
												<div className="input_field_div">
													<h5 className="fw_500 require my-4">
														Member Type
													</h5>
													<div className="form_blk">
														<Select
															disabled={
																props.disable
															}
															value={
																values.member_type
															}
															onChange={(
																event: SelectChangeEvent
															) => {
																setFieldValue(
																	"member_type",
																	event.target
																		.value
																);
															}}
															style={{
																width: "100%",
																lineHeight:
																	"3rem",
															}}
															displayEmpty
															renderValue={() =>
																values?.member_type
																	? values.member_type
																	: "Select Member Type"
															}
														>
															{member_type?.map(
																(item: any) => (
																	<MenuItem
																		key={
																			item.id
																		}
																		value={
																			item.memberType
																		}
																		style={{
																			fontSize: 17,
																			alignSelf:
																				"center",
																		}}
																	>
																		{
																			item.memberType
																		}
																	</MenuItem>
																)
															)}
														</Select>
														<ErrorText
															text={
																errors.member_type
															}
															isTouched={
																touched.member_type
															}
														/>
													</div>
												</div>
											</div>
										</div>
										{!props.disable && (
											<div className="btn_blk mt-5 pt-3">
												<button
													className="site_btn w-100"
													type="submit"
												>
													{isLoading ? (
														<ScreenLoader
															show={isLoading}
														/>
													) : props.updateLawyer ? (
														"Update Lawyer"
													) : (
														"Add Lawyer"
													)}
												</button>
											</div>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
