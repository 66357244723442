import { Button, Image, Modal } from "react-bootstrap";
import ELALOGO from "../../assets/images/elaLogo.png";

const DeleteConfirmation = ({
	showModal,
	hideModal,
	message,
	handleDelete,
}: any) => {
	return (
		<Modal show={showModal} onHide={hideModal} centered>
			<Image src={ELALOGO} style={{ height: "8rem", width: "8rem" , alignSelf:"center", marginTop:"1rem"}} />
			<Modal.Header>
				<Modal.Title className='text-center w-100'>
					Delete Confirmation
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="alert alert-danger">{message}</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="default"
					onClick={hideModal}
					style={{ fontSize: "2rem" }}
				>
					Cancel
				</Button>
				<Button
					variant="danger"
					onClick={() => handleDelete(true)}
					style={{ fontSize: "2rem" }}
				>
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteConfirmation;
