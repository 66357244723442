import { RootState, useAppSelector } from "../../store";

export const useGetRole = () => {
	const role = useAppSelector(
		(state: RootState) => state.authReducer.userRole
	);
	return role;
};

export const GetLoginUserData = () => {
	const data = useAppSelector(
		(state: RootState) => state.authReducer.data
	);
	return data;
};
