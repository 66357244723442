const PdfIcon = ({
	width = 40,
	height = 40,
}: {
	width?: number;
	height?: number;
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 48 65"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M47.9958 44.648V14.5919C47.9968 14.1665 47.9137 13.745 47.7512 13.352C47.5887 12.959 47.3502 12.6022 47.0493 12.3022L35.7301 0.949215C35.4311 0.647399 35.0753 0.408091 34.6833 0.245156C34.2914 0.0822209 33.8711 -0.00110316 33.4468 1.10279e-05H3.22781C2.37208 0.00111261 1.55171 0.342492 0.946619 0.949284C0.341524 1.55608 0.0010985 2.37875 0 3.23688V44.648C15.9409 46.575 32.0549 46.575 47.9958 44.648Z"
				fill="#DCDFE1"
			/>
			<path
				d="M0 44.6484V60.8349C0.0010985 61.693 0.341524 62.5157 0.946619 63.1225C1.55171 63.7293 2.37209 64.0706 3.22782 64.0717H44.7701C45.6258 64.0706 46.4462 63.7293 47.0513 63.1225C47.6564 62.5157 47.9968 61.693 47.9979 60.8349V44.6484H0Z"
				fill="#114D96"
			/>
			<path
				d="M47.055 12.3009L35.7338 0.945864C35.2608 0.472264 34.6517 0.15923 33.9922 0.0507812V11.002C33.9933 11.8094 34.3136 12.5833 34.8829 13.1542C35.4522 13.7251 36.2239 14.0463 37.029 14.0474H47.9496C47.8406 13.3858 47.5278 12.7751 47.055 12.3009Z"
				fill="#ABB2B8"
			/>
			<path
				d="M15.5469 48.6367H12.4332C12.1983 48.6373 11.9732 48.7311 11.8071 48.8977C11.641 49.0642 11.5474 49.29 11.5469 49.5256V59.2299C11.5469 59.4659 11.6404 59.6923 11.8068 59.8592C11.9732 60.0261 12.1989 60.1198 12.4343 60.1198C12.6696 60.1198 12.8953 60.0261 13.0617 59.8592C13.2282 59.6923 13.3216 59.4659 13.3216 59.2299V56.1742H15.5386C16.3093 56.1742 17.0485 55.8671 17.5935 55.3206C18.1385 54.7741 18.4446 54.0328 18.4446 53.2599V51.5405C18.4419 50.7709 18.1357 50.0336 17.5928 49.4895C17.0499 48.9455 16.3144 48.6389 15.5469 48.6367ZM16.674 53.2662C16.6735 53.5658 16.5545 53.853 16.3433 54.0648C16.132 54.2767 15.8456 54.3959 15.5469 54.3965H13.3279V50.4165H15.5448C15.8436 50.417 16.1299 50.5363 16.3412 50.7482C16.5525 50.96 16.6714 51.2472 16.6719 51.5468L16.674 53.2662Z"
				fill="white"
			/>
			<path
				d="M24.2456 60.1227H21.3395C21.1046 60.1221 20.8794 60.0283 20.7133 59.8617C20.5472 59.6951 20.4537 59.4694 20.4531 59.2338V49.5295C20.4537 49.2939 20.5472 49.0682 20.7133 48.9016C20.8794 48.735 21.1046 48.6412 21.3395 48.6406H24.2456C25.0713 48.6406 25.8633 48.9696 26.4472 49.5552C27.0311 50.1407 27.3592 50.9349 27.3592 51.763V57.0107C27.3565 57.837 27.0272 58.6285 26.4436 59.2118C25.8599 59.7951 25.0695 60.1227 24.2456 60.1227ZM22.2362 58.3429H24.2476C24.6014 58.3424 24.9406 58.2012 25.1908 57.9503C25.441 57.6994 25.5818 57.3593 25.5823 57.0045V51.7568C25.5818 51.402 25.441 51.0618 25.1908 50.811C24.9406 50.5601 24.6014 50.4189 24.2476 50.4183H22.2362V58.3429Z"
				fill="white"
			/>
			<path
				d="M35.5562 48.6367H30.4353C30.2001 48.6367 29.9744 48.7303 29.8078 48.8969C29.6413 49.0636 29.5474 49.2896 29.5469 49.5256V59.2299C29.5469 59.4659 29.6404 59.6923 29.8068 59.8592C29.9732 60.0261 30.1989 60.1198 30.4343 60.1198C30.6696 60.1198 30.8953 60.0261 31.0618 59.8592C31.2282 59.6923 31.3217 59.4659 31.3217 59.2299V55.2749H33.7877C34.0156 55.2639 34.2305 55.1653 34.3879 54.9996C34.5453 54.8339 34.6332 54.6139 34.6332 54.385C34.6332 54.1562 34.5453 53.9361 34.3879 53.7705C34.2305 53.6048 34.0156 53.5062 33.7877 53.4951H31.3217V50.4227H35.5541C35.7821 50.4117 35.997 50.3131 36.1544 50.1474C36.3118 49.9817 36.3996 49.7617 36.3996 49.5328C36.3996 49.304 36.3118 49.084 36.1544 48.9183C35.997 48.7526 35.7821 48.654 35.5541 48.643L35.5562 48.6367Z"
				fill="white"
			/>
		</svg>
	);
};

export default PdfIcon;
