import { useLocation } from "react-router-dom";
import Location from "../../../assets/images/Location.svg";
import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";

const EventDetails = () => {
  const location = useLocation()
  const eventDetails: any = location.state
  return (
    <Layout>
      <section id="eventDetails" className="sec_pad_btm">
        <div className="contain sm">
          <TopBlock title="Event Details" url="events" />
          <div className="details_blk">
            <div className="bg_blk">
              <img src={eventDetails?.eventDetails?.news_image} alt="" />
            </div>
            <div className="firm-event-info">
              <div className="event-by">
                <h3 className="fw_600">{eventDetails?.eventDetails?.name}</h3>
                <h5 className="mb-0 fw_400">by Johni Khaba</h5>
              </div>
              <div className="info">
                <h5 className="fw_500">Location</h5>
                <div className="location">
                  <div className="location_icon">
                    <img src={Location} alt="" />
                  </div>
                  {eventDetails?.eventDetails?.location}
                </div>
              </div>
            </div>
            <div className="firm-event-details">
              <h3 className="ctn-main-heading">Event Details</h3>
              <p>
                {eventDetails?.eventDetails?.description}
              </p>
            </div>

            <a href={eventDetails?.eventDetails?.link} className="site_btn register_btn">Register</a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EventDetails;
