import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import importIcon from "../../../assets/images/import.svg";
import NoImage from "../../../assets/images/noImage.png";
import plus from "../../../assets/images/plus.svg";
import search from "../../../assets/images/search.svg";
import DeleteConfirmation from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import LawyerDirectoryCard from "../../../components/LawyerDirectoryCard";
import Layout from "../../../components/Layout";
import AddFirmLawyers from "../../../components/Popup/AddFirmLawyer/AddFirmLawyer";
import ImportFromCSV from "../../../components/Popup/ImportFirmLawyers/ImportFromCsv";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import {
	useDeleteFirmLawyerMutation,
	useGetFirmInfoQuery,
	useGetFirmSearchMutation,
} from "../../../redux/services/Firm/FirmLawyer";

const RegisteredFirmsInfo = () => {
	const [addLawyer, setAddLawyer] = useState(false);
	const [importLawyers, setImportLawyers] = useState(false);
	const [deleteLawyer, setDeleteLawyer] = useState(false);
	const [lawyerUpdated, setLawyerUpdated] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [deleteLawyers, setDeleteLawyers] = useState(false);
	const [lawyers, setLawyers] = useState<any>([]);
	const [searchText, setSearchText] = useState<any | null>("");
	const item = localStorage.getItem("item");
	const firm = JSON.parse(item || "");
	const timestampRef = useRef(Date.now()).current;
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);

	const [searching, { data: searchData, isLoading: searchLoading }] =
		useGetFirmSearchMutation();

	const [deleteFirmLawyer, { data, isSuccess, isError }] =
		useDeleteFirmLawyerMutation();

	const {
		data: firmDetail,
		isLoading,
		refetch,
	} = useGetFirmInfoQuery({
		id: firm.id,
		sessionId: timestampRef,
		active: addLawyer,
	});

	useEffect(() => {
		refetch();
		setLawyers(firmDetail?.lawyers);
		setDeletePopUp(false);
	}, [addLawyer, deleteLawyer, lawyerUpdated]);

	const handleAddLawyer = () => {
		setAddLawyer(!addLawyer);
	};

	const handleImportLawyers = () => {
		setImportLawyers(!importLawyers);
	};

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searching({ search, lawyer_search: true, firm_id: firm.id });
			} else {
				setLawyers(firmDetail?.lawyers);
			}
		}, 1000);
	};
	useEffect(() => {
		if (searchData?.lawyers && searchText !== "") {
			setLawyers(searchData?.lawyers);
		} else {
			setLawyers(firmDetail?.lawyers);
		}
	}, [searchData, firmDetail, addLawyer, searchText]);

	const handleDelete = () => {
		setDeleteLawyer(!deleteLawyer);
	};

	useEffect(() => {
		if (selectedItems?.length === firmDetail?.lawyers?.length) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [selectedItems]);

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(lawyers?.map((item: { id: any }) => item.id));
		}
		setSelectAll(!selectAll);
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	useEffect(() => {
		if (deleteLawyers) {
			deleteFirmLawyer(selectedItems);
			setDeleteLawyers(false);
		}
		if (isSuccess) {
			toast.success("Firms Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
			setDeletePopUp(false);
		}
		if (isError) {
			toast.error(
				"Could not delete firms at the moment, Please try again"
			);
		}
	}, [deleteLawyers, isSuccess, isError]);

	return (
		<Layout>
			<ScreenLoader show={isLoading || searchLoading} />
			<section id="manage_registered_firms_info">
				<div
					style={{
						maxWidth: "180rem",
						padding: "0rem 2rem",
						margin: "0 auto",
					}}
				>
					<div className="top_blk mb-5">
						<TopBlock
							title="Firm Details"
							url="registered-firms"
							state={{ lawyerManage: true }}
						/>
					</div>
					<div className="firm_detail_container">
						<img
							style={{ width: "15%" }}
							src={firm?.profile_image || NoImage}
						/>
						<div className="firm_info_container">
							<div className="txt">
								<h5>
									<span>Name: </span>
									{firm?.name}
								</h5>
								<p>
									<span style={{ fontWeight: 600 }}>
										Phone no:{" "}
									</span>{" "}
									{firm?.phone}
								</p>
								<p>
									<span style={{ fontWeight: 600 }}>
										Email:{" "}
									</span>
									{firm?.email}
								</p>
								<p>
									<span style={{ fontWeight: 600 }}>
										Firm:{" "}
									</span>
									<a href={firm.firm_url} target="_blank">
										{firm?.firm_url}
									</a>
								</p>
								{firm?.referral_email && <p>
									<span style={{ fontWeight: 600 }}>
										Firm Referral CC Email:{" "}
									</span>
									{firm?.referral_email}
								</p>}
							</div>
						</div>
					</div>

					<div className="ytd_blk mt-5 pt-3">
						<div className="firm_lawyer_container">
							<h3 className="fw_50 mb-5">Lawyers</h3>
							<div className="btn_container">
								<button
									className="site_btn"
									style={{ marginRight: "2rem" }}
									onClick={handleImportLawyers}
								>
									Import Lawyers
									<img src={importIcon} alt="icon" />
								</button>
								<button
									className="site_btn"
									onClick={handleAddLawyer}
								>
									Add Lawyer
									<img src={plus} alt="icon" />
								</button>
							</div>
						</div>
						<div
							className="search_container"
							style={{
								justifyContent: "space-between",
								backgroundColor: "#63a744",
								alignItems: "center",
								padding: "1rem",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginLeft: "4rem",
								}}
							>
								<Checkbox
									checked={selectAll}
									classes={{ root: "custom-checkbox-root" }}
									onClick={() => {
										handleSelectAllChange();
									}}
								/>
								<p
									style={{
										fontSize: "2rem",
										lineHeight: "7rem",
									}}
								>
									Select All
								</p>
							</div>
							<div style={{ display: "flex" }}>
								{selectedItems?.length > 0 && (
									<DeleteIcon
										fontSize="large"
										sx={{
											color: "red",
											height: "2em",
											width: "5em",
										}}
										style={{
											marginTop: "1rem",
											marginRight: "2rem",
										}}
										onClick={() => {
											handleBulkDelete();
										}}
									/>
								)}
								<div className="search_blk_lawyers">
									<div className="img_blk">
										<img src={search} alt="search icon" />
									</div>
									<input
										type="search"
										placeholder="Search"
										value={searchText}
										style={{ marginTop: "0.5rem" }}
										onChange={(e) => {
											handleSearch(e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
						<div
							style={{
								overflowY:
									lawyers?.length > 0 ? "auto" : "unset",
								marginTop: "3rem",
							}}
						>
							{lawyers?.length > 0 ? (
								lawyers?.map((info: any) => {
									return (
										<LawyerDirectoryCard
											item={info}
											firm={true}
											key={info.id}
											checkbox={true}
											setDelete={handleDelete}
											setUpdate={setLawyerUpdated}
											isSelected={selectedItems.includes(
												info?.id
											)}
											onSelect={handleItemSelect}
										/>
									);
								})
							) : (
								<p
									style={{
										textAlign: "center",
										fontSize: "3rem",
										marginTop: "3rem",
									}}
								>
									Lawyers not found
								</p>
							)}
						</div>
					</div>
				</div>
			</section>
			<AddFirmLawyers
				active={addLawyer}
				setActive={handleAddLawyer}
				lawyer={firm}
				updateLawyer={false}
				setUpdateLawyer={setLawyerUpdated}
			/>
			<ImportFromCSV
				active={importLawyers}
				setActive={handleImportLawyers}
				toImport="lawyers"
				firm_id={firm.id}
				refetch={setLawyerUpdated}
			/>
			<DeleteConfirmation
				showModal={deletePopUp}
				message={
					"Are you sure you want to delete the selected Lawyers?"
				}
				handleDelete={setDeleteLawyers}
				hideModal={handleBulkDelete}
			/>
		</Layout>
	);
};

export default RegisteredFirmsInfo;
