import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Toast from './components/Shared/Toast';
import {
	AdminRoutes,
	ClientRoutes,
	LawyerRoutes,
	PublicRoutes,
} from './routes';
import { PrivateRoute } from './routes/Guard/PrivateRoute';
import { PublicRoute } from './routes/Guard/PublicRoute';
import { UserRole } from './utills/Const/const';
function App() {
	console.log('app.js file logged::');
	
	return (
		<BrowserRouter>
			<Routes>
				{AdminRoutes.map(({ component, path }, index) => (
					<Route
						key={index}
						path={`/firm/${path}`}
						element={
							<PrivateRoute role={UserRole.FIRM}>
								{component}
							</PrivateRoute>
						}
					></Route>
				))}
				{LawyerRoutes.map(({ component, path }, index) => (
					<Route
						key={index}
						path={`/lawyer/${path}`}
						element={
							<PrivateRoute role={UserRole.LAWYER}>
								{component}
							</PrivateRoute>
						}
					></Route>
				))}

				{ClientRoutes.map(({ component, path }, index) => (
					<Route
						key={index}
						path={`/client/${path}`}
						element={
							<PrivateRoute role={UserRole.CLIENT}>
								{component}
							</PrivateRoute>
						}
					></Route>
				))}
				{PublicRoutes.map(({ component, path }, index) => (
					<Route
						key={index}
						path={`/${path}`}
						element={<PublicRoute>{component}</PublicRoute>}
					></Route>
				))}

				<Route path='*' element={<Navigate to={`/home`} replace />} />
			</Routes>
			<Toast />
		</BrowserRouter>
	);
}

export default App;
