//Public Routes
import Home from '../containers/Public/HomePage';
import Login from '../containers/Public/Login';
import DeleteAccount from '../containers/Public/DeleteAccount';
// Admin Side
import AdminDashboard from '../containers/Admin/Dashboard';
import AdminEventDetails from '../containers/Admin/EventDetails';
import AdminEvents from '../containers/Admin/Events';
import AdminFirmLawyers from '../containers/Admin/FirmLawyers';
import AdminForgotPass from '../containers/Admin/ForgotPassword';
import AdminManageLawyers from '../containers/Admin/ManageLawyers';
import AdminMediaLibrary from '../containers/Admin/MediaLibrary';
import AdminNewsArticle from '../containers/Admin/NewsArticle';
import AdminNewsArticles from '../containers/Admin/NewsArticles';
import AdminNotifications from '../containers/Admin/Notifications';
import AdminReferals from '../containers/Admin/Referals';
import AdminResetPass from '../containers/Admin/ResetPassword';
import AdminResources from '../containers/Admin/Resources';
import AdminSettings from '../containers/Admin/Settings';
import AdminSignUp from '../containers/Admin/SignUp';
import AdminVerification from '../containers/Admin/VerificationCode';
import AdminWebinar from '../containers/Admin/Webinar';
// import AdminMediaSearch from "../containers/Admin/MediaSearch";

// Lawyers Side
import LawyersDashboard from '../containers/Lawyers/Dashboard';
import LawyersFirmLawyers from '../containers/Lawyers/FirmLawyers';
import LawyersForgotPass from '../containers/Lawyers/ForgotPassword';
import {
	default as LawyerDirectory,
	default as LawyersManageLawyers,
} from '../containers/Lawyers/LawyerDirectory';
import LawyersMediaSearch from '../containers/Lawyers/MediaSearch';
import LawyersResetPass from '../containers/Lawyers/ResetPassword';
import LawyersVerification from '../containers/Lawyers/VerificationCode';
import MyContacts from '../containers/Lawyers/MyContacts';

// Client Side
import AllMediaSearch from '../containers/Admin/AllMediaSearch';
import ClientDashboard from '../containers/Client/Dashboard';
import Directory from '../containers/Client/Directory';
import ClientFirmLawyers from '../containers/Client/FirmLawyers';
import ClientForgotPass from '../containers/Client/ForgotPassword';
import ClientHandbook from '../containers/Client/Handbook';
import ClientMediaSearch from '../containers/Client/MediaSearch';
import ClientReferals from '../containers/Client/Referals';
import ClientResetPass from '../containers/Client/ResetPassword';
import ClientSignUp from '../containers/Client/SignUp';
import ClientVerification from '../containers/Client/VerificationCode';
import RegisteredFirms from '../containers/Admin/RegistredFirms';
import RegisteredFirmsInfo from '../containers/Admin/RegisteredFirmsInfo';
import SuperAdmin from '../containers/Admin/SuperAdmin';
import MediaLibraryHandbooks from '../containers/Admin/SuperAdmin/MediaLibrary/Handbooks';
import MediaLibraryPodcasts from '../containers/Admin/SuperAdmin/MediaLibrary/Podcasts';
import MediaLibraryEvents from '../containers/Admin/SuperAdmin/MediaLibrary/Events';
import MediaLibraryNews from '../containers/Admin/SuperAdmin/MediaLibrary/News';
import MediaLibraryWebinars from '../containers/Admin/SuperAdmin/MediaLibrary/Webinars';
import AddNewHandbook from '../containers/Admin/SuperAdmin/MediaLibrary/AddNewHandbook';
import AddClient from '../containers/Admin/SuperAdmin/AddClient';
import AddJurisdiction from '../containers/Admin/SuperAdmin/AddJurisdiction/AddJurisdiction';
import ManageLawyers from '../containers/Admin/SuperAdmin/ManageLawyers';
import { PrivacyPolicy } from '../containers/Public/PrivacyPolicy/privacypolicy';
import Support from '../containers/Public/Support/support';
import Marketing from '../containers/Public/Marketing/marketing';
import ManageReferrals from '../containers/Admin/SuperAdmin/ManageReferrals/ManageReferrals';
import ManageMessages from '../containers/Admin/SuperAdmin/ManageMessages/ManageMessages';
import Chat from '../containers/Lawyers/Chat';

export const AdminRoutes = [
	// Admin Paths
	{
		path: 'dashboard',
		component: <AdminDashboard />,
	},
	{
		path: 'lawyers',
		component: <AdminManageLawyers />,
	},
	{
		path: 'referals',
		component: <AdminReferals />,
	},
	{
		path: 'firm-lawyer',
		component: <AdminFirmLawyers />,
	},
	{
		path: 'resources',
		component: <AdminResources />,
	},
	{
		path: 'media-library',
		component: <AdminMediaLibrary />,
	},
	{
		path: 'notifications',
		component: <AdminNotifications />,
	},
	{
		path: 'events',
		component: <AdminEvents />,
	},
	{
		path: 'news',
		component: <AdminNewsArticles />,
	},
	{
		path: 'webinar',
		component: <AdminWebinar />,
	},
	{
		path: 'settings',
		component: <AdminSettings />,
	},
	{
		path: 'event-details',
		component: <AdminEventDetails />,
	},
	{
		path: 'news-details',
		component: <AdminNewsArticle />,
	},
	{
		path: 'all-media',
		component: <AllMediaSearch />,
	},
	{
		path: 'registered-firms',
		component: <RegisteredFirms />,
	},
	{
		path: 'super-admin',
		component: <SuperAdmin />,
	},
	{
		path: 'super-admin-media-library-handbooks',
		component: <MediaLibraryHandbooks />,
	},
	{
		path: 'super-admin-media-library-podcasts',
		component: <MediaLibraryPodcasts />,
	},
	{
		path: 'super-admin-media-library-events',
		component: <MediaLibraryEvents />,
	},
	{
		path: 'super-admin-media-library-news',
		component: <MediaLibraryNews />,
	},
	{
		path: 'super-admin-media-library-webinars',
		component: <MediaLibraryWebinars />,
	},
	{
		path: 'firms-detail',
		component: <RegisteredFirmsInfo />,
	},
	{
		path: 'create-handbook',
		component: <AddNewHandbook />,
	},
	{
		path: 'super-admin-manage-client',
		component: <AddClient />,
	},
	{
		path: 'super-admin-manage-lawyers',
		component: <ManageLawyers />,
	},
	{
		path: 'super-admin-jurisdictions',
		component: <AddJurisdiction />,
	},
	{
		path: 'super-admin-manage-referrals',
		component: <ManageReferrals />,
	},
	{
		path: 'super-admin-manage-messages',
		component: <ManageMessages />,
	},
	{
		path: 'super-admin-manage-contacts',
		component: <MyContacts />,
	},
	{
		path: 'ela-lawyer-directory',
		component: <LawyerDirectory />,
	},
	// {
	// 	path: "media-search",
	// 	component: <AdminMediaSearch />,
	// },
];

// Lawyers Paths
export const LawyerRoutes = [
	{
		path: 'forgot-password',
		component: <LawyersForgotPass />,
	},
	{
		path: 'verification',
		component: <LawyersVerification />,
	},
	{
		path: 'reset-password',
		component: <LawyersResetPass />,
	},
	{
		path: 'dashboard',
		component: <LawyersDashboard />,
	},
	{
		path: 'lawyers',
		component: <LawyersManageLawyers />,
	},
	{
		path: 'referals',
		component: <AdminReferals />,
	},
	{
		path: 'firm-lawyer',
		component: <LawyersFirmLawyers />,
	},
	{
		path: 'resources',
		component: <AdminResources />,
	},
	{
		path: 'media-library',
		component: <AdminMediaLibrary />,
	},
	{
		path: 'notifications',
		component: <AdminNotifications />,
	},
	{
		path: 'events',
		component: <AdminEvents />,
	},
	{
		path: 'news',
		component: <AdminNewsArticles />,
	},
	{
		path: 'webinar',
		component: <AdminWebinar />,
	},
	{
		path: 'settings',
		component: <AdminSettings />,
	},
	{
		path: 'event-details',
		component: <AdminEventDetails />,
	},
	{
		path: 'news-details',
		component: <AdminNewsArticle />,
	},
	{
		path: 'media-search',
		component: <LawyersMediaSearch />,
	},
	{
		path: 'lawyer-directory',
		component: <LawyerDirectory />,
	},
	{
		path: 'all-media',
		component: <AllMediaSearch />,
	},
	{
		path: 'contacts',
		component: <MyContacts />,
	},
	//added
	{
		path: 'chat',
		component: <Chat />,
	},
];

// Client Paths
export const ClientRoutes = [
	{
		path: 'handbook',
		component: <ClientHandbook />,
	},
	{
		path: 'signup',
		component: <ClientSignUp />,
	},
	{
		path: 'forgot-password',
		component: <ClientForgotPass />,
	},
	{
		path: 'verification',
		component: <ClientVerification />,
	},
	{
		path: 'reset-password',
		component: <ClientResetPass />,
	},
	{
		path: 'dashboard',
		component: <ClientDashboard />,
	},
	// {
	// 	path: "lawyers",
	// 	component: <ClientManageLawyers />,
	// },
	{
		path: 'referals',
		component: <ClientReferals />,
	},
	{
		path: 'firm-lawyer',
		component: <ClientFirmLawyers />,
	},
	{
		path: 'resources',
		component: <AdminResources />,
	},
	{
		path: 'media-library',
		component: <AdminMediaLibrary />,
	},
	{
		path: 'notifications',
		component: <AdminNotifications />,
	},
	{
		path: 'events',
		component: <AdminEvents />,
	},
	{
		path: 'news',
		component: <AdminNewsArticles />,
	},
	{
		path: 'webinar',
		component: <AdminWebinar />,
	},
	{
		path: 'settings',
		component: <AdminSettings />,
	},
	{
		path: 'event-details',
		component: <AdminEventDetails />,
	},
	{
		path: 'news-details',
		component: <AdminNewsArticle />,
	},
	{
		path: 'media-search',
		component: <ClientMediaSearch />,
	},
	{
		path: 'directory',
		component: <Directory />,
	},
	{
		path: 'all-media',
		component: <AllMediaSearch />,
	},
];

//Public Paths
export const PublicRoutes = [
	{
		path: 'home',
		component: <Home />,
	},
	{
		path: 'privacy-policy',
		component: <PrivacyPolicy />,
	},
	{
		path: 'support',
		component: <Support />,
	},
	{
		path: 'marketing',
		component: <Marketing />,
	},
	{
		path: 'login',
		component: <Login />,
	},
	{
		path: 'signup',
		component: <AdminSignUp />,
	},
	{
		path: 'forgot-password',
		component: <AdminForgotPass />,
	},
	{
		path: 'verification',
		component: <AdminVerification />,
	},
	{
		path: 'reset-password',
		component: <AdminResetPass />,
	},
	{
		path: 'delete-account',
		component: <DeleteAccount />,
	},
];
