import { ILawyerDashboard } from "../../../../interfaces/Lawyer";

export enum LawyerDashboardActionTypes {
	SET_LAWYER_DASHBOARD_DATA = "SET_LAWYER_DASHBOARD_DATA",
	RESET_LAWYER_DASHBOARD_DATA = "RESET_LAWYER_DASHBOARD_DATA",
}

export const setDashboardData = (payload: ILawyerDashboard) => {
	return {
		type: LawyerDashboardActionTypes.SET_LAWYER_DASHBOARD_DATA,
		payload,
	};
};

export const resetDashboardData = () => {
	return {
		type: LawyerDashboardActionTypes.RESET_LAWYER_DASHBOARD_DATA,
	};
};
