import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ArrowLeft } from "../../../icons";
import { useCreateNewJurisdictionMutation } from "../../../redux/services/Firm/FirmLawyer";
import { addFirmLawyerSchema } from "../../../utills/YupValidationSchemas";
import ScreenLoader from "../../Shared/ScreenLoader";
import TextInput from "../../TextInput";
import "../AddNewClient/AddNewClient.scss";


interface AddJurisdiction {
    active: boolean,
    setActive: any,
    isUpdate: boolean,
    jurisdiction?: any,
    setRefetch: any,
}

export default function AddNewJurisdiction(props: AddJurisdiction) {

    const [addJurisdiction, { data: addNewJurisdiction, isSuccess, isError, isLoading }] =
        useCreateNewJurisdictionMutation();


    const handleAddJurisdiction = (values: any) => {
        if (props.isUpdate) {
            addJurisdiction({ ...values, jurisdiction_id: props?.jurisdiction?.id })
        } else {
            addJurisdiction(values);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            handleClose();
            props.setRefetch();
            if (isSuccess && props.isUpdate) {
                toast.success('Jurisdiction Updated Successfully')
            } else if (isSuccess && !props.isUpdate) {
                toast.success('Jurisdiction created Successfully')
            } else {
                toast.success(addNewJurisdiction?.message)
            }
        }
        else if (isError && !props.isUpdate) {
            toast.error('Error! Jurisdiction not created Successfully, Please try Again')
        } else if (isError && props.isUpdate) {
            toast.error('Error! Could not update the jurisdiction at the moment, Please try Again')

        }
    }, [isSuccess, isError])

    const handleClose = () => {
        props.setActive();
    };


    const initialValues = {
        name: props?.jurisdiction?.name ? props?.jurisdiction.name : '',
        status: props?.jurisdiction?.status ? props?.jurisdiction?.status : false,
    }

    const {
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        resetForm
    } = useFormik({
        initialValues,
        onSubmit: (values) => {
            handleAddJurisdiction(values);
        },
    });

    const handleHeading = () => {
        if (props.isUpdate) {
            return 'Update Jurisdiction'
        } else {
            return 'Create Jurisdiction'
        }
    }

    return (
        <section
            id="add_lawyer_popup"
            className={props.active ? "popup active" : "popup"}
        >
            <div className="overlay" onClick={handleClose}></div>
            <div className="table_dv">
                <div className="table_cell">
                    <div className="contain">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8 col-sm-10">
                                <div className="inner_div">
                                    <button
                                        type="button"
                                        className="back_btn"
                                        onClick={props.setActive}
                                    >
                                        <ArrowLeft />
                                    </button>
                                    <h2 className="text-center">{handleHeading()}</h2>

                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}
                                    >

                                        {/* ------------------------------------------------------------------------------------------------------------------
                                        Name Row
------------------------------------------------------------------------------------------------------------------ */}

                                        <div className="row" style={{ justifyContent: 'center', marginTop: 25 }}>
                                            <div className="form_blk_container">
                                                <div style={{ width: "100%" }}>
                                                    <h5 className="fw_500 require">
                                                        Name
                                                    </h5>
                                                    <TextInput
                                                        placeholder="eg: Arizona"
                                                        type="text"
                                                        name="name"
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        handleBlur={handleBlur}
                                                        value={values.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-11">
                                                <h5 className="fw_500 require my-4">
                                                    Status
                                                </h5>
                                                <div className="form_blk" style={{ marginBottom: 0 }}>
                                                    <div className="switch switch2">
                                                        <input
                                                            type="checkbox"
                                                            name=""
                                                            checked={values.status}
                                                            onChange={() => {
                                                                setFieldValue('status', !values.status)
                                                            }}
                                                        />
                                                        <em></em>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn_blk mt-5 pt-3">
                                            <button
                                                className="site_btn w-100"
                                                type="submit"
                                            >
                                                {isLoading ? <ScreenLoader show={isLoading} /> : handleHeading()}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
