import { useState, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import EmojiPicker from "emoji-picker-react";

function Popover({ setNewMessage }) {
	const [show, setShow] = useState(false);
	const target = useRef(null);

	const handleEmojiClick = (emoji) => {
		setShow(false);
		setNewMessage((prevMessage) => prevMessage + emoji.emoji);
	};

	return (
		<>
			<span
				ref={target}
				onClick={() => setShow(!show)}
				style={{ cursor: "pointer" }}
			>
				😀
			</span>
			<Overlay target={target.current} show={show} placement='left'>
				{(props) => (
					<Tooltip id='overlay-example' {...props}>
						<EmojiPicker onEmojiClick={handleEmojiClick} />
					</Tooltip>
				)}
			</Overlay>
		</>
	);
}

export default Popover;
