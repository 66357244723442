import { useState } from "react";
import Modal from "../../../components/Modal";
import ResetForm from "./resetPass";

export default function ResetPass() {
	const [modalShow, setModalShow]: any = useState(false);
	const handleModal = () => {
		setModalShow(!modalShow);
	};
	return (
		<>
			{/* <Button label="Login" onClick={handleModal} /> */}
			<ResetForm />
			<Modal children={<ResetForm />} show={modalShow} onHide={handleModal} />
		</>
	);
}
