import { noAuthApiSlice } from "../noAuthApiSlice";

const jurisdictionApiSlice = noAuthApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStates: builder.query<any, void>({
            query: () => ({
                url: `/states`,
            }),
        }),
    })
});

export const { useGetStatesQuery } =
jurisdictionApiSlice;
export default jurisdictionApiSlice;