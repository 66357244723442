import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useCreateChatMutation } from "../../redux/services/Firm/FirmLawyer";
import { toast } from "react-toastify";

interface FormValues {
	name: string;
	email: string;
	phone: string;
	message: string;
}

const ContactForm: React.FC = () => {
	const [formValues, setFormValues] = useState<FormValues>({
		name: "",
		email: "",
		phone: "",
		message: "",
	});

	const [addChat, { data: addChatData, isSuccess, isError, isLoading }] =
		useCreateChatMutation();

	const [submitted, setSubmitted] = useState(false);

	const handleInputChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = event.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		addChat({
			...formValues,
		});
		event.preventDefault();
		setSubmitted(true);
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success(addChatData?.message);
		} else if (addChatData?.message === "Something wents wrong") {
			toast.error("Something went wrong, Please try again");
		}
	}, [isSuccess, isError]);

	return (
		<Container
			maxWidth='xl'
			sx={{
				"& .MuiContainer-root": {
					padding: { xs: "0px" },
				},
				"& .MuiInputBase-root": {
					lineHeight: "normal",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					mt: 2,
					"& .MuiFormLabel-root": {
						fontSize: "2rem",
					},
					"& .css-lll1vm-MuiButtonBase-root-MuiButton-root": {
						width: "100%",
						height: "4rem",
						fontSize: "1.2rem",
					},
				}}
			>
				{!submitted ? (
					<form onSubmit={handleSubmit}>
						<TextField
							name='name'
							label='Name'
							variant='standard'
							margin='normal'
							fullWidth
							required
							value={formValues.name}
							onChange={handleInputChange}
						/>
						<TextField
							name='email'
							label='Email'
							variant='standard'
							margin='normal'
							fullWidth
							required
							type='email'
							value={formValues.email}
							onChange={handleInputChange}
						/>
						<TextField
							name='phone'
							label='Contact Number'
							variant='standard'
							margin='normal'
							fullWidth
							required
							value={formValues.phone}
							onChange={handleInputChange}
						/>
						<TextField
							name='message'
							label='Message'
							variant='standard'
							margin='normal'
							fullWidth
							multiline
							rows={4}
							required
							value={formValues.message}
							onChange={handleInputChange}
						/>
						<Button
							type='submit'
							variant='contained'
							color='primary'
							sx={{ mt: 2 }}
						>
							Submit
						</Button>
					</form>
				) : (
					<div>
						Thanks for contacting us! We'll get back to you soon.
					</div>
				)}
			</Box>
		</Container>
	);
};

export default ContactForm;
