import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userRole: "firm",
	data: ""
};

export const authReducer = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setRole: (state, action) => {
			state.userRole = action.payload.userRole;
		},
		setData: (state, action) => {
			state.data = action.payload;
		}
	},
});

export const { setRole, setData } = authReducer.actions;

export default authReducer.reducer;
