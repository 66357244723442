import React from "react";
import NoImage from "../../assets/images/noImage.png";
import { ArrowLeft } from "../../icons";
import { useGetFirmLawyerDetailQuery } from "../../redux/services/Firm/FirmLawyer";
import ScreenLoader from "../Shared/ScreenLoader";
import Image from "material-ui-image";

export default function LawyerInfo({ active, setActive, lawyerId }: any) {
	const { data: firmLawyerDetail, isLoading } = useGetFirmLawyerDetailQuery(
		{
			id: lawyerId,
		},
		{
			skip: active === false && lawyerId === "",
		}
	);

	return (
		<>
			{active && (
				<section
					id="lawyer_info_popup"
					className={active ? "popup active" : "popup"}
				>
					<div className="overlay" onClick={setActive}></div>
					<div className="table_dv">
						<div className="table_cell">
							<div className="contain">
								<div className="row justify-content-center">
									<div className="col-lg-6 col-md-8 col-sm-10">
										<div className="_inner">
											<button
												type="button"
												className="back_btn"
												onClick={setActive}
											>
												<ArrowLeft />
											</button>
											<h2 className="text-center">
												Lawyer Info
											</h2>
											{isLoading && (
												<ScreenLoader show={true} />
											)}
											<div className="prof_blk">
												<div className="ico round fill">
													<Image
														src={
															firmLawyerDetail?.profile_image ||
															NoImage
														}
													/>
												</div>
												<div className="txt">
													<h5>
														{
															firmLawyerDetail
																?.lawyer?.name
														}
													</h5>
													<p>
														{
															firmLawyerDetail
																?.lawyer
																?.member_type
														}
													</p>
												</div>
											</div>
											<ul className="listing">
												<li>
													<h5>Phone no:</h5>
													<p>
														{
															firmLawyerDetail
																?.lawyer?.phone
														}
													</p>
												</li>
												<li>
													<h5>Email:</h5>
													<p>
														{
															firmLawyerDetail
																?.lawyer?.email
														}
													</p>
												</li>
											</ul>
											<div className="ytd_blk mt-5 pt-3">
												<h3 className="fw_50 mb-5">
													YTD Referrals
												</h3>
												<ul className="text-center">
													<li>
														<div className="inner">
															<h2>
																{
																	firmLawyerDetail?.incoming
																}
															</h2>
															<p>
																Incoming
																Referrals
															</p>
														</div>
													</li>
													<li>
														<div className="inner">
															<h2>
																{
																	firmLawyerDetail?.out_going
																}
															</h2>
															<p>
																Outgoing
																Referrals
															</p>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
}
