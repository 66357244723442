import { IFirm } from "../../../../interfaces/Admin";

export enum FirmActionTypes {
	SET_FIRM = "SET_FIRM",
	RESET_FIRM = "RESET_FIRM",
}

export const setFirmData = (payload: IFirm) => {
	return {
		type: FirmActionTypes.SET_FIRM,
		payload,
	};
};

export const resetFirmData = () => {
	return {
		type: FirmActionTypes.RESET_FIRM,
	};
};
