import Image from "material-ui-image";
import { useEffect, useState } from "react";
import search from "../../../assets/images/search.svg";
import Layout from "../../../components/Layout";
import MediaHeader from "../../../components/MediaHeader";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import { IWebinars } from "../../../interfaces/Common";
import { useGetRole } from "../../../redux/selectors/Common";
import {
	useGetMediaSearchQuery,
	useGetWebinarsQuery,
} from "../../../redux/services/Firm/MediaLibrary";

const Webinar = () => {
	const [webinars, setWebinars] = useState<any>(null);
	const [text, setText] = useState<any | null>("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const { data: searchData, isLoading: searchLoading } =
		useGetMediaSearchQuery(text);
	const [onDemand, setOnDemand] = useState(false);
	const role = useGetRole();
	let upcomingWebinars: any = [];
	let onDemandWebinars: any = [];
	const { data, isLoading, refetch } = useGetWebinarsQuery({
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber,
	});

	useEffect(() => {
		refetch();
	}, [rowsPerPage, pageNumber]);

	useEffect(() => {
		setWebinars(data);
	}, []);

	useEffect(() => {
		setWebinars(searchData);
	}, [searchData, data]);

	const handleSearch = (value: any) => {
		setText(value);
	};

	webinars?.webnars.forEach((upcoming: IWebinars) => {
		upcoming.webnar_type === "upcoming"
			? upcomingWebinars.push(upcoming)
			: onDemandWebinars.push(upcoming);
	});

	const handleOnDemand = () => {
		setOnDemand(!onDemand);
	};
	return (
		<>
			<Layout>
				<section id="webinar" className="sec_pad_btm">
					<div className="contain sm">
						<div className="top_sec">
							<TopBlock title="Events" url="media-library" />
							<div className="left_blk">
								<div className="search_blk">
									<div className="img_blk">
										<img src={search} alt="" />
									</div>
									<input
										type="search"
										placeholder="Search"
										value={text}
										onChange={(e) =>
											handleSearch(e.target.value)
										}
									/>
								</div>
							</div>
						</div>
						{(isLoading || searchLoading) && (
							<ScreenLoader show={true} />
						)}
						<MediaHeader />
						<ul className="webinar-tabs">
							<li>
								<a
									className={!onDemand ? "active" : " "}
									onClick={handleOnDemand}
								>
									Upcoming Webinars
								</a>
							</li>
							<li>
								<a
									className={onDemand ? "active" : " "}
									onClick={handleOnDemand}
								>
									On Demand Webinars
								</a>
							</li>
						</ul>
						<div className="row">
							{!onDemand &&
								upcomingWebinars.map(
									(webinar: IWebinars, index: number) => {
										const date = new Date(webinar?.created_at);
										return (
											<div key={index}>
												<h3 className="ctn-main-heading">
													{webinar.category}
												</h3>
												<div className="row">
													<div className="col-lg-6">
														<div className="webinar_card">
															<a
																href={
																	webinar.link
																}
																className="card-image"
															>
																<Image
																	src={
																		webinar.image
																	}
																	aspectRatio={
																		5 / 4
																	}
																/>
															</a>
															<div className="card-detail">
																<div className="card-title">
																	<a
																		href={
																			webinar.link
																		}
																		className="card-title"
																	>
																		{
																			webinar.name
																		}
																	</a>
																	{/* <h3 className="evnet-by">by Johni Khaba</h3> */}
																</div>
																<p></p>
															<a href={webinar.link} style={{color: '#114d96'}} >
															<h5>{date.toDateString()}</h5>
														</a>
																<a
																	href={
																		webinar.link
																	}
																	className="site_btn"
																>
																	Join Now
																</a>
															</div>
															{/* <p className="webnr-date">13-Mar-2022</p> */}
														</div>
													</div>
												</div>
											</div>
										);
									}
								)}
							{upcomingWebinars.length === 0 && !onDemand && (
								<p
									style={{
										marginTop: "5rem",
										fontSize: "2.5rem",
									}}
								>
									{" "}
									No Upcoming Webinar Available{" "}
								</p>
							)}
							{onDemandWebinars.length === 0 && onDemand && (
								<p
									style={{
										marginTop: "5rem",
										fontSize: "2.5rem",
									}}
								>
									{" "}
									No On Demand Webinar Available{" "}
								</p>
							)}

							<div className="library">

								{onDemand &&
									onDemandWebinars.map(
										(webinar: IWebinars, index: number) => {
										const date = new Date(webinar?.created_at);
										return (
												<div
													key={index}
													className='col-lg-6 col-md-12'
												>
													<div
														style={{ margin: "10px" }}
														className='event_card'
													>
														<a
															href={webinar.link}
															className='img_blk'
														>
															<Image
																src={webinar.image}
																aspectRatio={5 / 4}
																alt='Event_Image'
															/>
														</a>
														<div className='card-detail'>
															<div className='card-title'>
																<a
																	href={
																		webinar.link
																	}
																	className='card-title'
																>
																	{webinar.name}
																</a>
																{/* <h3 className="evnet-by">by Johni Khaba</h3> */}
															</div>
															<p></p>
															<a href={webinar.link} style={{color: '#114d96'}} >
															<h5>{date.toDateString()}</h5>
														</a>
															<a
																href={webinar.link}
																className='site_btn'
															>
																Join Now
															</a>
														</div>
														{/* <p className="webnr-date">13-Mar-2022</p> */}
													</div>
												</div>
											);
										}
									)}
							</div>

						</div>
					</div>
					{!isLoading && !searchLoading && (
						<ReactPagination
							setRowsPerPage={setRowsPerPage}
							setPages={setPageNumber}
							totalRecords={data?.total_records}
						/>
					)}
				</section>
			</Layout>
		</>
	);
};

export default Webinar;
