import { basePaths, dashboardTypes } from "../../../utills/Const/const";
import { authApiSlice } from "../authApiSlice";

interface IDashboard {
	firm_image?: string;
	firm_name?: string;
	incoming_referel?: number;
	outgoing_referel?: number;
	referral_email?: string;
}

const dashboardApiSlice = authApiSlice.injectEndpoints({
	endpoints: (build) => ({
		getDashboardData: build.query<IDashboard, any>({
			query: (role) => {
				return {
					url: `/${basePaths[role]}/${dashboardTypes[role]}`,
					method:"POST"
				};
			},
		}),
		getAnalyticsData: build.query<any, any>({
			query: (data) => {
				return {
					url: `/admins/analytics`,
					method: "GET",
				};
			},
		}),
		setting: build.mutation<any, any>({
			query: (values: any) => {

				const userType = values.userType;
				let formData = new FormData();

				// TODO: add interface for values...
				formData.append(`${userType}[phone]`, values.phone);
				formData.append(`${userType}[info]`, values.info);
				formData.append(`${userType}[is_news]`, values.is_news);
				formData.append(`${userType}[is_webinar]`, values.is_webinar);
				formData.append(`${userType}[is_podcast]`, values.is_podcast);
				formData.append(`${userType}[is_event]`, values.is_news);
				if (userType === "lawyer") {
					formData.append(`${userType}[is_referral]`, values.is_referral);
				}
				if (userType === "firm") {
					formData.append(`${userType}[referral_email]`, values.referral_email);
					formData.append(`${userType}[description]`, values.description);
				} else {
					formData.append(`${userType}[name]`, values.name);
				}

				if (values.profile_image) {
					formData.append(
						`${userType}[profile_image]`,
						values.profile_image
					);
				}
				return {
					url: `/${basePaths[userType]}`,
					method: "PATCH",
					body: formData,
				};
			},
		}),
	}),
});

export const { useGetDashboardDataQuery,useGetAnalyticsDataQuery ,useSettingMutation } =
	dashboardApiSlice;
export default dashboardApiSlice;
