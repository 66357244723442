import { Link } from "react-router-dom";
import handbook from "../../../assets/images/handbook.svg";
import { ArrowRight } from "../../../icons";

export default function Handbook() {

    return (
        <>
            <section id="logon" className="handbook">
                <div className="contain">
                    <div className="flex_blk">
                        <div className="log_blk">
                            <div className="inner text-center">
                                <div className="ico">
                                    <img src={handbook} alt="" />
                                </div>
                                <p>The Global Employer Handbook is a comprehensive resource for labor and employment law in over 100 countries, including 170+ jurisdictions around the globe. Filter the employment law information by using the drop-down menus. Submit your search to discover the labor law information you requested.</p>
                                <div className="btn_blk justify-content-end mt-5">
                                    <Link to="/client/resources" type="button" className="site_btn md">
                                        <ArrowRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
