import { Link } from 'react-router-dom';
import NoImage from '../../../assets/images/noImage.png';
import DashboardCard from '../../../components/DashboardCard';
import Layout from '../../../components/Layout';
import { useAppSelector } from '../../../redux/store';
import { ClientDashboard } from '../../../utills/DashboardData';
import FloatingChat from '../../../components/FloatingChat';

const Dashboard = () => {
	const data = useAppSelector((state: any) => state.authReducer.data);
	return (
		<Layout>
			<section id='dashboard' className='sec_pad_btm'>
				<div className='client_board_content'>
					<div className='contain sm'>
						<div className='top_blk'>
							<img
								src={
									data?.profile_image
										? data?.profile_image
										: NoImage
								}
								className='img_blk'
								alt='profile_image'
							/>
							<div className='text_blk'>
								<p>{data?.client?.name}</p>
								{/* <p>Employment Lawyer</p> */}
							</div>
						</div>
						<div className='row'>
							{ClientDashboard.map((item: any, index: number) => {
								return (
									<div
										key={index}
										className='col-md-6 col-sm-12 '
									>
										<Link to={`/client${item.link}`}>
											<DashboardCard
												item={item}
												key={item.id}
											/>
										</Link>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</section>
			<div
				style={{
					position: 'fixed',
					bottom: '10%',
					right: '2%',
					zIndex: 20,
				}}
			>
				<FloatingChat />
				<strong>Contact Us</strong>
			</div>
		</Layout>
	);
};

export default Dashboard;
