import Avatar from "@mui/material/Avatar";
import "../LawyerContactCard/ContactCard.scss";

const LawyerContactCard = ({ item, handleChatSelect }: any) => {
	return (
		<>
			<div
				className='contact_card'
				onClick={() => handleChatSelect(item)}
			>
				<div className='profile_blk'>
					<div className='img_blk'>
						<Avatar
							alt='Remy Sharp'
							src={item?.profile_image || item.image_url}
							sx={{ width: 56, height: 56 }}
						/>
					</div>
				</div>
				<div className='phone_email' style={{ marginLeft: "1rem" }}>
					<div className='text_blk'>
						<h6 className='fs_5 fw_500'>
							<span style={{ color: "#31317f" }}>
								{item.name}
							</span>
						</h6>
					</div>
				</div>
			</div>
		</>
	);
};

export default LawyerContactCard;
