import { lazy, Suspense, useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";

import groupPerson from "../../assets/images/groupPerson.svg";
import messageIcon from "../../assets/images/messageIcon.svg";
import phoneIcon from "../../assets/images/phoneIcon.svg";
import chat from "../../assets/images/chat.png";

import {
	useDeleteContactMutation,
	useDeleteFirmLawyerMutation,
	useSendLawyerEmailMutation,
} from "../../redux/services/Firm/FirmLawyer";
import ScreenLoader from "../Shared/ScreenLoader";

const AddContact = lazy(() => import("../Popup/AddNewContact/AddContact"));
const AddFirmLawyers = lazy(
	() => import("../Popup/AddFirmLawyer/AddFirmLawyer")
);
const LawyerContactInfo = lazy(() => import("../Popup/LawyerContactInfo"));
const DeleteConfirmation = lazy(
	() => import("../DeleteConfirmationModal/DeleteConfirmationModal")
);
const LawyerContactShare = lazy(() => import("../Popup/LawyerContactShare"));
const LawyerComposeReferral = lazy(
	() => import("../Popup/LawyerComposeReferral")
);
const EmailSend = lazy(() => import("../EmailSend"));
const Avatar = lazy(() => import("../Avatar"));

const LawyerDirectoryCard = ({
	item,
	firm,
	setDelete,
	setUpdate,
	isSelected,
	onSelect,
	checkbox,
	newLawyer,
	totalRecords,
	isContact,
	setShowAddModel,
	showAddModal,
	hadnleContactAdded,
	refetchAnalyticsData,
}: any) => {
	const currentUrl = window?.location?.href;
	const [composeRef, setComposeRef] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [editLawyer, setEditLawyer] = useState(false);
	const [editContact, setEditContact] = useState(false);
	const [lawyerDelete, setLawyerDelete] = useState(false);
	const [contactDelete, setContactDelete] = useState(false);
	const [deleteLawyerPopUp, setDeleteLawyerPopup] = useState(false);
	const [contactInfo, setContactInfo] = useState(false);
	const [contactShare, setContactShare] = useState(false);
	const [visible, setVisible] = useState(false);
	const [emailInfo, setEmailInfo] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [selectedEmail, setSelectedEmail] = useState<string[]>([]);

	const [deleteFirmLawyer, { data, isSuccess, isError }] =
		useDeleteFirmLawyerMutation();

	const [
		deleteContact,
		{ data: contactData, isSuccess: contactSuccess, isError: contactError },
	] = useDeleteContactMutation();

	const [
		sendEmail,
		{
			data: emailData,
			isSuccess: isSendEmailSuccess,
			isError: isSendEmailError,
		},
	] = useSendLawyerEmailMutation();

	const handleComposeRef = useCallback(() => {
		setComposeRef(!composeRef);
	}, [composeRef]);

	const handleContactInfo = useCallback(() => {
		setContactInfo(!contactInfo);
	}, [contactInfo]);

	const handleEmailInfo = useCallback(() => {
		setEmailInfo(!emailInfo);
	}, [emailInfo]);

	const handleDeleteLawyer = useCallback(() => {
		setDeleteLawyerPopup(!deleteLawyerPopUp);
	}, [deleteLawyerPopUp]);

	const handleUpdateLawyer = useCallback(() => {
		setEditLawyer(!editLawyer);
	}, [editLawyer]);

	const handleUpdateContact = useCallback(() => {
		setEditContact(!editContact);
	}, [editContact]);

	const handleContactShare = useCallback(() => {
		setContactShare(!contactShare);
	}, [contactShare]);

	useEffect(() => {
		if (isSuccess) {
			setDelete();
			refetchAnalyticsData();
			setDeleteLawyerPopup(false);
			toast.success("Lawyer Deleted Successfully");
		}

		if (isError) {
			toast.error("Error! Can not delete the lawyer. Please try Again");
		}
	}, [isSuccess, isError]);

	useEffect(() => {
		if (isSendEmailSuccess) {
			toast.success("Email Sent Successfully");
			handleEmailModalClose();
		}

		if (isSendEmailError) {
			toast.error("Could not send email, Please try again");
		}
	}, [isSendEmailSuccess, isSendEmailError]);

	useEffect(() => {
		if (lawyerDelete) {
			deleteFirmLawyer({
				id: item.id,
			});
			setLawyerDelete(false);
		}
	}, [lawyerDelete]);

	useEffect(() => {
		if (contactDelete) {
			deleteContact({
				id: item.id,
			});
			setContactDelete(false);
		}
	}, [contactDelete]);

	useEffect(() => {
		if (contactSuccess) {
			setDelete();
			toast.success("Contact Deleted Successfully");
		}

		if (contactError) {
			toast.error("Error! Can not delete the contact. Please try Again");
		}
	}, [contactSuccess, contactError]);

	const handleSelect = useCallback(() => {
		onSelect(item?.id);
	}, [item]);

	const handleViewLawyer = useCallback(() => {
		setVisible(!visible);
	}, [visible]);

	const handleSendEmail = useCallback((payload: any) => {
		sendEmail(payload);
	}, []);

	const handleEmailModalOpen = useCallback(
		(email: string) => {
			setSelectedEmail([...selectedEmail, email]);
			setShowEmailModal(true);
		},
		[selectedEmail]
	);

	const handleEmailModalClose = useCallback(() => {
		setShowEmailModal(false);
		setSelectedEmail([]);
	}, []);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 991px)");

		const handleMediaQueryChange = (e: any) => {
			setIsMobile(e.matches);
		};

		// Initial check
		handleMediaQueryChange(mediaQuery);

		// Add listener for changes
		mediaQuery.addEventListener("change", handleMediaQueryChange);

		// Clean up listener when component unmounts
		return () => {
			mediaQuery.removeEventListener("change", handleMediaQueryChange);
		};
	}, []);

	const renderLoader = useCallback(() => <ScreenLoader show />, []);

	return (
		<Suspense fallback={renderLoader()}>
			<div
				className="lawyer_Directory_card"
				style={{
					flexDirection: isMobile ? "column" : "row",
				}}
			>
				{checkbox && (
					<Checkbox
						checked={isSelected}
						onClick={() => handleSelect()}
						classes={{ root: "custom-checkbox-root" }}
					/>
				)}
				<div className="profile_blk">
					<div className="img_blk">
						<Avatar url={item?.profile_image || item.image_url} />
					</div>
					<div className="text_blk">
						<h5 className="fs_5 fw_500">{item.name}</h5>
						{!isContact && (
							<p>Jurisdiction: {item?.jurisdiction}</p>
						)}
					</div>
				</div>
				<div
					className="phone_email"
					style={{ display: isMobile && checkbox ? "none" : "flex" }}
				>
					{!currentUrl.includes("/lawyers") && (
						<div className="phone">
							<h5 className="fs_5 fw_500">Phone no</h5>
							<p className="fw_400">
								{item?.phone || "Unavailable"}
							</p>
						</div>
					)}
					<div className="email">
						<h5 className="fs_5 fw_500">Email</h5>
						<p className="fw_400">{item?.email}</p>
					</div>
					{firm && (
						<div>
							<div
								style={{
									borderRadius: "100%",
									height: "24px",
									width: "24px",
									backgroundColor:
										item?.device?.length > 2
											? "#00FF00"
											: "red",
								}}
							></div>
						</div>
					)}
					{currentUrl.includes("/lawyers") && (
						<div className="email">
							<h5 className="fs_5 fw_500">Firm</h5>
							<div
								style={{
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								}}
							>
								<a
									href={item.firm_url}
									target="_blank"
									className="fw_400"
									rel="noreferrer"
								>
									{item?.firm_name}
								</a>
							</div>
						</div>
					)}
				</div>
				{!firm ? (
					<ul className="icons">
						<li>
							<button
								type="button"
								className="icon_blk"
								onClick={handleContactShare}
							>
								<img src={chat} loading="lazy" alt="chat" />
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								onClick={handleContactInfo}
							>
								<img src={phoneIcon} loading="lazy" alt="" />
							</button>
						</li>
						<li>
							<button type="button" className="icon_blk">
								<img
									src={messageIcon}
									alt=""
									loading="lazy"
									onClick={handleEmailInfo}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								onClick={handleComposeRef}
							>
								<img
									src={groupPerson}
									loading="lazy"
									alt="group person"
								/>
							</button>
						</li>
					</ul>
				) : (
					<ul className="edit_icons">
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={() => handleEmailModalOpen(item.email)}
							>
								<ForwardToInboxIcon
									sx={{
										color: "#eaa221",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={handleViewLawyer}
							>
								<VisibilityIcon
									sx={{
										color: "grey",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={
									isContact
										? handleUpdateContact
										: handleUpdateLawyer
								}
							>
								<BorderColorIcon
									sx={{
										color: "#63A744",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={handleDeleteLawyer}
							>
								<DeleteIcon
									sx={{
										color: "red",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
					</ul>
				)}
				{/* <div className="pop_btn" onClick={handleLawyerInfo}></div> */}
			</div>

			{showEmailModal && (
				<EmailSend
					emails={selectedEmail}
					showEmailModal={showEmailModal}
					handleEmailModalClose={handleEmailModalClose}
					handleSendEmail={handleSendEmail}
					isDisable={true}
				/>
			)}

			{/* for share */}
			{contactShare && (
				<LawyerContactShare
					active={contactShare}
					setActive={handleContactShare}
					lawyer_name={item?.name}
					lawyer_contact={item?.phone}
				/>
			)}

			{composeRef && (
				<LawyerComposeReferral
					active={composeRef}
					setActive={handleComposeRef}
					lawyer={item}
					totalRecords={totalRecords}
				/>
			)}
			{/* for contactInfo */}
			{contactInfo && (
				<LawyerContactInfo
					active={contactInfo}
					setActive={handleContactInfo}
					lawyer_name={item?.name}
					lawyer_contact={item?.phone}
				/>
			)}
			{/* for EmailInfo */}
			{emailInfo && (
				<LawyerContactInfo
					active={emailInfo}
					setActive={handleEmailInfo}
					lawyer_name={item?.name}
					lawyer_contact={item?.email}
					emailPopUp={true}
				/>
			)}

			{isContact ? (
				<>
					<AddContact
						active={editContact}
						setActive={handleUpdateContact}
						contact={item}
						updateContact={true}
						setUpdateContact={setUpdate}
						newLawyer={newLawyer}
					/>
					<AddContact
						active={showAddModal}
						setActive={setShowAddModel}
						updateContact={false}
						setUpdateContact={hadnleContactAdded}
					/>
					<AddContact
						active={visible}
						setActive={handleViewLawyer}
						contact={item}
						newLawyer={newLawyer}
						disable={true}
						setUpdateContact={true}
					/>
					<DeleteConfirmation
						message={
							"Are you sure you want to delete this Contact ?"
						}
						showModal={deleteLawyerPopUp}
						handleDelete={setContactDelete}
						hideModal={handleDeleteLawyer}
					/>
				</>
			) : (
				<>
					<AddFirmLawyers
						active={editLawyer}
						setActive={handleUpdateLawyer}
						lawyer={item}
						updateLawyer={true}
						setUpdateLawyer={setUpdate}
						newLawyer={newLawyer}
					/>
					<AddFirmLawyers
						active={visible}
						setActive={handleViewLawyer}
						lawyer={item}
						newLawyer={newLawyer}
						disable={true}
						updateLawyer={true}
					/>
					<DeleteConfirmation
						message={
							"Are you sure you want to delete this Lawyer ?"
						}
						showModal={deleteLawyerPopUp}
						handleDelete={setLawyerDelete}
						hideModal={handleDeleteLawyer}
					/>
				</>
			)}
		</Suspense>
	);
};

export default LawyerDirectoryCard;
