import { ILoginInput, IResetInput } from "../../../interfaces/Common";
import { basePaths } from "../../../utills/Const/const";
import { noAuthApiSlice } from "../noAuthApiSlice";

const firmLoginApiSlice = noAuthApiSlice.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<any, ILoginInput>({
			query: (data) => {
				let userType = "";
				if (data.userType === "superAdmin") {
					userType = "firm";
				} else {
					userType = data.userType;
				}
				let formData = new FormData();
				formData.append(`${userType}[email]`, data.email);
				formData.append(`${userType}[password]`, data.password);
				return {
					url: `/${basePaths[userType]}/sign_in.json`,
					method: "POST",
					body: formData,
				};
			},
		}),
		signUp: build.mutation<any, any>({
			query: (values: any) => {
				// TODO: add interface for values...

				const userType = values.userType || "lawyer";
				let formData = new FormData();
				formData.append(`${userType}[email]`, values.email);
				formData.append(`${userType}[phone]`, values.phone);
				formData.append(`${userType}[password]`, values.password);
				formData.append(`${userType}[is_news]`, values.is_news);
				formData.append(`${userType}[is_webinar]`, values.is_webinar);
				formData.append(`${userType}[is_podcast]`, values.is_podcast);
				formData.append(`${userType}[is_event]`, values.is_news);

				formData.append(
					`${userType}[password_confirmation]`,
					values.password_confirmation
				);
				if (userType === "lawyer") {
					formData.append(`${userType}[name]`, values.name);
					formData.append(`${userType}[firm_code]`, values.firm_code);
					formData.append(
						`${userType}[member_type]`,
						values.member_type
					);
					formData.append(`${userType}[firm_code]`, values.firm_code);
					formData.append(
						`${userType}[jurisdiction]`,
						values.jurisdiction
					);
					formData.append(
						`${userType}[is_referral]`,
						values.is_referral
					);
				}
				return {
					url: `/${basePaths[userType]}`,
					method: "POST",
					body: formData,
				};
			},
		}),
		forgetPassword: build.mutation<any, ILoginInput>({
			query: (data) => {
				const userType = data.userType;
				let formData = new FormData();
				formData.append("email", data.email);
				return {
					url: `/${basePaths[userType]}/password/forgot`,
					method: "POST",
					body: formData,
				};
			},
		}),
		resetPassword: build.mutation<any, IResetInput>({
			query: (data) => {
				const userType = data.userType;
				let formData = new FormData();
				formData.append("email", data.email);
				formData.append("password", data.password);
				formData.append("token", data.otp);
				return {
					url: `/${basePaths[userType]}/password/reset`,
					method: "POST",
					body: formData,
				};
			},
		}),
	}),
});

export const {
	useLoginMutation,
	useSignUpMutation,
	useForgetPasswordMutation,
	useResetPasswordMutation,
} = firmLoginApiSlice;
export default firmLoginApiSlice;
