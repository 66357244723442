import Layout from "../../../components/Layout";
import MediaHeader from "../../../components/MediaHeader";
import NewsCard from "../../../components/NewsCard";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import {
	useGetMediaSearchQuery,
	useGetNewsQuery,
} from "../../../redux/services/Firm/MediaLibrary";
import search from "../../../assets/images/search.svg";
import { useEffect, useState } from "react";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";

const NewsArticles = () => {
	const [text, setText] = useState<any | null>("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [news, setNews] = useState<any>(null);
	const { data, isLoading, refetch } = useGetNewsQuery({
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber,
	});
	const { data: searchData, isLoading: searchLoading } =
		useGetMediaSearchQuery(text);

	useEffect(() => {
		refetch();
	}, [rowsPerPage, pageNumber]);

	useEffect(() => {
		setNews(data);
	}, []);

	useEffect(() => {
		if (text == "") {
			setNews(data);
		} else {
			setNews(searchData);
		}
	}, [searchData, data]);

	const handleSearch = (value: any) => {
		setText(value);
	};

	return (
		<Layout>
			<section id="NewsArticles" className="sec_pad_btm">
				<div className="contain sm">
					<div className="top_sec">
						<TopBlock title="News & Articles" url="media-library" />
						<div className="left_blk">
							<div className="search_blk">
								<div className="img_blk">
									<img src={search} alt="" />
								</div>
								<input
									type="search"
									placeholder="Search"
									value={text}
									onChange={(e) =>
										handleSearch(e.target.value)
									}
								/>
							</div>
						</div>
					</div>
					{(isLoading || searchLoading) && (
						<ScreenLoader show={true} />
					)}
					<MediaHeader />
					<div className="row">
						{news?.news.map((item: any, index: number) => {
							return (
								<div className="col-lg-6 col-md-12" key={index}>
									<NewsCard item={item} />
								</div>
							);
						})}
						{!isLoading && !searchLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={news?.total_records}
							/>
						)}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default NewsArticles;
