import { FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import AutoComplete from "../../components/MuiSelect";
import { ArrowLeft, Cross, Search } from "../../icons";
import TextInput from "../TextInput";

import _ from "lodash";
import { useGetJurisdictionQuery } from "../../redux/services/Client/ClientDashboard/index";
import { JurisdictionTopic } from "../../utills/Const/const";
import { resourcesFilterSchema } from "../../utills/YupValidationSchemas";
import ErrorText from "../Shared/ErrorText";

export interface IClientState {
	id: number;
	name: string;
	created_at: string;
	updated_at: string;
	status: boolean;
}

export default function AdminResourcesFilter({
	active,
	setActive,
	searchingResources,
}: any) {
	const [value, setValue] = useState("");
	let [data, setData] = useState<any>(null);

	const { data: jurisdiction, isLoading } = useGetJurisdictionQuery(null);
	useEffect(()=>{
		let payload:any = jurisdiction
		var sortedJurisdictions = _.sortBy(payload?.states, 'name');
		setData({states:sortedJurisdictions})
		
	},[jurisdiction])

	const handleSubmit = (values: any) => {
		let filterObj = {
			state: values?.jurisdiction,
			law: values?.topic,
		};

		searchingResources(filterObj);
		setActive(false);
	};

	return (
		<section
			id="filter_popup"
			className={active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={setActive}></div>

			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="_inner">
									<button
										type="button"
										className="back_btn"
										onClick={setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">Filter</h2>

									{/* Filter Form */}
									<div>
										<Formik
											initialValues={{
												jurisdiction: [],
												topic: [],
												searchItems: [],
											}}
											validationSchema={
												resourcesFilterSchema
											}
											onSubmit={(values) =>
												handleSubmit(values)
											}
										>
											{({
												values,
												handleSubmit,
												setFieldValue,
												errors, touched,
											}) => (
												<form
													onSubmit={handleSubmit}
													className=""
												>
													<div className="row">
														{/* Jurisdiction */}
														<div className="col-12">
															<div
																className=""
																style={{
																	width: "100% !important",
																}}
															>
																<AutoComplete
																	options={
																		data?.states
																			? data?.states
																			: []
																	}
																	multiple={
																		true
																	}
																	field="jurisdiction"
																	placeholder="Jurisdiction(s)"
																	handleChange={(
																		value: any
																	) => {
																		let selectedJurisdictions: any =
																			value?.map(
																				(
																					i: any
																				) =>
																					i.id
																			);

																		setFieldValue(
																			"jurisdiction",
																			selectedJurisdictions
																		);
																	}}
																/>
															</div>
															<ErrorText
																text={errors?.jurisdiction}
																isTouched={
																	touched?.jurisdiction
																}
															/>
														</div>

														{/* Topic */}
														<div className="col-12">
															<div
																className=""
																style={{
																	width: "100% !important",
																}}
															>
																<AutoComplete
																	options={
																		JurisdictionTopic
																	}
																	multiple={
																		true
																	}
																	field="topic"
																	placeholder="Topic"
																	handleChange={(
																		value: any
																	) => {
																		let selectedTopics: any =
																			value?.map(
																				(
																					i: any
																				) =>
																					i.key
																			);

																		setFieldValue(
																			"topic",
																			selectedTopics
																		);
																	}}
																/>
															</div>
															<ErrorText
																text={errors?.topic}
																isTouched={
																	touched?.topic
																}
															/>
														</div>

														{/* keyword search */}
														<div className="col-12">
															<FieldArray
																name="searchItems"
																render={(
																	arrayHelpers
																) => (
																	<>
																		<div className="form_blk">
																			<Search />
																			<TextInput
																				style={{
																					height: "50px",
																					paddingLeft:
																						"50px",
																				}}
																				placeholder="Keyword Search"
																				type="text"
																				value={
																					value
																				}
																				handleChange={(
																					e: any
																				) =>
																					setValue(
																						e
																							.target
																							.value
																					)
																				}
																				onKeyPress={(
																					e: any
																				) => {
																					if (
																						e.key ===
																						"Enter" &&
																						value !==
																						""
																					) {
																						arrayHelpers.push(
																							value
																						);
																						setValue(
																							""
																						);
																					}
																				}}
																			/>
																		</div>
																		<ul className="tag_list">
																			{values.searchItems &&
																				values
																					.searchItems
																					.length >
																				0 && (
																					<>
																						{values.searchItems.map(
																							(
																								searchItem,
																								index
																							) => (
																								<li>
																									<span>
																										{
																											searchItem
																										}{" "}
																										<Cross
																											onClick={() =>
																												arrayHelpers.remove(
																													index
																												)
																											}
																										/>
																									</span>
																								</li>
																							)
																						)}
																					</>
																				)}
																		</ul>
																	</>
																)}
															/>
														</div>
													</div>

													{/* Submit */}
													<div className="btn_blk mt-5 pt-3">
														<button
															className="site_btn w-100"
															type="submit"
														>
															Search
														</button>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}