import { Link } from "react-router-dom";
import importIcon from "../../assets/images/import.svg";
import backArrow from "../../assets/images/backArrow.svg";
import { usePapaParse } from "react-papaparse";
import { useGetRole } from "../../redux/selectors/Common";

type Props = {
	title: string;
	url?: string;
	state?: object;
	button?: boolean;
	setAddFirm?: any;
	handleImport?: any;
	buttonText?: string;
	importButtonText?: string;
	deleteButtonText?: string;
	handleDelete?: any;
	firms?: any;
};

const TopBlock: React.FC<Props> = ({
	title,
	url,
	state,
	button,
	setAddFirm,
	buttonText,
	handleImport,
	importButtonText,
	deleteButtonText,
	handleDelete,
	firms,
}) => {
	const role = useGetRole();

	const { jsonToCSV } = usePapaParse();

	const downloadFirmCSV = () => {
		const csvData = jsonToCSV(firms);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = "FirmID's.csv";
		link.href = url;
		link.click();
	};
	return (
		<>
			<div className="top_bar_container" style={{ justifyContent: "space-between", display: "flex" }}>
				<div id="top_blk" className="fs_3 fw_600">
					<div className="img_blk">
						<Link
							to={`/${role}/${url}`}
							state={state ? state : null}
						>
							<img src={backArrow} alt="" />
						</Link>
					</div>
					{title}
				</div>
				{button && (
					<div className="btn_container">
						{firms && (
							<button
								onClick={downloadFirmCSV}
								// style={{ marginRight: "2rem" }}
								className="site_btn"
							>
								Download Firm ID's
							</button>
						)}
						{deleteButtonText && (
							<button
								className="site_btn"
								// style={{ marginRight: "2rem" }}
								onClick={handleDelete}
							>
								{deleteButtonText}
							</button>
						)}
						{importButtonText && (
							<button
								className="site_btn"
								// style={{ marginRight: "2rem" }}
								onClick={handleImport}
							>
								{importButtonText}
							</button>
						)}
						<button
							className="site_btn"
							onClick={() => {
								setAddFirm(true);
							}}
						>
							{buttonText}
							
						</button>
					</div>
				)}
			</div>
		</>
	);
};

export default TopBlock;
