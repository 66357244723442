import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.3,0h1.3c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0.1,1.1,0.1c1.6,0.3,3,0.8,4.4,1.7c0.3,0.2,0.6,0.4,0.9,0.6l-2.8,3.6 c-1-0.8-2.2-1.3-3.4-1.4C11.9,4.5,10.7,4.6,9.5,5c-1.2,0.4-2.2,1.1-3,2.1C5.6,8,5,9.1,4.8,10.3c-0.3,1.2-0.2,2.5,0.1,3.7 c0.3,1.2,1,2.3,1.9,3.2c0.9,0.9,2,1.5,3.2,1.9c1.2,0.4,2.5,0.4,3.7,0.1c3-0.6,5-3,5.5-5h-7V9.7h11.7c0.1,0,0.2,0,0.3,0v3 c0,0.1,0,0.2,0,0.2c-0.1,0.5-0.1,1-0.2,1.5c-0.6,2.9-2.1,5.2-4.5,7c-2.9,2.2-6.2,2.9-9.8,2.2c-2.9-0.6-5.2-2.1-7-4.4 c-1.9-2.5-2.7-5.4-2.4-8.5C0.3,8.3,1.3,5.9,3,4.1C4.8,2,7.2,0.6,9.9,0.2C10.4,0.1,10.8,0.1,11.3,0z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent
