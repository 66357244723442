import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import Layout from "../../../components/Layout";
import PaginatedItems from "../../../components/Paginator";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";
import ReferalCard from "../../../components/ReferalCard";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import { useGetRole } from "../../../redux/selectors/Common";
import { useGetExportReferralLawyerQuery, useGetReferralLawyerQueryQuery, useGetFirmAnalyticsDataQuery } from "../../../redux/services/Firm/FirmLawyer";
import { useDeleteReferralMutation } from "../../../redux/services/Lawyer/DashboardApi";
import AnalyticsTile from "../../../components/AnalyticsTile";
import icon3 from "../../../assets/images/icon4.svg"

const Referals = () => {
	const role = useGetRole();	
	const setURL=()=>{
		if (role === "lawyer") {
			return{ url: `${role}s/referrals?records_per_page=${rowsPerPage}&page_number=${pageNumber}`};
		} else {
			return{ url: `firms/lawyers/referrals?records_per_page=${rowsPerPage}&page_number=${pageNumber}` };
		}
	}

	const setExportReferralsURL=()=>{
		if (role === "lawyer") {
			return{ url: `${role}s/export_referrals`};
		} else {
			return{ url: `firms/lawyers/export_referrals`};
		}
	}

	const [id, setID] = useState(1);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [deleteReferralCall, setDeleteReferralCall] = useState(false);

	const  { data: referralsData, isLoading,refetch } =
	useGetReferralLawyerQueryQuery({
		url: setURL().url, 
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber
	});

	const  { data: firmAnalyticsData} = useGetFirmAnalyticsDataQuery({});

	const  { data } =	useGetExportReferralLawyerQuery({
		url: setExportReferralsURL().url
	});

	const [deleteReferral, { isSuccess, isError }] = useDeleteReferralMutation();

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(
				referralsData?.recents.recent?.map((item: { id: any }) => item.id)
			);
		}

		setSelectAll(!selectAll);
	};


	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	const handleSelect = (id: any) => {
		handleItemSelect(id);
	};


	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	const handleExportCsv = () => {
		const csvData = createCsvData(data);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = "exported referrals.csv";
		link.href = url;
		link.click();
	}

	const createCsvData = (data: any) => {

		let csvData = `"Send Date","From Region","From Jurisdiction","From Firm","To Region","To Jurisdiction","To Firm",Message/Description,"Referral Type","Type of Matter",""\n`;

		data?.referrals?.forEach((item: any) => {
			csvData = csvData + `"${item.send_date}","${item.from_region}","${item.from_jurisdiction}","${item.from_firm}","${item.to_region}","${item.to_jurisdiction}","${item.to_firm}","${item.message}"\n`;
		});

		return csvData;
	}

	
	useEffect(() => {
		if (deleteReferralCall) {
			deleteReferral({ id: selectedItems, admin: false });
			setDeleteReferralCall(false);
		}

		if (isSuccess) {
			refetch();
			setSelectedItems([]);
			toast.success("Referrals Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
		}
		 if (isError) {
			toast.error(
				"Could not delete referrals at the moment, Please try again"
			);
		}
		setDeletePopUp(false);
	}, [deleteReferralCall, isSuccess, isError]);

	useEffect(() => {
		refetch();
	}, [role]);

	return (
		<Layout>
			<div id="referals" className="sec_pad_btm">
				<div className="contain sm">
					<TopBlock title="Referrals" url="dashboard" />
					{/* <div id="dashboard">
					{role === "firm" && <AnalyticsTile currentMonth={firmAnalyticsData?.referrals?.entries_within_period} title="Referrals" count={firmAnalyticsData?.referrals.total_count} path="#" percentage={firmAnalyticsData?.referrals?.percentage_difference
} icon={icon3}/>}
					</div> */}
					<div className="tabs mb-4">
						<button
							className={
								id === 1 ? "site_btn active_btn" : "site_btn"
							}
							onClick={() => setID(1)}
						>
							Recent
						</button>
						<button
							className={
								id === 2 ? "site_btn active_btn" : "site_btn"
							}
							onClick={() => setID(2)}
						>
							Incoming
						</button>
						<button
							className={
								id === 3 ? "site_btn active_btn" : "site_btn"
							}
							onClick={() => setID(3)}
						>
							Outgoing
						</button>
					</div>
					{isLoading && <ScreenLoader show={true} />}
					{id === 1 && referralsData?.recents?.recent?.length ? (
							<div>
								<div
									className='search_container'
									style={{
										justifyContent: "space-between",
										backgroundColor: "#63a744",
										alignItems: "center",
										padding: "1rem",
										borderRadius: "1rem",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												marginLeft: "0.5rem",
											}}
										>
											<Checkbox
												checked={selectAll}
												classes={{ root: "custom-checkbox-root" }}
												onClick={() => {
													handleSelectAllChange();
												}}
											/>
											<p
												style={{
													fontSize: "2rem",
													lineHeight: "7rem",
												}}
											>
												Select All
											</p>
										</div>
									</div>
									<div>
									{selectedItems?.length > 1 && (
										<Button
											variant='danger'
											onClick={handleBulkDelete}
											style={{
												fontSize: "2rem",
												width: "15rem",
												height: "5rem",
												marginRight: "2rem",
											}}
										>
											Delete
										</Button>
									)}
									<Button
											variant='primary'
											onClick={handleExportCsv}
											className="export_button"
										>
											Export All Referrals History In CSV
										</Button>
									</div>
								</div>
								<ScreenLoader show={isLoading} />
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										margin: "auto",
										width: "100%",
									}}
								>
									{referralsData?.recents?.recent?.map((item: any) => {
										return (
											<ReferalCard
												item={item}
												key={item.id}
												refetch={refetch}
												checkbox={true}
												handleSelect={handleSelect}
												isSelected={selectedItems.includes(item.id)}
												admin={false}
											/>
										);
									})}
									{!isLoading && (
										<ReactPagination
											setRowsPerPage={setRowsPerPage}
											setPages={setPageNumber}
											totalRecords={referralsData?.recents?.total_records}
										/>
									)}
								</div>
								<DeleteConfirmation
									showModal={deletePopUp}
									message={
										"Are you sure you want to delete the selected Referrals?"
									}
									handleDelete={setDeleteReferralCall}
									hideModal={handleBulkDelete}
								/>
							</div>
					) : (
						(!referralsData?.recents?.recent?.length && id === 1) && <p
							style={{
								fontSize: "2rem",
								textAlign: "center",
								marginTop: "20rem",
							}}
						>
							{id === 1 && "No Recent Referrals To Show"}
						</p>
					)}
					{id === 2 && referralsData?.incomings?.incoming?.length ? (
						<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							margin: "auto",
							width: "100%",
						}}
					>
						{referralsData?.incomings?.incoming?.map((item: any) => {
							return (
								<ReferalCard
									item={item}
									key={item.id}
									refetch={refetch}
									checkbox={false}
									admin={false}
								/>
							);
						})}
						{!isLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={referralsData?.incomings?.total_records}
							/>
						)}
					</div>
					) : (
						!(referralsData?.outgoings?.out_going?.length) && <p
							style={{
								fontSize: "2rem",
								textAlign: "center",
								marginTop: "20rem",
							}}
						>
							{id === 2 && "No Incoming Referrals To Show"}
						</p>
					)}
					{id === 3 && referralsData?.outgoings?.out_going?.length ? (
						<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							margin: "auto",
							width: "100%",
						}}
					>
						{referralsData?.outgoings?.out_going?.map((item: any) => {
							return (
								<ReferalCard
									item={item}
									key={item.id}
									refetch={refetch}
									checkbox={false}
									admin={false}
								/>
							);
						})}
						{!isLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={referralsData?.outgoings?.total_records}
							/>
						)}
					</div>
					) : 
						 id === 3 && <p
							style={{
								fontSize: "2rem",
								textAlign: "center",
								marginTop: "20rem",
							}}
						>
							{"No Outgoing Referrals To Show"}
						</p>
					}
				</div>
			</div>
		</Layout>
	);
};

export default Referals;
