import Checkbox from "@mui/material/Checkbox";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import filterClose from "../../../../assets/images/filterClose.png";
import filters from "../../../../assets/images/filters.svg";
import search from "../../../../assets/images/search.svg";
import DeleteConfirmation from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import LawyerDirectoryCard from "../../../../components/LawyerDirectoryCard";
import Layout from "../../../../components/Layout";
import AddFirmLawyers from "../../../../components/Popup/AddFirmLawyer/AddFirmLawyer";
import ImportFromCSV from "../../../../components/Popup/ImportFirmLawyers/ImportFromCsv";
import SuperAdminFilter from "../../../../components/Popup/SuperAdminFilter";
import ReactPagination from "../../../../components/ReactPagination/ReactPagination";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TopBlock from "../../../../components/TopBlock";
import {
	useDeleteFirmLawyerMutation,
	useGetAllLawyersQuery,
	useGetLawyerListQuery,
	useGetLawyerSearchMutation,
	useSendLawyerEmailMutation,
} from "../../../../redux/services/Firm/FirmLawyer";
import AnalyticsTile from "../../../../components/AnalyticsTile";
import { useGetAnalyticsDataQuery } from "../../../../redux/services/Dashboard";
import icon2 from "../../../../assets/images/icon2.svg";
import EmailSend from "../../../../components/EmailSend";
import { useLocation } from "react-router-dom";
const ManageLawyers = () => {
	const [addLawyer, setAddLawyer] = useState(false);
	const [deleteLawyer, setDeleteLawyer] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [selectAll, setSelectAll] = useState(false);
	const [deleteLawyers, setDeleteLawyers] = useState(false);
	const [filter, setFilter] = useState(false);
	const [filteredData, setFilteredData] = useState<any | null>(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [bulkDeleteLawyers, setBulkDeleteLawyers] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [updateLawyer, setUpdateLawyer] = useState(false);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [lawyers, setLawyers] = useState([]);
	const [importLawyers, setImportLawyers] = useState(false);
	const [searchText, setSearchText] = useState<any | null>("");
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [selectedEmail, setSelectedEmail] = useState<string[]>([]);
	const location = useLocation();

	const [
		sendEmail,
		{
			data: emailData,
			isSuccess: isSendEmailSuccess,
			isError: isSendEmailError,
		},
	] = useSendLawyerEmailMutation();
	const [
		searching,
		{
			data: searchData,
			isLoading: searchLoading,
			isSuccess: isSearchSuccess,
		},
	] = useGetLawyerSearchMutation();
	const params = new URLSearchParams(location.search);
	const registeredParam = params.get("registered");
	const thisMonthParam = params.get("this_month");
	const queryParams = {
		...(registeredParam && { registered: registeredParam }),
		...(thisMonthParam && { this_month: thisMonthParam }),
	};
	const { data, isLoading, refetch } = useGetLawyerListQuery({
		rowsPerPage,
		pageNumber,
		...queryParams,
	});

	const { data: exportData } = useGetAllLawyersQuery({});

	const {
		data: analyticsData,
		isLoading: isAnalyticsLoading,
		isSuccess: isAnalyticsSuccess,
		refetch: refetchAnalyticsData,
	} = useGetAnalyticsDataQuery({
		refetchOnMountOrArgChange: true,
	});

	const [deleteFirmLawyer, { isSuccess, isError }] =
		useDeleteFirmLawyerMutation();

	const handleImportLawyers = () => {
		setImportLawyers(!importLawyers);
	};

	const handleDelete = () => {
		setDeleteLawyer(!deleteLawyer);
	};

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(
				data?.lawyers?.map((item: { id: any }) => item.id)
			);
		}
		setSelectAll(!selectAll);
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	useEffect(() => {
		setSearchText("");
		setFilteredData(null);
		refetch();
	}, [deleteLawyer, updateLawyer, pageNumber, rowsPerPage]);

	const handleBulkDeleteLawyers = () => {
		setBulkDeleteLawyers(!bulkDeleteLawyers);
	};

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searching({ search });
			} else {
				setFilteredData(null);
				setLawyers(data.lawyers);
			}
		}, 1000);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems?.includes(itemId)) {
			setSelectedItems(selectedItems?.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};
	useEffect(() => {
		if (selectedItems?.length === data?.lawyers?.length) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [selectedItems]);

	const handleAddLawyer = () => {
		setAddLawyer(!addLawyer);
	};

	useEffect(() => {
		if (searchData?.lawyers) {
			setFilteredData(searchData?.lawyers);
		} else {
			setLawyers(data?.lawyers);
		}
	}, [searchData, data]);

	useEffect(() => {
		if (deleteLawyers) {
			deleteFirmLawyer(selectedItems);
			setDeleteLawyers(false);
		}
		if (isSuccess) {
			refetchAnalyticsData()
			toast.success("Lawyers Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
			setDeletePopUp(false);
			setSelectedItems([]);
		}
		if (isError) {
			toast.error(
				"Could not delete firms at the moment, Please try again"
			);
		}
	}, [deleteLawyers, isSuccess, isError]);

	useEffect(() => {
		if (isSendEmailSuccess) {
			toast.success("Email Sent Successfully");
			handleEmailModalClose();
		}
		if (isSendEmailError) {
			toast.error("Could not send email, Please try again");
		}
	}, [isSendEmailSuccess, isSendEmailError]);

	const handleFilter = () => {
		setFilter(!filter);
		setSearchText("");
	};

	const handleClearFilter = () => {
		setSearchText("");
		setFilteredData(null);
	};

	const handleEmailModalClose = () => {
		setShowEmailModal(false);
		setSelectedEmail([]);
	};

	const handleSendEmail = (payload: any) => {
		sendEmail(payload);
	};

	const handleExportCsv = () => {
		const csvData = createCsvData(exportData);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = "exported ela-members.csv";
		link.href = url;
		link.click();
	};

	const createCsvData = (data: any) => {
		let csvData = `"Name", "Email", "Jurisdiction", "Phone" ,"Mobile App"\n`;

		data?.forEach((item: any) => {
			csvData =
				csvData +
				`"${item.name}","${item.email}","${item.jurisdiction}","${
					item.phone
				}","${item?.device?.length > 2 ? "Yes" : "No"}"\n`;
		});

		return csvData;
	};

	return (
		<Layout>
			<ScreenLoader
				show={searchLoading || isLoading || isAnalyticsLoading}
			/>
			<section id="manage_registered_firms" className="sec_pad_btm ">
				<div
					style={{
						maxWidth: "180rem",
						padding: "0rem 2rem",
						margin: "0 auto",
					}}
				>
					<div id="dashboard">
						<AnalyticsTile
							title="ELA Members"
							icon={icon2}
							path="/firm/super-admin-manage-lawyers"
							count={analyticsData?.ela_members?.total_count}
							currentMonth={
								analyticsData?.ela_members?.current_month_count
							}
							percentage={
								analyticsData?.ela_members
									?.percentage_difference
							}
							mobileRegistered={
								analyticsData.ela_members
									?.registered_on_mobile_count
							}
							mobileUnregistered={
								analyticsData?.ela_members
									?.unregistered_on_mobile_count
							}
						/>
					</div>
					<div className="top_blk mb-5">
						<TopBlock
							title="All Lawyers"
							url="dashboard"
							button={true}
							setAddFirm={setAddLawyer}
							buttonText="Add Lawyer"
							importButtonText="Import Lawyers"
							handleImport={handleImportLawyers}
							deleteButtonText="Delete Via Csv"
							handleDelete={handleBulkDeleteLawyers}
						/>
					</div>
					<div
						className="search_container"
						style={{
							justifyContent: "space-between",
							backgroundColor: "#63a744",
							alignItems: "center",
							padding: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginLeft: "4rem",
								}}
							>
								<Checkbox
									checked={selectAll}
									classes={{ root: "custom-checkbox-root" }}
									onClick={() => {
										handleSelectAllChange();
									}}
								/>
								<p
									style={{
										fontSize: "2rem",
										lineHeight: "7rem",
									}}
								>
									Select All
								</p>
							</div>
							{selectedItems?.length > 0 && (
								<Button
									variant="danger"
									onClick={handleBulkDelete}
									style={{
										fontSize: "2rem",
										width: "15rem",
										height: "5rem",
										marginTop: "1rem",
										marginLeft: "2rem",
									}}
								>
									Delete
								</Button>
							)}
						</div>
						<div style={{ display: "flex" }}>
							<button
								className="site_btn"
								style={{ marginRight: "2rem" }}
								onClick={handleExportCsv}
							>
								Export All ELA Members History In CSV
							</button>
							<div className="left_blk">
								<button
									className="site_btn"
									style={{ marginRight: "2rem" }}
									onClick={() => setShowEmailModal(true)}
								>
									Send Bulk Emails
								</button>
								<div className="search_blk_lawyers">
									<div className="img_blk">
										<img src={search} alt="search icon" />
									</div>
									<input
										type="search"
										placeholder="Search"
										value={searchText}
										style={{ marginTop: "0.5rem" }}
										onChange={(e) => {
											handleSearch(e.target.value);
										}}
									/>
								</div>
								<div>
									{filteredData?.length > 0 &&
										searchText === "" && (
											<img
												onClick={() =>
													handleClearFilter()
												}
												style={{
													width: "3rem",
													height: "3rem",
													position: "absolute",
													marginTop: "-1rem",
													marginLeft: "0.5rem",
													cursor: "pointer",
												}}
												src={filterClose}
												alt=""
											/>
										)}
									<div
										className="filter_blk pop_btn"
										onClick={handleFilter}
									>
										<img src={filters} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<AddFirmLawyers
						active={addLawyer}
						setActive={handleAddLawyer}
						updateLawyer={false}
						newLawyer={true}
						setUpdateLawyer={setUpdateLawyer}
					/>

					{(lawyers?.length > 0 || filteredData?.length > 0) &&
						(filteredData ? filteredData : lawyers)?.map(
							(info: any) => {
								return (
									<LawyerDirectoryCard
										item={info}
										firm={true}
										key={info.id}
										setDelete={handleDelete}
										setUpdate={setUpdateLawyer}
										isSelected={selectedItems?.includes(
											info?.id
										)}
										checkbox={true}
										onSelect={handleItemSelect}
										newLawyer={true}
										refetchAnalyticsData={refetchAnalyticsData}
									/>
								);
							}
						)}

					<>
						{!searchLoading &&
						!isLoading &&
						lawyers?.length <= 0 ? (
							<p
								style={{
									textAlign: "center",
									fontSize: "3rem",
									marginTop: "3rem",
								}}
							>
								No Lawyer found
							</p>
						) : (
							isSearchSuccess &&
							filteredData?.length <= 0 && (
								<div>
									<p
										style={{
											textAlign: "center",
											marginTop: "30rem",
											verticalAlign: "middle",
											fontSize: "large",
											color: "#114D96",
										}}
									>
										No Record Found Against Your Search
									</p>
									<p
										onClick={handleClearFilter}
										style={{
											textAlign: "center",
											verticalAlign: "middle",
											fontSize: "large",
											color: "red",
											cursor: "pointer",
										}}
									>
										Clear Search
									</p>
								</div>
							)
						)}
					</>

					{!searchLoading &&
						!isLoading &&
						lawyers?.length > 0 &&
						filteredData === null && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={data?.total_records}
							/>
						)}
				</div>
			</section>
			<EmailSend
				emails={selectedEmail}
				showEmailModal={showEmailModal}
				handleEmailModalClose={handleEmailModalClose}
				handleSendEmail={handleSendEmail}
			/>
			<DeleteConfirmation
				showModal={deletePopUp}
				message={
					"Are you sure you want to delete the selected Lawyers?"
				}
				handleDelete={setDeleteLawyers}
				hideModal={handleBulkDelete}
			/>
			<ImportFromCSV
				active={importLawyers}
				setActive={handleImportLawyers}
				toImport="lawyers"
				refetch={setDeleteLawyer}
				firm={true}
			/>
			<ImportFromCSV
				active={bulkDeleteLawyers}
				setActive={handleBulkDeleteLawyers}
				toDelete="lawyers"
				refetch={setDeleteLawyers}
			/>
			<SuperAdminFilter
				active={filter}
				setActive={handleFilter}
				searchingResources={searching}
				lawyer={true}
				mediaType=""
			/>
		</Layout>
	);
};

export default ManageLawyers;
