import { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";
import searchImg from "../../../assets/images/search.svg";
import ContactCard from "../../../components/ContactCard";
import { useGetConversationsQuery } from "../../../redux/services/Lawyer/DashboardApi";
import { Button } from "@mui/material";
import ThreePIcon from "@mui/icons-material/ThreeP";

import { GetLoginUserData } from "../../../redux/selectors/Common";
import RightChatScreen from "../../../components/RightChatScreen";
import LawyersContactModal from "../../../components/Popup/LawyersContactModal";

const Chat = () => {
	let userData: any = GetLoginUserData();
	const userName = userData?.lawyer?.name;
	const [search, setSearch]: any = useState();
	const [receiver, setReceiver]: any = useState();
	const [isContactList, setIsContactList]: any = useState(false);
	const [conversations, setConversations]: any = useState([]);
	const [showRightChat, setShowRightChat]: any = useState(false);
	const [conversationId, setConversationId]: any = useState();
	const [channelName, setChannelName]: any = useState();

	const handleContactListModel = () => {
		setIsContactList(!isContactList);
	};

	const {
		data: conversationsList,
		isLoading: getConvLoading,
		refetch,
	} = useGetConversationsQuery({});

	useEffect(() => {
		if (getConvLoading) {
			setConversations([]);
		} else {
			setConversations(conversationsList?.conversations);
		}
	}, [conversationsList]);

	useEffect(() => {
		if (search) {
			const searchResult = conversationsList?.conversations.filter(
				(item: any) => {
					if (item.receiver_name !== userData?.lawyer?.name) {
						return item.receiver_name
							.toLowerCase()
							.includes(search.toLowerCase());
					} else {
						return item.sender_name
							.toLowerCase()
							.includes(search.toLowerCase());
					}
				}
			);
			setConversations(searchResult);
		} else {
			setConversations(conversationsList?.conversations);
		}
	}, [search]);

	useEffect(() => {
		refetch();
	}, []);

	return (
		<>
			<Layout>
				<section id="chatBox" className=" sec_pad_btm ">
					<div className="contain sm">
						<div className="Top_blk">
							<TopBlock title="Chat" url="dashboard" />
						</div>
						<div className="chatBox">
							<div className="chatBox_outer">
								<div className="row ">
									{/* LEFT SIDE BLOCK */}

									<div className="col-md-4 ">
										<div className="left_blk_chat">
											<div className="contacts_blk_top">
												<img
													src={
														userData?.profile_image
													}
													style={{
														height: "8rem",
														width: "8rem",
														borderRadius: "50%",
														display: "inline-block",
														marginRight: "1rem",
													}}
												/>
												<h4
													style={{
														display: "inline-block",
													}}
												>
													{userData?.lawyer?.name}
												</h4>

												<div className="search_blk_chat">
													<div className="img_blk">
														<img
															src={searchImg}
															alt=""
														/>
													</div>
													<input
														type="search"
														value={search}
														placeholder="Search Chats"
														onChange={(e) => {
															setSearch(
																e.target.value
															);
														}}
													/>
												</div>
											</div>
											<div className="contacts_blk">
												{conversations?.map(
													(conversation: any) => {
														return (
															<ContactCard
																item={
																	conversation
																}
																key={
																	conversation.id
																}
																currentUserName={
																	userData
																		?.lawyer
																		?.name || userData?.firm?.name
																}
																setReciever={
																	setReceiver
																}
																setShowRightChat={
																	setShowRightChat
																}
																setConversationId={
																	setConversationId
																}
																setChannelName={
																	setChannelName
																}
																setSearch={setSearch}
															/>
														);
													}
												)}
											</div>
											<div
												style={{
													position: "absolute",
													right: "0",
													bottom: "0",
													padding: "1rem",
													color: "#114d96",
													borderRadius: "50%",
												}}
												onClick={handleContactListModel}
											>
												<ThreePIcon
													sx={{
														height: "3em",
														width: "3em",
													}}
													style={{cursor: "pointer"}}
												/>
											</div>
										</div>
									</div>

									{/* RIGHT SIDE BLOCK */}

									<div className="col-md-8">
										<div className="right_blk_chat">
											{!showRightChat && (
												<>
													<p>no conversations yet</p>
													<Button
														disableRipple
														variant="contained"
														className="start_conversation_btn"
														onClick={
															handleContactListModel
														}
													>
														Click to Start
													</Button>
												</>
											)}
											{receiver && showRightChat && (
												<RightChatScreen
													receiver={receiver}
													key={receiver.id}
													conversationId={
														conversationId
													}
													channelName={channelName}
													refetchList={refetch}
													setReceiver={setReceiver}
													setShowRightChat={setShowRightChat}
													currentUserName={
														userData
															?.lawyer
															?.name || userData?.firm?.name
													}
												/>
											)}
										</div>
										<LawyersContactModal
											active={isContactList}
											setActive={handleContactListModel}
											setReceiver={setReceiver}
											refetch={refetch}
											setChannelName={setChannelName}
											channelName={channelName}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</>
	);
};

export default Chat;
