import icon1 from '../assets/images/icon1.svg';
import icon4 from '../assets/images/icon4.svg';
import lawyerUser from '../assets/images/lawyerUser.svg';
import resource from '../assets/images/resource.svg';
import download from '../assets/images/download.svg';
import tic from '../assets/images/tic.svg';
import pdf from '../assets/images/pdf.svg';
import processing from '../assets/images/processing.svg';
import eventCard from '../assets/images/eventCard.svg';
import eventCard2 from '../assets/images/eventCard2.jpg';
import eventCard3 from '../assets/images/eventCard3.jpg';
import lawyerIcon3 from '../assets/images/lawyerIcon3.svg';
import lawyerIcon4 from '../assets/images/lawyerIcon4.svg';
import news1 from '../assets/images/news1.jpg';
import news2 from '../assets/images/news2.jpg';
import news3 from '../assets/images/news3.jpg';
import news4 from '../assets/images/news4.jpg';

const ClientDashboard = [
	{
		id: '1',
		title: 'Search Directory',
		image: icon1,
		link: '/directory',
	},
	{
		id: '2',
		title: 'Global Employer',
		title2: 'Handbook ',
		image: icon4,
		link: '/handbook',
	},
	{
		id: '3',
		title: 'Media Library',
		image: lawyerIcon3,
		link: '/all-media',
	},
	{
		id: '4',
		title: 'Settings',
		image: lawyerIcon4,
		link: '/settings',
	},
];
const LAWYERDIRECTORY = [
	{
		id: '1',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '2',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		employment: 'Employment Lawyer',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '3',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '4',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '5',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
];
const LAWYERS_DATA = [
	{
		id: '1',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '2',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		employment: 'Employment Lawyer',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '3',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '4',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
	{
		id: '5',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
		toggles: true,
		toggle: false,
	},
];
const REFERAL_DATA = [
	{
		id: '1',
		name1: 'William Jones',
		name2: 'Bradley Martin',
		referalBy: 'Referal By:',
		referalTo: 'Referal To:',
		action: 'Action:',
		link: 'Referal Info',
	},
	{
		id: '2',
		name1: 'William Jones',
		name2: 'Bradley Martin',
		referalBy: 'Referal By:',
		referalTo: 'Referal To:',
		action: 'Action:',
		link: 'Referal Info',
	},
	{
		id: '3',
		name1: 'William Jones',
		name2: 'Bradley Martin',
		referalBy: 'Referal By:',
		referalTo: 'Referal To:',
		action: 'Action:',
		link: 'Referal Info',
	},
	{
		id: '4',
		name1: 'William Jones',
		name2: 'Bradley Martin',
		referalBy: 'Referal By:',
		referalTo: 'Referal To:',
		action: 'Action:',
		link: 'Referal Info',
	},
	{
		id: '5',
		name1: 'William Jones',
		name2: 'Bradley Martin',
		referalBy: 'Referal By:',
		referalTo: 'Referal To:',
		action: 'Action:',
		link: 'Referal Info',
	},
];
const FIRMLAWYER_DATA = [
	{
		id: '1',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
	},
	{
		id: '2',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		employment: 'Employment Lawyer',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		image: lawyerUser,
	},
	{
		id: '3',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
	},
	{
		id: '4',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
	},
	{
		id: '5',
		name: 'William',
		title: 'Phone no',
		number: '12456788080',
		emailTitle: 'Email:',
		email: 'bradelysimon98@gmail.com',
		employment: 'Employment Lawyer',
		image: lawyerUser,
	},
];

const RESOURCE_DATA = [
	{
		id: '1',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: resource,
		download: download,
	},
	{
		id: '2',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: resource,
		download: download,
	},
	{
		id: '3',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: resource,
		download: download,
	},
];
const LAWYER_DATA = [
	{
		id: '1',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: resource,
		download: download,
		pdf: pdf,
	},
	{
		id: '2',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: resource,
		download: processing,
		pdf: pdf,
	},
	{
		id: '3',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: resource,
		download: tic,
		pdf: pdf,
	},
];
const SAVED_DATA = [
	{
		id: '1',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: pdf,
		download: download,
	},
	{
		id: '2',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: pdf,
		download: processing,
	},
	{
		id: '3',
		title: 'Lawyer Rules...PDF',
		date: '03/03/2020',
		image: pdf,
		download: tic,
	},
];
const NOTIFICATIONS_DATA = [
	{
		id: '1',
		title: 'Employment Lawyer',
		date: '29 November 2021 (13.00) ',
		record_rate: ' Record rate 1.1% ',
		time: '3m',
	},
	{
		id: '2',
		title: 'Employment Lawyer',
		date: '29 November 2021 (13.00) ',
		record_rate: ' Record rate 1.1% ',
		time: '1d',
	},
	{
		id: '3',
		title: 'Employment Lawyer',
		date: '29 November 2021 (13.00) ',
		record_rate: ' Record rate 1.1% ',
		time: '2d',
	},
	{
		id: '4',
		title: 'Employment Lawyer',
		date: '29 November 2021 (13.00) ',
		record_rate: ' Record rate 1.1% ',
		time: '3d',
	},
	{
		id: '5',
		title: 'Employment Lawyer',
		date: '29 November 2021 (13.00) ',
		record_rate: ' Record rate 1.1% ',
		time: '4d',
	},
];

const EVENTS_DATA = [
	{
		id: '1',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard,
	},
	{
		id: '2',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard2,
	},
	{
		id: '3',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard3,
	},
	{
		id: '4',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard2,
	},
	{
		id: '5',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard3,
	},
	{
		id: '6',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard2,
	},
	{
		id: '7',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard2,
	},
	{
		id: '8',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		register: 'Register',
		CardImg: eventCard,
	},
];

const NEWSARTICLES_DATA = [
	{
		id: '1',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news1,
	},
	{
		id: '2',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news2,
	},
	{
		id: '3',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news3,
	},
	{
		id: '4',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news4,
	},
	{
		id: '5',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news1,
	},
	{
		id: '6',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news3,
	},
	{
		id: '7',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news2,
	},
	{
		id: '8',
		title: 'Latin America: 2022 Employment Law Year in Review Series',
		date: '13-Mar-2022',
		CardImg: news1,
	},
];
export {
	LAWYERS_DATA,
	REFERAL_DATA,
	FIRMLAWYER_DATA,
	RESOURCE_DATA,
	LAWYER_DATA,
	SAVED_DATA,
	NOTIFICATIONS_DATA,
	EVENTS_DATA,
	NEWSARTICLES_DATA,
	LAWYERDIRECTORY,
	ClientDashboard,
};
