import { ILawyer } from "../../../../interfaces/Lawyer";
import { IAction } from "../../../../interfaces/Common";
import { LawyerActionTypes as ActionTypes } from "../../../action/Lawyer/User";

const initialState: ILawyer = {
	id: undefined,
	name: undefined,
	email: undefined,
	member_type: undefined,
	phone: undefined,
	profile_image: undefined,
	firm_json: {
		firm_name: undefined,
		country: undefined,
		law_type: undefined,
		firm_address: undefined,
	},
};

const lawyerReducer = (state = initialState, action: IAction<ILawyer>) => {
	switch (action.type) {
		case ActionTypes.SET_LAWYER:
			return {
				...state,
				id: action.payload?.id,
				name: action.payload?.name,
				email: action.payload?.email,
				member_type: action.payload?.member_type,
				phone: action.payload?.phone,
				profile_image: action.payload?.profile_image,
				firm_json: {
					firm_name: action.payload?.firm_json.firm_name,
					country: action.payload?.firm_json.country,
					law_type: action.payload?.firm_json.law_type,
					firm_address: action.payload?.firm_json.firm_address,
				},
			};
		case ActionTypes.RESET_LAWYER:
			return {
				...state,
				id: undefined,
				name: undefined,
				email: undefined,
				member_type: undefined,
				phone: undefined,
				profile_image: undefined,
				firm_json: {
					firm_name: undefined,
					country: undefined,
					law_type: undefined,
					firm_address: undefined,
				},
			};
		default:
			return state;
	}
};

export default lawyerReducer;
