import { combineReducers } from "redux";
import firmReducer from "./User";
import dashboardReducer from "./Dashboard";

const adminReducer = combineReducers({
	firmReducer,
	dashboardReducer,
});

export default adminReducer;
