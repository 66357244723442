import { Link } from "react-router-dom";
import TextInput from "../../../components/TextInput";
import { Envelope } from "../../../icons";

export default function Forgot() {

    return (
        <>
            <section id="logon">
                <div className="contain">
                    <div className="flex_blk">
                        <form action="" method="POST" className="auth-form">
                            <div className="log_blk">
                                <div className="inner">
                                    <div className="txt text-center mb-5 pb-3">
                                        <h2>Reset Password</h2>
                                        <p className="fs_6 opacity-50">Enter the email address associated with your account and we’ll send a verification code</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="fw_500 require">Email Address</h5>
                                            <div className="form_blk">
                                                <Envelope />
                                                <TextInput placeholder="eg: sample@gmail.com" type="email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn_blk justify-content-center mt-5 pt-3">
                                        {/* <Button label="Send Verification Code" type="submit" onClick={handleSubmit} className="site_btn w-100" /> */}
                                        <Link to="/client/verification" className="site_btn w-100">Send Verification Code</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
