import { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import TopBlock from "../../../../components/TopBlock";
import { Checkbox } from "@mui/material";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import ReactPagination from "../../../../components/ReactPagination/ReactPagination";
import "./messages.scss";
import MessagesCard from "../../../../components/MessagesCard";
import { useDeleteChatMutation, useDeleteContactMutation, useGetChatsQuery } from "../../../../redux/services/Firm/FirmLawyer";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";

const ManageMessages = () => {

	const [selectAll, setSelectAll] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [deleteChat, setDeleteChat] = useState(false);

	const {
		data: chatsData,
		isLoading,
		refetch,
	} = useGetChatsQuery({ rowsPerPage, pageNumber });

	const [
		deleteChatCall,
		{ data: contactData, isSuccess: chatSuccess, isError: chatError }
	] = useDeleteChatMutation();

	const handleSelectAllChange = () => {
		if (selectAll) {	
			setSelectedItems([]);
		} else {
			setSelectedItems(
				chatsData.chats?.map((item: { id: any }) => item.id)
			);
		}
		setSelectAll(!selectAll);	
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	const handleSelect = (id: any) => {
		handleItemSelect(id);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	const handleExportCsv = () => {
		const csvData = createCsvData(chatsData?.chats);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = "exported-admin-messages.csv";
		link.href = url;
		link.click();
	}

	const createCsvData = (data: any) => {

		let csvData = `"Name","Email","Phone","Message"\n`;

		data?.forEach((item: any) => {
			csvData = csvData + `"${item.name}","${item.email}","${item.phone}","${item.message}"\n`;
		});

		return csvData;
	}

	useEffect(() => {
		if (selectedItems.length === 0) {
			setSelectAll(false);
		}
	}, [selectedItems]);

	useEffect(() => {
		if (deleteChat) {
			deleteChatCall({ id: selectedItems });
			setDeleteChat(false);

		}
		if (chatSuccess) {
			refetch();
			setSelectedItems([]);
			toast.success("Chat Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
		}
		if (chatError) {
			toast.error(
				"Could not delete Chat at the moment, Please try again"
			);
		}
		setDeletePopUp(false);
	}, [deleteChat, chatSuccess, chatError]);

	const refetchData = () => {
		refetch();

	}

	useEffect(() => {
		refetchData();
	}, []);

	return (
		<Layout>
			<ScreenLoader show={isLoading} />
			<section id='admin_manage_messages' className='sec_pad_btm '>
				<div className='contain sm'>
					<TopBlock title='Messages' url='dashboard' />
					<div
						className='search_container'
						style={{
							justifyContent: "space-between",
							backgroundColor: "#63a744",
							alignItems: "center",
							padding: "1rem",
							borderRadius: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginLeft: "0.5rem",
								}}
							>
								<Checkbox
									checked={selectAll}
									classes={{ root: "custom-checkbox-root" }}
									onClick={() => {
										handleSelectAllChange();
									}}
								/>
								<p
									style={{
										fontSize: "2rem",
										lineHeight: "7rem",
										marginLeft: "0.5rem"
									}}
								>
									Select All
								</p>
							</div>
						</div>
						<div>
						{selectedItems?.length > 1 && (
							<Button
								variant='danger'
								onClick={handleBulkDelete}
								style={{
									fontSize: "2rem",
									width: "15rem",
									height: "5rem",
									marginRight: "2rem",
								}}
							>
								Delete
							</Button>
						)}
						<Button
							variant='primary'
							onClick={handleExportCsv}
							className="export_button"
						>
							Export All Messages History In CSV
						</Button>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							margin: "auto",
							width: "100%",
						}}
					>
						{chatsData?.chats?.map((item: any) => {
							return (
								<MessagesCard
									item={item}
									key={item.id}
									checkbox={true}
									handleSelect={handleSelect}
									isSelected={selectedItems.includes(item.id)}
									data={chatsData?.chats}
									refetch={refetchData}
								/>
							);
						})}
						{!isLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={chatsData?.total_records}
							/>
						)}
					</div>
					<DeleteConfirmation
						showModal={deletePopUp}
						message={
							"Are you sure you want to delete the selected messages?"
						}
						handleDelete={setDeleteChat}
						hideModal={handleBulkDelete}
					/>
				</div>
			</section>
		</Layout>
	);
};
export default ManageMessages;
