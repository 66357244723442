import { useState } from "react";
import Modal from "../../../components/Modal";
import LoginForm from "./handbook";

export default function Handbook() {
    const [modalShow, setModalShow]: any = useState(false);
    const handleModal = () => {
        setModalShow(!modalShow);
    };
    return (
        <>
            {/* <Button label="Login" onClick={handleModal} /> */}
            <LoginForm />
            <Modal children={<LoginForm />} show={modalShow} onHide={handleModal} />
        </>
    );
}
