import { ArrowLeft } from "../../../icons";
import DirectoryForm from "./DirectoryForm";
const DirectoryFilter = ({
	active,
	setActive,
	handlerSearchResources,
}: any) => {
	return (
		<>
			<section
				id="filter_popup"
				className={active ? "popup active" : "popup"}
			>
				<div className="overlay" onClick={setActive}></div>
				<div className="table_dv">
					<div className="table_cell">
						<div className="contain">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-8 col-sm-10">
									<div className="_inner">
										<button
											type="button"
											className="back_btn"
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<h2 className="text-center">Filter</h2>
										<DirectoryForm
											handlerSearchResources={
												handlerSearchResources
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default DirectoryFilter;
