import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ClientDirectoryCard from "../ClientDirectoryCard";
import "./paginator.scss";
import LawyerDirectoryCard from "../LawyerDirectoryCard";
import RegisteredFirmsCard from "../RegisteredFirmsCard";
import ReferalCard from "../ReferalCard";
import ManageLawyersCard from "../ManageLawyersCard";
import ResourcesCard from "../ResourcesCard";
import MediaLibraryCard from "../MediaLibraryCards/MediaLibraryCard";

const PaginatedItems = (data: any) => {
	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = data?.itemsPerPage + itemOffset;
	const currentItems = data?.items?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.items?.length / data?.itemsPerPage);

	const handlePageClick = (event: any) => {
		const newOffset =
			(event.selected * data?.itemsPerPage) % data?.items?.length;
		setItemOffset(newOffset);
	};
	return (
		<>
			{/* ---------------------------------------------------------------------------------------------------- */}
			{/* Super Admin */}
			{data?.superAdmin &&
				currentItems?.map((item: any) => {
					return <RegisteredFirmsCard item={item} key={item.id}
						setDelete={data?.setDelete} setUpdate={data?.setUpdate || data.setRefetch}
						isSuperAdmin={data?.isSuperAdmin} isClient={data?.isClient}
						isJurisdiction={data?.isJurisdiction} isFirm={data.isFirm} />;
				})}

			{
				data.mediaLibrary &&
				currentItems.map((item: any) => {
					return (<div key={item.id} style={{ width: '80%', alignSelf: 'center' }}>
						<MediaLibraryCard item={item} isDeleted={data.podcastDeleted}
							setRefetch={data.refetch} toDelete={data.toDelete} />
					</div>)
				})
			}

			{/* ---------------------------------------------------------------------------------------------------- */}

			{data?.directory &&
				currentItems?.map((item: any) => {
					return <ClientDirectoryCard item={item} key={item.id} />;
				})}
			{data?.lawyer &&
				currentItems?.map((item: any) => {
					return <LawyerDirectoryCard item={item} key={item.id} />;
				})}

			{data?.recentReferal &&
				currentItems?.map((item: any) => {
					return <ReferalCard item={item} key={item.id} refetch={data?.refetch}/>;
				})}

			{data?.incomingReferal &&
				currentItems?.map((item: any) => {
					return <ReferalCard item={item} key={item.id} refetch={data?.refetch}/>;
				})}

			{data?.outgoingReferal &&
				currentItems?.map((item: any) => {
					return <ReferalCard item={item} key={item.id} refetch={data?.refetch} admin={data?.admin}/>;
				})}

			{data?.firmLawyers &&
				currentItems?.map((item: any) => {
					return <ManageLawyersCard item={item} />;
				})}

			{data?.handbooks &&
				currentItems?.map((item: any, index: any) => {
					return <ResourcesCard
						item={item}
						key={index + `${item.law}`}
					/>
				})}


			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					padding: "2rem",
					boxSizing: "border-box",
					width: "100%",
					height: "100%",
				}}
			>
				<ReactPaginate
					activeClassName={"Paginatoritem activeTab "}
					breakClassName={"Paginatoritem break-me "}
					breakLabel={"..."}
					containerClassName={"pagination"}
					disabledClassName={"disabled-page"}
					marginPagesDisplayed={5}
					nextClassName={"Paginatoritem next "}
					nextLabel={
						<ArrowForwardIosIcon
							style={{ fontSize: 18, width: 150 }}
						/>
					}
					onPageChange={handlePageClick}
					pageCount={pageCount}
					pageClassName={"Paginatoritem pagination-page "}
					pageRangeDisplayed={2}
					previousClassName={"Paginatoritem previous"}
					previousLabel={
						<ArrowBackIosIcon
							style={{ fontSize: 18, width: 150 }}
						/>
					}
				/>
			</div>
		</>
	);
};
export default PaginatedItems;
