import React from "react";
import twitter from "../../../assets/images/twitter.svg";
import skype from "../../../assets/images/Skype.svg";
import linkedIn from "../../../assets/images/Linkedin.svg";
import facebook from "../../../assets/images/Facebook.svg";
import vimeo from "../../../assets/images/vimeo.svg";


const TopBar = () => {
  return (
    <>
      <div id="top_bar">
        <div className="contain">
          <ul>
            <li>
              <a href="https://twitter.com/elaglobal">
                <img src={twitter} alt="icon" />
              </a>
            </li>
            {/* <li>
              <a href="?">
                <img src={skype} alt="icon" />
              </a>
            </li> */}
            <li>
              <a href="https://www.linkedin.com/company/employment-law-alliance">
                <img src={linkedIn} alt="icon" />
              </a>
            </li>
            {/* <li>
              <a href="?">
                <img src={facebook} alt="icon" />
              </a>
            </li> */}
            <li>
              <a href="https://vimeo.com/employmentlawalliance">
                <img src={vimeo} alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TopBar;
