import {
	IAddLawyer,
	IFirmLawyerDetails,
	IFirmLawyerObj,
	IFirmLawyers,
	IFirmLawyersArray,
	IFirmsArray,
	IGenerateCode,
	IResAddLawyer,
	ReferralArray,
	ReferralURL,
} from "../../../../interfaces/Admin";
import { authApiSlice } from "../../authApiSlice";

const firmLawyerApiSlice = authApiSlice.injectEndpoints({
	endpoints: (build) => ({
		getFirmLawyer: build.query<IFirmLawyersArray, any>({
			query: () => {
				return {
					url: "firms/lawyers.json",
				};
			},
			keepUnusedDataFor: 0,
		}),
		getFirmAnalyticsData: build.query<any, any>({
			query: (data) => {
				return {
					url: `/firms/lawyers/firm_analytics`,
					method: "GET",
				};
			},
		}),
		getAllFirms: build.query<any, any>({
			query: () => {
				return {
					url: "admins/get_all_firms",
					headers: {
						Accept: "text/csv",
					},
				};
			},
			keepUnusedDataFor: 0,
		}),
		getFirmLawyerDetail: build.query<IFirmLawyerObj, any>({
			query: ({ id }) => {
				return {
					url: `firms/lawyers/${id}`,
				};
			},
		}),
		getFirmInfo: build.query<IFirmLawyerDetails, any>({
			query: ({ id }) => {
				return {
					url: `admins/firm_lawyers?firm_id=${id}`,
				};
			},
			keepUnusedDataFor: 3,
		}),
		generateFirmCode: build.mutation<IGenerateCode, any>({
			query: () => {
				return {
					url: "firms/lawyers/generate_firm_code",
				};
			},
		}),
		getFirmsList: build.query<IFirmsArray, any>({
			query: (data) => {
				let url = `/admins/all_firms_list?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}&value=Admin`;
				if (data.registered) {
					url += `&registered=${data.registered}`;
				} else if (data.registered == false) {
					url += `&registered=${data.registered}`;
				}
				if (data.this_month) {
					url += `&this_month=${data.this_month}`;
				}

				return {
					url,
				};
			},
			keepUnusedDataFor: 0,
		}),

		addFirmLawyer: build.mutation<IResAddLawyer, IAddLawyer>({
			query: (data) => {
				let formData = new FormData();
				formData.append("invitation[email]", data.email.toLowerCase());
				formData.append("invitation[firm_code]", data.firm_code);
				formData.append("invitation[firm_id]", data.firm_id);
				formData.append(
					"invitation[profile_image]",
					data?.profile_image
				);
				formData.append("invitation[name]", data?.name);

				return {
					url: "firms/lawyers/invite_new_lawyer",
					method: "POST",
					body: formData,
				};
			},
		}),
		updateFirmLawyer: build.mutation<IFirmLawyers, any>({
			// TODO: added interface for isActive and isVisible
			query: (data) => {
				let formData = new FormData();
				formData.append(
					"lawyer[is_active]",
					data.isActive ? data.isActive : true
				);
				formData.append(
					"lawyer[is_visible]",
					data.isVisible ? data.isVisible : true
				);
				return {
					url: `firms/lawyers/`,
					method: "PATCH",
					body: formData,
				};
			},
		}),
		GetReferralLawyer: build.mutation<ReferralArray, ReferralURL>({
			query: (data) => {
				return {
					url: data.url,
					method: "GET",
				};
			},
		}),
		GetReferralLawyerQuery: build.query<ReferralArray, any>({
			query: (data) => {
				return {
					url: data.url,
				};
			},
		}),
		GetExportReferralLawyer: build.query<any, any>({
			query: (data) => {
				return {
					url: data.url,
				};
			},
		}),
		deleteFirmLawyer: build.mutation<any, any>({
			query: (data) => {
				const body = {
					id: data.id ? data.id : data,
				};
				return {
					url: `admins/lawyer`,
					method: "DELETE",
					body: body,
				};
			},
		}),
		deleteContact: build.mutation<any, any>({
			query: (data) => {
				const body = {
					id: data.id ? data.id : data,
				};
				return {
					url: `contacts/delete`,
					method: "DELETE",
					body: body,
				};
			},
		}),
		deleteAllContact: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `contacts/deleteAll`,
					method: "DELETE",
					body: data,
				};
			},
		}),
		createContact: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`contact[email]`, values.email);
				if (values.phone.includes("+")) {
					formData.append(`contact[phone]`, values.phone);
				} else {
					formData.append(`contact[phone]`, "+" + values.phone);
				}
				formData.append(`contact[name]`, values.name);
				formData.append(`contact[lawyer_id]`, values.lawyer_id);
				formData.append(`contact[profile_image]`, values.profile_image);

				return {
					url: values.id ? `contacts?id=${values.id}` : `contacts`,
					method: "POST",
					body: formData,
				};
			},
		}),
		createChat: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`support_chat[email]`, values.email);
				if (values?.phone?.includes("+")) {
					formData.append(`support_chat[phone]`, values.phone);
				} else {
					formData.append(`support_chat[phone]`, "+" + values.phone);
				}
				formData.append(`support_chat[name]`, values.name);
				formData.append(`support_chat[message]`, values.message);
				return {
					url: `support_chats`,
					method: "POST",
					body: formData,
				};
			},
		}),
		getChats: build.query<any, any>({
			query: (data) => {
				return {
					url:
						data.rowsPerPage && data.pageNumber
							? `/support_chats?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`
							: "/contacts",
					method: "GET",
				};
			},
		}),
		deleteChat: build.mutation<any, any>({
			query: (data) => {
				const body = {
					id: data.id ? data.id : data,
				};
				return {
					url: `support_chats/delete`,
					method: "DELETE",
					body: body,
				};
			},
		}),
		getAllLawyers: build.query<any, any>({
			query: () => {
				return {
					url: "admins/get_all_lawyers",
					headers: {
						Accept: "text/csv",
					},
				};
			},
			keepUnusedDataFor: 0,
		}),
		createFirmLawyers: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`lawyer[email]`, values.email);
				if (values.phone.includes("+")) {
					formData.append(`lawyer[phone]`, values.phone);
				} else {
					formData.append(`lawyer[phone]`, "+" + values.phone);
				}
				formData.append(`lawyer[password]`, values.password);
				formData.append(
					`lawyer[password_confirmation]`,
					values.password_confirmation || values.password
				);
				formData.append(`lawyer[name]`, values.name);
				formData.append(`lawyer[firm_id]`, values.firm_id);
				formData.append(`lawyer[member_type]`, values.member_type);
				formData.append(`lawyer[jurisdiction]`, values.jurisdiction);
				formData.append(`lawyer[profile_image]`, values.profile_image);

				return {
					url: values.lawyer_id
						? `admins/lawyer?id=${values.lawyer_id}`
						: `admins/lawyer`,
					method: "POST",
					body: formData,
				};
			},
		}),
		createNewFirm: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`firm[email]`, values.email);
				if (values.phone.includes("+")) {
					formData.append(`firm[phone]`, values.phone);
				} else {
					formData.append(`firm[phone]`, "+" + values.phone);
				}
				formData.append(`firm[password]`, values.password);
				formData.append(
					`firm[password_confirmation]`,
					values.password_confirmation || values.password
				);
				formData.append(`firm[name]`, values.name);
				formData.append(`firm[profile_image]`, values.profile_image);
				formData.append(`firm[firm_url]`, values.firm_url);
				formData.append(`firm[region]`, values.region);
				formData.append(`firm[firm_Address]`, values?.firm_Address);
				formData.append(`firm[jurisdiction]`, values.jurisdiction);
				formData.append(`firm[role]`, values.role);
				formData.append(`firm[referral_email]`, values.referral_email);

				return {
					url: values.firm_id
						? `admins/firm?id=${values.firm_id}`
						: `admins/firm`,
					method: "POST",
					body: formData,
				};
			},
		}),

		createNewHandBook: build.mutation<any, any>({
			query: (values) => {
				const checkStatus = !values.status;
				let formData = new FormData();
				formData.append(
					`law[additional_information]`,
					values?.additional_information
				);
				formData.append(`law[compensation]`, values?.compensation);
				formData.append(
					`law[discrimination_and_harassment]`,
					values?.discrimination_and_harassment
				);
				formData.append(`law[introduction]`, values?.introduction);
				formData.append(
					`law[employee_injurie_and_workers_compensation]`,
					values?.employee_injurie_and_workers_compensation
				);
				formData.append(
					`law[health_and_safety]`,
					values?.health_and_safety
				);
				formData.append(`law[hiring]`, values?.hiring);
				formData.append(
					`law[immigration_labor_migration]`,
					values?.immigration_labor_migration
				);
				formData.append(
					`law[layoffs_workforce_reductions_redundancies_collective_dismissals]`,
					values?.layoffs_workforce_reductions_redundancies_collective_dismissals
				);
				formData.append(
					`law[personnel_administration]`,
					values?.personnel_administration
				);
				formData.append(`law[privacy]`, values?.privacy);
				formData.append(
					`law[termination_dismissal_issues]`,
					values?.termination_dismissal_issues
				);
				formData.append(
					`law[trade_union_industrial_relations]`,
					values?.trade_union_industrial_relations
				);
				formData.append(
					`law[off_leaves_of_absence]`,
					values?.off_leaves_of_absence
				);
				formData.append(
					`law[unemployment_compensation]`,
					values?.unemployment_compensation
				);
				formData.append(
					`law[unfair_competition_covenants_not_to_compete]`,
					values?.unfair_competition_covenants_not_to_compete
				);
				formData.append(`law[contact]`, values?.contact);
				formData.append(`law[state_id]`, values?.state_id);
				formData.append(`law[rich_text_status]`, values?.status);
				formData.append(`law[pdf_link_status]`, checkStatus.toString());

				return {
					url: values?.handbook_id
						? `admins/laws?id=${values?.handbook_id}`
						: `admins/laws`,
					method: "POST",
					body: formData,
				};
			},
		}),

		deleteFirm: build.mutation<any, any>({
			query: (data) => {
				const body = { id: data };
				return {
					url: `admins/firm`,
					method: "DELETE",
					body: body,
				};
			},
		}),

		getFirmSearch: build.mutation<any, any>({
			query: (data) => {
				return {
					url: !data.lawyer_search
						? `/admins/firms_list?value=${
								data.isSuperAdmin ? "Super Admin" : "Admin"
						  }`
						: `/admins/firm_lawyers?search=${data.search}&firm_id=${data.firm_id}`,
					method: !data.lawyer_search ? "POST" : "GET",
					...(data.lawyer_search ? {} : { body: data }),
				};
			},
		}),

		getSuperAdminList: build.query<IFirmsArray, any>({
			query: () => {
				return {
					url: "admins/firms_list?value=Super Admin",
					method: "GET",
				};
			},
			keepUnusedDataFor: 0,
		}),

		getClientList: build.query<any, any>({
			query: (data) => {
				let url = `/admins/client_list?records_per_page=${data.rowsPerPage}`;
				if (data.pageNumber !== undefined) {
					url += `&page_number=${data.pageNumber}`;
				}
				if (data.registered) {
					url += `&registered=${data.registered}`;
				} else if (data.registered == false) {
					url += `&registered=${data.registered}`;
				}
				if (data.this_month) {
					url += `&this_month=${data.this_month}`;
				}
				console.log("url", url)
				return {
					url: url,
					method: "GET",
				};
			},
			keepUnusedDataFor: 0,
		}),

		getClientSearch: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `/admins/client_list?search=${data.search}`,
					method: "GET",
				};
			},
		}),

		getLawyerSearch: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `/admins/lawyers_search`,
					method: "POST",
					body: data,
				};
			},
		}),

		getLawyerList: build.query<any, any>({
			query: (data) => {
				let url = `/admins/show_lawyers_list?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`;
				if (data.registered) {
					url += `&registered=${data.registered}`;
				}else if(data.registered ==false){
					url += `&registered=${data.registered}`;
				}
				if (data.this_month) {
					url += `&this_month=${data.this_month}`;
				}

				return {
					url: url,
				};
			},
			keepUnusedDataFor: 0,
		}),

		deleteClient: build.mutation<any, any>({
			query: (data) => {
				const body = { id: data };
				return {
					url: `admins/client`,
					method: "DELETE",
					body: body,
				};
			},
		}),

		createNewClient: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`client[email]`, values.email);
				if (values.phone.includes("+")) {
					formData.append(`client[phone]`, values.phone);
				} else {
					formData.append(`client[phone]`, "+" + values.phone);
				}
				formData.append(`client[password]`, values.password);
				formData.append(
					`client[password_confirmation]`,
					values.password_confirmation || values.password
				);
				formData.append(`client[name]`, values.name);
				formData.append(`client[profile_image]`, values.profile_image);

				return {
					url: values.client_id
						? `admins/client?id=${values.client_id}`
						: `admins/client`,
					method: "POST",
					body: formData,
				};
			},
		}),

		createNewMedia: build.mutation<any, any>({
			query: (values) => {
				let url;
				let formData = new FormData();

				const date = new Date(values.date);
				const day = ("0" + date.getDate()).slice(-2);
				const month = ("0" + (date.getMonth() + 1)).slice(-2);
				const year = date.getFullYear();
				const newDate = `${year}-${month}-${day}`;

				if (values.mediaLibrary === "podcast") {
					formData.append(`podcast[name]`, values.name);
					formData.append(`podcast[location]`, values.location);
					formData.append(`podcast[description]`, values.description);
					formData.append(`podcast[featured]`, values.featured);
					formData.append(`podcast[pod_cast_date]`, newDate);
					formData.append(`podcast[image]`, values.image);
					formData.append(`podcast[link]`, values.link);
					formData.append(`podcast[category]`, values.category);

					url = values.isUpdate
						? `admins/podcast?id=${values.id}`
						: `admins/podcast`;
				} else if (values.mediaLibrary === "news") {
					formData.append(`news[name]`, values.name);
					formData.append(`news[location]`, values.location);
					formData.append(`news[description]`, values.description);
					formData.append(`news[featured]`, values.featured);
					formData.append(`news[news_date]`, newDate);
					formData.append(`news[image]`, values.image);
					formData.append(`news[link]`, values.link);
					formData.append(`news[category]`, values.category);

					url = values.isUpdate
						? `admins/news?id=${values.id}`
						: `admins/news`;
				} else if (values.mediaLibrary === "webinar") {
					formData.append(`webnar[name]`, values.name);
					formData.append(`webnar[location]`, values.location);
					formData.append(`webnar[description]`, values.description);
					formData.append(`webnar[featured]`, values.featured);
					formData.append(`webnar[webnars_date]`, newDate);
					formData.append(`webnar[image]`, values.image);
					formData.append(`webnar[link]`, values.link);
					formData.append(`webnar[category]`, values.category);
					formData.append(`webnar[webnar_type]`, values.webnar_type);

					url = values.isUpdate
						? `admins/webinar?id=${values.id}`
						: `admins/webinar`;
				} else if (values.mediaLibrary === "event") {
					formData.append(`event[name]`, values.name);
					formData.append(`event[location]`, values.location);
					formData.append(`event[description]`, values.description);
					formData.append(`event[featured]`, values.featured);
					formData.append(`event[event_date]`, newDate);
					formData.append(`event[image]`, values.image);
					formData.append(`event[link]`, values.link);
					formData.append(`event[category]`, values.category);

					url = values.isUpdate
						? `admins/event?id=${values.id}`
						: `admins/event`;
				}
				return {
					url: url || "",
					method: "POST",
					body: formData,
				};
			},
		}),
		getMediaLibrarySearchHandbook: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `admins/search_handbook?search=${data}`,
					method: "GET",
				};
			},
		}),

		getMediaLibrarySearch: build.query<any, any>({
			query: (data) => {
				console.log("🚀 ~ file: index.ts:482 ~ data:", data);
				let toSearch = "";
				if (data?.toSearch === "podcast") {
					toSearch = "search_podcast";
				} else if (data?.toSearch === "events") {
					toSearch = "search_event";
				} else if (data?.toSearch === "webinar") {
					toSearch = "search_webinar";
				} else {
					toSearch = "search_news";
				}
				const searchParams = new URLSearchParams(data?.filterObj);
				return {
					url: data?.filterObj
						? `admins/${toSearch}?${searchParams}&records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`
						: `admins/${toSearch}?search=${data?.search}&records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`,
					method: "GET",
				};
			},
		}),

		deleteMediaLibrary: build.mutation<any, any>({
			query: (data) => {
				let url = "";
				const body = {
					id: data.selectedItems ? data.selectedItems : data.id,
				};

				if (data.toDelete === "news") {
					url = `admins/news`;
				} else if (data.toDelete === "podcast") {
					url = `admins/podcast`;
				} else if (data.toDelete === "webinar") {
					url = `admins/webinar`;
				} else if (data.toDelete === "handbook") {
					url = `admins/laws`;
				} else {
					url = `admins/event`;
				}

				return {
					url: url,
					method: "DELETE",
					body: body,
				};
			},
		}),

		getJurisdictionList: build.query<any, any>({
			query: () => {
				return {
					url: "admins/jurisdiction",
					method: "GET",
				};
			},
			keepUnusedDataFor: 0,
		}),
		getReferralList: build.query<any, any>({
			query: (data) => {
				let url = `admins/referrals?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`

				if (data.this_month) {
					url += `&this_month=${data.this_month}`;
				}
				return {
					url: url,
					method: "GET",
				};
			},
			keepUnusedDataFor: 0,
		}),
		getExportReferralList: build.query<any, any>({
			query: () => {
				return {
					url: `admins/export_referrals`,
					method: "GET",
				};
			},
			keepUnusedDataFor: 0,
		}),
		getJurisdictionSearch: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `/admins/jurisdiction?search=${data.search}`,
					method: "GET",
				};
			},
		}),

		deleteJurisdiction: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `admins/jurisdiction?id=${data}`,
					method: "DELETE",
				};
			},
		}),

		createNewJurisdiction: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`state[name]`, values.name);
				formData.append(`state[status]`, values.status);
				return {
					url: values.jurisdiction_id
						? `admins/jurisdiction?id=${values.jurisdiction_id}`
						: `admins/jurisdiction`,
					method: "POST",
					body: formData,
				};
			},
		}),
		uploadDataInBulk: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`type`, values.type);
				formData.append(`file`, values.csvFile);
				if (values.type === "lawyer" && values.firm_id) {
					formData.append(`firm_id`, values.firm_id);
				}
				return {
					url: "admins/bulk_upload",
					method: "POST",
					body: formData,
				};
			},
		}),
		uploadMediaDataInBulk: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`type`, values.type);
				formData.append(`file`, values.csvFile);
				return {
					url: "admins/bulk_media_upload",
					method: "POST",
					body: formData,
				};
			},
		}),
		addHandbookPdf: build.mutation<any, any>({
			query: (values) => {
				const richTextStatus = !values.status;
				let formData = new FormData();
				formData.append(`state_id`, values.state_id);
				formData.append(`pdf_link`, values.pdfLink);
				formData.append(`pdf_link_status`, values.status);
				formData.append(`rich_text_status`, richTextStatus.toString());
				return {
					url: "admins/pdf_link",
					method: "POST",
					body: formData,
				};
			},
		}),
		deleteDataFromCsv: build.mutation<any, any>({
			query: (values) => {
				let formData = new FormData();
				formData.append(`type`, values.type);
				formData.append(`file`, values.csvFile);
				return {
					url: "admins/delete_from_csv",
					method: "Delete",
					body: formData,
				};
			},
		}),
		sendLawyerEmail: build.mutation<any, any>({
			query: (data) => {
				let formData = new FormData();
				formData.append("payload[to_emails]", data.emails);
				formData.append("payload[subject]", data.subject);
				formData.append("payload[body]", data.content);

				return {
					url: "admins/send_email",
					method: "POST",
					body: formData,
				};
			},
		}),
	}),
});

export const {
	useGetFirmsListQuery,
	useGetFirmLawyerQuery,
	useGetFirmLawyerDetailQuery,
	useGetFirmInfoQuery,
	useAddFirmLawyerMutation,
	useGenerateFirmCodeMutation,
	useUpdateFirmLawyerMutation,
	useGetReferralLawyerMutation,
	useDeleteFirmLawyerMutation,
	useCreateFirmLawyersMutation,
	useDeleteFirmMutation,
	useCreateNewFirmMutation,
	useGetFirmSearchMutation,
	useGetSuperAdminListQuery,
	useCreateNewMediaMutation,
	useDeleteMediaLibraryMutation,
	useCreateNewHandBookMutation,
	useGetMediaLibrarySearchQuery,
	useGetClientListQuery,
	useCreateNewClientMutation,
	useDeleteClientMutation,
	useGetMediaLibrarySearchHandbookMutation,
	useGetClientSearchMutation,
	useGetLawyerSearchMutation,
	useCreateNewJurisdictionMutation,
	useDeleteJurisdictionMutation,
	useGetJurisdictionListQuery,
	useGetJurisdictionSearchMutation,
	useUploadDataInBulkMutation,
	useAddHandbookPdfMutation,
	useUploadMediaDataInBulkMutation,
	useGetAllFirmsQuery,
	useGetLawyerListQuery,
	useDeleteDataFromCsvMutation,
	useGetReferralLawyerQueryQuery,
	useGetReferralListQuery,
	useGetExportReferralLawyerQuery,
	useGetExportReferralListQuery,
	useDeleteContactMutation,
	useDeleteAllContactMutation,
	useCreateContactMutation,
	useGetAllLawyersQuery,
	useCreateChatMutation,
	useGetChatsQuery,
	useGetFirmAnalyticsDataQuery,
	useDeleteChatMutation,
	useSendLawyerEmailMutation,
} = firmLawyerApiSlice;
export default firmLawyerApiSlice;
