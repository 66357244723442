import search from "../../../assets/images/search.svg";
import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";

import Image from "material-ui-image";
import { useEffect, useRef, useState } from "react";
import MediaHeader from "../../../components/MediaHeader";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import { IPodcasts } from "../../../interfaces/Common";
import {
	useGetMediaSearchQuery,
	useGetPodcastsQuery,
} from "../../../redux/services/Firm/MediaLibrary";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";

const MediaLibrary = () => {
	const [category, setCategory] = useState<string>("");
	const [text, setText] = useState<any | null>("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [podcasts, setPodcasts] = useState<any>(null);
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);

	const { data: searchData, isLoading: searchLoading } =
		useGetMediaSearchQuery(text);
	const { data, isLoading, refetch } = useGetPodcastsQuery({
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber,
	});

	useEffect(() => {
		refetch();
		setPodcasts(data);
	}, []);

	useEffect(() => {
		if (text === "") {
			setPodcasts(data);
		} else {
			setPodcasts(searchData);
		}
	}, [searchData, data]);

	let categories: Array<string> = ["All Categories"];

	const handlePodcast = (category: string) => {
		if (category === "All Categories") {
			setCategory("");
		} else {
			setCategory(category);
		}
	};

	const handleSearch = (search: string) => {
		setText(search);
	};
	return (
		<Layout>
			<section id="media_library" className="sec_pad_btm">
				<div className="contain sm">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="Top_blk">
								<TopBlock title="Podcasts" url="all-media" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="left_blk">
								<div className="search_blk">
									<div className="img_blk">
										<img src={search} alt="" />
									</div>
									<input
										type="search"
										placeholder="Search"
										value={text}
										onChange={(e) =>
											handleSearch(e.target.value)
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="library">
							<MediaHeader />
							{podcasts?.podcasts.map(
								(item: IPodcasts, index: number) => {
									if (!categories.includes(item.category)) {
										categories.push(item.category);
									}
										const date = new Date(item?.created_at);
										return (
										<div
											className="col-lg-6 col-md-12 mb-5"
											key={index}
										>
											<div
												style={{ margin: "10px" }}
												className="event_card"
											>
												<a
													href={item.link}
													className="img_blk"
												>
													<Image
														src={item.image}
														alt="Event_Image"
														aspectRatio={5 / 4}
													/>
												</a>
												<div>

												<a
													href={item.link}
													className="content"
												>
													<h5>{item.name}</h5>
												</a>
												<p></p>
															<a href={item.link} style={{color: '#114d96'}} >
															<h5>{date.toDateString()}</h5>
														</a>
													</div>
											</div>
										</div>
									);
								}
							)}
						</div>
						{!isLoading && !searchLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={data?.total_records}
							/>
						)}
					</div>
				</div>
			</section>
			<ScreenLoader show={searchLoading || isLoading} />
		</Layout>
	);
};

export default MediaLibrary;
