import { Formik } from "formik";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorText from "../../../components/Shared/ErrorText";
import Toast from "../../../components/Shared/Toast";
import TextInput from "../../../components/TextInput";
import { Lock } from "../../../icons";
import { IResetInput } from "../../../interfaces/Common";
import { useGetRole } from "../../../redux/selectors/Common";
import { useResetPasswordMutation } from "../../../redux/services/Auth";
import { resetPassSchema } from "../../../utills/YupValidationSchemas";

export default function Reset() {
  const navigate = useNavigate();
  const location = useLocation();
	const state: any = location.state;
  const [resetPass, { error, isLoading, data: resetStatus, isSuccess, }] = useResetPasswordMutation();
  const handleSubmit = (values: any) => {
    const reset: IResetInput = { ...state, password: values.password, userType: state.userType }
    resetPass(reset)
  };

  useEffect(() => {
    if(resetStatus && isSuccess){
      toast.success(resetStatus.status)
      setTimeout(() => {
        navigate('/login')
      }, 3000);
    }


  }, [isSuccess, resetStatus])

  return (
    <>
      <section id="logon">
        <div className="contain">
          <div className="flex_blk">
            <Formik
              initialValues={{
                password: "",
                password_confirmation: "",
              }}
              validationSchema={resetPassSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="auth-form"
                >
                  <div className="log_blk">

                    <div className="inner">
                      <div className="txt text-center mb-5 pb-3">
                        <h2>Create New Password</h2>
                        <p className="fs_5 opacity-50">Your new password must be different from your previously used passwords</p>
                      </div>
                      <div className="col-12">
                        <h5 className="fw_500 require">
                          Password
                        </h5>
                        <div className="form_blk">
                          <Lock />
                          <TextInput
                            type="password"
                            name="password"
                            handleChange={
                              handleChange
                            }
                            handleBlur={
                              handleBlur
                            }
                            value={
                              values.password
                            }
                            className="input"
                            placeholder="eg: PassLogin%7"
                          />
                        </div>
                        <ErrorText
                          text={errors.password}
                          isTouched={
                            touched.password
                          }
                        />
                      </div>
                      <div className="col-12">
                        <h5 className="fw_500 require">
                          Confirm Password
                        </h5>
                        <div className="form_blk">
                          <Lock />
                          <TextInput
                            type="password"
                            name="password_confirmation"
                            handleChange={
                              handleChange
                            }
                            handleBlur={
                              handleBlur
                            }
                            value={
                              values.password_confirmation
                            }
                            className="input"
                            placeholder="eg: PassLogin%7"
                          />
                        </div>
                        <ErrorText
                          text={
                            errors.password_confirmation
                          }
                          isTouched={
                            touched.password_confirmation
                          }
                        />
                      </div>
                    </div>
                    <div className="btn_blk justify-content-center mt-5 pt-3">
                      <button
                        type="submit"
                        className="site_btn w-50"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <Toast/>
      </section>
    </>
  );
}
