import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.9977 20.678C10.1554 20.678 8.29088 20.5972 6.45445 20.4367L4.68595 20.2845C3.86352 20.2146 3.08874 19.8692 2.487 19.3043C1.88527 18.7393 1.49181 17.9878 1.3703 17.1714C0.930172 13.9881 0.882185 10.7627 1.22742 7.56765C1.26724 7.23269 1.31408 6.88719 1.3703 6.51358C1.4906 5.69612 1.88353 4.94327 2.48538 4.37715C3.08722 3.81102 3.86266 3.46482 4.68595 3.39469L6.45562 3.24009C8.29088 3.08081 10.1554 3 11.9977 3C13.84 3 15.7045 3.08081 17.541 3.24009L19.3095 3.39352C20.1331 3.46345 20.909 3.80971 21.5111 4.37609C22.1132 4.94247 22.5062 5.69572 22.6263 6.51358C22.679 6.86493 22.727 7.21629 22.7692 7.56765C23.115 10.7627 23.067 13.9881 22.6263 17.1714C22.5052 17.9882 22.112 18.7403 21.5102 19.3057C20.9084 19.8711 20.1334 20.2168 19.3106 20.2868L17.5421 20.4391C15.7057 20.596 13.84 20.678 11.9977 20.678ZM2.85186 8.93443C2.62587 11.5932 2.71234 14.2693 3.10952 16.9079C3.17266 17.3343 3.37798 17.7268 3.69218 18.0219C4.00638 18.317 4.41104 18.4973 4.84054 18.5335L6.60904 18.6869C8.39394 18.8427 10.2081 18.9212 11.9965 18.9212C13.7849 18.9212 15.6015 18.8427 17.384 18.6869L19.1525 18.5335C19.582 18.4973 19.9867 18.317 20.3009 18.0219C20.6151 17.7268 20.8204 17.3343 20.8835 16.9079C21.2828 14.2695 21.3709 11.5934 21.1459 8.93443L15.2688 12.1974C14.2683 12.7531 13.1428 13.0447 11.9983 13.0447C10.8538 13.0447 9.72822 12.7531 8.72773 12.1974L2.85186 8.93443ZM11.9977 4.75679C10.2058 4.75679 8.39277 4.83526 6.61021 4.99103L4.84171 5.14328C4.41183 5.17935 4.00677 5.35969 3.69231 5.65501C3.37785 5.95033 3.17247 6.34329 3.10952 6.77007C3.09547 6.86025 3.08258 6.95277 3.0697 7.04295L9.5827 10.6619C10.3222 11.0725 11.1542 11.288 12 11.288C12.8459 11.288 13.6778 11.0725 14.4174 10.6619L20.9292 7.04295C20.9163 6.95277 20.9023 6.86025 20.8894 6.77007C20.8261 6.34314 20.6203 5.95017 20.3054 5.65504C19.9905 5.35991 19.585 5.17996 19.1549 5.14445L17.3864 4.9922C15.6026 4.83643 13.7896 4.75679 11.9977 4.75679Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent
