import ArrowLeft from "../../icons/ArrowLeft";
import TextArea from "../TextArea";
import TextInput from "../TextInput";

export default function ReferralInfoModal({ active, setActive, item }: any) {
	const htmlPattern = /<[a-z][\s\S]*>/i;
	return (
		<>
			<section
				id="contactInfo_popUp"
				className={active ? "popup active" : "popup"}
			>
				<div className="overlay" onClick={setActive}></div>
				<div className="table_dv">
					<div className="table_cell">
						<div className="contain">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-8 col-sm-10">
									<div className="_inner">
										<button
											type="button"
											className="back_btn"
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<div className="row mb-5">
											<div className="col-12">
												<h5>To Email</h5>
												<div className="form_blk">
													<TextInput
														placeholder=""
														type="text"
														handleChange={() => {}}
														handleBlur={() => {}}
														value={
															item?.referred_to_email ||
															""
														}
														readOnly
													/>
												</div>
											</div>
										</div>
										<div className="row mb-5">
											<div className="col-12">
												<h5>Cc</h5>
												<div className="form_blk">
													<TextInput
														placeholder=""
														type="text"
														handleChange={() => {}}
														handleBlur={() => {}}
														value={item?.cc || ""}
														readOnly
													/>
												</div>
											</div>
										</div>
										<div className="row mb-5">
											<div className="col-12">
												<h5>Bcc</h5>
												<div className="form_blk">
													<TextInput
														placeholder=""
														type="text"
														handleChange={() => {}}
														handleBlur={() => {}}
														value={item?.bcc || ""}
														readOnly
													/>
												</div>
											</div>
										</div>
										<h5>Referral Information</h5>
										{htmlPattern?.test(
											item?.description || ""
										) ? (
											<div
												dangerouslySetInnerHTML={{
													__html: item?.description,
												}}
												style={{
													border: "1px solid #114d96",
													padding: "6px 20px",
													borderRadius: "0.5rem",
													// background: "rgba(17, 77, 150,0.05)"
												}}
											/>
										) : (
											<TextArea
												placeholder="Description"
												value={item?.description}
												type={""}
												readOnly
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
