import { isEmptyArray } from "formik";
import { useEffect, useRef, useState } from "react";
import search from "../../../assets/images/search.svg";
import Layout from "../../../components/Layout";
import PaginatedItems from "../../../components/Paginator";
import AddNewFirm from "../../../components/Popup/AddNewFirm/AddNewFirm";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import {
	useGetFirmSearchMutation,
	useGetSuperAdminListQuery,
} from "../../../redux/services/Firm/FirmLawyer";
import RegisteredFirmsCard from "../../../components/RegisteredFirmsCard";
const SuperAdmin = () => {
	const { data, isLoading, refetch } = useGetSuperAdminListQuery(null);
	const [addSuperAdmin, setAddSuperAdmin] = useState(false);
	const [deleteSuperAdmin, setDeleteSuperAdmin] = useState(false);
	const [updateSuperAdmin, setUpdateSuperAdmin] = useState(false);
	const [searchText, setSearchText] = useState<any | null>("");
	const [firms, setFirms] = useState<any | null>(null);
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);

	const [searching, { data: searchData, isLoading: searchLoading }] =
		useGetFirmSearchMutation();

	const handleSetAddFirm = () => {
		setAddSuperAdmin(!addSuperAdmin);
	};
	const handleUpdate = () => {
		setUpdateSuperAdmin(!updateSuperAdmin);
	};
	const handleDelete = () => {
		setDeleteSuperAdmin(!deleteSuperAdmin);
	};

	useEffect(() => {
		refetch();
	}, [addSuperAdmin, deleteSuperAdmin, updateSuperAdmin]);

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searching({ search, isSuperAdmin: true });
			} else {
				setFirms(data?.firms);
			}
		}, 1000);
	};
	useEffect(() => {
		if (searchData?.firms && searchText != "") {
			setFirms(searchData?.firms);
		} else {
			setFirms(data?.firms);
		}
	}, [searchData, data]);

	return (
		<Layout>
			<section id="manage_registered_firms" className="sec_pad_btm ">
				<div className="contain sm">
					<div className="top_blk mb-5">
						<TopBlock
							title="Super Admins"
							url="dashboard"
							button={true}
							setAddFirm={setAddSuperAdmin}
							buttonText="Add New Super Admin"
						/>
					</div>
					<div className="search_container mb-4">
						<div className="search_blk_lawyers">
							<div className="img_blk">
								<img src={search} alt="search icon" />
							</div>
							<input
								type="search"
								placeholder="Search"
								value={searchText}
								style={{ marginTop: "0.5rem" }}
								onChange={(e) => {
									handleSearch(e.target.value);
								}}
							/>
						</div>
					</div>
					{isLoading && <ScreenLoader show={true} />}
					{isEmptyArray(firms) ? (
						<div className="empty_lawyer_text">
							No Super Admin to show{" "}
						</div>
					) : null}

					{addSuperAdmin && (
						<AddNewFirm
							active={addSuperAdmin}
							setActive={handleSetAddFirm}
							isUpdate={false}
							isSuperAdmin={true}
						/>
					)}
					{firms?.map((item: any) => {
						return (
							<RegisteredFirmsCard
								item={item}
								key={item.id}
								setDelete={handleDelete}
								setUpdate={handleUpdate}
								isSuperAdmin={true}
								checkbox={false}
							
							/>
						);
					})}
				</div>
			</section>
		</Layout>
	);
};

export default SuperAdmin;
