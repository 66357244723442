import logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import TermsAndConditions from "../../TermsAndConditions";
import { useState } from "react";

const Header = () => {
	const [clicked, setClicked] = useState(false);
	const termsAndCondition = localStorage.getItem('termsAndCondition')
	const handleClick = () => {
		setClicked(true)
	}
	return (
		<>
			<div className='public_header'>
				<div className='contain'>
					<div className='header_container'>
						<Link to='/admin' className='logo_container'>
							<img src={logo} alt='Logo' />
						</Link>
						<div className='buttons_container'>
							{/* <div className='nav_btn'>About Us</div>
							<div className='nav_btn'>News & Insights</div> */}
							{/* <div></div>
							<div></div> */}
							{clicked && <TermsAndConditions redirect='login' />}
							{termsAndCondition === 'true' ?
								<Link to='/signup'>
									<div className='nav_btn'  >Sign Up</div>
								</Link> : <div className='nav_btn' onClick={handleClick}>Sign Up</div>}
							{termsAndCondition !== 'true' ?
								<div className='login_nav_btn' onClick={handleClick}>Login</div>
								:
								<Link to='/login'>
									<div className='login_nav_btn'>Login</div>
								</Link>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
