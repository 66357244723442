import { SVGProps } from "react";

const KeyboardIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="#BCB9B9"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.6142 22.0556H5.38576C5.36181 22.0431 5.33664 22.033 5.31064 22.0255C3.87348 21.753 3 20.7029 3 19.2433V15.4209C3 11.5245 3 7.62759 3 3.73011C3 2.73654 3.42221 1.94696 4.26389 1.42714C4.60001 1.21933 5.01016 1.13873 5.38576 1H19.6142C19.6522 1.01438 19.691 1.02646 19.7305 1.03619C21.0464 1.26045 21.9967 2.3593 21.9978 3.68898C22.0018 8.91634 22.0003 14.1422 21.9934 19.3667C21.9901 20.4957 21.4325 21.3198 20.4126 21.8078C20.1653 21.9268 19.8829 21.975 19.6142 22.0556ZM4.23428 11.5278C4.23428 14.098 4.23428 16.668 4.23428 19.2378C4.23428 20.2094 4.84182 20.8208 5.80797 20.8214H19.1942C19.3042 20.8245 19.4142 20.8184 19.5232 20.8033C20.2821 20.675 20.7657 20.0817 20.7657 19.2784C20.7657 14.1311 20.7657 8.98397 20.7657 3.83703C20.7657 2.82756 20.1669 2.23373 19.1503 2.23373H5.85129C4.83689 2.23373 4.23757 2.82976 4.23702 3.83813L4.23428 11.5278Z"
			fill="#BCB9B9"
		/>
		<path
			d="M16.7502 17.2663C17.0677 16.9466 17.3506 16.6478 17.6478 16.3648C18.0097 16.0199 18.5706 16.1751 18.6896 16.6456C18.7179 16.7489 18.7176 16.858 18.6886 16.9612C18.6596 17.0643 18.6031 17.1577 18.5251 17.2312C17.8693 17.8932 17.2103 18.5523 16.5479 19.2084C16.4941 19.2648 16.4297 19.3098 16.3583 19.3409C16.287 19.3721 16.2102 19.3887 16.1323 19.3898C16.0545 19.391 15.9772 19.3766 15.905 19.3475C15.8327 19.3184 15.767 19.2752 15.7117 19.2205C15.0277 18.5548 14.3535 17.8813 13.6889 17.1999C13.634 17.1425 13.5911 17.0747 13.5627 17.0004C13.5344 16.9262 13.5211 16.847 13.5238 16.7676C13.5264 16.6882 13.5449 16.6101 13.5782 16.5379C13.6114 16.4657 13.6588 16.4009 13.7174 16.3473C13.7737 16.2913 13.8405 16.247 13.9139 16.2169C13.9874 16.1868 14.066 16.1715 14.1454 16.1719C14.2247 16.1722 14.3032 16.1883 14.3764 16.219C14.4496 16.2498 14.5159 16.2947 14.5717 16.3511C14.8727 16.6401 15.1584 16.9455 15.4847 17.2783V5.8381L15.4457 5.79697C15.2577 5.99821 15.0701 6.20054 14.8799 6.40013C14.7954 6.49006 14.7072 6.57614 14.6183 6.66168C14.3305 6.93913 13.9538 6.9512 13.7032 6.69294C13.4526 6.43468 13.4696 6.07004 13.7437 5.79368C14.1237 5.40986 14.5114 5.02603 14.8914 4.6422C15.1436 4.39052 15.3931 4.1361 15.6481 3.88716C15.9464 3.59545 16.2962 3.59436 16.5912 3.88716C17.2236 4.51518 17.8536 5.1452 18.4813 5.77724C18.768 6.06565 18.7856 6.43522 18.5361 6.69129C18.2751 6.95558 17.9044 6.93913 17.6072 6.64633C17.3298 6.37217 17.0589 6.09362 16.7551 5.78985L16.7502 17.2663Z"
			fill="#BCB9B9"
		/>
		<path
			d="M10.232 9.39303H7.52715C7.40103 9.72202 7.27821 10.051 7.15209 10.3729C7.00624 10.7463 6.67231 10.9146 6.33619 10.7869C6.00006 10.6591 5.85805 10.3098 6.00006 9.93587C6.72166 8.03611 7.44472 6.13836 8.16923 4.24262C8.30522 3.88621 8.55306 3.70965 8.89467 3.71294C9.23627 3.71623 9.4748 3.89224 9.60749 4.23604C10.3284 6.14201 11.0472 8.04872 11.764 9.95616C11.8995 10.3175 11.7509 10.6613 11.4208 10.7869C11.0907 10.9124 10.7535 10.7507 10.6126 10.3926C10.481 10.0614 10.3587 9.72805 10.232 9.39303ZM8.88589 5.82508L8.00254 8.14394H9.75992L8.88589 5.82508Z"
			fill="#BCB9B9"
		/>
		<path
			d="M8.08023 18.1482H9.53328C9.97194 18.1482 10.4106 18.1482 10.8493 18.1482C11.2287 18.1509 11.5073 18.4141 11.5073 18.7623C11.5073 19.1105 11.2282 19.3797 10.8493 19.3803C9.56728 19.3803 8.28585 19.3803 7.00386 19.3803C6.74286 19.3803 6.53285 19.2958 6.40071 19.0623C6.26856 18.8287 6.30804 18.5918 6.45554 18.367C7.47433 16.8068 8.49147 15.2461 9.50696 13.6848C9.54754 13.6223 9.58702 13.5587 9.65117 13.4573H7.09434C6.84046 13.4573 6.64142 13.3531 6.52079 13.1283C6.468 13.0352 6.44187 12.9293 6.44525 12.8224C6.44864 12.7154 6.48141 12.6114 6.53998 12.5218C6.59065 12.4312 6.66491 12.356 6.75489 12.3041C6.84486 12.2523 6.94719 12.2258 7.05102 12.2274C8.30504 12.2241 9.5596 12.2186 10.8136 12.2274C11.3225 12.2318 11.5763 12.7697 11.289 13.2144C10.7703 14.021 10.2428 14.8215 9.71971 15.627L8.08023 18.1482Z"
			fill="#BCB9B9"
		/>
	</svg>
);

export default KeyboardIcon;
