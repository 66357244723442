import Image from 'material-ui-image';
const DashboardCard = ({ item }: any) => {
	return (
		<>
			<div className='dashboard_card'>
				<div className='img_blk'>
					<Image src={item.image} />
				</div>
				<p className='fw_500'>
					{item.title}
					<strong className='color'>{item.count}</strong>
				</p>
				<p className='fw_500'>
					{item.title2}
					<strong className='color'>{item.count2}</strong>
				</p>
			</div>
		</>
	);
};

export default DashboardCard;
