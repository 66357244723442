import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.16769 8.18477C9.16769 6.78005 10.4358 5.64129 12 5.64129C13.5642 5.64129 14.8323 6.78005 14.8323 8.18477C14.8323 9.5895 13.5642 10.7283 12 10.7283C10.4358 10.7283 9.16769 9.5895 9.16769 8.18477Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5838 12.6359C8.23749 12.6359 6.33539 14.344 6.33539 16.4511C6.33539 17.1535 6.96943 17.7228 7.75154 17.7228H16.2484C17.0305 17.7228 17.6646 17.1535 17.6646 16.4511C17.6646 14.344 15.7625 12.6359 13.4161 12.6359H10.5838Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.68896 0.405122C9.87482 -0.135041 14.1252 -0.135041 18.311 0.405122C20.3864 0.672934 21.9853 2.18553 22.1753 4.06066L22.3551 5.83567C22.7705 9.93675 22.7705 14.0632 22.3551 18.1643L22.1753 19.9393C21.9853 21.8145 20.3864 23.3271 18.311 23.5949C14.1252 24.135 9.87482 24.135 5.68896 23.5949C3.61361 23.3271 2.01465 21.8145 1.8247 19.9393L1.6449 18.1643C1.22947 14.0632 1.22947 9.93676 1.6449 5.83567L1.8247 4.06066C2.01465 2.18553 3.61361 0.672934 5.68896 0.405122ZM18.0089 2.29334C14.0234 1.77904 9.97656 1.77904 5.99111 2.29334C4.88964 2.43548 4.041 3.23827 3.94019 4.23348L3.76039 6.00849C3.3566 9.9946 3.3566 14.0054 3.76039 17.9915L3.94019 19.7665C4.041 20.7617 4.88963 21.5645 5.9911 21.7067C9.97656 22.221 14.0234 22.221 18.0089 21.7067C19.1104 21.5645 19.959 20.7617 20.0598 19.7665L20.2396 17.9915C20.6434 14.0054 20.6434 9.9946 20.2396 6.00849L20.0598 4.23348C19.959 3.23827 19.1104 2.43548 18.0089 2.29334Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent
