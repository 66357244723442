import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Checkbox } from "@mui/material";
import Image from "material-ui-image";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NoImage from "../../assets/images/noImage.png";
import {
	useDeleteClientMutation,
	useDeleteFirmMutation,
	useDeleteJurisdictionMutation,
} from "../../redux/services/Firm/FirmLawyer";
import DeleteConfirmation from "../DeleteConfirmationModal/DeleteConfirmationModal";
import AddNewJurisdiction from "../Popup/AddJurisdiction/AddJurisdiction";
import AddNewClient from "../Popup/AddNewClient/AddNewClinet";
import AddNewFirm from "../Popup/AddNewFirm/AddNewFirm";

type Props = {
	item: any;
	setDelete?: any;
	setUpdate?: any;
	isSuperAdmin?: any;
	isClient?: any;
	isJurisdiction?: any;
	isFirm?: any;
	checkAll?: boolean;
	isSelected?: any;
	onSelect?: any;
	checkbox?: boolean;
	refetchAnalyticsData?: any;
};

const RegisteredFirmsCard: React.FC<Props> = ({
	item,
	setDelete,
	setUpdate,
	isSuperAdmin,
	isClient,
	isJurisdiction,
	isFirm,
	isSelected,
	onSelect,
	checkbox,
	refetchAnalyticsData
}) => {
	const navigation = useNavigate();
	const location = useLocation();
	const [updateFirm, setUpdateFirm] = useState(false);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [deletedFirm, setDeletedFirm] = useState(false);
	const [deletedClient, setDeletedClient] = useState(false);
	const [deletedJurisdiction, setDeletedJurisdiction] = useState(false);
	const [visible, setVisible] = useState(false);

	const lawyerManage = location.state;
	const [firmInfo, setFirmInfo] = useState(false);

	const [
		deleteFirm,
		{ isSuccess: deletedSuccessfully, isError: deletionError },
	] = useDeleteFirmMutation();

	const [
		deleteClient,
		{ isSuccess: deletedClientSuccessfully, isError: deletionClientError },
	] = useDeleteClientMutation();

	const [
		deleteJurisdiction,
		{
			isSuccess: deletedJurisdictionSuccessfully,
			isError: deletionJurisdictionError,
		},
	] = useDeleteJurisdictionMutation();

	useEffect(() => {
		if (deletedSuccessfully) {
			setDeletePopUp(!deletePopUp);
			if (isSuperAdmin) {
				toast.success("Super Admin Deleted Successfully");
			} else {
				refetchAnalyticsData()
				toast.success("Firm Deleted Successfully");
			}
			setDelete(true);
		}
		if (deletionError) {
			toast.error("Can not delete the Firm right now");
		}
		if (deletedClientSuccessfully) {
			setDeletePopUp(!deletePopUp);
			toast.success("Client Deleted Successfully");
			setDelete(true);
		}
		if (deletionClientError) {
			toast.error("Can not delete the Client right now");
		}
		if (deletedJurisdictionSuccessfully) {
			setDeletePopUp(!deletePopUp);
			toast.success("Jurisdiction Deleted Successfully");
			setDelete(true);
		}
		if (deletionJurisdictionError) {
			toast.error("Can not delete the Jurisdiction right now");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		deletedSuccessfully,
		deletionError,
		deletedClientSuccessfully,
		deletionClientError,
		deletedJurisdictionSuccessfully,
		deletionJurisdictionError,
	]);

	const handleFirmInfo = () => {
		if (isFirm) {
			setFirmInfo(!firmInfo);
			localStorage.setItem("item", JSON.stringify(item));
			navigation("/firm/firms-detail");
		}
	};

	const handleUpdateFirm = () => {
		setUpdateFirm(!updateFirm);
	};
	const handleDelete = () => {
		setDeletePopUp(!deletePopUp);
	};
	const handleViewFirm = () => {
		setVisible(!visible);
	};

	useEffect(() => {
		if (deletedFirm) {
			deleteFirm(item?.id);
			setDeletedFirm(false);
		} else if (deletedClient) {
			deleteClient([item.id]);
			setDeletedClient(false);
		} else if (deletedJurisdiction) {
			deleteJurisdiction(item?.id);
			setDeletedJurisdiction(false);
		}
	}, [deletedFirm, deletedClient, deletedJurisdiction]);

	const handleSelect = () => {
		onSelect(item.id);
	};

	return (
		<>
			{lawyerManage ? (
				<div className="lawyer_card lawyer_firm">
					{checkbox && (
						<Checkbox
							classes={{ root: "custom-checkbox-root" }}
							checked={isSelected}
							onClick={() => handleSelect()}
						/>
					)}
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
							alignItems: "center",
							width: "100%",
						}}
						onClick={handleFirmInfo}
					>
						<div className="profile_blk">
							{!isJurisdiction && (
								<div className="img_blk">
									<Image
										src={item.profile_image || NoImage}
										alt=""
									/>
								</div>
							)}
							<div
								className="text_blk"
								style={{
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								}}
							>
								<h5
									className="fs_5 fw_500 "
									style={{ display: "inline" }}
								>
									{item.name}
								</h5>
							</div>
						</div>
						{!isJurisdiction && (
							<>
								<div className="phone">
									<h5 className="fs_5 fw_500">Phone no</h5>
									<p className="fw_400">{item.phone}</p>
								</div>
								<div className="phone_email">
									<div className="email">
										<h5 className="fs_5 fw_500">Email</h5>
										<p className="fw_400">{item.email}</p>
									</div>
								</div>
							</>
						)}
						{!isJurisdiction && (
							<div
								style={{
									borderRadius: "100%",
									height: "24px",
									width: "24px",
									backgroundColor:
										item?.device?.length > 2
											? "#00FF00"
											: "red",
								}}
							></div>
						)}
						<div></div>
						{isJurisdiction && (
							<div className="phone_email">
								<div className="email">
									<h5 className="fs_5 fw_500">Status</h5>
									<p className="fw_400">
										{item?.status === false
											? "DISABLE"
											: "ACTIVE"}
									</p>
								</div>
							</div>
						)}
					</div>
					<div className="action_btn_container">
						<div
							onClick={handleViewFirm}
							style={{ cursor: "pointer" }}
						>
							<VisibilityIcon
								fontSize="large"
								sx={{
									height: "2em",
									width: "2em",
									color: "grey",
								}}
							/>
						</div>
						<div
							onClick={handleUpdateFirm}
							style={{ cursor: "pointer" }}
						>
							<BorderColorIcon
								fontSize="large"
								sx={{
									height: "2em",
									width: "2em",
									color: "#63A744",
								}}
							/>
						</div>
						<div
							onClick={handleDelete}
							style={{ cursor: "pointer" }}
						>
							<DeleteIcon
								fontSize="large"
								sx={{
									height: "2em",
									width: "2em",
									color: "red",
								}}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="lawyer_card">
					<div className="profile_blk">
						<div className="img_blk">
							<img
								src={item.profile_image || NoImage}
								alt=""
								loading="lazy"
							/>
						</div>
						<div className="text_blk">
							<h5 className="fs_5 fw_500">{item.name}</h5>
							{/* <p className="fw_400">{item.member_type}</p> */}
						</div>
					</div>

					<div className="phone_email">
						<div className="phone">
							<h5 className="fs_5 fw_500">Phone no</h5>
							<p className="fw_400">{item.phone}</p>
						</div>
						<div className="email">
							<h5 className="fs_5 fw_500">Email</h5>
							<p className="fw_400">{item.email}</p>
						</div>
					</div>
				</div>
			)}

			{isClient ? (
				<AddNewClient
					active={updateFirm}
					setActive={handleUpdateFirm}
					isUpdate={true}
					client={item}
					setUpdateRefetch={setUpdate}
				/>
			) : isJurisdiction ? (
				<AddNewJurisdiction
					active={updateFirm}
					setActive={handleUpdateFirm}
					isUpdate={true}
					jurisdiction={item}
					setRefetch={setUpdate}
				/>
			) : (
				<AddNewFirm
					active={updateFirm}
					setActive={handleUpdateFirm}
					isUpdate={true}
					firm={item}
					isSuperAdmin={isSuperAdmin}
				/>
			)}
			<AddNewFirm
				active={visible}
				setActive={handleViewFirm}
				isUpdate={true}
				firm={item}
				disable={true}
				isSuperAdmin={isSuperAdmin}
			/>

			<DeleteConfirmation
				showModal={deletePopUp}
				message={
					isClient
						? `Are you sure you want to delete this client`
						: isJurisdiction
						? "Are you sure you want to delete this Jurisdiction"
						: `Are you sure you want to delete this ${
								isSuperAdmin ? "Super Admin" : "Firm"
						  } `
				}
				handleDelete={
					isClient
						? setDeletedClient
						: isJurisdiction
						? setDeletedJurisdiction
						: setDeletedFirm
				}
				hideModal={handleDelete}
			/>
		</>
	);
};

export default RegisteredFirmsCard;
