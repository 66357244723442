//CONVERSATION CARD

import Avatar from "@mui/material/Avatar";
import NoImage from "../../assets/images/noImage.png";
import { GetLoginUserData } from "../../redux/selectors/Common";
import { toast } from "react-toastify";

const ContactCard = ({
	item,
	currentUserName,
	setReciever,
	setShowRightChat,
	setConversationId,
	setChannelName,
}: any) => {
	let userData: any = GetLoginUserData();
	const handleConversationClick = (item: any) => () => {

		if (item.id !== userData?.lawyer?.id) {
			setShowRightChat(false);
			setReciever(item);
			setConversationId(item?.conversation_id);
			setChannelName(item?.channel_id);
			setShowRightChat(true);
		} else {
			toast.error("You cannot create chat with yourself")
		}

	};

	const image =
		currentUserName !== item?.receiver_name
			? item?.receiver_profile_image
			: item?.sender_profile_image;

	return (
		<>
			<div id="contact_card" onClick={handleConversationClick(item)}>
				<div className="profile_blk">
					<div className="img_blk">
						<Avatar
							alt="Remy Sharp"
							src={image ? image : NoImage}
						/>
					</div>
					<div className="text_blk">
						<h4 className="fs_5 fw_400">
							{currentUserName !== item?.receiver_name
								? item?.receiver_name
								: item?.sender_name}
						</h4>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactCard;
