import dayjs from "dayjs";

export const getResourcesList = ({ resources }: any) => {
	try {
		let resourcesArr: any = [];

		if (resources?.laws?.length > 0 || resources?.handbooks?.length > 0) {
			const data: any = resources?.laws
				? resources?.laws
				: resources.handbooks;
			data.forEach((resource: any) => {
				if (resource?.laws?.length === 0) {
					let resourceObj: any = {};
					resourceObj.title = resource?.state || "";
					resourceObj.state_id = resource?.state_id;
					resourceObj.date = resource?.created_at
						? dayjs(resource?.created_at).format("DD-MMM-YYYY")
						: "";
					resourceObj.pdf_link = resource?.pdf_link;
					resourceObj.pdf_link_status = resource?.pdf_link_status;
					resourceObj.rich_text_status = resource?.rich_text_status;
					resourcesArr.push(resourceObj);
				} else {
					if (resources?.handbooks) {
						let resourceObj: any = {};
						resourceObj.title = resource?.state || "";
						resourceObj.state_id = resource?.state_id;
						resourceObj.date = resource?.created_at
							? dayjs(resource?.created_at).format("DD-MMM-YYYY")
							: "";
						resourceObj.pdf_link = resource?.pdf_link;
						resourceObj.pdf_link_status = resource?.pdf_link_status;
						resourceObj.rich_text_status =
							resource?.rich_text_status;
						resourcesArr.push(resourceObj);
					}
					if (resource?.rich_text_status) {
						resource?.laws?.forEach((obj: any) => {
							let resourceObj: any = {};

							resourceObj.title = obj?.data?.name || "";
							resourceObj.date = obj?.data?.created_at
								? dayjs(resource?.created_at).format(
										"DD-MMM-YYYY"
								  )
								: "";
							resourceObj.state_id = resource?.state_id;
							resourceObj.state = resource?.state || "";
							resourceObj.law = obj?.data?.name;

							resourcesArr.push(resourceObj);
						});
					}
				}
			});
			return resourcesArr;
		} else {
			return [];
		}
	} catch (err) {
		console.log("error: ", err);
		return [];
	}
};
