import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.8623 16.2034C11.5113 18.8873 6.60614 18.6761 3.49977 15.5697C0.166744 12.2367 0.166744 6.83279 3.49977 3.49977C6.83279 0.166744 12.2367 0.166744 15.5697 3.49977C18.6761 6.60614 18.8873 11.5113 16.2034 14.8623L22.7222 21.3811C23.0926 21.7515 23.0926 22.3519 22.7222 22.7222C22.3519 23.0926 21.7515 23.0926 21.3811 22.7222L14.8623 16.2034ZM4.84087 14.2286C2.24852 11.6363 2.24852 7.43323 4.84087 4.84087C7.43322 2.24852 11.6363 2.24852 14.2286 4.84087C16.8191 7.43132 16.821 11.6301 14.2343 14.2229C14.2324 14.2248 14.2305 14.2267 14.2286 14.2286C14.2267 14.2305 14.2248 14.2324 14.2229 14.2343C11.6301 16.821 7.43132 16.8191 4.84087 14.2286Z"
            clipRule="evenodd"
            fillRule="evenodd"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent
