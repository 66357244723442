import { useEffect } from 'react';

import { toast } from 'react-toastify';

import Layout from '../../../components/Layout';

import TopBlock from '../../../components/TopBlock';
import { setData } from '../../../redux/reducers/Auth';
import { useSettingMutation } from '../../../redux/services/Dashboard';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import EditProfile from './ProfileSetting';

const Settings = () => {
	const dispatch = useAppDispatch();

	const data = useAppSelector((state: any) => state.authReducer.data);

	const [setting, { isLoading, isSuccess, data: settingData }] =
		useSettingMutation();

	const handleSubmit = (values: any) => {
		setting(values);
	};

	useEffect(() => {
		if (settingData && isSuccess) {
			toast.success('Your Setting update successfully');
			dispatch(
				setData({
					message: data.message,
					token: data.token,
					firm: settingData.firm,
					profile_image: settingData.profile_image,
					lawyer: settingData.lawyer,
				})
			);
		}
	}, [isSuccess, settingData]);

	return (
		<Layout>
			<section id='settings' className='sec_pad_btm'>
				<div className='contain sm'>
					<TopBlock title='Settings' url='dashboard' />
					<div className='settings_outer'>
						<EditProfile data={data} handleSubmit={handleSubmit} />
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Settings;
