import { isEmptyArray } from "formik";
import { useEffect, useRef, useState } from "react";
import search from "../../../../assets/images/search.svg";
import Layout from "../../../../components/Layout";
import PaginatedItems from "../../../../components/Paginator";
import AddNewJurisdiction from "../../../../components/Popup/AddJurisdiction/AddJurisdiction";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TopBlock from "../../../../components/TopBlock";
import { useGetJurisdictionListQuery, useGetJurisdictionSearchMutation } from "../../../../redux/services/Firm/FirmLawyer";


const AddJurisdiction = () => {
    const { data, isLoading, refetch } = useGetJurisdictionListQuery(null)
    const [addJurisdiction, setAddJurisdiction] = useState(false);
    const [deleteSuperAdmin, setDeleteSuperAdmin] = useState(false);
    const [updateJurisdiction, setUpdateJurisdiction] = useState(false);
    const [searchText, setSearchText] = useState<any | null>("");
    const [jurisdiction, setJurisdictions] = useState<any | null>(null);
    const timeout: { current: NodeJS.Timeout | null } = useRef(null);

    const [searching, { data: searchData, isLoading: searchLoading }] =
        useGetJurisdictionSearchMutation();

    const handleSetAddJurisdiction = () => {
        setAddJurisdiction(!addJurisdiction)
    }

    const handleUpdate = () => {
        setUpdateJurisdiction(!updateJurisdiction)
    }
    const handleDelete = () => {
        setDeleteSuperAdmin(!deleteSuperAdmin)
    }

    useEffect(() => {
        refetch()
    }, [addJurisdiction, updateJurisdiction])

    const handleSearch = (search: string) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setSearchText(search);
        timeout.current = setTimeout(() => {
            if (search.length > 0) {
                searching({ search });
            } else {
                setJurisdictions(data);
            }
        }, 1000);
    };
    useEffect(() => {
        if (searchData && searchText != '') {
            setJurisdictions(searchData);
        } else {
            setJurisdictions(data);
        }
    }, [searchData, data]);

    return (
        <Layout>
            <section id="manage_registered_firms" className="sec_pad_btm ">
                <div className="contain sm">
                    <div className="top_blk mb-5">
                        <TopBlock title="Jurisdictions" url="dashboard" button={true} setAddFirm={setAddJurisdiction} buttonText='Add New Jurisdiction' />
                    </div>
                    <div className="search_container">
                        <div className="search_blk_lawyers">
                            <div className="img_blk">
                                <img src={search} alt="search icon" />
                            </div>
                            <input
                                type="search"
                                placeholder="Search"
                                value={searchText}
                                style={{ marginTop: '0.5rem' }}
                                onChange={(e) => {
                                    handleSearch(e.target.value)
                                }
                                } />
                        </div>
                    </div>
                    {isEmptyArray(jurisdiction) ? (
                        <div className="empty_lawyer_text">
                            No Jurisdiction to show{" "}
                        </div>
                    ) : <PaginatedItems
                        itemsPerPage={10}
                        items={jurisdiction}
                        superAdmin={true}
                        isJurisdiction={true}
                        setDelete={handleDelete}
                        setUpdate={handleUpdate}
                        deleted={deleteSuperAdmin}
                    />}
                    <AddNewJurisdiction active={addJurisdiction} setActive={handleSetAddJurisdiction} isUpdate={false} jurisdiction={jurisdiction} setRefetch={handleUpdate} />
                </div>
            </section>
            <ScreenLoader show={isLoading || searchLoading} />
        </Layout>
    );
};

export default AddJurisdiction;
