import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M27.051 32.8038C26.7196 33.1594 26.5392 33.6297 26.5478 34.1157C26.5564 34.6016 26.7533 35.0653 27.0969 35.409C27.4406 35.7526 27.9043 35.9495 28.3902 35.9581C28.8762 35.9667 29.3465 35.7863 29.7021 35.4549L39.7064 25.4506C39.881 25.2766 40.0196 25.0698 40.1141 24.8421C40.2086 24.6144 40.2573 24.3703 40.2573 24.1238C40.2573 23.8773 40.2086 23.6332 40.1141 23.4055C40.0196 23.1778 39.881 22.971 39.7064 22.797L29.7021 12.7927C29.3482 12.4511 28.8742 12.2623 28.3824 12.2668C27.8906 12.2713 27.4202 12.4688 27.0726 12.8168C26.7249 13.1647 26.5279 13.6353 26.5239 14.1271C26.5198 14.6189 26.7091 15.0927 27.051 15.4463L33.8514 22.2492L9.61847 22.2492C9.12097 22.2492 8.64386 22.4469 8.29207 22.7987C7.94029 23.1504 7.74266 23.6276 7.74266 24.125C7.74266 24.6225 7.94029 25.0997 8.29207 25.4514C8.64386 25.8032 9.12097 26.0009 9.61847 26.0009L33.8514 26.0009L27.051 32.8038Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#000"
        />
    </svg>
)

export default SvgComponent
