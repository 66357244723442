import React from "react";
import { IClientDirectory } from "../../redux/services/Client/ClientDashboard";
import NoImage from "../../assets/images/noImage.png";
import Image from "material-ui-image";

type Props = {
	item: IClientDirectory;
};

const ClientDirectoryCard: React.FC<Props> = ({ item }) => {
	return (
		<div className="directory_card">
			<div className="profile_blk">
				<div className="img_blk">
					<Image
						src={
							item?.profile_image ? item?.profile_image : NoImage
						}
					/>
				</div>
				<div className="text_blk">
					<h5 className="fs_5 fw_500">{item?.name}</h5>
					<p className="fw_400">{item?.firm_name}</p>
					<p className="fw_400">Jurisdiction: {item?.jurisdiction}</p>
				</div>
			</div>
			<div className="right_blk">
				<span>Lawyer Type</span>
				<p>{item?.member_type}</p>
			</div>
		</div>
	);
};

export default ClientDirectoryCard;
