import EventCard from "../../../components/EventCard";
import Layout from "../../../components/Layout";
import MediaHeader from "../../../components/MediaHeader";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import { IEvents } from "../../../interfaces/Common";
import {
	useGetEventsQuery,
	useGetMediaSearchQuery,
} from "../../../redux/services/Firm/MediaLibrary";
import search from "../../../assets/images/search.svg";
import { useEffect, useState } from "react";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";
const Events = () => {
	let [events, setEvents] = useState<any>(null);
	let [search_value, setSearchValue] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const { data, isLoading, refetch } = useGetEventsQuery({
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber,
	});

	const { data: searchData, isLoading: searchLoading } =
		useGetMediaSearchQuery(search_value);

	useEffect(() => {
		refetch();
	}, [rowsPerPage, pageNumber]);

	useEffect(() => {
		setEvents(data);
	}, []);

	useEffect(() => {
		if (search_value === "") {
			setEvents(data);
		} else {
			setEvents(searchData);
		}
	}, [searchData, data]);
	const handleSearch = (value: any) => {
		setSearchValue(value);
	};
	return (
		<Layout>
			<section id="events" className="sec_pad_btm">
				<div className="contain sm">
					<div className="top_sec">
						<TopBlock title="Events" url="media-library" />
						<div className="left_blk">
							<div className="search_blk">
								<div className="img_blk">
									<img src={search} alt="" />
								</div>
								<input
									type="search"
									placeholder="Search"
									value={search_value}
									onChange={(e) =>
										handleSearch(e.target.value)
									}
								/>
							</div>
						</div>
					</div>
					<MediaHeader />
					<div className="row">
						{events?.events.map((item: IEvents, index: any) => {
							return (
								<div className="col-lg-6 col-md-12" key={index}>
									<EventCard item={item} />
								</div>
							);
						})}
						{!isLoading && !searchLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={events?.total_records}
							/>
						)}
					</div>
				</div>
			</section>
			{<ScreenLoader show={isLoading || searchLoading} />}
		</Layout>
	);
};

export default Events;
