import { useLocation } from "react-router-dom";
import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";
import Image from "material-ui-image";

const NewsArticle = () => {
	const location = useLocation();
	const NewsDetails: any = location.state;

	return (
		<Layout>
			<section id="news_article" className="sec_pad_btm">
				<div className="contain">
					<TopBlock title="News & Articles" url="news" />
					<div className="details_blk">
						<div>
							<Image
								src={NewsDetails?.NewsDetails.news_image}
								aspectRatio={5 / 4}
							/>
						</div>
						<div className="firm-event-info">
							<h3 className="fw_600">
								{NewsDetails?.NewsDetails.location} <br />{" "}
								{NewsDetails?.NewsDetails.name}
							</h3>
							<p>March 8-10, 2022</p>
						</div>
						<div className="firm-event-details">
							<p>{NewsDetails?.NewsDetails.description}</p>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default NewsArticle;
