import Header from "./Header";
import TopBar from "./TopBar";

const Layout = (props: any) => {
  return (
    <>
      <TopBar />
      <Header />
      {props.children}
    </>
  );
};

export default Layout;
