import { useEffect, useRef, useState } from "react";
import filters from "../../../assets/images/filters.svg";
import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";
// import DirectoryFilter from "../../../components/Popup/DirectoryFilter";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import { useGetLawyersQuery } from "../../../redux/services/Lawyer/DashboardApi";

import { isEmptyArray } from "formik";
import LawyerDirectoryCard from "../../../components/LawyerDirectoryCard";
import DirectoryFilter from "../../../components/Popup/DirectoryFilter/index";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";
import { useGetSearchedLawyersMutation } from "../../../redux/services/Lawyer/DashboardApi";
import { useGetRole } from "../../../redux/selectors/Common";

const LawyerDirectory = () => {
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const role = useGetRole();
	const [directory, setDirectory] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [lawyers, setLawyers]: any = useState([]);
	const [search, setSearch]: any = useState([]);
	const {
		data: lawyersList,
		isLoading,
		refetch,
	} = useGetLawyersQuery({ rowsPerPage, pageNumber, role });

	const [
		searchingResources,
		{ data: searchData, isLoading: searchLoading, isSuccess },
	] = useGetSearchedLawyersMutation();

	useEffect(() => {
		refetch();
		if (lawyersList) {
			setLawyers(lawyersList?.lawyers || []);
		}
	}, [lawyersList, pageNumber, rowsPerPage]);

	useEffect(() => {
		if (isSuccess && searchData) {
			setLawyers(searchData?.lawyers?.data || []);
		}
	}, [isSuccess, searchData]);

	const handleDirectory = () => setDirectory(!directory);

	const handlerSearchResources = (data: any) => {
		searchingResources({ ...data, member_type: data?.member_type[0] });
		handleDirectory();
	};

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearch(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searchingResources({ search });
			} else {
				setLawyers(lawyersList?.lawyers);
			}
		}, 1000);
	};

	return (
		<>
			<Layout>
				<section id="manage_lawyers" className=" sec_pad_btm ">
					<div className="contain sm">
						<div className="Top_blk">
							<TopBlock title="Directory" url="dashboard" />
							<div className="left_blk">
								<div className="search_blk">
									<div className="img_blk">
										<img src={search} alt="" />
									</div>
									<input
										type="search"
										value={search}
										placeholder="Search"
										onChange={(e) => {
											handleSearch(e.target.value);
										}}
									/>
								</div>
								<div
									className="filter_blk pop_btn"
									onClick={handleDirectory}
								>
									<img src={filters} alt="" />
								</div>
							</div>
						</div>
						<ScreenLoader show={isLoading || searchLoading} />

						{!isEmptyArray(lawyers)
							? lawyers?.map((item: any) => {
									return (
										<LawyerDirectoryCard
											item={item}
											key={item.id}
											totalRecords={lawyersList?.total_records}
										/>
									);
							  })
							: !isLoading &&
							  !searchLoading && (
									<div className="text-center mt-5">
										<h3>No Records Found</h3>
									</div>
							  )}
						{!isLoading && !searchLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={lawyersList?.total_records}
							/>
						)}
					</div>
				</section>
				<DirectoryFilter
					active={directory}
					setActive={handleDirectory}
					handlerSearchResources={handlerSearchResources}
				/>
			</Layout>
		</>
	);
};

export default LawyerDirectory;