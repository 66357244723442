import { Link } from "react-router-dom";
import lawCard1 from "../../../assets/images/lawCard1.jpg";
import media1 from "../../../assets/images/media1.jpg";
import media2 from "../../../assets/images/media2.jpg";
import media3 from "../../../assets/images/media3.jpg";
import search from "../../../assets/images/search.svg";
import EventCard from "../../../components/EventCard";
import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";
import { EVENTS_DATA } from "../../../utills/DashboardData";

const MediaSearch = () => {
	return (
		<>
			<Layout>
				<section id="media_search" className="sec_pad_btm">
					<div className="contain sm">
						<div className="Top_blk">
							<TopBlock title="Media Library" />
							<div className="left_blk">
								<div className="search_blk">
									<div className="img_blk">
										<img src={search} alt="" />
									</div>
									<input type="search" placeholder="Search" />
								</div>
							</div>
						</div>

						<h3 className="mb-4 fw_600">Podcast</h3>
						<div className="library">
							<div className="img_blk">
								<img src={media1} alt="" />
							</div>
							<div className="img_blk">
								<img src={media2} alt="" />
							</div>
							<div className="img_blk">
								<img src={media3} alt="" />
							</div>
							<div className="img_blk">
								<img src={media1} alt="" />
							</div>
							<div className="img_blk">
								<img src={media2} alt="" />
							</div>
						</div>
						<h3 className="ctn-main-heading">Upcoming Webniars</h3>
						<div className="row">
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
						</div>
						<h3 className="ctn-main-heading">On Demand Webniars</h3>
						<div className="row">
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="webinar_card">
									<Link to="/lawyer/" className="card-image">
										<img src={lawCard1} alt="" />
									</Link>
									<div className="card-detail">
										<div>
											<Link
												to="/lawyer/"
												className="card-title"
											>
												Law Firm
											</Link>
											<h3 className="evnet-by">
												by Johni Khaba
											</h3>
										</div>
										<a href="#" className="site_btn">
											Join Now
										</a>
									</div>
									<p className="webnr-date">13-Mar-2022</p>
								</div>
							</div>
						</div>

						<h3 className="ctn-main-heading">Events</h3>
						<div className="row">
							{EVENTS_DATA.map((item: any) => {
								return (
									<>
										<div
											className="col-lg-6 col-md-12"
											key={1}
										>
											<EventCard
												item={item}
												key={item.id}
											/>
										</div>
									</>
								);
							})}
						</div>
					</div>
				</section>
			</Layout>
		</>
	);
};

export default MediaSearch;
