import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20.949 15.1962C21.2804 14.8406 21.4608 14.3703 21.4522 13.8843C21.4436 13.3984 21.2467 12.9347 20.9031 12.591C20.5594 12.2474 20.0957 12.0505 19.6098 12.0419C19.1238 12.0333 18.6535 12.2137 18.2979 12.5451L8.29358 22.5494C8.11897 22.7234 7.98043 22.9302 7.8859 23.1579C7.79137 23.3856 7.74271 23.6297 7.74271 23.8762C7.74271 24.1227 7.79137 24.3668 7.8859 24.5945C7.98043 24.8222 8.11897 25.029 8.29358 25.203L18.2979 35.2073C18.6518 35.5489 19.1258 35.7377 19.6176 35.7332C20.1094 35.7287 20.5798 35.5312 20.9274 35.1832C21.2751 34.8353 21.4721 34.3647 21.4761 33.8729C21.4802 33.3811 21.2909 32.9073 20.949 32.5537L14.1486 25.7508H38.3815C38.879 25.7508 39.3561 25.5531 39.7079 25.2013C40.0597 24.8496 40.2573 24.3724 40.2573 23.8749C40.2573 23.3775 40.0597 22.9003 39.7079 22.5486C39.3561 22.1968 38.879 21.9991 38.3815 21.9991H14.1486L20.949 15.1962Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#000"
        />
    </svg>
)

export default SvgComponent
