
export const PrivacyPolicy=()=>{
    return (<div style={{ padding: "30px"}}>
        <h2>
          Privacy Policy
        </h2>
        <p>
          <strong>Last updated March 30, 2023</strong>
        </p>
        <p>
          This privacy notice for Employment Law Alliance, Inc. (doing business as
          ELA) ("ELA," "we,"
          <br />
          "us," or "our"), describes how and why we might collect, store, use, and/or
          share ("process") your information when you use our services ("Services"),
          such as when you:
        </p>
        <ul>
          <li>
            Download and use our mobile application (ELA Mobile App), or any other
            application of
          </li>
        </ul>
        <p>Ours that links to this privacy notice</p>
        <ul>
          <li>
            Engage with us in other related ways, including any sales, marketing. or
            events
          </li>
        </ul>
        <p>
          Questions or concerns? Reading this privacy notice will help you understand
          your privacy rights and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any questions
          or concems, please contact us at Memberservices@ela.law
        </p>
        <p>
          <strong>
            <br />
            SUMMARY OF KEY POINTS
          </strong>
        </p>
        <p>
          This summary provides key points from our privacy notice, but you can find
          out more details about any of these topics by clicking the link following
          each key point or by using our table of contents below to find the section
          you are looking for.
        </p>
        <p>
          <br />
          <strong>What personal information do we process?</strong>&nbsp;
          <br />
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with ELA and the Services, the
          choices you make, and the products and features you use. Learn more about
          personal information you disclose to us.
        </p>
        <p>
          <br />
          <strong>Do we process any sensitive personal information?</strong>&nbsp;
          <br />
          We do not process sensitive personal information.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>Do we receive any information from third parties?</strong>&nbsp;
          <br />
          We do not receive any information from third parties.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services, communicate
          with you, for security and fraud prevention, and to comply with law. We may
          also process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do so.
          Learn more about how we process your information.
        </p>
        <p>
          <strong>
            In what situations and with which parties do we share personal
            information?
          </strong>
          &nbsp;
          <br />
          We may share information in specific situations and with specific third
          parties. Learn more about when and with whom we share your personal
          information.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>How do we keep your information safe?</strong> We have
          organizational and technical processes and procedures in place to protect
          your personal information. However, no electronic transmission over the
          internet or information storage technology can be guaranteed to be 100%
          secure, so we cannot promise or guarantee that hackers, cybercriminals, or
          other unauthorized third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information. Learn more
          about how we keep your information safe.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>What are your rights?</strong> Depending on where you are located
          geographically, the applicable privacy law may mean you have certain rights
          regarding your personal information. Learn more about your privacy rights.
        </p>
        <p>
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by submitting a data subject access request, or by
          contacting us. We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>
          <strong>
            Want to learn more about what ELA does with any information we collect?
          </strong>
          &nbsp;
          <br />
          Review the privacy notice in full.
        </p>
        <p>
          <strong>TABLE OF CONTENTS</strong>
        </p>
        <ul>
          <li>WHAT INFORMATION DO WE COLLECT?</li>
          <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li>
            WHAT LEGAL BASES DC WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
          </li>
          <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
          <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
        </p>
        <p>Personal information you disclose to us</p>
        <p>
          <strong>In Short:</strong> We collect personal information that you provide
          fo us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us when you
          register on the
        </p>
        <p>
          Services, express an interest in obtaining information about us or our
          products and Services,
        </p>
        <p>
          when you participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on
        </p>
        <p>
          the context of your interactions with us and the Services, the choices you
          make, and the
        </p>
        <p>
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul>
          <li>Names</li>
          <li>Email addresses</li>
          <li>User names</li>
          <li>Passwords</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>
            Sensitive Information
            <br />
          </strong>
          We do not process sensitive information.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            Social Media Login Data:
            <br />
          </strong>
          We may provide you with the option to register with us using your existing
          social media account details, like your Facebook, Twitter, or other social
          media account. If you choose to register in this way, we will collect the
          information described in the section called "HOW DO WE HANDLE YOUR SOCIAL
          LOGINS?" below.
        </p>
        <p>
          <br />
          <strong>Application Data.</strong> If you use our application(s), we also
          may collect the following information if
        </p>
        <p>you choose to provide us with access or permission:&nbsp;</p>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <strong>Mobile Device Access.</strong> We may request access or permission
            to certain features from your mobile device, including your mobile
            device's calendar, sms messages, and other features. If you wish to change
            our access or permissions, you may do so in your device's settings.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <strong>Mobile Device Data.</strong> We automatically collect device
            information (such as your mobile device ID, model, and manufacturer),
            operating system, version information and system configuration
            information, device and application identification numbers, browser type
            and version, hardware model Internet service provider and/or mobile
            carrier, and Internet Protocol (IP) address (or proxy server). If you are
            using our application(s), we may also collect information about the phone
            network associated with your mobile device, your mobile device's operating
            system or platform, the type of mobile device you use, your mobile
            device's unigue device ID, and information about the features of our
            application(s) you accessed.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <strong>Push Notifications.</strong> We may request to send you push
            notifications regarding your account or certain features of the
            application(s). If you wish to opt out from receiving these types of
            communications, you may turn them off in your device's settings.&nbsp;
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          This information is primarily needed to maintain the security and operation
          of our application(s), for troubleshooting, and for our intemal analytics
          and reporting purposes.
        </p>
        <p>
          All personal information that you provide to us must be true, complete, and
          accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
        </p>
        <p>
          <strong>In Short:</strong> We process your information to provide, improve,
          and administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your information for
          other purposes with your consent.
        </p>
        <p>
          <br />
        </p>
        <p>
          We process your personal information for a variety of reasons, depending on
          how you interact with cur Services, including:
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li>
            To facilitate account creation and authentication and otherwise manage
            user accounts. We may process your information so you can create and log
            in to your account, as well as keep your account in working order.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            To deliver and facilitate delivery of services to the user. We may process
            your information to provide you with the requested service.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            To respond to user inquiriesioffer support to users. We may process your
            information to respond to your inquiries and solve any potential issues
            you might have with the requested service.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            To send administrative information to you. We may process your information
            to send you details about our products and services, changes to our terms
            and policies, and other similar information.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li>
            To save or protect an individual's vital interest. We may process your
            information when necessary to save or protect an individual's vital
            interest, such as to prevent harm.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: We only process your personal information when we believe it is
          necessary and we
        </p>
        <p>
          have a valid legal reason (i.e., legal basis) to do so under applicable law,
          like with your consent,
        </p>
        <p>
          to comply with laws, to provide you with services to enter into or fulfill
          our contractual obligations,
        </p>
        <p>
          fo protect your rights, or to fulfill our legitimate business interests.
        </p>
        <p>
          <br />
        </p>
        <p>If you are located in the EU or UK, this section applies to you.</p>
        <p>
          <br />
        </p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us to
          explain the valid
        </p>
        <p>
          legal bases we rely on in order to process your personal information. As
          such, we may rely on
        </p>
        <p>the following legal bases to process your personal information:</p>
        <p>
          <br />
        </p>
        <p>
          = Consent. We may process your information if you have given us permission
          (i.e.
        </p>
        <p>
          consent) to use your personal information for a specific purpose. You can
          withdraw your
        </p>
        <p>consent at any time. Learn more about withdrawing your consent.</p>
        <p>
          = Performance of a Contract. We may process your personal information when
          we believe
        </p>
        <p>
          it is necessary to fulfill our contractual obligations to you, including
          providing our Services
        </p>
        <p>or at your request prior to entering into a contract with you.</p>
        <p>
          <br />
        </p>
        <p>
          = Legal Obligations. We may process your information where we believe it is
          necessary
        </p>
        <p>
          for compliance with our legal obligations, such as to cooperate with a law
          enforcement
        </p>
        <p>
          body or regulatory agency, exercise or defend our legal rights, or disclose
          your
        </p>
        <p>information as evidence in litigation in which we are involved.</p>
        <p>
          <br />
        </p>
        <p>
          = Vital Interests. We may process your information where we believe it is
          necessary to
        </p>
        <p>
          protect your vital interests or the vital interests of a third party, such
          as situations involving
        </p>
        <p>potential threats to the safety of any person.</p>
        <p>
          <br />
        </p>
        <p>If you are located in Canada, this section applies to you.</p>
        <p>
          <br />
        </p>
        <p>
          We may process your information if you have given us specific permission
          (i.e., express
        </p>
        <p>
          consent) to use your personal information for a specific purpose, or in
          situations where your
        </p>
        <p>
          permission can be inferred (i.e, implied consent). You can withdraw your
          consent at any time.
        </p>
        <p>
          <br />
        </p>
        <p>
          In some exceptional cases, we may be legally permitted under applicable law
          to process your
        </p>
        <p>information without your consent, including, for example:</p>
        <p>
          <br />
        </p>
        <p>
          = [If collection is clearly in the interests of an individual and consent
          cannot be obtained in a
        </p>
        <p>timely way</p>
        <p>
          <br />
        </p>
        <p>= For investigations and fraud detection and prevention</p>
        <p>For business transactions provided certain conditions are met</p>
        <p>
          <br />
        </p>
        <p>
          If it is contained in a witness statement and the collection is necessary to
          assess, Process,
        </p>
        <p>or settle an insurance claim</p>
        <p>
          <br />
        </p>
        <p>
          For identifying injured, ill, or deceased persons and communicating with
          next of kin
        </p>
        <p>
          <br />
        </p>
        <p>
          If we have reasonable grounds to believe an individual has been, is, or may
          be victim of
        </p>
        <p>financial abuse</p>
        <p>
          <br />
        </p>
        <p>
          If it is reasonable to expect collection and use with consent would
          compromise the
        </p>
        <p>
          availability or the accuracy of the information and the collection is
          reasonable for purposes
        </p>
        <p>
          related to investigating a breach of an agreement or a contravention of the
          laws of
        </p>
        <p>Canada or a province</p>
        <p>
          <br />
        </p>
        <p>
          IT disclosure is required to comply with a subpoena, warrant, court order,
          or rules of the
        </p>
        <p>court relating to the production of records</p>
        <p>
          <br />
        </p>
        <p>
          IT it was produced by an individual in the course of their employment,
          business. or
        </p>
        <p>
          profession and the collection is consistent with the purposes for which the
          information was
        </p>
        <p>produced</p>
        <p>
          <br />
        </p>
        <p>
          If the collection is solely for journalistic, artistic, or literary purposes
        </p>
        <p>
          <br />
        </p>
        <p>
          If the information is publicly available and is specified by the regulations
        </p>
        <p>
          <strong>
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: We may share information in specific situations described in this
          section and/or with
        </p>
        <p>the following third parties.</p>
        <p>
          <br />
        </p>
        <p>
          We may need to share your personal information in the following situations:
        </p>
        <p>
          <br />
        </p>
        <p>
          = Business Transfers. We may share or transfer your information in
          connection with, or
        </p>
        <p>
          during negotiations of, any merger, sale of company assets, financing, or
          acquisition of all
        </p>
        <p>or a portion of our business to another company.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: We may use cookies and other tracking technologies to collect and
          store your
        </p>
        <p>information.</p>
        <p>
          <br />
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons and
          pixels) to access
        </p>
        <p>
          or store information. Specific information about how we use such
          technologies and how you can
        </p>
        <p>refuse certain cookies is set out in our Cookie Notice.</p>
        <p>
          <strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: If you choose to register or log in to our Services using a social
          media account, we
        </p>
        <p>may have access to certain information about you.</p>
        <p>
          <br />
        </p>
        <p>
          Qur Services offer you the ability to register and log in using your
          third-party social media
        </p>
        <p>
          account details (like your Facebook or Twitter logins). Where you choose to
          do this, we will
        </p>
        <p>
          receive certain profile information about you from your social media
          provider. The profile
        </p>
        <p>
          information we receive may vary depending on the social media provider
          concerned, but will
        </p>
        <p>
          often include your name, email address, friends list, and profile picture,
          as well as other
        </p>
        <p>information you choose to make public on such a social media platform.</p>
        <p>
          <br />
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy
        </p>
        <p>
          notice or that are otherwise made clear to you on the relevant Services.
          Please note that we do
        </p>
        <p>
          not control, and are not responsible for, other uses of your personal
          information by your third-
        </p>
        <p>
          party social media provider. We recommend that you review their privacy
          notice to understand
        </p>
        <p>
          how they collect, use, and share your personal information, and how you can
          set your privacy
        </p>
        <p>preferences on their sites and apps.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: We keep your information for as long as necessary to fulfill the
          purposes outlined in
        </p>
        <p>this privacy notice unless otherwise required by law.</p>
        <p>
          <br />
        </p>
        <p>
          We will only keep your personal information for as long as it is necessary
          for the purposes set
        </p>
        <p>
          out in this privacy notice, unless a longer retention period is required or
          permitted by law (such
        </p>
        <p>
          as tax, accounting, or other legal requirements). No purpose in this notice
          will require us keeping
        </p>
        <p>
          your personal information for longer than the period of time in which users
          have an account with us.
        </p>
        <p>
          <br />
        </p>
        <p>
          When we have no ongoing legitimate business need to process your personal
          information, we
        </p>
        <p>
          will either delete or anonymize such information, or, if this is not
          possible (for example, because
        </p>
        <p>
          your personal information has been stored in backup archives), then we will
          securely store your
        </p>
        <p>
          personal information and isolate it from any further processing until
          deletion is possible.
          <br />
          <br />
          <strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
        </p>
        <p>
          In Short: We aim fo protect your personal information through a system of
          organizational and
        </p>
        <p>technical security measures.</p>
        <p>
          We have implemented appropriate and reasonable technical and organizational
          security
        </p>
        <p>
          measures designed to protect the security of any personal information we
          process. However,
        </p>
        <p>
          despite our safeguards and efforts to secure your information, no electronic
          transmission over
        </p>
        <p>
          the Internet or information storage technology can be guaranteed to be 100%
          secure, so we
        </p>
        <p>
          cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will
        </p>
        <p>
          not be able to defeat our security and improperly collect, access, steal, or
          modify your
        </p>
        <p>
          information. Although we will do our best to protect your personal
          information, transmission of
        </p>
        <p>
          personal information to and from our Services is at your own risk. You
          should only access the
        </p>
        <p>Services within a secure environment.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: We do not knowingly collect data from or market fo children under
          18 years of age.
        </p>
        <p>
          <br />
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18 years
          of age. By using the
        </p>
        <p>
          Services, you represent that you are at least 18 or that you are the parent
          or guardian of such a
        </p>
        <p>
          minor and consent to such minor dependents use of the Services. IT we leam
          that personal
        </p>
        <p>
          information from users less than 18 years of age has been collected, we will
          deactivate the
        </p>
        <p>
          account and take reasonable measures to promptly delete such data from our
          records. If you&nbsp;
        </p>
        <p>
          become aware of any data we may have collected from children under age 18,
          please contact
        </p>
        <p>us at Membersernvices@ela.law.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: In some regions, such as the European Economic Area (EEA), United
          Kingdom (UK),
        </p>
        <p>
          and Canada, you have rights that allow you greater access to and control
          over your personal
        </p>
        <p>
          information. You may review, change, or terminate your account at any time.
        </p>
        <p>
          <br />
        </p>
        <p>
          In some regions (like the EEA, UK, and Canada), you have certain rights
          under applicable data
        </p>
        <p>
          protection laws. These may include the right (i) to request access and
          obtain a copy of your
        </p>
        <p>
          personal information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your
        </p>
        <p>
          personal information; and (iv) if applicable, to data portability. In
          certain circumstances, you may
        </p>
        <p>
          also have the right to object to the processing of your personal
          information. You can make such
        </p>
        <p>
          a request by contacting us by using the contact details provided in the
          section "HOW CAN YOU
        </p>
        <p>CONTACT US ABOUT THIS NOTICE?" below.</p>
        <p>
          <br />
        </p>
        <p>
          We will consider and act upon any request in accordance with applicable data
          protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal
        </p>
        <p>
          information, you also have the right to complain to your Member State data
          protection authority
        </p>
        <p>
          <br />
        </p>
        <p>or UK data protection authority.</p>
        <p>
          <br />
        </p>
        <p>
          If you are located in Switzerland, you may contact the Federal Data
          Protection and Information
        </p>
        <p>Commissioner.</p>
        <p>
          Withdrawing your consent: If we are relying on your consent to process your
          personal
        </p>
        <p>
          information, which may be express and/or implied consent depending on the
          applicable law, you
        </p>
        <p>
          have the right to withdraw your consent at any time. You can withdraw your
          consent at any time
        </p>
        <p>
          by contacting us by using the contact details provided in the section "HOW
          CAN YOU
        </p>
        <p>CONTACT US ABOUT THIS NOTICE?" below or updating your preferences.</p>
        <p>
          <br />
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its
        </p>
        <p>
          withdrawal nor, when applicable law allows, will it affect the processing of
          your personal
        </p>
        <p>
          information conducted in reliance on lawful processing grounds other than
          consent.
        </p>
        <p>
          <br />
        </p>
        <p>
          Opting _out of marketing and promotional communications: You can unsubscribe
          from our
        </p>
        <p>
          marketing and promotional communications at any time by clicking on the
          unsubscribe link in the
        </p>
        <p>
          emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages
          that we send,
        </p>
        <p>
          ar by contacting us using the details provided in the section "HOW CAN YOU
          CONTACT US
        </p>
        <p>
          ABOUT THIS NOTICE?" below. You will then be removed from the marketing
          lists. However, we
        </p>
        <p>
          may still communicate with you — for example, to send you service-related
          messages that are
        </p>
        <p>
          necessary for the administration and use of your account, to respond to
          service requests, or for
        </p>
        <p>other non-marketing purposes.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Account Information</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          If you would at any time like to review or change the information in your
          account or terminate
        </p>
        <p>Your account, you can:</p>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <strong>
              Log in to your account settings and update your user account.
            </strong>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          Upon your request to terminate your account, we will deactivate or delete
          your account and
        </p>
        <p>
          information from our active databases. However, we may retain some
          information in our files to
        </p>
        <p>
          prevent fraud, troubleshoot problems, assist with any investigations,
          enforce our legal terms
        </p>
        <p>and/or comply with applicable legal requirements.</p>
        <p>
          <br />
        </p>
        <p>
          Cookies and similar technologies: Most Web browsers are set to accept
          cookies by default. If
        </p>
        <p>
          you prefer, you can usually choose to set your browser to remove cookies and
          to reject cookies.
        </p>
        <p>
          IT you choose to remove cookies or reject cookies, this could affect certain
          features or services
        </p>
        <p>
          of our Services. You may also opt out of interest-based advertising by
          advertisers on our
        </p>
        <p>Services.</p>
        <p>
          <br />
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may email
          us at
        </p>
        <p>Memberservices@ela law.</p>
        <p>
          <strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          Most web browsers and some mobile operating systems and mobile applications
          include a Do-
        </p>
        <p>
          Not-Track ("DNT") feature or setting you can activate to signal your privacy
          preference not to
        </p>
        <p>
          have data about your online browsing activities monitored and collected. At
          this stage no uniform
        </p>
        <p>
          technology standard for recognizing and implementing DNT signals has been
          finalized. As such,
        </p>
        <p>
          we do not currently respond to DNT browser signals or any other mechanism
          that automatically
        </p>
        <p>
          communicates your choice not to be tracked online. If a standard for online
          tracking is adopted
        </p>
        <p>
          that we must follow in the future, we will inform you about that practice in
          a revised version of
        </p>
        <p>this privacy notice.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: Yes, if you are a resident of California, you are granted specific
          rights regarding
        </p>
        <p>access to your personal information.</p>
        <p>
          <br />
        </p>
        <p>
          California Civil Code Section 1798.83. also known as the "Shine The Light"
          law, permits our
        </p>
        <p>
          users who are California residents to request and obtain from us, once a
          year and free of
        </p>
        <p>
          charge, information about categories of personal information (if any) we
          disclosed to third parties
        </p>
        <p>
          for direct marketing purposes and the names and addresses of all third
          parties with which we
        </p>
        <p>
          shared personal information in the immediately preceding calendar year. If
          you are a California
        </p>
        <p>
          resident and would like to make such a request, please submit your request
          in writing to us
        </p>
        <p>using the contact information provided below.</p>
        <p>
          <br />
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with
        </p>
        <p>
          Services, you have the right to request removal of unwanted data that you
          publicly post on the
        </p>
        <p>
          Services. To request removal of such data, please contact us using the
          contact information
        </p>
        <p>
          provided below and include the email address associated with your account
          and a statement
        </p>
        <p>
          that you reside in California. We will make sure the data is not publicly
          displayed on the
        </p>
        <p>
          Services, but please be aware that the data may not be completely or
          comprehensively removed
        </p>
        <p>
          from all our systems (e.g., backups, etc).&nbsp;
          <br />
          <br />
          <strong>CCPA Privacy Notice</strong>
        </p>
        <p>
          <br />
        </p>
        <p>The California Code of Regulations defines a "resident" as:</p>
        <p>
          (1) every individual who is in the State of California for other than a
          temporary or transitory
        </p>
        <p>
          purpose and&nbsp;
          <br />
          <br />
          (2) every individual who is domiciled in the State of California who is
          outside the State of
        </p>
        <p>California for a temporary or transitory purpose</p>
        <p>
          <br />
        </p>
        <p>
          <strong>All other individuals are defined as "non-residents."</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          If this definition of “resident” applies to you. we must adhere to certain
          rights and obligations
        </p>
        <p>
          regarding your personal information.&nbsp;
          <br />
          <br />
        </p>
        <p>
          <strong>What categories of personal information do we collect?</strong>
        </p>
        <p>
          We have collected the following categories of personal information in the
          past twelve (12) months:
        </p>
        <ul>
          <li>Category Examples Collected</li>
          <li>Contact details, such as real name, alias, postal</li>
          <li>Address, telephone or mobile contact number,</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>
            A. Identifiers unique personal identifier, online identifier, YES
          </strong>
        </p>
        <p>Internet Protocol address, email address, and account name</p>
        <p>
          <br />
        </p>
        <p>B. Personal information )</p>
        <p>
          <br />
        </p>
        <p>oo Name, contact information, education,</p>
        <p>categories listed in the ) )</p>
        <p>employment, employment history, and financial YES</p>
        <p>
          <br />
        </p>
        <p>California Customer Records |.</p>
        <p>information</p>
        <p>
          <br />
        </p>
        <p>statute</p>
        <p>
          <br />
        </p>
        <p>C. Protected classification</p>
        <p>
          <br />
        </p>
        <p>
          characteristics under California | Gender and date of birth NO or federal
          law) Transaction information, purchase history,
        </p>
        <p>
          <br />
        </p>
        <p>
          D. Commercial information ) ) ) YES financial details, and payment
          information
        </p>
        <p>
          <br />
        </p>
        <p>E. Biometric information Fingerprints and voiceprints NO</p>
        <p>Browsing history, search history, online</p>
        <p>
          <br />
        </p>
        <p>
          F. Internet or other similar behavior, interest data, and interactions with
          our YES
        </p>
        <p>
          <br />
        </p>
        <p>
          network activity and other websites, applications, systems, and
          advertisements
        </p>
        <p>
          <br />
        </p>
        <p>G. Geolocation data Device location</p>
        <p>
          <br />
        </p>
        <p>
          H. Audio, electronic, visual, Images and audio, video or call recordings
        </p>
        <p>thermal, olfactory, or similar created in connection with our business</p>
        <p>information activities</p>
        <p>
          <br />
        </p>
        <p>Business contact details in order to provide you</p>
        <p>
          |. Professional or employment- |our Services at a business level or job
          title, work
        </p>
        <p>related information history, and professional qualifications if you</p>
        <p>apply for a job with us</p>
        <p>
          <br />
        </p>
        <p>J. Education Information Student records and directory information</p>
        <p>
          <br />
        </p>
        <p>Inferences drawn from any of the collected</p>
        <p>
          K. Inferences drawn from other | personal information listed above to create
          a
        </p>
        <p>personal information profile or summary about, for example, an</p>
        <p>individual's preferences and characteristics</p>
        <p>
          <br />
        </p>
        <p>L. Sensitive Personal</p>
        <p>Information</p>
        <p>
          <br />
        </p>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or
        </p>
        <p>far:</p>
        <p>
          <br />
        </p>
        <ul>
          <li>Category A - As long as the user has an account with us</li>
          <li>Category B - As long as the user has an account with us</li>
          <li>Category D - As long as the user has an account with us</li>
          <li>Category F - As long as the user has an account with us</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          We may also collect other personal information outside of these categories
          through instances
        </p>
        <p>
          where you interact with us in person, online, or by phone or mail in the
          context of:
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>How do we use and share your personal information?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          More information about our data collection and sharing practices can be
          found in this privacy
        </p>
        <p>notice.</p>
        <p>
          <br />
        </p>
        <p>
          You may contact us by email at Memberservices@ela.law, or by referring to
          the contact details
        </p>
        <p>at the bottom of this document.</p>
        <p>
          <br />
        </p>
        <p>
          If you are using an authorized agent to exercise your right to opt out we
          may deny a request if
        </p>
        <p>
          the authorized agent does not submit proof that they have been validly
          authorized to act on your
        </p>
        <p>behalf.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Will your information be shared with anyone else?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written
        </p>
        <p>
          contract between us and each service provider. Each service provider is a
          for-profit entity that
        </p>
        <p>
          processes the information on our behalf, following the same strict privacy
          protection obligations
        </p>
        <p>mandated by the CCPA.</p>
        <p>
          <br />
        </p>
        <p>
          We may use your personal information for our own business purposes, such as
          for undertaking
        </p>
        <p>
          internal research for technological development and demonstration. This is
          not considered to be
        </p>
        <p>"selling" of your personal information.</p>
        <p>
          <br />
        </p>
        <p>
          Employment Law Alliance, Inc. has not disclosed, sold, or shared any
          personal information to
        </p>
        <p>
          third parties for a business or commercial purpose in the preceding twelve
          (12) months.
        </p>
        <p>
          Employment Law Alliance, Inc. will not sell or share personal information in
          the future belonging
        </p>
        <p>to website visitors, users, and other consumers.&nbsp;</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Your rights with respect to your personal data</strong>
        </p>
        <p>
          <br />
        </p>
        <p>Right to request deletion of the data — Request to delete</p>
        <p>
          <br />
        </p>
        <p>
          You can ask for the deletion of your personal information. If you ask us to
          delete your personal
        </p>
        <p>
          information, we will respect your request and delete your personal
          information, subject to certain
        </p>
        <p>
          exceptions provided by law, such as (but not limited to) the exercise by
          another consumer of his
        </p>
        <p>
          or her right to free speech, our compliance requirements resulting from a
          legal obligation, or any
        </p>
        <p>processing that may be required to protect against illegal activities.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Right to be informed — Request to know</strong>
        </p>
        <p>
          <br />
        </p>
        <p>Depending on the circumstances, you have a right to know:</p>
        <ul>
          <li>whether we collect and use your personal information;</li>
          <li>the categories of personal information that we collect;</li>
          <li>the purposes for which the collected personal information is used;</li>
          <li>whether we sell or share personal information to third parties;</li>
          <li>
            the categories of personal information that we sold, shared, or disclosed
            for a business
          </li>
          <li>purpose;</li>
          <li>
            &nbsp;the categories of third parties to whom the personal information was
            sold, shared, or disclosed for a business purpose;
          </li>
          <li>
            the business or commercial purpose for collecting, selling, or sharing
            personal
          </li>
          <li>information; and</li>
          <li>the specific pieces of personal information we collected about you.</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          In accordance with applicable law, we are not obligated to provide or delete
          consumer
        </p>
        <p>
          information that is de-identified in response to a consumer request or to
          re-identify individual
        </p>
        <p>data to verify a consumer request.</p>
        <p>
          <br />
        </p>
        <p>
          Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights
        </p>
        <p>
          We will not discriminate against you if you exercise your privacy rights.
        </p>
        <p>
          <br />
        </p>
        <p>Right to Limit Use and Disclosure of Sensitive Personal Information</p>
        <p>
          <br />
        </p>
        <p>We do not process consumer's sensitive personal information.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Verification process</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same
        </p>
        <p>
          person about whom we have the information in our system. These verification
          efforts require us
        </p>
        <p>
          to ask you to provide information so that we can match it with information
          you have previously
        </p>
        <p>
          provided us. For instance, depending on the type of request you submit, we
          may ask you to
        </p>
        <p>
          provide certain information so that we can match the information you provide
          with the
        </p>
        <p>
          information we already have on file, or we may contact you through a
          communication method
        </p>
        <p>
          (e.g., phone or email) that you have previously provided to us. We may also
          use other
        </p>
        <p>verification methods as the circumstances dictate.</p>
        <p>
          <br />
        </p>
        <p>
          We will only use personal information provided in your request to verify
          your identity or authority
        </p>
        <p>
          to make the request. To the extent possible, we will avoid requesting
          additional information from
        </p>
        <p>
          you for the purposes of verification. However, if we cannot verify your
          identity from the
        </p>
        <p>
          information already maintained by us, we may request that you provide
          additional information for
        </p>
        <p>
          the purposes of verifying your identity and for security or fraud-prevention
          purposes. We will
        </p>
        <p>
          delete such additionally provided information as soon as we finish verifying
          you.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>Other privacy rights</strong>
        </p>
        <ul>
          <li>You may object to the processing of your personal information.</li>
          <li>
            You may request correction of your personal data if it is incorrect or no
            longer relevant, or
          </li>
          <li>ask to restrict the processing of the information.</li>
          <li>
            You can designate an authorized agent to make a request under the CCPA on
            your
          </li>
          <li>
            behalf. We may deny a request from an authorized agent that does not
            submit proof that
          </li>
          <li>
            they have been validly authorized to act on your behalf in accordance with
            the CCPA.
          </li>
          <li>
            You may request to opt out from future selling or sharing of your personal
            information to
          </li>
          <li>
            third parties. Upon receiving an opt-out request, we will act upon the
            request as soon as
          </li>
          <li>
            feasibly possible, but no later than fifteen (15) days from the date of
            the request
          </li>
          <li>submission.</li>
          <li>
            To exercise these rights, you can contact us by email at
            Memberservices@ela. law, or by
          </li>
          <li>
            referring to the contact details at the bottom of this document. If you
            have a complaint about how
          </li>
          <li>we handle your data, we would like to hear from you.</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: Yes, if you are a resident of Virginia, you may be granted
          specific rights regarding
        </p>
        <p>access to and use of your personal information.</p>
        <p>
          <br />
        </p>
        <p>Virginia CDPA Privacy Notice</p>
        <p>
          <br />
        </p>
        <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
        <p>
          <br />
        </p>
        <p>
          "Consumer" means a natural person who is a resident of the Commonwealth
          acting only in an
        </p>
        <p>
          individual or household context. It does not include a natural person acting
          in a commercial or
        </p>
        <p>employment context.</p>
        <p>
          <br />
        </p>
        <p>
          "Personal data” means any information that is linked or reasonably linkable
          to an identified or
        </p>
        <p>
          identifiable natural person. "Personal data" does not include de-identified
          data or publicly
        </p>
        <p>available information.</p>
        <p>
          <br />
        </p>
        <p>
          "Sale of personal data" means the exchange of personal data for monetary
          consideration.
        </p>
        <p>
          <br />
        </p>
        <p>
          If this definition "consumer" applies to you, we must adhere to certain
          rights and obligations
        </p>
        <p>regarding your personal data.</p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          The information we collect, use, and disclose about you will vary depending
          on how you interact
        </p>
        <p>
          with Employment Law Alliance, Inc. and our Services. To find out more,
          please visit the following
        </p>
        <p>links:</p>
        <ul>
          <li>Personal data we collect</li>
          <li>How we use your personal data</li>
          <li>When and with whom we share your personal data</li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <strong>Your rights with respect to your personal data</strong>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li>
            Right to be informed whether or not we are processing your personal data
          </li>
          <li>Right to access your personal data</li>
          <li>Right to correct inaccuracies in your personal data</li>
          <li>Right to request deletion of your personal data</li>
          <li>
            Right to obtain a copy of the personal data you previously shared with us
          </li>
          <li>
            Right to opt out of the processing of your personal data if it is used for
            targeted advertising, the sale of personal data, or profiling in
            furtherance of decisions that produce legal or similarly significant
            effects ("profiling")
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          Employment Law Alliance, Inc. has not sold any personal data to third
          parties for business or
        </p>
        <p>
          commercial purposes. Employment Law Alliance, Inc. will not sell personal
          data in the future
        </p>
        <p>belonging to website visitors, users, and other consumers.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Exercise your rights provided under the Virginia COPA</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          More information about our data collection and sharing practices can be
          found in this privacy
        </p>
        <p>
          notice. You may contact us by email at Memberservices@ela.law, by submitting
          a data subject access
        </p>
        <p>
          request, or by referring to the contact details at the bottom of this
          document.
        </p>
        <p>
          <br />
        </p>
        <p>
          If you are using an authorized agent to exercise your rights, we may deny a
          request if the
        </p>
        <p>
          authorized agent does not submit proof that they have been validly
          authorized to act on your
        </p>
        <p>behalf.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Verification process</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          We may request that you provide additional information reasonably necessary
          to verify you and
        </p>
        <p>
          your consumer's request. If you submit the request through an authorized
          agent, we may need
        </p>
        <p>
          to collect additional information to verify your identity before processing
          your request.
        </p>
        <p>
          <br />
        </p>
        <p>
          Upon receiving your request, we will respond without undue delay, but in all
          cases, within forty-
        </p>
        <p>
          five (45) days of receipt. The response period may be extended once by
          forty-five (45) additional
        </p>
        <p>
          days when reasonably necessary. We will inform you of any such extension
          within the initial 45-
        </p>
        <p>day response period, together with the reason for the extension.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>Right to appeal</strong>
        </p>
        <p>
          IT we decline to take action regarding your request, we will inform you of
          our decision and
        </p>
        <p>
          reasoning behind it. If you wish to appeal our decision, please email us at
        </p>
        <p>
          Memberservices@ela. law. Within sixty (60) days of receipt of an appeal, we
          will inform you in
        </p>
        <p>
          writing of any action taken or not taken in response to the appeal,
          including a written explanation
        </p>
        <p>
          of the reasons for the decisions. If your appeal if denied, you may contact
          the Attorney General
        </p>
        <p>to submit a complaint.</p>
        <p>
          <br />
        </p>
        <p>
          <strong>14. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          In Short: Yes, we will update this nofice as necessary to stay compliant
          with relevant laws.
        </p>
        <p>
          <br />
        </p>
        <p>
          We may update this privacy notice from time to time. The updated version
          will be indicated by an
        </p>
        <p>
          updated "Revised" date and the updated version will be effective as soon as
          it is accessible. If
        </p>
        <p>
          we make material changes to this privacy notice, we may notify you either by
          prominently
        </p>
        <p>
          posting a notice of such changes or by directly sending you a notification.
          We encourage you to
        </p>
        <p>
          review this privacy notice frequently to be informed of how we are
          protecting your information.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          If you have questions or comments about this notice, you may email us at
        </p>
        <p>
          Memberservices@ela.law or by post to: Employment Law Alliance, Inc. 456
          Montgomery Street Suite 2200 San Francisco, CA 94104 United States
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          Based on the applicable laws of your country, you may have the right to
          request access fo the
        </p>
        <p>
          Personal information we collect from you, change that information, or delete
          it. To request to
        </p>
        <p>
          Review, update, or delete your personal information, please fill out and
          submit a data subject
        </p>
        <p>Access request.</p>
        <p>
          <br />
        </p>
    
      </div>)
}