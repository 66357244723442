import { IFirm } from "../../../../interfaces/Admin";
import { IAction } from "../../../../interfaces/Common";
import { FirmActionTypes as ActionTypes } from "../../../action/Admin/User";

const initialState: IFirm = {
	id: undefined,
	name: undefined,
	email: undefined,
	phone: undefined,
	profile_image: undefined,
};

const firmReducer = (state = initialState, action: IAction<IFirm>) => {
	switch (action.type) {
		case ActionTypes.SET_FIRM:
			return {
				...state,
				id: action.payload?.id,
				name: action.payload?.name,
				email: action.payload?.email,
				phone: action.payload?.phone,
				profile_image: action.payload?.profile_image,
			};
		case ActionTypes.RESET_FIRM:
			return {
				...state,
				id: undefined,
				name: undefined,
				email: undefined,
				phone: undefined,
				profile_image: undefined,
			};
		default:
			return state;
	}
};

export default firmReducer;
