import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_ENDPOINT,
	prepareHeaders: (headers) => {
		headers.set("cache-control", "no-cache");
		return headers;
	},
});

export const noAuthApiSlice = createApi({
	reducerPath: "noAuthApi",
	baseQuery,
	endpoints: () => ({}),
});
