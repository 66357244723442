import Select from "../../../components/Select";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import {
	ArrowLeft,
	Envelope,
	Lock,
	LogoIcon,
	PhoneBook,
	User,
} from "../../../icons";

export default function Signup() {
	const handleSubmit = () => {
		alert("submitted!");
	};
	return (
		<>
			<section id='logon'>
				<div className='contain'>
					<div className='flex_blk'>
						<form action='' method='POST' className='auth-form'>
							<div className='log_blk'>
								<Link to='/' className='back_btn'>
									<ArrowLeft />
								</Link>
								<div className='inner'>
									<LogoIcon />
									<div className='txt text-center mb-5 pb-3'>
										<h2>Create Account</h2>
										<p className='fs_5'>
											Create an account to access ELA
										</p>
									</div>
									<div className='row'>
										<div className='col-12'>
											<h5 className='fw_500'>
												Select User
											</h5>
											<div className='form_blk'>
												<User />
												<Select
													label='Select'
													className='input'
												/>
											</div>
										</div>
										<div className='col-12'>
											<h5 className='fw_500 require'>
												Email Address
											</h5>
											<div className='form_blk'>
												<Envelope />
												<TextInput
													placeholder='eg: sample@gmail.com'
													type='email'
												/>
											</div>
										</div>
										<div className='col-12'>
											<h5 className='fw_500 require'>
												Phone
											</h5>
											<div className='row'>
												<div className='col-4 col-sm-3'>
													<div className='form_blk'>
														<PhoneBook />
														<Select
															label='+92'
															className='input'
														/>
													</div>
												</div>
												<div className='col-8 col-sm-9'>
													<div className='form_blk'>
														<TextInput
															placeholder='Enter Phone Number'
															type='phone'
														/>
													</div>
												</div>
											</div>
										</div>
										<div className='col-12'>
											<h5 className='fw_500 require'>
												Password
											</h5>
											<div className='form_blk'>
												<Lock />
												<input
													type='password'
													name=''
													className='input'
													placeholder='eg: PassLogin%7'
												/>
											</div>
										</div>
										<div className='col-12'>
											<h5 className='fw_500 require'>
												Confirm Password
											</h5>
											<div className='form_blk'>
												<Lock />
												<input
													type='password'
													name=''
													className='input'
													placeholder='eg: PassLogin%7'
												/>
											</div>
										</div>
									</div>
									<div className='btn_blk justify-content-center mt-5 pt-3'>
										<Button
											label='Create'
											type='submit'
											onClick={handleSubmit}
											className='site_btn w-100'
										/>
									</div>
									<div className='already_acc text-center'>
										Already have an account?&nbsp;
										<Link
											to='/client/login'
											className='fw_500'
										>
											Login
										</Link>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	);
}
