import React from "react";
import Layout from "../../../components/Layout";
import ReferalCard from "../../../components/ReferalCard";
import TopBlock from "../../../components/TopBlock";
import { REFERAL_DATA } from "../../../utills/DashboardData";

const Referals = () => {
  return (
    <>
      <Layout>
        <div id="referals" className="sec_pad_btm">
          <div className="contain sm">
            <TopBlock title="Referals" />
            <div className="tabs mb-4">
              <button className="site_btn active_btn">Recent</button>
              <button className="site_btn">Incoming</button>
              <button className="site_btn">Outgoing</button>
            </div>
            {REFERAL_DATA.map((item: any) => {
              return <ReferalCard item={item} key={item.id} />;
            })}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Referals;
