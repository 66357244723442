import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "../DeleteConfirmationModal/DeleteConfirmationModal";
import { Checkbox } from "@mui/material";
import { toast } from "react-toastify";
import MessageInfoModal from "../Popup/MessageInfoModal";
import { useDeleteChatMutation } from "../../redux/services/Firm/FirmLawyer";
import dayjs from "dayjs";

type itemInterface ={
    id:string,
    name:string,
    email:string,
    phone:string,
    query:string
	created_at:string
}

type Props = {
	item: itemInterface;
	checkbox?: boolean;
	handleSelect?: any;
	isSelected?: boolean;
    data?:any;
    setData?:any;
	refetch?:any;
};

const MessagesCard: React.FC<Props> = ({
	item,
	checkbox,
	handleSelect,
	isSelected,
	refetch,
}) => {
	const [messageModal, setMessageModal] = useState(false);
	const [deleteMessageModal, setDeleteMessageModal] = useState(false);

	const [
		deleteChatCall,
		{ data: contactData, isSuccess: chatSuccess, isError: chatError }
	] = useDeleteChatMutation();
	
	const handleSetActive = () => {
		setMessageModal(!messageModal);
	};
	const handleMessageModal = () => {
		setMessageModal(true);
	};
	const handleDeleteMessage = () => {
		setDeleteMessageModal(!deleteMessageModal);
	};

	const handleDeleteReferralModal = () => {
		
		deleteChatCall({
			id: item.id,
		});
	
		setDeleteMessageModal(false);
};

	useEffect(() => {
		if (chatSuccess) {
			toast.success("Chat Deleted Successfully");
			refetch();
		}      
		if (chatError) {
			toast.error("Error! Can not delete the chat. Please try Again");
		}
	}, [chatSuccess, chatError]);

	return (
		<>
			<div className="message_Card">
				{checkbox && <Checkbox
					checked={isSelected}
					style={{
						marginLeft: "-6rem",
					}}
					classes={{
						root: "custom-checkbox-root",
					}}
					onClick={() => {handleSelect(item?.id)}}
				/>}
				<div className="message_to" style={{
					marginLeft: "2rem",
				}}>
					<h5 className="fw_500">Name:</h5>
					<p>{ item?.name}</p>
				</div>
				<div>
					<h5 className="fw_500">DateTime</h5>
					<p>{dayjs(item?.created_at).format('MM/DD/YYYY , h:mm:ss A') }</p>
				</div>
				<div className="message_by">
					<h5 className="fw_500">Email:</h5>
					<p>{item?.email}</p>
				</div>
				<div className="message_by">
					<h5 className="fw_500">Phone:</h5>
					<p>{item?.phone}</p>
				</div>
				<div className="actions">
					<h5 className="fw_500">Action</h5>
						<div style={{ display: "flex" }}>
							<button
								type="button"
								className="icon_blk"
								style={{
									cursor: "pointer",
									border: "none",
									background: "transparent",
								}}
								onClick={handleMessageModal}
							>
								<VisibilityIcon
									sx={{
										color: "grey",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
							<button
								type="button"
								className="icon_blk"
								style={{
									cursor: "pointer",
									border: "none",
									background: "transparent",
								}}
								onClick={handleDeleteMessage}
							>
								<DeleteIcon
									sx={{
										color: "red",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</div>
				</div>
			</div>
			<MessageInfoModal
				active={messageModal}
				setActive={handleSetActive}
				item={item}
			/>
			<DeleteConfirmation
				message={"Are you sure you want to delete this Message ?"}
				showModal={deleteMessageModal}
				handleDelete={handleDeleteReferralModal}
				hideModal={handleDeleteMessage}
			/>
		</>
	);
};

export default MessagesCard;
