import React from "react";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";

export default function Verification() {
    const handleSubmit = () => {
        alert("submiited!");
    };
    return (
        <>
            <section id="logon">
                <div className="contain">
                    <div className="flex_blk">
                        <form action="" method="POST" className="auth-form">
                            <div className="log_blk">
                                <div className="inner">
                                    <div className="txt text-center mb-5 pb-3">
                                        <h2>Enter verification code</h2>
                                        <p className="fs_5 opacity-50">Enter the verification code sent to your <br /> email address</p>
                                    </div>
                                    <div className="row verify_row justify-content-center">
                                        <div className="col-auto">
                                            <div className="form_blk">
                                                <TextInput placeholder="0" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="form_blk">
                                                <TextInput placeholder="0" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="form_blk">
                                                <TextInput placeholder="0" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="form_blk">
                                                <TextInput placeholder="0" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn_blk justify-content-center mt-5 pt-3">
                                        <Button label="Submit" type="submit" onClick={handleSubmit} className="site_btn w-100" />
                                    </div>
                                    <div className="already_acc text-center">
                                        <p className="fs_6 opacity-50">Entered a wrong email address?</p>
                                        <button type="button" className="site_btn text">Resend Verification Code Password</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
