import { isEmptyArray } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import search from "../../../../assets/images/search.svg";
import Layout from "../../../../components/Layout";
import AddNewClient from "../../../../components/Popup/AddNewClient/AddNewClinet";
import RegisteredFirmsCard from "../../../../components/RegisteredFirmsCard";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TopBlock from "../../../../components/TopBlock";
import {
	useDeleteClientMutation,
	useGetClientListQuery,
	useGetClientSearchMutation,
} from "../../../../redux/services/Firm/FirmLawyer";
import AnalyticsTile from "../../../../components/AnalyticsTile";
import { useGetAnalyticsDataQuery } from "../../../../redux/services/Dashboard";
import icon1 from "../../../../assets/images/icon1.svg";
import { Checkbox } from "@mui/material";
import { Button } from "react-bootstrap";
import DeleteConfirmation from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import { toast } from "react-toastify";
import ReactPagination from "../../../../components/ReactPagination/ReactPagination";
const AddClient = () => {
	
	const [addSuperAdmin, setAddSuperAdmin] = useState(false);
	const [deleteSuperAdmin, setDeleteSuperAdmin] = useState(false);
	const [updateSuperAdmin, setUpdateSuperAdmin] = useState(false);
	const [searchText, setSearchText] = useState<any | null>("");
	const [clients, setClients] = useState<any | null>(null);
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [selectAll, setSelectAll] = useState(false);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [deleteClients, setDeleteClients] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filteredData, setFilteredData] = useState<any | null>(null);

	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const registeredParam = params.get('registered');
	const thisMonthParam = params.get('this_month');
	const queryParams = {
		...(registeredParam && { registered: registeredParam }),
		...(thisMonthParam && { this_month: thisMonthParam })
	  };
	const { data, isLoading, refetch } = useGetClientListQuery({
		rowsPerPage,
		pageNumber,
		...queryParams
	});
	const [searching, { data: searchData, isLoading: searchLoading }] =
		useGetClientSearchMutation();

	const {
		data: analyticsData,
		isLoading: isAnalyticsLoading,
		isSuccess: isAnalyticsSuccess,
		refetch: refetchAnalyticsData,
	} = useGetAnalyticsDataQuery({
		refetchOnMountOrArgChange: true,
	});

	const [deleteClient, { isSuccess, isError }] = useDeleteClientMutation();

	const handleSetAddFirm = () => {
		setAddSuperAdmin(!addSuperAdmin);
	};
	const handleUpdate = () => {
		setUpdateSuperAdmin(!updateSuperAdmin);
	};
	const handleDelete = () => {
		setDeleteSuperAdmin(!deleteSuperAdmin);
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	useEffect(() => {
		if (deleteClients) {
			deleteClient(selectedItems);
			setDeleteClients(false);
		}
		if (isSuccess) {
			toast.success("Clients Deleted Successfully");
			searching({ search: "" });
			refetchAnalyticsData();
			if (selectAll) {
				setSelectAll(!selectAll);
			}
			setDeletePopUp(false);
			setSelectedItems([]);
		}
		if (isError) {
			toast.error(
				"Could not delete clients at the moment, Please try again"
			);
		}
	}, [deleteClients, isSuccess, isError]);

	useEffect(() => {
		searching({ search: "" });
		refetchAnalyticsData();
	}, [addSuperAdmin, deleteSuperAdmin, updateSuperAdmin]);

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searching({ search });
			} else {
				setFilteredData(null);
				setClients(data?.client);
			}
		}, 1000);
	};

	useEffect(() => {
		if (searchData?.client && searchText.length > 0) {	
			setFilteredData(searchData?.client);
		} else {
			setClients(data?.client);
		}
	}, [searchData, data]);

	const handleItemSelect = (itemId: any) => {
		if (selectedItems?.includes(itemId)) {
			setSelectedItems(selectedItems?.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};
	useEffect(() => {
		if (selectedItems?.length === data?.client?.length) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [selectedItems]);

	useEffect(() => {
		setSearchText("");
		setFilteredData(null);
		refetch();
	}, [deleteClient, pageNumber, rowsPerPage]);

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(
				data?.client?.map((item: { id: any }) => item.id)
			);
		}
		setSelectAll(!selectAll);
	};
	return (
		<Layout>
			<section id="manage_registered_firms" className="sec_pad_btm ">
				<div className="contain sm">
					<div id="dashboard" className="mb-5">
						<AnalyticsTile
							title="Client / Guest"
							icon={icon1}
							path="/firm/super-admin-manage-client"
							count={analyticsData?.clients?.total_count}
							currentMonth={
								analyticsData?.clients?.current_month_count
							}
							percentage={
								analyticsData?.clients?.percentage_difference
							}
							mobileRegistered={analyticsData?.clients?.registered_on_mobile_count}
							mobileUnregistered={analyticsData?.clients?.unregistered_on_mobile_count}
						/>
					</div>
					<div className="top_blk mb-5">
						<TopBlock
							title="Clients"
							url="dashboard"
							button={true}
							setAddFirm={setAddSuperAdmin}
							buttonText="Add New Client"
						/>
					</div>

					<div
						className="search_container"
						style={{
							justifyContent: "space-between",
							backgroundColor: "#63a744",
							alignItems: "center",
							padding: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginLeft: "4rem",
								}}
							>
								<Checkbox
									checked={selectAll}
									classes={{ root: "custom-checkbox-root" }}
									onClick={() => {
										handleSelectAllChange();
									}}
								/>
								<p
									style={{
										fontSize: "2rem",
										lineHeight: "7rem",
									}}
								>
									Select All
								</p>
							</div>
							{selectedItems?.length > 0 && (
								<Button
									variant="danger"
									onClick={handleBulkDelete}
									style={{
										fontSize: "2rem",
										width: "15rem",
										height: "5rem",
										marginTop: "1rem",
										marginLeft: "2rem",
									}}
								>
									Delete
								</Button>
							)}
						</div>
						<div style={{ display: "flex" }}>
							<div className="left_blk">
								<div className="search_container">
									<div className="search_blk_lawyers">
										<div className="img_blk">
											<img
												src={search}
												alt="search icon"
											/>
										</div>
										<input
											type="search"
											placeholder="Search"
											value={searchText}
											style={{ marginTop: "0.5rem" }}
											onChange={(e) => {
												handleSearch(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					{isEmptyArray(clients?.client) ? (
						<div className="empty_lawyer_text">
							No Super Admin to show{" "}
						</div>
					) : null}

					{addSuperAdmin && (
						<AddNewClient
							active={addSuperAdmin}
							setActive={handleSetAddFirm}
							isUpdate={false}
							client={clients?.client}
						/>
					)}

					{(clients?.length > 0 || filteredData?.length > 0) &&
						(filteredData ? filteredData : clients)?.map((item: any) => {
						return (
							<RegisteredFirmsCard
								item={item}
								key={item.id}
								setDelete={handleDelete}
								setUpdate={handleUpdate}
								isSuperAdmin={true}
								isClient={true}
								isSelected={selectedItems?.includes(item?.id)}
								checkbox={true}
								onSelect={handleItemSelect}
							/>
						);
					})}

					{!searchLoading && clients?.length > 0 &&
						filteredData === null && (
						<ReactPagination
							setRowsPerPage={setRowsPerPage}
							setPages={setPageNumber}
							totalRecords={data?.total_records}
						/>
					)}
				</div>
			</section>
			<DeleteConfirmation
				showModal={deletePopUp}
				message={
					"Are you sure you want to delete the selected Lawyers?"
				}
				handleDelete={setDeleteClients}
				hideModal={handleBulkDelete}
			/>
			<ScreenLoader show={isLoading || searchLoading} />
		</Layout>
	);
};

export default AddClient;
