
export default function TermsAndConditionsText() {
    return (
        <>
            {/* Hello world */}
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
                }}
            />
            <div data-custom-class="body">
                <div align="center" style={{ textAlign: "left" }}>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            align="center"
                            className="MsoNormal"
                            style={{ textAlign: "center", lineHeight: "150%" }}
                        >
                            <a name="_2cipo4yr3w5d" />
                            <div
                                align="center"
                                className="MsoNormal"
                                style={{ lineHeight: "22.5px" }}
                            >
                                <div
                                    align="center"
                                    className="MsoNormal"
                                    style={{ lineHeight: "150%" }}
                                >
                                    <a name="_gm5sejt4p02f" />
                                    <div
                                        align="center"
                                        className="MsoNormal"
                                        data-custom-class="title"
                                        style={{ textAlign: "left", lineHeight: "1.5" }}
                                    >

                                    </div>
                                    <div
                                        align="center"
                                        className="MsoNormal"
                                        style={{ lineHeight: "22.5px", textAlign: "left" }}
                                    >
                                        <a name="_7m5b3xg56u7y" />
                                    </div>
                                </div>
                            </div>
                            <div
                                align="center"
                                className="MsoNormal"
                                style={{ lineHeight: "17.25px", textAlign: "left" }}
                            >
                                <br />
                            </div>
                            <div
                                align="center"
                                className="MsoNormal"
                                style={{ lineHeight: "17.25px", textAlign: "left" }}
                            >
                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                    <strong>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>Last updated</span>
                                            &nbsp;
                                        </span>
                                    </strong>
                                    <bdt className="question">
                                        <strong>
                                            <span data-custom-class="body_text">January 31, 2023</span>
                                        </strong>
                                    </bdt>
                                </span>
                            </div>
                            <div
                                align="center"
                                className="MsoNormal"
                                style={{ lineHeight: "17.25px", textAlign: "left" }}
                            >
                                <br />
                            </div>
                            <div
                                align="center"
                                className="MsoNormal"
                                style={{ lineHeight: "17.25px", textAlign: "left" }}
                            >
                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                    <br />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            style={{ lineHeight: "115%" }}
                        >
                            <a name="_a7mwfgcrtsqn" />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166
                                }}
                            >
                                <bdt className="question">Employment Law Alliance - Mobile App</bdt>{" "}
                                is licensed to You (End-User) by{" "}
                                <bdt className="question">Employment Law Alliance </bdt>, located
                                <bdt className="block-component" /> at{" "}
                                <bdt className="question">456 Montgomery Street</bdt>,
                                <span
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "115%",
                                        msoFareastFontFamily: "Calibri",
                                        color: "#595959",
                                        msoThemecolor: "text1",
                                        msoThemetint: 166
                                    }}
                                >
                                    <bdt className="block-component" />{" "}
                                    <bdt className="question">Suite 2200</bdt>,
                                    <bdt className="statement-end-if-in-editor" />{" "}
                                    <bdt className="question">San Francisco</bdt>,{" "}
                                    <bdt className="block-component" />
                                    <bdt className="question">California</bdt>
                                    <bdt className="block-component" />{" "}
                                    <bdt className="question">94104</bdt>
                                    <span style={{ fontSize: 15 }}>
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />,{" "}
                                        <bdt className="question">United States</bdt>
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        &nbsp;
                                    </span>
                                </span>
                                (<bdt className="block-component" />"<strong>Licensor</strong>"
                                <bdt className="statement-end-if-in-editor" />
                                ), for use only under the terms of this{" "}
                                <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement.
                                <bdt className="block-component" />{" "}
                                <span
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "115%",
                                        msoFareastFontFamily: "Calibri",
                                        color: "#595959",
                                        msoThemecolor: "text1",
                                        msoThemetint: 166
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            msoFareastFontFamily: "Calibri",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                msoFareastFontFamily: "Calibri",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "11.0pt",
                                                    lineHeight: "115%",
                                                    msoFareastFontFamily: "Calibri",
                                                    color: "#595959",
                                                    msoThemecolor: "text1",
                                                    msoThemetint: 166
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "11.0pt",
                                                        lineHeight: "115%",
                                                        msoFareastFontFamily: "Calibri",
                                                        color: "#595959",
                                                        msoThemecolor: "text1",
                                                        msoThemetint: 166
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "11.0pt",
                                                            lineHeight: "115%",
                                                            msoFareastFontFamily: "Calibri",
                                                            color: "#595959",
                                                            msoThemecolor: "text1",
                                                            msoThemetint: 166
                                                        }}
                                                    >
                                                        We are registered in
                                                        <bdt className="block-component" />
                                                        <bdt className="block-component" />
                                                        <bdt className="block-component" />{" "}
                                                        <bdt className="question">Nevada</bdt>
                                                        <span
                                                            style={{
                                                                fontSize: "11.0pt",
                                                                lineHeight: "115%",
                                                                msoFareastFontFamily: "Calibri",
                                                                color: "#595959",
                                                                msoThemecolor: "text1",
                                                                msoThemetint: 166
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize: "11.0pt",
                                                                    lineHeight: "115%",
                                                                    msoFareastFontFamily: "Calibri",
                                                                    color: "#595959",
                                                                    msoThemecolor: "text1",
                                                                    msoThemetint: 166
                                                                }}
                                                            >
                                                                , <bdt className="question">United States</bdt>
                                                            </span>
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                </span>
                                                <bdt className="block-component">
                                                    <span
                                                        style={{
                                                            fontSize: "11.0pt",
                                                            lineHeight: "115%",
                                                            msoFareastFontFamily: "Calibri",
                                                            color: "#595959",
                                                            msoThemecolor: "text1",
                                                            msoThemetint: 166
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "11.0pt",
                                                                lineHeight: "115%",
                                                                msoFareastFontFamily: "Calibri",
                                                                color: "#595959",
                                                                msoThemecolor: "text1",
                                                                msoThemetint: 166
                                                            }}
                                                        >
                                                            &nbsp;and have our registered office at{" "}
                                                            <bdt className="question">112 North Curry Street</bdt>
                                                            <bdt className="block-component" />
                                                        </span>
                                                    </span>
                                                    , <bdt className="question">Carson City </bdt>
                                                </bdt>
                                            </span>
                                        </span>
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />,{" "}
                                                            <bdt className="question">NV</bdt>
                                                            <bdt className="statement-end-if-in-editor" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />{" "}
                                                            <bdt className="question">89703</bdt>
                                                            <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text">
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <bdt className="statement-end-if-in-editor">.</bdt>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                msoFareastFontFamily: "Calibri",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <bdt className="question">
                                                <bdt className="statement-end-if-in-editor" />
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <span
                                style={{
                                    fontSize: 15,
                                    lineHeight: "115%",
                                    fontFamily: "Arial",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "115%",
                                        msoFareastFontFamily: "Calibri",
                                        color: "#595959",
                                        msoThemecolor: "text1",
                                        msoThemetint: 166
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            msoFareastFontFamily: "Calibri",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                msoFareastFontFamily: "Calibri",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "11.0pt",
                                                    lineHeight: "115%",
                                                    msoFareastFontFamily: "Calibri",
                                                    color: "#595959",
                                                    msoThemecolor: "text1",
                                                    msoThemetint: 166
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "11.0pt",
                                                        lineHeight: "115%",
                                                        msoFareastFontFamily: "Calibri",
                                                        color: "#595959",
                                                        msoThemecolor: "text1",
                                                        msoThemetint: 166
                                                    }}
                                                >
                                                    <bdt className="block-component" />
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: 1 }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166
                                }}
                            >
                                By downloading the Licensed Application from{" "}
                                <bdt className="block-component" />
                                Apple's software distribution platform (
                                <bdt className="block-component" />
                                "App Store"
                                <bdt className="statement-end-if-in-editor" />)
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" /> and{" "}
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                Google's software distribution platform (
                                <bdt className="block-component" />
                                "Play Store"
                                <bdt className="statement-end-if-in-editor" />)
                                <bdt className="statement-end-if-in-editor" />, and any update
                                thereto (as permitted by this <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement), You
                                indicate that You agree to be bound by all of the terms and
                                conditions of this <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement, and that
                                You accept this <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement.{" "}
                                <bdt className="block-component" />
                                App Store and Play Store are
                                <bdt className="block-component" /> referred to in this{" "}
                                <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement as{" "}
                                <bdt className="block-component" />"<strong>Services</strong>."
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: 1 }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166
                                }}
                            >
                                The parties of this <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement acknowledge
                                that the Services are not a Party to this{" "}
                                <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement and are not
                                bound by any provisions or obligations with regard to the Licensed
                                Application, such as warranty, liability, maintenance and support
                                thereof. <bdt className="question">Employment Law Alliance </bdt>,
                                not the Services, is solely responsible for the Licensed Application
                                and the content thereof.
                            </span>
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: 1 }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <bdt data-type="conditional-block">
                                <span style={{ fontSize: 15 }}>
                                    <bdt data-type="body">
                                        <span
                                            style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                        >
                                            This <bdt className="block-component" />
                                            License
                                            <bdt className="statement-end-if-in-editor" /> Agreement may
                                            not provide for usage rules for the Licensed Application that
                                            are in conflict with the latest{" "}
                                            <bdt className="block-component" />
                                        </span>
                                    </bdt>
                                </span>
                            </bdt>
                            <a
                                data-custom-class="link"
                                href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <bdt data-type="conditional-block">
                                    <span style={{ fontSize: 15 }}>
                                        <bdt data-type="body">
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                Apple Media Services Terms and Conditions
                                            </span>
                                        </bdt>
                                    </span>
                                </bdt>
                            </a>
                            <bdt data-type="conditional-block">
                                <span style={{ fontSize: 15 }}>
                                    <bdt data-type="body">
                                        <span
                                            style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                        >
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" /> and{" "}
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />
                                        </span>
                                    </bdt>
                                </span>
                            </bdt>
                            <a
                                data-custom-class="link"
                                href="https://play.google.com/intl/en_US/about/play-terms/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <bdt data-type="conditional-block">
                                    <span style={{ fontSize: 15 }}>
                                        <bdt data-type="body">
                                            <span
                                                style={{
                                                    lineHeight: "16.8667px",
                                                    color: "rgb(89, 89, 89)"
                                                }}
                                            >
                                                Google Play Terms of Service
                                            </span>
                                        </bdt>
                                    </span>
                                </bdt>
                            </a>
                            <bdt data-type="conditional-block">
                                <span style={{ fontSize: 15 }}>
                                    <bdt data-type="body">
                                        <span
                                            style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                        >
                                            <bdt className="statement-end-if-in-editor" /> (
                                            <bdt className="block-component" />"
                                            <strong>Usage Rules</strong>"
                                            <bdt className="statement-end-if-in-editor" />
                                            ).
                                        </span>
                                    </bdt>
                                </span>
                            </bdt>{" "}
                            <bdt data-type="conditional-block">
                                <span style={{ fontSize: 15 }}>
                                    <bdt data-type="body">
                                        <span
                                            style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                        >
                                            <bdt className="question">Employment Law Alliance </bdt>{" "}
                                            acknowledges that it had the opportunity to review the Usage
                                            Rules and this <bdt className="block-component" />
                                            License
                                            <bdt className="statement-end-if-in-editor" /> Agreement is
                                            not conflicting with them.
                                        </span>
                                    </bdt>
                                </span>
                            </bdt>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <bdt className="question">Employment Law Alliance - Mobile App</bdt>{" "}
                            <bdt data-type="conditional-block">
                                <span style={{ fontSize: 15 }}>
                                    <bdt data-type="body">
                                        <span
                                            style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                        >
                                            when purchased or downloaded through the Services, is licensed
                                            to You for use only under the terms of this{" "}
                                            <bdt className="block-component" />
                                            License
                                            <bdt className="statement-end-if-in-editor" /> Agreement. The
                                            Licensor reserves all rights not expressly granted to You.{" "}
                                            <bdt className="question">
                                                Employment Law Alliance - Mobile App
                                            </bdt>{" "}
                                            is to be used on devices that operate with{" "}
                                            <bdt className="block-component" />
                                            Apple's operating systems (<bdt className="block-component" />
                                            "iOS" and "Mac OS"
                                            <bdt className="statement-end-if-in-editor" />)
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" /> or{" "}
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />
                                            Google's operating system (<bdt className="block-component" />
                                            "Android"
                                            <bdt className="statement-end-if-in-editor" />)
                                            <bdt className="statement-end-if-in-editor" />.
                                        </span>
                                    </bdt>
                                </span>
                            </bdt>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            style={{ lineHeight: "1.5" }}
                        >
                            <strong>
                                <span style={{ fontSize: 19 }}>TABLE OF CONTENTS</span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#application">
                                1. THE APPLICATION
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#scope">
                                2. SCOPE OF <bdt className="block-component" />
                                LICENSE
                                <bdt className="statement-end-if-in-editor" />
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#requirements">
                                3. TECHNICAL REQUIREMENTS
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <bdt className="block-component" />
                            <a data-custom-class="link" href="#nosupport">
                                4. NO MAINTENANCE AND SUPPORT
                            </a>
                            <bdt className="statement-end-if-in-editor" />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <bdt className="block-component" />
                            <a data-custom-class="link" href="#datause">
                                5. USE OF DATA
                            </a>
                            <bdt className="statement-end-if-in-editor" />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#ugc">
                                6. USER-GENERATED CONTRIBUTIONS
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#contribution">
                                7. CONTRIBUTION <bdt className="block-component" />
                                LICENSE
                                <bdt className="statement-end-if-in-editor" />
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#liability">
                                8. LIABILITY
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#warranty">
                                9. WARRANTY
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#productclaims">
                                10. PRODUCT CLAIMS
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#compliance">
                                11. LEGAL COMPLIANCE
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#contact">
                                12. CONTACT INFORMATION
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#termination">
                                13. TERMINATION
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#thirdparty">
                                14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#ipr">
                                15. INTELLECTUAL PROPERTY RIGHTS
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#law">
                                16. APPLICABLE LAW
                            </a>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a data-custom-class="link" href="#misc">
                                17. MISCELLANEOUS
                            </a>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="application"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a name="_4rd71iod99ud" />
                            <strong>
                                <span
                                    style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                                >
                                    <span style={{ fontSize: 19 }}>
                                        <strong>1.</strong>
                                        <strong>&nbsp;THE APPLICATION</strong>
                                    </span>
                                </span>
                            </strong>
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: 1 }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "115%",
                                        msoFareastFontFamily: "Calibri",
                                        color: "#595959",
                                        msoThemecolor: "text1",
                                        msoThemetint: 166
                                    }}
                                >
                                    <bdt className="question">
                                        Employment Law Alliance - Mobile App
                                    </bdt>
                                </span>{" "}
                                (<bdt className="block-component" />"
                                <strong>Licensed Application</strong>"
                                <bdt className="statement-end-if-in-editor" />) is a piece of
                                software created to{" "}
                                <bdt className="question">
                                    to provide access to relevant content, and direct contact with
                                    labor and employment from around the world whose firm is a member
                                    of the Employment Law Alliance.{" "}
                                </bdt>{" "}
                                — and <bdt className="block-component" />
                                customized
                                <bdt className="statement-end-if-in-editor" /> for{" "}
                                <bdt className="block-component" />
                                iOS
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" /> and{" "}
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                Android
                                <bdt className="statement-end-if-in-editor" /> mobile devices (
                                <bdt className="block-component" />"<strong>Devices</strong>"
                                <bdt className="statement-end-if-in-editor" />
                                ). It is used to{" "}
                                <bdt className="question">
                                    Contact ELA member lawyers, view ELA content to include: access to
                                    the ELA Global Employers Handbook (GEH), webinars, news articles,
                                    podcasts, vlogs, and other content from the ELA.
                                </bdt>
                                .<bdt className="block-component" />
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.1" }}
                        >
                            <span
                                style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "115%",
                                        msoFareastFontFamily: "Calibri",
                                        color: "#595959",
                                        msoThemecolor: "text1",
                                        msoThemetint: 166
                                    }}
                                >
                                    <bdt className="statement-end-if-in-editor">
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                    </bdt>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <bdt className="question">
                                The ELA expressly states that the information found on this
                                Application is for information purposes only, and should not be
                                construed as legal advise on any subject matter.{" "}
                            </bdt>
                            <span
                                style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "115%",
                                        msoFareastFontFamily: "Calibri",
                                        color: "#595959",
                                        msoThemecolor: "text1",
                                        msoThemetint: 166
                                    }}
                                >
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="statement-end-if-in-editor" />
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="scope"
                            style={{ lineHeight: "1.5" }}
                        >
                            <a name="_vhkegautf00d" />
                            <strong>
                                <span
                                    style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                                >
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <span style={{ fontSize: 19 }}>
                                                <strong>
                                                    2. SCOPE OF <bdt className="block-component" />
                                                    LICENSE
                                                    <bdt className="statement-end-if-in-editor" />
                                                </strong>
                                            </span>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    />
                                </span>{" "}
                                <bdt className="block-component" />
                            </span>
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: 1 }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        2.1 &nbsp;You are given a non-transferable,
                                                                        non-exclusive, non-sublicensable{" "}
                                                                        <bdt className="block-component" />
                                                                        license
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        to install and use the Licensed Application on
                                                                        any Devices that You (End-User) own or control
                                                                        and as permitted by the Usage Rules, with the
                                                                        exception that such Licensed Application may be
                                                                        accessed and used by other accounts associated
                                                                        with You (End-User, The Purchaser) via Family
                                                                        Sharing or volume purchasing.
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            msoFareastFontFamily: "Calibri",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                msoFareastFontFamily: "Calibri",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <bdt className="statement-end-if-in-editor">
                                                <bdt className="block-component" />
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        2
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        .
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        2 &nbsp;This <bdt className="block-component" />
                                                                        license
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        will also govern any updates of the Licensed
                                                                        Application provided by Licensor that replace,
                                                                        repair, and/or supplement the first Licensed
                                                                        Application, unless a separate{" "}
                                                                        <bdt className="block-component" />
                                                                        license
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        is provided for such update, in which case the
                                                                        terms of that new{" "}
                                                                        <bdt className="block-component" />
                                                                        license
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        will govern.
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            msoFareastFontFamily: "Calibri",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                msoFareastFontFamily: "Calibri",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <bdt className="statement-end-if-in-editor">
                                                <bdt className="block-component" />
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span
                                style={{
                                    fontSize: "11pt",
                                    lineHeight: "16.8667px",
                                    color: "rgb(89, 89, 89)"
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "11pt",
                                        lineHeight: "16.8667px",
                                        color: "rgb(89, 89, 89)"
                                    }}
                                >
                                    2
                                    <bdt
                                        className="block-container if"
                                        data-type="if"
                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                    >
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body">
                                                <span
                                                    style={{
                                                        color: "rgb(89, 89, 89)",
                                                        fontSize: "14.6667px"
                                                    }}
                                                >
                                                    .
                                                    <bdt
                                                        className="block-container if"
                                                        data-type="if"
                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                    >
                                                        <bdt data-type="conditional-block">
                                                            <bdt data-type="body">
                                                                <span
                                                                    style={{
                                                                        color: "rgb(89, 89, 89)",
                                                                        fontSize: "14.6667px"
                                                                    }}
                                                                >
                                                                    3 &nbsp;You may not share or make the Licensed
                                                                    Application available to third parties (unless to
                                                                    the degree allowed by the Usage Rules, and with{" "}
                                                                    <bdt className="question">
                                                                        Employment Law Alliance{" "}
                                                                    </bdt>
                                                                    's prior written consent), sell, rent, lend, lease
                                                                    or otherwise redistribute the Licensed
                                                                    Application.
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: "11.0pt",
                                                                                    lineHeight: "115%",
                                                                                    msoFareastFontFamily: "Calibri",
                                                                                    color: "#595959",
                                                                                    msoThemecolor: "text1",
                                                                                    msoThemetint: 166
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: "11.0pt",
                                                                                        lineHeight: "115%",
                                                                                        msoFareastFontFamily: "Calibri",
                                                                                        color: "#595959",
                                                                                        msoThemecolor: "text1",
                                                                                        msoThemetint: 166
                                                                                    }}
                                                                                >
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                    <bdt className="block-component" />
                                                                </span>
                                                            </bdt>
                                                        </bdt>
                                                    </bdt>
                                                </span>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        2
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        .
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        4 &nbsp;You may not reverse engineer, translate,
                                                                        disassemble, integrate, decompile, remove,
                                                                        modify, combine, create derivative works or
                                                                        updates of, adapt, or attempt to derive the
                                                                        source code of the Licensed Application, or any
                                                                        part thereof (except with{" "}
                                                                        <bdt className="question">
                                                                            Employment Law Alliance{" "}
                                                                        </bdt>
                                                                        's prior written consent).
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11pt",
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: "11pt",
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <bdt
                                                                                    className="block-container if"
                                                                                    data-type="if"
                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                >
                                                                                    <bdt data-type="conditional-block">
                                                                                        <bdt data-type="body">
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "rgb(89, 89, 89)",
                                                                                                    fontSize: "14.6667px"
                                                                                                }}
                                                                                            >
                                                                                                <bdt
                                                                                                    className="block-container if"
                                                                                                    data-type="if"
                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                >
                                                                                                    <bdt data-type="conditional-block">
                                                                                                        <bdt data-type="body">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)",
                                                                                                                    fontSize: "14.6667px"
                                                                                                                }}
                                                                                                            >
                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                    <bdt className="block-component" />
                                                                                                                </bdt>
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        2
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        .
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        5 &nbsp;You may not copy (excluding when
                                                                        expressly <bdt className="block-component" />
                                                                        authorized
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        by this <bdt className="block-component" />
                                                                        license
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        and the Usage Rules) or alter the Licensed
                                                                        Application or portions thereof. You may create
                                                                        and store copies only on devices that You own or
                                                                        control for backup keeping under the terms of
                                                                        this <bdt className="block-component" />
                                                                        license
                                                                        <bdt className="statement-end-if-in-editor" />,
                                                                        the Usage Rules, and any other terms and
                                                                        conditions that apply to the device or software
                                                                        used. You may not remove any intellectual
                                                                        property notices. You acknowledge that no{" "}
                                                                        <bdt className="block-component" />
                                                                        unauthorized
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        third parties may gain access to these copies at
                                                                        any time. If you sell your Devices to a third
                                                                        party, you must remove the Licensed Application
                                                                        from the Devices before doing so.
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11pt",
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        2
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        .
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        6 &nbsp;Violations of the obligations mentioned
                                                                        above, as well as the attempt of such
                                                                        infringement, may be subject to prosecution and
                                                                        damages.
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "16.8667px",
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <bdt
                                                                                        className="block-container if"
                                                                                        data-type="if"
                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                    >
                                                                                        <bdt data-type="conditional-block">
                                                                                            <bdt data-type="body">
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <bdt
                                                                                                        className="block-container if"
                                                                                                        data-type="if"
                                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                    >
                                                                                                        <bdt data-type="conditional-block">
                                                                                                            <bdt data-type="body">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                        <bdt className="block-component" />
                                                                                                                    </bdt>
                                                                                                                </span>
                                                                                                            </bdt>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        2
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        .
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        7 &nbsp;Licensor reserves the right to modify
                                                                        the terms and conditions of licensing.
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11pt",
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <bdt className="block-component" />
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: 1 }}
                        >
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        2
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        .
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        8 &nbsp;Nothing in this{" "}
                                                                        <bdt className="block-component" />
                                                                        license
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        should be interpreted to restrict third-party
                                                                        terms. When using the Licensed Application, You
                                                                        must ensure that You comply with applicable
                                                                        third-party terms and conditions.
                                                                        <span
                                                                            style={{
                                                                                fontSize: "11pt",
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "16.8667px",
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <bdt
                                                                                        className="block-container if"
                                                                                        data-type="if"
                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                    >
                                                                                        <bdt data-type="conditional-block">
                                                                                            <bdt data-type="body">
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <bdt
                                                                                                        className="block-container if"
                                                                                                        data-type="if"
                                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                    >
                                                                                                        <bdt data-type="conditional-block">
                                                                                                            <bdt data-type="body">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            fontSize: "14.6667px"
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </bdt>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "1.5" }}
                    >
                        <br />
                    </div>
                    <div
                        align="center"
                        className="MsoNormal"
                        style={{ textAlign: "left", lineHeight: "150%" }}
                    >
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="requirements"
                            style={{ lineHeight: "17.25px" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>3. TECHNICAL REQUIREMENTS</strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                            <span style={{ fontSize: 15 }}>
                                <bdt className="block-component" />
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        3.
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        1 &nbsp;The Licensed Application requires a
                                                                        firmware version{" "}
                                                                        <bdt className="block-component" />
                                                                        <bdt className="question">__________</bdt> or
                                                                        higher
                                                                        <bdt className="statement-end-if-in-editor" />.
                                                                        Licensor recommends using the latest version of
                                                                        the firmware.
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <bdt className="block-component" />
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        3.
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        2 &nbsp;Licensor attempts to keep the Licensed
                                                                        Application updated so that it complies with
                                                                        modified/new versions of the firmware and new
                                                                        hardware. You are not granted rights to claim
                                                                        such an update.
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "16.8667px",
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <bdt
                                                                                        className="block-container if"
                                                                                        data-type="if"
                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                    >
                                                                                        <bdt data-type="conditional-block">
                                                                                            <bdt data-type="body">
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <bdt
                                                                                                        className="block-container if"
                                                                                                        data-type="if"
                                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                    >
                                                                                                        <bdt data-type="conditional-block">
                                                                                                            <bdt data-type="body">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                                                <bdt className="block-component" />
                                                                                                                            </bdt>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </bdt>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        3.
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        3 &nbsp;You acknowledge that it is Your
                                                                        responsibility to confirm and determine that the
                                                                        app end-user device on which You intend to use
                                                                        the Licensed Application satisfies the technical
                                                                        specifications mentioned above.
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "16.8667px",
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <bdt
                                                                                        className="block-container if"
                                                                                        data-type="if"
                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                    >
                                                                                        <bdt data-type="conditional-block">
                                                                                            <bdt data-type="body">
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <bdt
                                                                                                        className="block-container if"
                                                                                                        data-type="if"
                                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                    >
                                                                                                        <bdt data-type="conditional-block">
                                                                                                            <bdt data-type="body">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                lineHeight:
                                                                                                                                    "16.8667px",
                                                                                                                                color:
                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    lineHeight:
                                                                                                                                        "16.8667px",
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt
                                                                                                                                    className="block-container if"
                                                                                                                                    data-type="if"
                                                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                                                >
                                                                                                                                    <bdt data-type="conditional-block">
                                                                                                                                        <bdt data-type="body">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <bdt
                                                                                                                                                    className="block-container if"
                                                                                                                                                    data-type="if"
                                                                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                                                                >
                                                                                                                                                    <bdt data-type="conditional-block">
                                                                                                                                                        <bdt data-type="body">
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                            </bdt>
                                                                                                                                                                        </bdt>
                                                                                                                                                                    </bdt>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </bdt>
                                                                                                                                                    </bdt>
                                                                                                                                                </bdt>
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                    </bdt>
                                                                                                                                </bdt>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </bdt>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        3.
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        4 &nbsp;Licensor reserves the right to modify
                                                                        the technical specifications as it sees
                                                                        appropriate at any time.
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <bdt
                                                                                    className="block-container if"
                                                                                    data-type="if"
                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                >
                                                                                    <bdt data-type="conditional-block">
                                                                                        <bdt data-type="body">
                                                                                            <span
                                                                                                style={{ color: "rgb(89, 89, 89)" }}
                                                                                            >
                                                                                                <bdt
                                                                                                    className="block-container if"
                                                                                                    data-type="if"
                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                >
                                                                                                    <bdt data-type="conditional-block">
                                                                                                        <bdt data-type="body">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        lineHeight: "16.8667px",
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            lineHeight:
                                                                                                                                "16.8667px",
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt
                                                                                                                            className="block-container if"
                                                                                                                            data-type="if"
                                                                                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                                        >
                                                                                                                            <bdt data-type="conditional-block">
                                                                                                                                <bdt data-type="body">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt
                                                                                                                                            className="block-container if"
                                                                                                                                            data-type="if"
                                                                                                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                                                        >
                                                                                                                                            <bdt data-type="conditional-block">
                                                                                                                                                <bdt data-type="body">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                lineHeight:
                                                                                                                                                                    "16.8667px",
                                                                                                                                                                color:
                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    lineHeight:
                                                                                                                                                                        "16.8667px",
                                                                                                                                                                    color:
                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <bdt
                                                                                                                                                                    className="block-container if"
                                                                                                                                                                    data-type="if"
                                                                                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                                                                                >
                                                                                                                                                                    <bdt data-type="conditional-block">
                                                                                                                                                                        <bdt data-type="body">
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    color:
                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <bdt
                                                                                                                                                                                    className="block-container if"
                                                                                                                                                                                    data-type="if"
                                                                                                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                                                                                                >
                                                                                                                                                                                    <bdt data-type="conditional-block">
                                                                                                                                                                                        <bdt data-type="body">
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                    </bdt>
                                                                                                                                                                                                </bdt>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </bdt>
                                                                                                                                                                                    </bdt>
                                                                                                                                                                                </bdt>
                                                                                                                                                                            </span>
                                                                                                                                                                        </bdt>
                                                                                                                                                                    </bdt>
                                                                                                                                                                </bdt>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </bdt>
                                                                                                                                            </bdt>
                                                                                                                                        </bdt>
                                                                                                                                    </span>
                                                                                                                                </bdt>
                                                                                                                            </bdt>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </bdt>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                            <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                            </bdt>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="nosupport"
                            style={{ lineHeight: "17.25px" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>4. NO MAINTENANCE OR SUPPORT</strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        4.1 &nbsp;
                                                                        <bdt className="question">
                                                                            Employment Law Alliance{" "}
                                                                        </bdt>{" "}
                                                                        is not obligated, expressed or implied, to
                                                                        provide any maintenance, technical or other
                                                                        support for the Licensed Application.
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ lineHeight: "16.8667px" }}>
                                <span style={{ lineHeight: "16.8667px" }}>
                                    <bdt
                                        className="block-container if"
                                        data-type="if"
                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                    >
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body">
                                                <bdt
                                                    className="block-container if"
                                                    data-type="if"
                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                >
                                                    <bdt data-type="conditional-block">
                                                        <bdt data-type="body">
                                                            <font color="#595959">
                                                                <span style={{ fontSize: 15 }}>
                                                                    4.2 &nbsp;
                                                                    <bdt className="question">
                                                                        Employment Law Alliance{" "}
                                                                    </bdt>
                                                                </span>
                                                            </font>
                                                            <font color="#595959">
                                                                <span style={{ fontSize: 15 }}>
                                                                    &nbsp;and the End-User acknowledge that the
                                                                    Services have no obligation whatsoever to furnish
                                                                    any maintenance and support services with respect
                                                                    to the Licensed Application.
                                                                    <bdt data-type="conditional-block">
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </bdt>
                                                                </span>
                                                            </font>
                                                        </bdt>
                                                    </bdt>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                            <span style={{ fontSize: 15 }}>
                                <bdt className="block-component" />
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="datause"
                            style={{ lineHeight: "17.25px" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>5. USE OF DATA</strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        You acknowledge that Licensor will be able to
                                                                        access and adjust Your downloaded Licensed
                                                                        Application content and Your personal
                                                                        information, and that Licensor's use of such
                                                                        material and information is subject to Your
                                                                        legal agreements with Licensor and Licensor's
                                                                        privacy policy
                                                                        <bdt className="block-component" />.
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                You acknowledge that the Licensor may periodically collect and use
                                technical data and related information about your device, system,
                                and application software, and peripherals, offer product support,
                                facilitate the software updates, and for purposes of providing other
                                services to you (if any) related to the Licensed Application.
                                Licensor may also use this information to improve its products or to
                                provide services or technologies to you, as long as it is in a form
                                that does not personally identify you.
                            </span>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt className="block-container if" data-type="if">
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt className="block-container if" data-type="if">
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="ugc"
                            style={{ lineHeight: "17.25px" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>6. USER-GENERATED CONTRIBUTIONS</strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="block-component" />
                                                                        The Licensed Application may invite you to chat,
                                                                        contribute to, or participate in blogs, message
                                                                        boards, online forums, and other functionality,
                                                                        and may provide you with the opportunity to
                                                                        create, submit, post, display, transmit,
                                                                        perform, publish, distribute, or broadcast
                                                                        content and materials to us or in the Licensed
                                                                        Application, including but not limited to text,
                                                                        writings, video, audio, photographs, graphics,
                                                                        comments, suggestions, or personal information
                                                                        or other material (collectively,{" "}
                                                                        <bdt className="block-component" />
                                                                        "Contributions"
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                        ). Contributions may be viewable by other users
                                                                        of the Licensed Application and through
                                                                        third-party websites or applications. As such,
                                                                        any Contributions you transmit may be treated as
                                                                        non-confidential and non-proprietary. When you
                                                                        create or make available any Contributions, you
                                                                        thereby represent and warrant that:
                                                                        <bdt className="else-block" />
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                1. The creation, distribution, transmission, public display, or
                                performance, and the accessing, downloading, or copying of your
                                Contributions do not and will not infringe the proprietary rights,
                                including but not limited to the copyright, patent, trademark, trade
                                secret, or moral rights of any third party.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                2. You are the creator and owner of or have the necessary{" "}
                                <bdt className="block-component" />
                                licenses
                                <bdt className="statement-end-if-in-editor" />, rights, consents,
                                releases, and permissions to use and to{" "}
                                <bdt className="block-component" />
                                authorize
                                <bdt className="statement-end-if-in-editor" /> us, the Licensed
                                Application, and other users of the Licensed Application to use your
                                Contributions in any manner contemplated by the Licensed Application
                                and this <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                3. You have the written consent, release, and/or permission of each
                                and every identifiable individual person in your Contributions to
                                use the name or likeness or each and every such identifiable
                                individual person to enable inclusion and use of your Contributions
                                in any manner contemplated by the Licensed Application and this{" "}
                                <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                4. Your Contributions are not false, inaccurate, or misleading.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                5. Your Contributions are not unsolicited or{" "}
                                <bdt className="block-component" />
                                unauthorized
                                <bdt className="statement-end-if-in-editor" /> advertising,
                                promotional materials, pyramid schemes, chain letters, spam, mass
                                mailings, or other forms of solicitation.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                6. Your Contributions are not obscene, lewd, lascivious, filthy,
                                violent, harassing, <bdt className="block-component" />
                                libelous
                                <bdt className="statement-end-if-in-editor" />, slanderous, or
                                otherwise objectionable (as determined by us).
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                7. Your Contributions do not ridicule, mock, disparage, intimidate,
                                or abuse anyone.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                8. Your Contributions are not used to harass or threaten (in the
                                legal sense of those terms) any other person and to promote violence
                                against a specific person or class of people.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                9. Your Contributions do not violate any applicable law, regulation,
                                or rule.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                10. Your Contributions do not violate the privacy or publicity
                                rights of any third party.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                11. Your Contributions do not violate any applicable law concerning
                                child pornography, or otherwise intended to protect the health or
                                well-being of minors.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                12. Your Contributions do not include any offensive comments that
                                are connected to race, national origin, gender, sexual preference,
                                or physical handicap.
                            </span>
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5", marginLeft: 20 }}
                        >
                            <span style={{ fontSize: 15 }}>
                                13. Your Contributions do not otherwise violate, or link to material
                                that violates, any provision of this{" "}
                                <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement, or any
                                applicable law or regulation.
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1, marginLeft: 20 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                Any use of the Licensed Application in violation of the foregoing
                                violates this <bdt className="block-component" />
                                License
                                <bdt className="statement-end-if-in-editor" /> Agreement and may
                                result in, among other things, termination or suspension of your
                                rights to use the Licensed Application.
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="contribution"
                            style={{ lineHeight: "17.25px" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>
                                                            7. CONTRIBUTION <bdt className="block-component" />
                                                            LICENSE
                                                            <bdt className="statement-end-if-in-editor" />
                                                        </strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component" />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                By posting your Contributions to any part of the Licensed
                                Application or making Contributions accessible to the Licensed
                                Application by linking your account from the Licensed Application to
                                any of your social networking accounts, you automatically grant, and
                                you represent and warrant that you have the right to grant, to us an
                                unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
                                transferable, royalty-free, fully-paid, worldwide right, and{" "}
                                <bdt className="block-component" />
                                license
                                <bdt className="statement-end-if-in-editor" /> to host, use copy,
                                reproduce, disclose, sell, resell, publish, broad cast, retitle,
                                archive, store, cache, publicly display, reformat, translate,
                                transmit, excerpt (in whole or in part), and distribute such
                                Contributions (including, without limitation, your image and voice)
                                for any purpose, commercial advertising, or otherwise, and to
                                prepare derivative works of, or incorporate in other works, such as
                                Contributions, and grant and <bdt className="block-component" />
                                authorize sublicenses
                                <bdt className="statement-end-if-in-editor" /> of the foregoing. The
                                use and distribution may occur in any media formats and through any
                                media channels.
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                This <bdt className="block-component" />
                                license
                                <bdt className="statement-end-if-in-editor" /> will apply to any
                                form, media, or technology now known or hereafter developed, and
                                includes our use of your name, company name, and franchise name, as
                                applicable, and any of the trademarks, service marks, trade names,
                                logos, and personal and commercial images you provide. You waive all
                                moral rights in your Contributions, and you warrant that moral
                                rights have not otherwise been asserted in your Contributions.
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                We do not assert any ownership over your Contributions. You retain
                                full ownership of all of your Contributions and any intellectual
                                property rights or other proprietary rights associated with your
                                Contributions. We are not liable for any statements or
                                representations in your Contributions provided by you in any area in
                                the Licensed Application. You are solely responsible for your
                                Contributions to the Licensed Application and you expressly agree to
                                exonerate us from any and all responsibility and to refrain from any
                                legal action against us regarding your Contributions.
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                We have the right, in our sole and absolute discretion, (1) to edit,
                                redact, or otherwise change any Contributions; (2) to{" "}
                                <bdt className="block-component" />
                                recategorize
                                <bdt className="statement-end-if-in-editor" /> any Contributions to
                                place them in more appropriate locations in the Licensed
                                Application; and (3) to prescreen or delete any Contributions at any
                                time and for any reason, without notice. We have no obligation to
                                monitor your Contributions.
                                <bdt className="else-block" />
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="liability"
                            style={{ lineHeight: "17.25px" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>8. LIABILITY</strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                            <span style={{ fontSize: 15 }}>
                                <bdt className="block-component" />
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="block-component" />8
                                                                        <bdt className="else-block" />
                                                                        .1 &nbsp;Licensor's responsibility in the case
                                                                        of violation of obligations and tort shall be
                                                                        limited to intent and gross negligence. Only in
                                                                        case of a breach of essential contractual duties
                                                                        (cardinal obligations), Licensor shall also be
                                                                        liable in case of slight negligence. In any
                                                                        case, liability shall be limited to the
                                                                        foreseeable, contractually typical damages. The
                                                                        limitation mentioned above does not apply to
                                                                        injuries to life, limb, or health.
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <bdt className="block-component" />
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="body_text"
                            style={{ lineHeight: "1.5" }}
                        >
                            <span style={{ fontSize: 15 }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <span
                                        style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}
                                    >
                                        <bdt
                                            className="block-container if"
                                            data-type="if"
                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                        >
                                            <bdt data-type="conditional-block">
                                                <bdt data-type="body">
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt
                                                            className="block-container if"
                                                            data-type="if"
                                                            id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                        >
                                                            <bdt data-type="conditional-block">
                                                                <bdt data-type="body">
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <bdt className="block-component" />8
                                                                        <bdt className="else-block" />
                                                                        .2 &nbsp;Licensor takes no accountability or
                                                                        responsibility for any damages caused due to a
                                                                        breach of duties according to Section 2 of this{" "}
                                                                        <bdt className="block-component" />
                                                                        License
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        Agreement. To avoid data loss, You are required
                                                                        to make use of backup functions of the Licensed
                                                                        Application to the extent allowed by applicable
                                                                        third-party terms and conditions of use. You are
                                                                        aware that in case of alterations or
                                                                        manipulations of the Licensed Application, You
                                                                        will not have access to the Licensed
                                                                        Application.
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "16.8667px",
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <bdt
                                                                                        className="block-container if"
                                                                                        data-type="if"
                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                    >
                                                                                        <bdt data-type="conditional-block">
                                                                                            <bdt data-type="body">
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <bdt
                                                                                                        className="block-container if"
                                                                                                        data-type="if"
                                                                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                    >
                                                                                                        <bdt data-type="conditional-block">
                                                                                                            <bdt data-type="body">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                                                <bdt className="block-component" />
                                                                                                                            </bdt>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </bdt>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </bdt>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </bdt>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </bdt>
                                        </bdt>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div
                            className="MsoNormal"
                            data-custom-class="heading_1"
                            id="warranty"
                            style={{ lineHeight: "17.25px" }}
                        >
                            <strong>
                                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>9. WARRANTY</strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </strong>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                >
                    <span style={{ fontSize: 15 }}>
                        <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                            <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                <bdt className="block-component" />9<bdt className="else-block" />
                                .1 &nbsp;Licensor warrants that the Licensed Application is free of
                                spyware, trojan horses, viruses, or any other malware at the time of
                                Your download. Licensor warrants that the Licensed Application works
                                as described in the user documentation.
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: 1 }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                >
                    <span style={{ fontSize: 15 }}>
                        <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                            <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                <bdt
                                    className="block-container if"
                                    data-type="if"
                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                >
                                    <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt
                                                    className="block-container if"
                                                    data-type="if"
                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                >
                                                    <bdt data-type="conditional-block">
                                                        <bdt data-type="body">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <bdt className="block-component" />9
                                                                <bdt className="else-block" />
                                                                .2 &nbsp;No warranty is provided for the Licensed
                                                                Application that is not executable on the device,
                                                                that has been <bdt className="block-component" />
                                                                unauthorizedly
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                modified, handled inappropriately or culpably,
                                                                combined or installed with inappropriate hardware or
                                                                software, used with inappropriate accessories,
                                                                regardless if by Yourself or by third parties, or if
                                                                there are any other reasons outside of{" "}
                                                                <bdt className="question">
                                                                    Employment Law Alliance{" "}
                                                                </bdt>
                                                                's sphere of influence that affect the executability
                                                                of the Licensed Application.
                                                            </span>
                                                        </bdt>
                                                    </bdt>
                                                </bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: 1 }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                >
                    <span style={{ fontSize: 15 }}>
                        <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                            <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                <bdt
                                    className="block-container if"
                                    data-type="if"
                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                >
                                    <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt
                                                    className="block-container if"
                                                    data-type="if"
                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                >
                                                    <bdt data-type="conditional-block">
                                                        <bdt data-type="body">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <bdt className="block-component" />9
                                                                <bdt className="else-block" />
                                                                .3 &nbsp;You are required to inspect the Licensed
                                                                Application immediately after installing it and
                                                                notify{" "}
                                                                <bdt className="question">
                                                                    Employment Law Alliance{" "}
                                                                </bdt>{" "}
                                                                about issues discovered without delay by email
                                                                provided in
                                                            </span>
                                                        </bdt>
                                                    </bdt>
                                                </bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </span>
                        </span>
                        &nbsp;
                    </span>
                    <a data-custom-class="link" href="#contact">
                        <span style={{ fontSize: 15 }}>
                            <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                    <bdt
                                        className="block-container if"
                                        data-type="if"
                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                    >
                                        <bdt data-type="conditional-block">
                                            <bdt data-type="body">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <bdt
                                                        className="block-container if"
                                                        data-type="if"
                                                        id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                    >
                                                        <bdt data-type="conditional-block">
                                                            <bdt data-type="body">
                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                    Contact Information
                                                                </span>
                                                            </bdt>
                                                        </bdt>
                                                    </bdt>
                                                </span>
                                            </bdt>
                                        </bdt>
                                    </bdt>
                                </span>
                            </span>
                        </span>
                    </a>
                    <span style={{ fontSize: 15 }}>
                        <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                            <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                <bdt
                                    className="block-container if"
                                    data-type="if"
                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                >
                                    <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt
                                                    className="block-container if"
                                                    data-type="if"
                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                >
                                                    <bdt data-type="conditional-block">
                                                        <bdt data-type="body">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                . The defect report will be taken into consideration
                                                                and further investigated if it has been emailed
                                                                within a period of{" "}
                                                                <bdt className="question">ten (10)</bdt> days after
                                                                discovery.
                                                            </span>
                                                        </bdt>
                                                    </bdt>
                                                </bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: 1 }}>
                    <br />
                </div>
                <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                >
                    <span style={{ fontSize: 15 }}>
                        <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                            <span style={{ lineHeight: "16.8667px", color: "rgb(89, 89, 89)" }}>
                                <bdt
                                    className="block-container if"
                                    data-type="if"
                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                >
                                    <bdt data-type="conditional-block">
                                        <bdt data-type="body">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt
                                                    className="block-container if"
                                                    data-type="if"
                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                >
                                                    <bdt data-type="conditional-block">
                                                        <bdt data-type="body">
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <bdt className="block-component" />9
                                                                <bdt className="else-block" />
                                                                .4 &nbsp;If we confirm that the Licensed Application
                                                                is defective,{" "}
                                                                <bdt className="question">
                                                                    Employment Law Alliance{" "}
                                                                </bdt>{" "}
                                                                reserves a choice to remedy the situation either by
                                                                means of solving the defect or substitute delivery.
                                                            </span>
                                                        </bdt>
                                                    </bdt>
                                                </bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </bdt>
                            </span>
                        </span>
                    </span>
                </div>
                <div style={{ lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="36ce5a69-4560-4947-dc72-46e53e2d562a"
                    >
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "11.0pt",
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    color: "#595959",
                                                    msoThemecolor: "text1",
                                                    msoThemetint: 166
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "11pt",
                                                        lineHeight: "16.8667px",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span style={{ fontSize: 15 }}>
                                                        <span
                                                            style={{
                                                                lineHeight: "16.8667px",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    lineHeight: "16.8667px",
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                                <bdt className="block-component" />9
                                                                <bdt className="else-block" />
                                                                .5&nbsp;
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            &nbsp;
                                        </span>
                                        In the event of any failure of the Licensed Application to
                                        conform to any applicable warranty, You may notify the Services
                                        Store Operator, and Your Licensed Application purchase price
                                        will be refunded to You. To the maximum extent permitted by
                                        applicable law, the Services Store Operator will have no other
                                        warranty obligation whatsoever with respect to the Licensed
                                        Application, and any other losses, claims, damages, liabilities,
                                        expenses, and costs attributable to any negligence to adhere to
                                        any warranty.
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="14038561-dad7-be9d-370f-f8aa487b2570"
                    >
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "11.0pt",
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    color: "#595959",
                                                    msoThemecolor: "text1",
                                                    msoThemetint: 166
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "11pt",
                                                        lineHeight: "16.8667px",
                                                        color: "rgb(89, 89, 89)"
                                                    }}
                                                >
                                                    <span style={{ fontSize: 15 }}>
                                                        <span
                                                            style={{
                                                                lineHeight: "16.8667px",
                                                                color: "rgb(89, 89, 89)"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    lineHeight: "16.8667px",
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                                <bdt className="block-component" />9
                                                                <bdt className="else-block" />
                                                                .6
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>{" "}
                                            &nbsp;
                                        </span>
                                        If the user is an entrepreneur, any claim based on faults
                                        expires after a statutory period of limitation amounting to
                                        twelve (12) months after the Licensed Application was made
                                        available to the user. The statutory periods of limitation given
                                        by law apply for users who are consumers.
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="14038561-dad7-be9d-370f-f8aa487b2570"
                    >
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">&nbsp;</bdt>&nbsp;
                        </bdt>
                        &nbsp;
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="productclaims"
                        style={{ lineHeight: "115%" }}
                    >
                        <a name="_wj13r09u8u3u" />
                        <strong>
                            <span
                                id="productclaims"
                                style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                            >
                                <strong>
                                    <span
                                        style={{
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            fontSize: 19
                                        }}
                                    >
                                        <strong>
                                            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                                <strong>
                                                    <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                                                        <strong>
                                                            <span
                                                                style={{ lineHeight: "24.5333px", fontSize: 19 }}
                                                            >
                                                                <strong>
                                                                    <span
                                                                        style={{
                                                                            lineHeight: "115%",
                                                                            fontFamily: "Arial",
                                                                            fontSize: 19
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "115%",
                                                                                    fontFamily: "Arial",
                                                                                    fontSize: 19
                                                                                }}
                                                                            >
                                                                                <span style={{ fontSize: 19 }}>
                                                                                    <strong>10. PRODUCT CLAIMS</strong>
                                                                                </span>
                                                                            </span>
                                                                        </strong>
                                                                    </span>
                                                                </strong>
                                                            </span>
                                                        </strong>
                                                    </span>
                                                </strong>
                                            </span>
                                        </strong>
                                    </span>
                                </strong>
                            </span>
                        </strong>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <bdt className="question">
                            <span style={{ fontSize: 15 }}>Employment Law Alliance </span>
                        </bdt>
                        <span style={{ fontSize: 15 }}>&nbsp;</span>
                        <span
                            style={{
                                fontSize: 15,
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "rgb(89, 89, 89)"
                            }}
                        >
                            and the End-User acknow
                        </span>
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            ledge that <bdt className="question">Employment Law Alliance </bdt>,
                            and not the Services, is responsible for addressing any claims of the
                            End-User or any third party relating to the Licensed Application or
                            the End-User’s possession and/or use of that Licensed Application,
                            including, but not limited to:
                        </span>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5", marginLeft: 20 }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        (i) product liability claims;
                                    </span>
                                </div>
                                &nbsp;
                            </bdt>
                            &nbsp;
                        </bdt>
                        &nbsp;
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5", marginLeft: 20 }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        (ii) any claim that the Licensed Application fails to conform to
                                        any applicable legal or regulatory requirement; and
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5", marginLeft: 20 }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        (iii) claims arising under consumer protection, privacy, or
                                        similar legislation
                                        <bdt className="block-component" />
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            , including in connection with Your Licensed Application’s use
                                            of the HealthKit and HomeKit
                                        </span>
                                        <bdt className="statement-end-if-in-editor" />.
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="87a7471d-cf82-1032-fdf8-601d37d7b017"
                    >
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="heading_1"
                                    id="compliance"
                                    style={{ lineHeight: "17.25px" }}
                                >
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "24.5333px",
                                                color: "black",
                                                backgroundImage: "initial",
                                                backgroundPosition: "initial",
                                                backgroundSize: "initial",
                                                backgroundRepeat: "initial",
                                                backgroundAttachment: "initial",
                                                backgroundOrigin: "initial",
                                                backgroundClip: "initial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <strong>
                                                        <span
                                                            style={{
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                fontSize: 19
                                                            }}
                                                        >
                                                            <strong>
                                                                <span
                                                                    style={{ lineHeight: "24.5333px", fontSize: 19 }}
                                                                >
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "24.5333px",
                                                                                fontSize: 19
                                                                            }}
                                                                        >
                                                                            <strong>
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "24.5333px",
                                                                                        fontSize: 19
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        <span
                                                                                            style={{
                                                                                                lineHeight: "115%",
                                                                                                fontFamily: "Arial",
                                                                                                fontSize: 19
                                                                                            }}
                                                                                        >
                                                                                            <strong>
                                                                                                <span
                                                                                                    style={{
                                                                                                        lineHeight: "115%",
                                                                                                        fontFamily: "Arial",
                                                                                                        fontSize: 19
                                                                                                    }}
                                                                                                >
                                                                                                    <span style={{ fontSize: 19 }}>
                                                                                                        <strong>
                                                                                                            11. LEGAL COMPLIANCE
                                                                                                        </strong>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </strong>
                                                                                        </span>
                                                                                    </strong>
                                                                                </span>
                                                                            </strong>
                                                                        </span>
                                                                    </strong>
                                                                </span>
                                                            </strong>
                                                        </span>
                                                    </strong>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ fontSize: "14.6667px", lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: 15,
                                            lineHeight: "16.8667px",
                                            color: "rgb(89, 89, 89)"
                                        }}
                                    >
                                        You represent and warrant that You are not located in a country
                                        that is subject to a US Government embargo, or that has been
                                        designated by the US Government as a{" "}
                                        <bdt className="block-component" />
                                        "terrorist supporting"
                                        <bdt className="statement-end-if-in-editor" /> country; and that
                                        You are not listed on any US Government list of prohibited or
                                        restricted parties.
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="923fc4bc-b171-82ba-b6eb-0a13c12d1b6b"
                    >
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="heading_1"
                                    id="contact"
                                    style={{ lineHeight: "115%" }}
                                >
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "24.5333px",
                                                        color: "black",
                                                        backgroundImage: "initial",
                                                        backgroundPosition: "initial",
                                                        backgroundSize: "initial",
                                                        backgroundRepeat: "initial",
                                                        backgroundAttachment: "initial",
                                                        backgroundOrigin: "initial",
                                                        backgroundClip: "initial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <strong>
                                                        <span
                                                            style={{
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                fontSize: 19
                                                            }}
                                                        >
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        lineHeight: "115%",
                                                                        fontFamily: "Arial",
                                                                        fontSize: 19
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "24.5333px",
                                                                                fontSize: 19
                                                                            }}
                                                                        >
                                                                            <strong>
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "24.5333px",
                                                                                        fontSize: 19
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        <span
                                                                                            style={{
                                                                                                lineHeight: "24.5333px",
                                                                                                fontSize: 19
                                                                                            }}
                                                                                        >
                                                                                            <strong>
                                                                                                <span
                                                                                                    style={{
                                                                                                        lineHeight: "115%",
                                                                                                        fontFamily: "Arial",
                                                                                                        fontSize: 19
                                                                                                    }}
                                                                                                >
                                                                                                    <strong>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                lineHeight: "115%",
                                                                                                                fontFamily: "Arial",
                                                                                                                fontSize: 19
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{ fontSize: 19 }}
                                                                                                            >
                                                                                                                <strong>
                                                                                                                    12. CONTACT INFORMATION
                                                                                                                </strong>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </strong>
                                                                                        </span>
                                                                                    </strong>
                                                                                </span>
                                                                            </strong>
                                                                        </span>
                                                                    </strong>
                                                                </span>
                                                            </strong>
                                                        </span>
                                                    </strong>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        For general inquiries, complaints, questions or claims
                                        concerning the Licensed Application, please contact:
                                    </span>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <bdt className="question">Peter Walts</bdt>
                                        <bdt className="block-component" />
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt
                        className="block-container if"
                        data-type="if"
                        id="923fc4bc-b171-82ba-b6eb-0a13c12d1b6b"
                    >
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <bdt className="question">181 Lookout Lane</bdt>
                                        <bdt className="block-component" />
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <bdt className="question">Willow Grove</bdt>,{" "}
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <bdt className="block-component" />
                                            <bdt className="block-component" />
                                            <bdt className="question">PA</bdt>
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />{" "}
                                            <bdt className="question">19090</bdt>
                                        </span>
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span style={{ fontSize: 15 }}>
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="question">United States</bdt>
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                                <bdt className="statement-end-if-in-editor" />
                                            </span>
                                        </bdt>
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        <bdt className="question">peter@ela.law</bdt>
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="heading_1"
                                    id="termination"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <strong>
                                                        <span
                                                            style={{
                                                                lineHeight: "24.5333px",
                                                                color: "black",
                                                                backgroundImage: "initial",
                                                                backgroundPosition: "initial",
                                                                backgroundSize: "initial",
                                                                backgroundRepeat: "initial",
                                                                backgroundAttachment: "initial",
                                                                backgroundOrigin: "initial",
                                                                backgroundClip: "initial",
                                                                fontSize: 19
                                                            }}
                                                        >
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        lineHeight: "115%",
                                                                        fontFamily: "Arial",
                                                                        fontSize: 19
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "115%",
                                                                                fontFamily: "Arial",
                                                                                fontSize: 19
                                                                            }}
                                                                        >
                                                                            <strong>
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "24.5333px",
                                                                                        fontSize: 19
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        <span
                                                                                            style={{
                                                                                                lineHeight: "24.5333px",
                                                                                                fontSize: 19
                                                                                            }}
                                                                                        >
                                                                                            <strong>
                                                                                                <span
                                                                                                    style={{
                                                                                                        lineHeight: "24.5333px",
                                                                                                        fontSize: 19
                                                                                                    }}
                                                                                                >
                                                                                                    <strong>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                lineHeight: "115%",
                                                                                                                fontFamily: "Arial",
                                                                                                                fontSize: 19
                                                                                                            }}
                                                                                                        >
                                                                                                            <strong>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        lineHeight: "115%",
                                                                                                                        fontFamily: "Arial",
                                                                                                                        fontSize: 19
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 19 }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            13. TERMINATION
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </strong>
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </strong>
                                                                                        </span>
                                                                                    </strong>
                                                                                </span>
                                                                            </strong>
                                                                        </span>
                                                                    </strong>
                                                                </span>
                                                            </strong>
                                                        </span>
                                                    </strong>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <bdt className="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <div
                                    className="MsoNormal"
                                    data-custom-class="body_text"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <span
                                        style={{
                                            fontSize: "11.0pt",
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            color: "#595959",
                                            msoThemecolor: "text1",
                                            msoThemetint: 166
                                        }}
                                    >
                                        The <bdt className="block-component" />
                                        license
                                        <bdt className="statement-end-if-in-editor" /> is valid until
                                        terminated by{" "}
                                        <bdt className="question">Employment Law Alliance </bdt> or by
                                        You. Your rights under this <bdt className="block-component" />
                                        license
                                        <bdt className="statement-end-if-in-editor" /> will terminate
                                        automatically and without notice from{" "}
                                        <bdt className="question">Employment Law Alliance </bdt> if You
                                        fail to adhere to any term(s) of this{" "}
                                        <bdt className="block-component" />
                                        license
                                        <bdt className="statement-end-if-in-editor" />. Upon{" "}
                                        <bdt className="block-component" />
                                        License
                                        <bdt className="statement-end-if-in-editor" /> termination, You
                                        shall stop all use of the Licensed Application, and destroy all
                                        copies, full or partial, of the Licensed Application.
                                    </span>
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <bdt className="block-container if" data-type="if">
                        &nbsp;{" "}
                        <bdt data-type="conditional-block">
                            &nbsp;{" "}
                            <bdt data-type="body">
                                &nbsp;&nbsp;
                                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                            </bdt>
                        </bdt>
                    </bdt>
                </div>
                <div style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="thirdparty"
                        style={{ lineHeight: "1.5" }}
                    >
                        <a name="_k3mndam4w6w1" />
                        <strong>
                            <span
                                style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                            >
                                <strong>
                                    <span
                                        style={{
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            fontSize: 19
                                        }}
                                    >
                                        <strong>
                                            <span
                                                style={{
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    fontSize: 19
                                                }}
                                            >
                                                <strong>
                                                    <span
                                                        style={{
                                                            lineHeight: "24.5333px",
                                                            color: "black",
                                                            backgroundImage: "initial",
                                                            backgroundPosition: "initial",
                                                            backgroundSize: "initial",
                                                            backgroundRepeat: "initial",
                                                            backgroundAttachment: "initial",
                                                            backgroundOrigin: "initial",
                                                            backgroundClip: "initial",
                                                            fontSize: 19
                                                        }}
                                                    >
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight: "115%",
                                                                    fontFamily: "Arial",
                                                                    fontSize: 19
                                                                }}
                                                            >
                                                                <strong>
                                                                    <span
                                                                        style={{
                                                                            lineHeight: "115%",
                                                                            fontFamily: "Arial",
                                                                            fontSize: 19
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "24.5333px",
                                                                                    fontSize: 19
                                                                                }}
                                                                            >
                                                                                <strong>
                                                                                    <span
                                                                                        style={{
                                                                                            lineHeight: "24.5333px",
                                                                                            fontSize: 19
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span
                                                                                                style={{
                                                                                                    lineHeight: "24.5333px",
                                                                                                    fontSize: 19
                                                                                                }}
                                                                                            >
                                                                                                <strong>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            lineHeight: "115%",
                                                                                                            fontFamily: "Arial",
                                                                                                            fontSize: 19
                                                                                                        }}
                                                                                                    >
                                                                                                        <strong>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    lineHeight: "115%",
                                                                                                                    fontFamily: "Arial",
                                                                                                                    fontSize: 19
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{ fontSize: 19 }}
                                                                                                                >
                                                                                                                    <strong>
                                                                                                                        14. THIRD-PARTY TERMS OF
                                                                                                                        AGREEMENTS AND
                                                                                                                        BENEFICIARY
                                                                                                                    </strong>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </strong>
                                                                                                    </span>
                                                                                                </strong>
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </strong>
                                                                            </span>
                                                                        </strong>
                                                                    </span>
                                                                </strong>
                                                            </span>
                                                        </strong>
                                                    </span>
                                                </strong>
                                            </span>
                                        </strong>
                                    </span>
                                </strong>
                            </span>
                        </strong>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: 15,
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "rgb(89, 89, 89)"
                            }}
                        >
                            <bdt className="question">Employment Law Alliance </bdt> represents
                            and warrants that{" "}
                            <bdt className="question">Employment Law Alliance </bdt> will comply
                            with applicable third-party terms of agreement when using Licensed
                            Application.
                        </span>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            In Accordance with Section 9 of the{" "}
                            <bdt className="block-component" />
                            "Instructions for Minimum Terms of Developer's End-User License
                            Agreement," <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" />
                            both Apple and Google and their
                            <bdt className="block-component" /> subsidiaries shall be third-party
                            beneficiaries of this End User <bdt className="block-component" />
                            License
                            <bdt className="statement-end-if-in-editor" /> Agreement and — upon
                            Your acceptance of the terms and conditions of this{" "}
                            <bdt className="block-component" />
                            License
                            <bdt className="statement-end-if-in-editor" /> Agreement,{" "}
                            <bdt className="block-component" />
                            both Apple and Google
                            <bdt className="block-component" /> will have the right (and will be
                            deemed to have accepted the right) to enforce this End User{" "}
                            <bdt className="block-component" />
                            License
                            <bdt className="statement-end-if-in-editor" /> Agreement against You
                            as a third-party beneficiary thereof.
                        </span>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left" }}>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="ipr"
                        style={{ lineHeight: "1.5" }}
                    >
                        <a name="_e2dep1hfgltt" />
                        <strong>
                            <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                                <span style={{ fontSize: 19 }}>
                                    <strong>
                                        <span
                                            style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                            }}
                                        >
                                            <strong>
                                                <span
                                                    style={{
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        fontSize: 19
                                                    }}
                                                >
                                                    <strong>
                                                        <span
                                                            style={{
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                fontSize: 19
                                                            }}
                                                        >
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        lineHeight: "24.5333px",
                                                                        color: "black",
                                                                        backgroundImage: "initial",
                                                                        backgroundPosition: "initial",
                                                                        backgroundSize: "initial",
                                                                        backgroundRepeat: "initial",
                                                                        backgroundAttachment: "initial",
                                                                        backgroundOrigin: "initial",
                                                                        backgroundClip: "initial",
                                                                        fontSize: 19
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "115%",
                                                                                fontFamily: "Arial",
                                                                                fontSize: 19
                                                                            }}
                                                                        >
                                                                            <strong>
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: "115%",
                                                                                        fontFamily: "Arial",
                                                                                        fontSize: 19
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        <span
                                                                                            style={{
                                                                                                lineHeight: "24.5333px",
                                                                                                fontSize: 19
                                                                                            }}
                                                                                        >
                                                                                            <strong>
                                                                                                <span
                                                                                                    style={{
                                                                                                        lineHeight: "24.5333px",
                                                                                                        fontSize: 19
                                                                                                    }}
                                                                                                >
                                                                                                    <strong>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                lineHeight: "24.5333px",
                                                                                                                fontSize: 19
                                                                                                            }}
                                                                                                        >
                                                                                                            <strong>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        lineHeight: "115%",
                                                                                                                        fontFamily: "Arial",
                                                                                                                        fontSize: 19
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <strong>
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                lineHeight: "115%",
                                                                                                                                fontFamily: "Arial",
                                                                                                                                fontSize: 19
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 19
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <strong>
                                                                                                                                    15. INTELLECTUAL
                                                                                                                                    PROPERTY RIGHTS
                                                                                                                                </strong>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </strong>
                                                                                                                </span>
                                                                                                            </strong>
                                                                                                        </span>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </strong>
                                                                                        </span>
                                                                                    </strong>
                                                                                </span>
                                                                            </strong>
                                                                        </span>
                                                                    </strong>
                                                                </span>
                                                            </strong>
                                                        </span>
                                                    </strong>
                                                </span>
                                            </strong>
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </strong>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            <bdt className="question">Employment Law Alliance </bdt> and the
                            End-User acknowledge that, in the event of any third-party claim that
                            the Licensed Application or the End-User's possession and use of that
                            Licensed Application infringes on the third party's intellectual
                            property rights,{" "}
                            <bdt className="question">Employment Law Alliance </bdt>, and not the
                            Services, will be solely responsible for the investigation,{" "}
                            <bdt className="block-component" />
                            defense
                            <bdt className="statement-end-if-in-editor" />, settlement, and
                            discharge or any such intellectual property infringement claims.
                        </span>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left" }}>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="law"
                        style={{ lineHeight: "1.5" }}
                    >
                        <a name="_p6vbf8atcwhs" />
                        <strong>
                            <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                                <span style={{ fontSize: 19 }}>
                                    <strong>
                                        <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                                            <span style={{ fontSize: 19 }}>
                                                <strong>
                                                    <span
                                                        style={{
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            fontSize: 19
                                                        }}
                                                    >
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight: "115%",
                                                                    fontFamily: "Arial",
                                                                    fontSize: 19
                                                                }}
                                                            >
                                                                <strong>
                                                                    <span
                                                                        style={{
                                                                            lineHeight: "115%",
                                                                            fontFamily: "Arial",
                                                                            fontSize: 19
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "24.5333px",
                                                                                    color: "black",
                                                                                    backgroundImage: "initial",
                                                                                    backgroundPosition: "initial",
                                                                                    backgroundSize: "initial",
                                                                                    backgroundRepeat: "initial",
                                                                                    backgroundAttachment: "initial",
                                                                                    backgroundOrigin: "initial",
                                                                                    backgroundClip: "initial",
                                                                                    fontSize: 19
                                                                                }}
                                                                            >
                                                                                <strong>
                                                                                    <span
                                                                                        style={{
                                                                                            lineHeight: "115%",
                                                                                            fontFamily: "Arial",
                                                                                            fontSize: 19
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span
                                                                                                style={{
                                                                                                    lineHeight: "115%",
                                                                                                    fontFamily: "Arial",
                                                                                                    fontSize: 19
                                                                                                }}
                                                                                            >
                                                                                                <strong>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            lineHeight: "24.5333px",
                                                                                                            fontSize: 19
                                                                                                        }}
                                                                                                    >
                                                                                                        <strong>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    lineHeight: "24.5333px",
                                                                                                                    fontSize: 19
                                                                                                                }}
                                                                                                            >
                                                                                                                <strong>
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            lineHeight:
                                                                                                                                "24.5333px",
                                                                                                                            fontSize: 19
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    lineHeight:
                                                                                                                                        "115%",
                                                                                                                                    fontFamily:
                                                                                                                                        "Arial",
                                                                                                                                    fontSize: 19
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <strong>
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "115%",
                                                                                                                                            fontFamily:
                                                                                                                                                "Arial",
                                                                                                                                            fontSize: 19
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 19
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <strong>
                                                                                                                                                16.
                                                                                                                                                APPLICABLE
                                                                                                                                                LAW
                                                                                                                                            </strong>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </strong>
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </strong>
                                                                                                            </span>
                                                                                                        </strong>
                                                                                                    </span>
                                                                                                </strong>
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </strong>
                                                                            </span>
                                                                        </strong>
                                                                    </span>
                                                                </strong>
                                                            </span>
                                                        </strong>
                                                    </span>
                                                </strong>
                                            </span>
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </strong>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: 1 }}>
                    <br />
                </div>
                <div style={{ textAlign: "left" }}>
                    <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                    >
                        <span
                            style={{
                                fontSize: "11.0pt",
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                color: "#595959",
                                msoThemecolor: "text1",
                                msoThemetint: 166
                            }}
                        >
                            This <bdt className="block-component" />
                            License
                            <bdt className="statement-end-if-in-editor" /> Agreement is governed
                            by the laws of <bdt className="block-component" />
                            <bdt className="block-component" />
                            the State of <bdt className="question">Nevada</bdt>
                            <bdt className="statement-end-if-in-editor" />
                            <bdt className="block-component" /> excluding its conflicts of law
                            rules.
                        </span>
                    </div>
                </div>
                <div style={{ textAlign: "left", lineHeight: "1.5" }}>
                    <br />
                </div>
                <div style={{ textAlign: "left" }}>
                    <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="misc"
                        style={{ lineHeight: "1.5" }}
                    >
                        <a name="_v5i5tjw62cyw" />
                        <strong>
                            <span
                                style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
                            >
                                <strong>
                                    <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                                        <span style={{ fontSize: 19 }}>
                                            <strong>
                                                <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                                                    <span style={{ fontSize: 19 }}>
                                                        <strong>
                                                            <span
                                                                style={{
                                                                    lineHeight: "115%",
                                                                    fontFamily: "Arial",
                                                                    fontSize: 19
                                                                }}
                                                            >
                                                                <strong>
                                                                    <span
                                                                        style={{
                                                                            lineHeight: "115%",
                                                                            fontFamily: "Arial",
                                                                            fontSize: 19
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "115%",
                                                                                    fontFamily: "Arial",
                                                                                    fontSize: 19
                                                                                }}
                                                                            >
                                                                                <strong>
                                                                                    <span
                                                                                        style={{
                                                                                            lineHeight: "24.5333px",
                                                                                            color: "black",
                                                                                            backgroundImage: "initial",
                                                                                            backgroundPosition: "initial",
                                                                                            backgroundSize: "initial",
                                                                                            backgroundRepeat: "initial",
                                                                                            backgroundAttachment: "initial",
                                                                                            backgroundOrigin: "initial",
                                                                                            backgroundClip: "initial",
                                                                                            fontSize: 19
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            <span
                                                                                                style={{
                                                                                                    lineHeight: "115%",
                                                                                                    fontFamily: "Arial",
                                                                                                    fontSize: 19
                                                                                                }}
                                                                                            >
                                                                                                <strong>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            lineHeight: "115%",
                                                                                                            fontFamily: "Arial",
                                                                                                            fontSize: 19
                                                                                                        }}
                                                                                                    >
                                                                                                        <strong>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    lineHeight: "24.5333px",
                                                                                                                    fontSize: 19
                                                                                                                }}
                                                                                                            >
                                                                                                                <strong>
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            lineHeight:
                                                                                                                                "24.5333px",
                                                                                                                            fontSize: 19
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    lineHeight:
                                                                                                                                        "24.5333px",
                                                                                                                                    fontSize: 19
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <strong>
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "115%",
                                                                                                                                            fontFamily:
                                                                                                                                                "Arial",
                                                                                                                                            fontSize: 19
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <strong>
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    lineHeight:
                                                                                                                                                        "115%",
                                                                                                                                                    fontFamily:
                                                                                                                                                        "Arial",
                                                                                                                                                    fontSize: 19
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 19
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <strong>
                                                                                                                                                        17.
                                                                                                                                                        MISCELLANEOUS
                                                                                                                                                    </strong>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </strong>
                                                                                                                                    </span>
                                                                                                                                </strong>
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </strong>
                                                                                                            </span>
                                                                                                        </strong>
                                                                                                    </span>
                                                                                                </strong>
                                                                                            </span>
                                                                                        </strong>
                                                                                    </span>
                                                                                </strong>
                                                                            </span>
                                                                        </strong>
                                                                    </span>
                                                                </strong>
                                                            </span>
                                                        </strong>
                                                    </span>
                                                </span>
                                            </strong>
                                        </span>
                                    </span>
                                </strong>
                            </span>
                        </strong>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <bdt
                            className="block-container if"
                            data-type="if"
                            id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                        >
                            <bdt data-type="conditional-block">
                                <bdt data-type="body">
                                    <div
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11.0pt",
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                color: "#595959",
                                                msoThemecolor: "text1",
                                                msoThemetint: 166
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "11.0pt",
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    color: "#595959",
                                                    msoThemecolor: "text1",
                                                    msoThemetint: 166
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "11.0pt",
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        color: "#595959",
                                                        msoThemecolor: "text1",
                                                        msoThemetint: 166
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "11.0pt",
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            color: "#595959",
                                                            msoThemecolor: "text1",
                                                            msoThemetint: 166
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "11.0pt",
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                color: "#595959",
                                                                msoThemecolor: "text1",
                                                                msoThemetint: 166
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize: "11pt",
                                                                    lineHeight: "16.8667px",
                                                                    color: "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span
                                                                        style={{
                                                                            lineHeight: "16.8667px",
                                                                            color: "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: "11pt",
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span
                                                                                        style={{
                                                                                            lineHeight: "16.8667px",
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                lineHeight: "16.8667px",
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <bdt
                                                                                                className="block-container if"
                                                                                                data-type="if"
                                                                                                id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                            >
                                                                                                <bdt data-type="conditional-block">
                                                                                                    <bdt data-type="body">
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <bdt
                                                                                                                className="block-container if"
                                                                                                                data-type="if"
                                                                                                                id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                            >
                                                                                                                <bdt data-type="conditional-block">
                                                                                                                    <bdt data-type="body">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color:
                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: "11pt",
                                                                                                                                    lineHeight:
                                                                                                                                        "16.8667px",
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "16.8667px",
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    "16.8667px",
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize:
                                                                                                                                                        "11.0pt",
                                                                                                                                                    lineHeight:
                                                                                                                                                        "115%",
                                                                                                                                                    fontFamily:
                                                                                                                                                        "Arial",
                                                                                                                                                    color:
                                                                                                                                                        "#595959",
                                                                                                                                                    msoThemecolor:
                                                                                                                                                        "text1",
                                                                                                                                                    msoThemetint: 166
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize:
                                                                                                                                                            "11.0pt",
                                                                                                                                                        lineHeight:
                                                                                                                                                            "115%",
                                                                                                                                                        fontFamily:
                                                                                                                                                            "Arial",
                                                                                                                                                        color:
                                                                                                                                                            "#595959",
                                                                                                                                                        msoThemecolor:
                                                                                                                                                            "text1",
                                                                                                                                                        msoThemetint: 166
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize:
                                                                                                                                                                "11.0pt",
                                                                                                                                                            lineHeight:
                                                                                                                                                                "115%",
                                                                                                                                                            fontFamily:
                                                                                                                                                                "Arial",
                                                                                                                                                            color:
                                                                                                                                                                "#595959",
                                                                                                                                                            msoThemecolor:
                                                                                                                                                                "text1",
                                                                                                                                                            msoThemetint: 166
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                fontSize:
                                                                                                                                                                    "11.0pt",
                                                                                                                                                                lineHeight:
                                                                                                                                                                    "115%",
                                                                                                                                                                fontFamily:
                                                                                                                                                                    "Arial",
                                                                                                                                                                color:
                                                                                                                                                                    "#595959",
                                                                                                                                                                msoThemecolor:
                                                                                                                                                                    "text1",
                                                                                                                                                                msoThemetint: 166
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    fontSize:
                                                                                                                                                                        "11.0pt",
                                                                                                                                                                    lineHeight:
                                                                                                                                                                        "115%",
                                                                                                                                                                    fontFamily:
                                                                                                                                                                        "Arial",
                                                                                                                                                                    color:
                                                                                                                                                                        "#595959",
                                                                                                                                                                    msoThemecolor:
                                                                                                                                                                        "text1",
                                                                                                                                                                    msoThemetint: 166
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span
                                                                                                                                                                    style={{
                                                                                                                                                                        fontSize:
                                                                                                                                                                            "11pt",
                                                                                                                                                                        lineHeight:
                                                                                                                                                                            "16.8667px",
                                                                                                                                                                        color:
                                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                                    }}
                                                                                                                                                                >
                                                                                                                                                                    <span
                                                                                                                                                                        style={{
                                                                                                                                                                            fontSize: 15
                                                                                                                                                                        }}
                                                                                                                                                                    >
                                                                                                                                                                        <span
                                                                                                                                                                            style={{
                                                                                                                                                                                lineHeight:
                                                                                                                                                                                    "16.8667px",
                                                                                                                                                                                color:
                                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                                            }}
                                                                                                                                                                        >
                                                                                                                                                                            <span
                                                                                                                                                                                style={{
                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                        "16.8667px",
                                                                                                                                                                                    color:
                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                <bdt className="block-component" />
                                                                                                                                                                                17
                                                                                                                                                                                <bdt className="else-block" />
                                                                                                                                                                                <span
                                                                                                                                                                                    style={{
                                                                                                                                                                                        fontSize:
                                                                                                                                                                                            "11pt",
                                                                                                                                                                                        lineHeight:
                                                                                                                                                                                            "16.8667px",
                                                                                                                                                                                        color:
                                                                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                                                                    }}
                                                                                                                                                                                >
                                                                                                                                                                                    <span
                                                                                                                                                                                        style={{
                                                                                                                                                                                            fontSize: 15
                                                                                                                                                                                        }}
                                                                                                                                                                                    >
                                                                                                                                                                                        <span
                                                                                                                                                                                            style={{
                                                                                                                                                                                                lineHeight:
                                                                                                                                                                                                    "16.8667px",
                                                                                                                                                                                                color:
                                                                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                                                                            }}
                                                                                                                                                                                        >
                                                                                                                                                                                            <span
                                                                                                                                                                                                style={{
                                                                                                                                                                                                    lineHeight:
                                                                                                                                                                                                        "16.8667px",
                                                                                                                                                                                                    color:
                                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                                }}
                                                                                                                                                                                            >
                                                                                                                                                                                                <bdt
                                                                                                                                                                                                    className="block-container if"
                                                                                                                                                                                                    data-type="if"
                                                                                                                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                                                                                                                >
                                                                                                                                                                                                    <bdt data-type="conditional-block">
                                                                                                                                                                                                        <bdt data-type="body">
                                                                                                                                                                                                            <span
                                                                                                                                                                                                                style={{
                                                                                                                                                                                                                    color:
                                                                                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                                                                                }}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                .1
                                                                                                                                                                                                            </span>
                                                                                                                                                                                                        </bdt>
                                                                                                                                                                                                    </bdt>
                                                                                                                                                                                                </bdt>
                                                                                                                                                                                            </span>
                                                                                                                                                                                        </span>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </span>
                                                                                                                                                                </span>
                                                                                                                                                            </span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </bdt>
                                                                                                                </bdt>
                                                                                                            </bdt>
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                </bdt>
                                                                                            </bdt>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    &nbsp;
                                                </span>
                                                &nbsp;
                                            </span>
                                            If any of the terms of this agreement should be or become
                                            invalid, the validity of the remaining provisions shall not be
                                            affected. Invalid terms will be replaced by valid ones
                                            formulated in a way that will achieve the primary purpose.
                                        </span>
                                    </div>
                                    &nbsp;
                                </bdt>
                                &nbsp;
                            </bdt>
                            &nbsp;
                        </bdt>{" "}
                        <bdt className="block-container if" data-type="if">
                            &nbsp;{" "}
                            <bdt data-type="conditional-block">
                                &nbsp;{" "}
                                <bdt data-type="body">
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                    <div
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11pt",
                                                lineHeight: "16.8667px",
                                                color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "11.0pt",
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    color: "#595959",
                                                    msoThemecolor: "text1",
                                                    msoThemetint: 166
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "11.0pt",
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        color: "#595959",
                                                        msoThemecolor: "text1",
                                                        msoThemetint: 166
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "11.0pt",
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            color: "#595959",
                                                            msoThemecolor: "text1",
                                                            msoThemetint: 166
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "11.0pt",
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                color: "#595959",
                                                                msoThemecolor: "text1",
                                                                msoThemetint: 166
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize: "11.0pt",
                                                                    lineHeight: "115%",
                                                                    fontFamily: "Arial",
                                                                    color: "#595959",
                                                                    msoThemecolor: "text1",
                                                                    msoThemetint: 166
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize: "11pt",
                                                                        lineHeight: "16.8667px",
                                                                        color: "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <bdt className="block-component" />
                                                                                17
                                                                                <bdt className="else-block" />
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: "11pt",
                                                                                        lineHeight: "16.8667px",
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span
                                                                                            style={{
                                                                                                lineHeight: "16.8667px",
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    lineHeight: "16.8667px",
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <bdt
                                                                                                    className="block-container if"
                                                                                                    data-type="if"
                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                >
                                                                                                    <bdt data-type="conditional-block">
                                                                                                        <bdt data-type="body">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                .2
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    &nbsp;
                                                </span>
                                                &nbsp;
                                            </span>
                                            Collateral agreements, changes and amendments are only valid
                                            if laid down in writing. The preceding clause can only be
                                            waived in writing.
                                            <bdt className="block-component" />
                                        </span>
                                    </div>
                                </bdt>
                            </bdt>
                        </bdt>
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: 1 }}>
                        <br />
                    </div>
                    <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                        <bdt className="block-container if" data-type="if">
                            <bdt data-type="conditional-block">
                                <bdt data-type="body">
                                    <div
                                        className="MsoNormal"
                                        data-custom-class="body_text"
                                        style={{ lineHeight: "1.5" }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "11pt",
                                                lineHeight: "16.8667px",
                                                color: "rgb(89, 89, 89)"
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: "11.0pt",
                                                    lineHeight: "115%",
                                                    fontFamily: "Arial",
                                                    color: "#595959",
                                                    msoThemecolor: "text1",
                                                    msoThemetint: 166
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "11.0pt",
                                                        lineHeight: "115%",
                                                        fontFamily: "Arial",
                                                        color: "#595959",
                                                        msoThemecolor: "text1",
                                                        msoThemetint: 166
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "11.0pt",
                                                            lineHeight: "115%",
                                                            fontFamily: "Arial",
                                                            color: "#595959",
                                                            msoThemecolor: "text1",
                                                            msoThemetint: 166
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "11.0pt",
                                                                lineHeight: "115%",
                                                                fontFamily: "Arial",
                                                                color: "#595959",
                                                                msoThemecolor: "text1",
                                                                msoThemetint: 166
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize: "11.0pt",
                                                                    lineHeight: "115%",
                                                                    fontFamily: "Arial",
                                                                    color: "#595959",
                                                                    msoThemecolor: "text1",
                                                                    msoThemetint: 166
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize: "11pt",
                                                                        lineHeight: "16.8667px",
                                                                        color: "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span
                                                                            style={{
                                                                                lineHeight: "16.8667px",
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    lineHeight: "16.8667px",
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <bdt className="block-component" />
                                                                                17
                                                                                <bdt className="else-block" />
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: "11pt",
                                                                                        lineHeight: "16.8667px",
                                                                                        color: "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span
                                                                                            style={{
                                                                                                lineHeight: "16.8667px",
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    lineHeight: "16.8667px",
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <bdt
                                                                                                    className="block-container if"
                                                                                                    data-type="if"
                                                                                                    id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                                                                                                >
                                                                                                    <bdt data-type="conditional-block">
                                                                                                        <bdt data-type="body">
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                .3 &nbsp;
                                                                                                                <bdt className="question">
                                                                                                                    __________
                                                                                                                </bdt>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: "11pt",
                                                                                                                        lineHeight: "16.8667px",
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                    </bdt>
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </bdt>
                            </bdt>
                        </bdt>
                    </div>
                </div>
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
                    }}
                />
            </div>
        </>

    );
}





