import { IFirmDashboard } from "../../../../interfaces/Admin";

export enum FirmDashboardActionTypes {
	SET_FIRM_DASHBOARD_DATA = "SET_FIRM_DASHBOARD_DATA",
	RESET_FIRM_DASHBOARD_DATA = "RESET_FIRM_DASHBOARD_DATA",
}

export const setFirmDashboardData = (payload: IFirmDashboard) => {
	return {
		type: FirmDashboardActionTypes.SET_FIRM_DASHBOARD_DATA,
		payload,
	};
};

export const resetFirmDashboardData = () => {
	return {
		type: FirmDashboardActionTypes.RESET_FIRM_DASHBOARD_DATA,
	};
};
