import { useState } from "react";
import chat from "../../assets/images/chat2.png";
import LawyerContactShare from "../Popup/LawyerContactShare";
import FloatingPanel from "./floatingPanel";

const FloatingChat = () => {

	const [contactShare, setContactShare] = useState(false);

    const handleContactShare = () => {
		setContactShare(!contactShare);
	};

	return (
        <>
		<div className="floating">
			<div
                style={{cursor: "pointer"}}
				onClick={handleContactShare}
			>
				<img src={chat} alt='' />
			</div>
		</div>
        			<FloatingPanel
                    active={contactShare}
                    setActive={handleContactShare}
                    lawyer_contact={"+12157689936"}
                />
        </>

	);
};

export default FloatingChat;
