import { isEmptyArray } from "formik";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import plus from "../../../assets/images/plus.svg";
import Layout from "../../../components/Layout";
import PaginatedItems from "../../../components/Paginator";
import AddLawyers from "../../../components/Popup/AddLawyers";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import { useGetFirmLawyerQuery } from "../../../redux/services/Firm/FirmLawyer";

const ManageLawyers = () => {
	const location = useLocation();
	const lawyerManage = location.state;
	const [addLawyer, setAddLawyer] = useState(false);
	const { data: firmLawyer, isLoading } = useGetFirmLawyerQuery(null);

	const handleAddLawyer = () => {
		setAddLawyer(!addLawyer);
	};

	return (
		<Layout>
			<section id="manage_lawyers" className="sec_pad_btm ">
				<div className="contain sm">
					{lawyerManage ? (
						<div className="top_blk">
							<TopBlock title="Firm Lawyers" url="dashboard" />
						</div>
					) : (
						<div className="top_blk">
							<TopBlock title="Manage Lawyers" url="dashboard"  />
							<button
								className="site_btn"
								onClick={handleAddLawyer}
							>
								Add Lawyer
								<img src={plus} alt="icon" />
							</button>
						</div>
					)}
					<ScreenLoader show={isLoading} />
					{isEmptyArray(firmLawyer?.lawyers) ? (
						<div className="empty_lawyer_text" >No Lawyer to show</div>
					) : <PaginatedItems
						itemsPerPage={10}
						items={firmLawyer?.lawyers}
						firmLawyers={true}
					/>}

				</div>
			</section>
			<AddLawyers active={addLawyer} setActive={handleAddLawyer} />
		</Layout>
	);
};

export default ManageLawyers;
