import { authApiSlice } from "../../authApiSlice";
import { basePaths } from "../../../../utills/Const/const";

interface LawyerDashboardData {
	firm_image?: string;
	firm_name?: string;
	member_type?: string;
	incoming_referel?: number;
	outgoing_referel?: number;
	referral_email?: string;
}

interface Lawyer {
	id: number;
	name: string;
	email: string;
	member_type: string;
	jurisdiction: string;
	firm_code: string;
	about_me: string;
	phone: string;
	is_active: boolean;
	is_visible: boolean;
	profile_image: string;
}
interface LawyersData {
	lawyers: Lawyer[];
	total_pages: number;
	total_records: number;
}

const lawyerDashboardApiSlice = authApiSlice.injectEndpoints({
	endpoints: (build) => ({
		getLawyerDashboardData: build.query<LawyerDashboardData, any>({
			query: () => {
				return { url: "/lawyers/lawyer_dashboard", method: "POST" };
			},
		}),
		getLawyers: build.query<LawyersData, any>({
			query: (data) => {
				return {
					url:
						data.role === "lawyer"
							? `/lawyers/lawyers_list?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`
							: `/firms/lawyers_list?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`,
					method: "GET",
				};
			},
		}),

		getLawyersList: build.query<any, any>({
			query: (data) => {
				return {
					url: "/conversation_chats/lawyer_list",
					method: "GET",
				};
			},
		}),

		createConversation: build.mutation<any, any>({
			query: (data) => {
				let formData = new FormData();
				formData.append(
					`[conversation][sender_lawyer_id]`,
					data?.sender_lawyer_id
				);
				formData.append(
					`[conversation][receiver_lawyer_id]`,
					data?.receiver_lawyer_id
				);
				formData.append(
					`[conversation][channel_id]`,
					data?.channel_name
				);
				formData.append(`[conversation][seen]`, data?.seen);

				return {
					url: "/conversation_chats",
					method: "POST",
					body: formData,
				};
			},
		}),
		getConversations: build.query<any, any>({
			query: (data) => {
				return {
					url: "/conversation_chats",
					method: "GET",
				};
			},
		}),

		deleteConversation: build.mutation<any, any>({
			query: (data) => {
				const body = {
					id: data.id,
				};
				return {
					url: "/conversation_chats/delete",
					method: "DELETE",
					body: body,
				};
			},
		}),

		createMessage: build.mutation<any, any>({
			query: (data) => {
				console.log({ data });
				let formData = new FormData();
				formData.append(
					`[message][conversation_id]`,
					data.conversation_id
				);
				formData.append(
					`[message][message_content]`,
					data.message_content
				);
				formData.append(
					`[message][has_attachment]`,
					data.has_attachment
				);
				formData.append(`[message][attachment]`, data.attachment);
				formData.append(`[message][sender_id]`, data.sender_id);
				return {
					url: "/conversation_chats/create_message",
					method: "POST",
					body: formData,
				};
			},
		}),

		getConversationMessages: build.query<any, any>({
			query: (data) => {
				return {
					url: `/conversation_chats/${data.id}`,
					method: "GET",
				};
			},
		}),

		getContacts: build.query<any, any>({
			query: (data) => {
				return {
					url:
						data.rowsPerPage && data.pageNumber
							? `/contacts?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`
							: "/contacts",
					method: "GET",
				};
			},
		}),
		composeReferral: build.mutation<any, any>({
			query: (data) => {
				const userType = data.userType;
				let formData = new FormData();
				formData.append(`[referral][referred_to_id]`, data.id);
				formData.append(`[referral][description]`, data.message);
				formData.append(`[referral][cc]`, data.cc);
				formData.append(`[referral][bcc]`, data.bcc);
				if (data.role === "firm") {
					formData.append(
						`[referral][referred_by_firm_id]`,
						data.userId
					);
					formData.append(
						`[referral][referred_by_firm]`,
						data.firmReferral
					);
				} else {
					formData.append(`[referral][referred_by_id]`, data.userId);
				}

				return {
					url: `/${basePaths[userType]}/referrals`,
					method: "POST",
					body: formData,
				};
			},
		}),

		deleteReferral: build.mutation<any, any>({
			query: (data) => {
				const body = {
					id: data.id ? data.id : data,
				};
				return {
					url: data?.admin
						? `admins/delete_referral`
						: `lawyers/delete_referral`,
					method: "DELETE",
					body: body,
				};
			},
		}),

		getSearchedLawyers: build.mutation<any, any>({
			query: (data) => {
				return {
					url: "lawyers/lawyer_search",
					method: "POST",
					body: data,
				};
			},
		}),

		getSearchedContacts: build.mutation<any, any>({
			query: (data) => {
				return {
					url: "contacts/search",
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useCreateConversationMutation,
	useGetConversationsQuery,
	useGetConversationMessagesQuery,
	useCreateMessageMutation,
	useGetLawyersListQuery,
	useGetLawyerDashboardDataQuery,
	useGetLawyersQuery,
	useGetContactsQuery,
	useComposeReferralMutation,
	useGetSearchedLawyersMutation,
	useDeleteReferralMutation,
	useGetSearchedContactsMutation,
	useDeleteConversationMutation,
} = lawyerDashboardApiSlice;
export default lawyerDashboardApiSlice;
