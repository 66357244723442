import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import JoditEditor from "jodit-react";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../../../components/Layout";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TopBlock from "../../../../components/TopBlock";
import { useGetJurisdictionQuery } from "../../../../redux/services/Client/ClientDashboard";
import { useCreateNewHandBookMutation } from "../../../../redux/services/Firm/FirmLawyer";
import "./AddNewHandbook.scss";

export default function AddNewHandbook() {
	const location = useLocation();
	const item: any = location.state;

	const navigation = useNavigate();

	const [addHandbook, { data: handbook, isLoading, isError }] =
		useCreateNewHandBookMutation();

	const editor = useRef(null);
	const [status, setStatus] = useState(
		item?.item?.rich_text_status ? item?.item?.rich_text_status : false
	);
	const [state, setState] = useState<any>(
		item?.item?.laws?.state_id
			? { id: item?.item?.laws?.state_id, name: item?.item?.state_name }
			: item?.item?.state_id
			? { id: item?.item?.state_id, name: item?.item?.state_name }
			: {}
	);
	const [emptyState, setEmptyState] = useState(false);
	const [data, setData] = useState<any>(null);
	const [introduction, setIntroduction] = useState(
		item?.item?.laws?.introduction
			? item?.item?.laws?.introduction?.body
			: ""
	);
	const [hiring, setHiring] = useState(
		item?.item?.laws?.hiring ? item?.item?.laws?.hiring?.body : ""
	);
	const [compensation, setCompensation] = useState(
		item?.item?.laws?.compensation
			? item?.item?.laws?.compensation?.body
			: ""
	);
	const [absence, setAbsence] = useState(
		item?.item?.laws?.off_leaves_of_absence
			? item?.item?.laws?.off_leaves_of_absence?.body
			: ""
	);
	const [discrimination, setDiscrimination] = useState(
		item?.item?.laws?.discrimination_and_harassment
			? item?.item?.laws?.discrimination_and_harassment?.body
			: ""
	);
	const [termination, setTermination] = useState(
		item?.item?.laws?.termination_dismissal_issues
			? item?.item?.laws?.termination_dismissal_issues?.body
			: ""
	);
	const [layoffs, setLayoffs] = useState(
		item?.item?.laws
			?.layoffs_workforce_reductions_redundancies_collective_dismissals
			? item?.item?.laws
					?.layoffs_workforce_reductions_redundancies_collective_dismissals
					?.body
			: ""
	);
	const [unfair, setUnfair] = useState(
		item?.item?.laws?.unfair_competition_covenants_not_to_compete
			? item?.item?.laws?.unfair_competition_covenants_not_to_compete
					?.body
			: ""
	);
	const [personnel, setPersonnel] = useState(
		item?.item?.laws?.personnel_administration
			? item?.item?.laws?.personnel_administration?.body
			: ""
	);
	const [privacy, setPrivacy] = useState(
		item?.item?.laws?.privacy ? item?.item?.laws?.privacy?.body : ""
	);
	const [injuries, setInjuries] = useState(
		item?.item?.laws?.employee_injurie_and_workers_compensation
			? item?.item?.laws?.employee_injurie_and_workers_compensation?.body
			: ""
	);
	const [unemployment, setUnemployment] = useState(
		item?.item?.laws?.unemployment_compensation
			? item?.item?.laws?.unemployment_compensation?.body
			: ""
	);
	const [health, setHealth] = useState(
		item?.item?.laws?.health_and_safety
			? item?.item?.laws?.health_and_safety?.body
			: ""
	);
	const [trade, setTrade] = useState(
		item?.item?.laws?.trade_union_industrial_relations
			? item?.item?.laws?.trade_union_industrial_relations?.body
			: ""
	);
	const [immigration, setImmigration] = useState(
		item?.item?.laws?.immigration_labor_migration
			? item?.item?.laws?.immigration_labor_migration?.body
			: ""
	);
	const [additional, setAdditional] = useState(
		item?.item?.laws?.additional_information
			? item?.item?.laws?.additional_information?.body
			: ""
	);
	const [contact, setContact] = useState(
		item?.item?.laws?.contact ? item?.item?.laws?.contact?.body : ""
	);

	const { data: jurisdiction } = useGetJurisdictionQuery(null);
	useEffect(() => {
		let payload: any = jurisdiction;
		var sortedJurisdictions = _.sortBy(payload?.states, "name");
		setData({ states: sortedJurisdictions });
	}, [jurisdiction]);

	const handleAddHandbook = () => {
		if (JSON.stringify(state) === "{}") {
			toast.error("Please Select State");
		} else {
			if (item.update) {
				addHandbook({
					introduction: introduction,
					hiring: hiring,
					compensation: compensation,
					off_leaves_of_absence: absence,
					discrimination_and_harassment: discrimination,
					termination_dismissal_issues: termination,
					layoffs_workforce_reductions_redundancies_collective_dismissals:
						layoffs,
					unfair_competition_covenants_not_to_compete: unfair,
					personnel_administration: personnel,
					privacy: privacy,
					employee_injurie_and_workers_compensation: injuries,
					unemployment_compensation: unemployment,
					health_and_safety: health,
					trade_union_industrial_relations: trade,
					immigration_labor_migration: immigration,
					additional_information: additional,
					contact: contact,
					handbook_id: item?.item?.laws?.id,
					state_id: state?.id,
					status: status,
				});
			} else {
				addHandbook({
					introduction: introduction,
					hiring: hiring,
					compensation: compensation,
					off_leaves_of_absence: absence,
					discrimination_and_harassment: discrimination,
					termination_dismissal_issues: termination,
					layoffs_workforce_reductions_redundancies_collective_dismissals:
						layoffs,
					unfair_competition_covenants_not_to_compete: unfair,
					personnel_administration: personnel,
					privacy: privacy,
					employee_injurie_and_workers_compensation: injuries,
					unemployment_compensation: unemployment,
					health_and_safety: health,
					trade_union_industrial_relations: trade,
					immigration_labor_migration: immigration,
					additional_information: additional,
					contact: contact,
					state_id: item.item?.laws?.state_id
						? item?.item?.laws?.state_id
						: state.id,
					status: status,
				});
			}
		}
	};

	useEffect(() => {
		if (
			handbook?.message === "Handbook is created successfully." ||
			handbook?.message === "Handbook is updated successfully."
		) {
			toast.success(handbook?.message);
			navigation("/firm/super-admin-media-library-handbooks");
		} else if (isError) {
			toast.error("Something went wrong, please try again");
		}
	}, [handbook]);

	return (
		<>
			<Layout>
				<section id="resources" className="sec_pad_btm">
					<div className="contain sm">
						<div className="Top_blk my-4">
							<TopBlock
								title={
									item.update
										? "Update Handbook"
										: "Create New Handbook"
								}
								url={"super-admin-media-library-handbooks"}
							/>
						</div>
						<h5 className="fw_500 require my-4">State</h5>
						<Select
							value={
								item?.item?.state?.name
									? item?.item?.state?.name
									: state || ""
							}
							onChange={(event: SelectChangeEvent) => {
								setState(event?.target?.value);
								setEmptyState(false);
							}}
							style={{ width: "100%", lineHeight: "3rem" }}
							displayEmpty
							renderValue={() =>
								state?.name ? state.name : "Select State"
							}
						>
							{data?.states &&
								data?.states?.map((item: any) => (
									<MenuItem
										key={item.id}
										value={item}
										style={{
											fontSize: 17,
											alignSelf: "center",
										}}
									>
										{item?.name}
									</MenuItem>
								))}
						</Select>
						<div className="col-11">
							<h5 className="fw_500 require my-4">Status</h5>
							<div
								className="form_blk"
								style={{ marginBottom: 0 }}
							>
								<div className="switch switch2">
									<input
										type="checkbox"
										name=""
										checked={status}
										onChange={() => {
											setStatus(!status);
										}}
									/>
									<em></em>
									<span></span>
								</div>
							</div>
						</div>
						<h4 style={{marginTop:'2rem'}}>
							If disabled it will disable RICH TEXT PDF and
							automatically enable PDF LINK and vice versa
						</h4>
						<div className="main_container">
							<div className="editor_container">
								<p className="editor_heading">Introduction</p>
								<JoditEditor
									ref={editor}
									value={introduction}
									className="editor-class"
									onChange={(newContent) =>
										setIntroduction(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">Hiring</p>
								<JoditEditor
									ref={editor}
									value={hiring}
									className="editor-class"
									onChange={(newContent) =>
										setHiring(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Compensation
								</p>
								<JoditEditor
									ref={editor}
									value={compensation}
									className="editor-class"
									onChange={(newContent) =>
										setCompensation(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Off leaves of absence
								</p>
								<JoditEditor
									ref={editor}
									value={absence}
									className="editor-class"
									onChange={(newContent) =>
										setAbsence(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Discrimination and harassment
								</p>
								<JoditEditor
									ref={editor}
									value={discrimination}
									className="editor-class"
									onChange={(newContent) =>
										setDiscrimination(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Termination dismissal issues
								</p>
								<JoditEditor
									ref={editor}
									value={termination}
									className="editor-class"
									onChange={(newContent) =>
										setTermination(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Layoffs workforce reductions redundancies
									collective dismissals
								</p>
								<JoditEditor
									ref={editor}
									value={layoffs}
									className="editor-class"
									onChange={(newContent) =>
										setLayoffs(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Unfair competition covenants not to compete
								</p>
								<JoditEditor
									ref={editor}
									value={unfair}
									className="editor-class"
									onChange={(newContent) =>
										setUnfair(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Personnel administration
								</p>
								<JoditEditor
									ref={editor}
									value={personnel}
									className="editor-class"
									onChange={(newContent) =>
										setPersonnel(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">Privacy</p>
								<JoditEditor
									ref={editor}
									value={privacy}
									className="editor-class"
									onChange={(newContent) =>
										setPrivacy(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Employee injuries and workers compensation
								</p>
								<JoditEditor
									ref={editor}
									value={injuries}
									className="editor-class"
									onChange={(newContent) =>
										setInjuries(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Unemployment compensation
								</p>
								<JoditEditor
									ref={editor}
									value={unemployment}
									className="editor-class"
									onChange={(newContent) =>
										setUnemployment(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Health and safety
								</p>
								<JoditEditor
									ref={editor}
									value={health}
									className="editor-class"
									onChange={(newContent) =>
										setHealth(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Trade union industrial relations
								</p>
								<JoditEditor
									ref={editor}
									value={trade}
									className="editor-class"
									onChange={(newContent) =>
										setTrade(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Immigration labor migration
								</p>
								<JoditEditor
									ref={editor}
									value={immigration}
									className="editor-class"
									onChange={(newContent) =>
										setImmigration(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">
									Additional information
								</p>
								<JoditEditor
									ref={editor}
									value={additional}
									className="editor-class"
									onChange={(newContent) =>
										setAdditional(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
							<div className="editor_container">
								<p className="editor_heading my-4">Contact</p>
								<JoditEditor
									ref={editor}
									value={contact}
									className="editor-class"
									onChange={(newContent) =>
										setContact(newContent)
									} // preferred to use only this option to update the content for performance reasons
								/>
							</div>
						</div>
						<div className="btn_blk mt-5 pt-3 my-4">
							<button
								className="site_btn w-100"
								onClick={handleAddHandbook}
							>
								{isLoading ? (
									<ScreenLoader show={isLoading} />
								) : item.update ? (
									"Update Handbook"
								) : (
									"Add New Handbook"
								)}
							</button>
						</div>
					</div>
				</section>
			</Layout>
		</>
	);
}
