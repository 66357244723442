import React, { FC } from "react";
interface InputProps {
	name?: string;
	placeholder: string;
	type: string;
	className?: string;
	handleChange?: any;
	handleBlur?: any;
	value?: string;
	readOnly?: boolean;
	disabled?: boolean;
}

const TextArea: FC<InputProps> = ({
	placeholder,
	className,
	handleChange,
	handleBlur,
	name,
	value,
	readOnly,
	disabled,
}) => {
	return (
		<textarea
			className={className ? className : "input"}
			placeholder={placeholder}
			onChange={handleChange}
			onBlur={handleBlur}
			name={name}
			value={value}
			readOnly={readOnly}
			disabled={disabled}
		/>
	);
};
export default TextArea;
