/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactPagination from "../../../../components/ReactPagination/ReactPagination";
import ImportSvg from "../../../../assets/images/import.svg";
import search from "../../../../assets/images/search.svg";
import HandbookTiles from "../../../../components/HandbookTiles/HandbookTiles";
import Layout from "../../../../components/Layout";
import ImportFromCSV from "../../../../components/Popup/ImportFirmLawyers/ImportFromCsv";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TopBlock from "../../../../components/TopBlock";
import { useDeleteMediaLibraryMutation, useGetMediaLibrarySearchHandbookMutation } from "../../../../redux/services/Firm/FirmLawyer";
import { useGetHandbooksMutation } from "../../../../redux/services/Firm/MediaLibrary";
import AddNewHandbookLink from "./AddNewHandbookLink";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "react-bootstrap";
import DeleteConfirmation from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import { toast } from "react-toastify";

const MediaLibraryHandbooks = () => {
	const [pageNumber, setPageNumber] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [addPdfLink, setAddPdfLink] = useState(false);
	const [importPdf, setImportPdf] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [importHandbook, setImportHandbook] = useState(false);
	const [activeHandbook, setActiveHandbook] = useState(true);
	const [refetch, setRefetch] = useState(false);
	const [searchText, setSearchText] = useState<any | null>("");
	const [handbook, setHandbook] = useState<any>(null);
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [deletedHandbook, setDeletedHandbook] = useState(false);


	const [handBooks, { data, isLoading }] = useGetHandbooksMutation();

	const [ deleteMedia, { isSuccess: deletedSuccessfully, isError: deletionError } ] = useDeleteMediaLibraryMutation();

	const [searching, { data: searchData, isLoading: searchLoading }] =
		useGetMediaLibrarySearchHandbookMutation();

	const handleAddPdfLink = () => {
		setAddPdfLink(!addPdfLink);
	};

	const handleImportPdfLink = () => {
		setImportPdf(!importPdf);
	};

	const handleImportHandbook = () => {
		setImportHandbook(!importHandbook);
	};

	useEffect(() => {
		if (deleted === true) {
			setDeleted(false);
			setHandbook(null);
		}
		handBooks({ pageNumber: pageNumber + 1, rowsPerPage });
	}, [pageNumber, rowsPerPage, deleted, refetch]);

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searching(search);
			} else {
				handBooks({ pageNumber: pageNumber + 1, rowsPerPage });
			}
		}, 1000);
	};
	useEffect(() => {
		if (searchData?.handbooks && searchText !== "") {
			setHandbook(searchData?.handbooks);
		} else {
			setHandbook(data?.handbooks);
		}
	}, [searchData, data]);

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(
				handbook?.map((item: { state_id: any; }) => item.state_id)
			);
		}

		setSelectAll(!selectAll);
	};

	const handleChange = () => {
		setActiveHandbook(!activeHandbook);
	};
	const handleRefetch = () => {
		setDeleted(true);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	useEffect(() => {
		if (deletedHandbook) {
			deleteMedia({
				id: selectedItems,
				toDelete: "handbook",
			});
			setDeletedHandbook(false);
		}
	}, [deletedHandbook]);

	useEffect(() => {
		if (deletedSuccessfully === true) {
			setDeletePopUp(!deletePopUp);
			setDeleted(true);
			setSelectedItems([]);
			toast.success(`Handbooks deleted successfully`);
		}
		if (deletionError) {
			toast.error(`Can not delete Handbooks Please try again`);
		}
	}, [deletedSuccessfully, deletionError]);

	return (
		<>
			<Layout>
				<section id="resources" className="sec_pad_btm">
					<div className="contain sm">
						<div className="Top_blk">
							<TopBlock title="Media Library" />
						</div>
						<ul>
							<li>
								<Link
									to="/firm/super-admin-media-library-handbooks"
									className="active"
								>
									Handbook
								</Link>
							</li>
							<li>
								<Link to="/firm/super-admin-media-library-news">
									News
								</Link>
							</li>
							<li>
								<Link to="/firm/super-admin-media-library-webinars">
									Webinars
								</Link>
							</li>
							<li>
								<Link to="/firm/super-admin-media-library-podcasts">
									Podcasts
								</Link>
							</li>
							<li>
								<Link to="/firm/super-admin-media-library-events">
									Events
								</Link>
							</li>
						</ul>

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginBottom: "3rem",
								flexDirection: "column",
								marginTop: "2rem",
							}}
						>
							<div className="search_container">
								<div className="search_blk_lawyers">
									<div className="img_blk">
										<img src={search} alt="search icon" />
									</div>
									<input
										type="search"
										placeholder="Search Handbook"
										value={searchText}
										style={{ marginTop: "0.5rem" }}
										onChange={(e) => {
											handleSearch(e.target.value);
										}}
									/>
								</div>
							</div>
							<div
								style={{
									alignSelf: "flex-end",
									marginTop: "2rem",
									flexDirection: "row",
									display: "flex",
								}}
							>
								{!activeHandbook && (
									<div>
										<button
											className="site_btn"
											style={{ marginRight: "2rem" }}
											onClick={() => {
												handleImportPdfLink();
											}}
										>
											{"Import Pdf Links"}
											<img
												style={{
													fontWeight: "500",
													fontSize: 25,
												}}
												src={ImportSvg}
											/>
										</button>
									</div>
								)}

								{activeHandbook && (
									<div>
										<button
											className="site_btn"
											style={{ marginRight: "2rem" }}
											onClick={() => {
												handleImportHandbook();
											}}
										>
											{"Import Handbooks"}
											<img
												style={{
													fontWeight: "500",
													fontSize: 25,
												}}
												src={ImportSvg}
											/>
										</button>
									</div>
								)}

								{!activeHandbook && (
									<div>
										<button
											className="site_btn"
											style={{ marginRight: "2rem" }}
											onClick={() => {
												handleAddPdfLink();
											}}
										>
											{"Add New Pdf Link"}{" "}
											<span
												style={{
													fontWeight: "500",
													fontSize: 25,
												}}
											>
												+
											</span>
										</button>
									</div>
								)}
								{activeHandbook && (
									<div>
										<Link
											to={`/firm/create-handbook`}
											state={{ update: false }}
										>
											<button className="site_btn">
												{"Add New Handbook"}{" "}
												<span
													style={{
														fontWeight: "500",
														fontSize: 25,
													}}
												>
													+
												</span>
											</button>
										</Link>
									</div>
								)}
							</div>
						</div>
						<ul>
							<li>
								<Link
									onClick={handleChange}
									to=""
									className={activeHandbook ? "active" : ""}
								>
									Handbook
								</Link>
							</li>
							<li>
								<Link
									to=""
									onClick={handleChange}
									className={!activeHandbook ? "active" : ""}
								>
									Handbook With PDF Link
								</Link>
							</li>
						</ul>
						<div
							className='search_container'
							style={{
								justifyContent: "space-between",
								backgroundColor: "#63a744",
								alignItems: "center",
								padding: "1rem",
								borderRadius: "1rem",
								marginTop: "2rem"
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "0.5rem",
									}}
								>
									<Checkbox
										checked={selectAll}
										classes={{ root: "custom-checkbox-root" }}
										onClick={() => {
											handleSelectAllChange();
										}}
									/>
									<p
										style={{
											fontSize: "2rem",
											lineHeight: "7rem",
											marginLeft: "0.5rem"
										}}
									>
										Select All
									</p>
								</div>
							</div>
							<div>
								{selectedItems?.length > 1 && (
									<Button
										variant='danger'
										onClick={handleBulkDelete}
										style={{
											fontSize: "2rem",
											width: "15rem",
											height: "5rem",
											marginRight: "2rem",
										}}
									>
										Delete
									</Button>
								)}
							</div>
						</div>
						{activeHandbook &&
							handbook?.map((item: any, index: any) => {
								return (
									<HandbookTiles
										item={item}
										key={index + `${item.title}`}
										deleted={setDeleted}
										isSelected={selectedItems.includes(item.state_id)}
										onSelect={handleItemSelect}
									/>
								);
							})}

						{!activeHandbook &&
							handbook?.map((item: any, index: any) => {
								return (
									<HandbookTiles
										item={item}
										pdf={true}
										key={index + `${item.title}`}
										deleted={setDeleted}
										isSelected={selectedItems.includes(item.state_id)}
										onSelect={handleItemSelect}
									/>
								);
							})}

						<ImportFromCSV
							active={importPdf}
							setActive={handleImportPdfLink}
							toImport="pdf"
							refetch={handleRefetch}
						/>

						<ImportFromCSV
							active={importHandbook}
							setActive={handleImportHandbook}
							toImport="handbook"
							refetch={handleRefetch}
						/>

						<AddNewHandbookLink
							active={addPdfLink}
							setActive={handleAddPdfLink}
							isUpdate={false}
							setRefetch={setRefetch}
						/>
						<DeleteConfirmation
							showModal={deletePopUp}
							message={`Are you sure you want to delete this Handbook`}
							handleDelete={setDeletedHandbook}
							hideModal={handleBulkDelete}
						/>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							{(!isLoading && !searchLoading) &&
								<ReactPagination
									setRowsPerPage={setRowsPerPage}
									setPages={setPageNumber}
									totalRecords={data?.total_records}
									rowsPerPageOptions={[10, 15, 20, 25]}
									currentPage={pageNumber}
								/>
							}
						</div>

						<ScreenLoader show={isLoading || searchLoading} />
					</div>
				</section>
			</Layout>
		</>
	);
};

export default MediaLibraryHandbooks;
