import { useEffect, useState } from "react";
import chat from "../../assets/images/chat.png";
import wa from "../../assets/images/whatsapp.png";
import wa2 from "../../assets/images/chat3.png";
import { ArrowLeft } from "../../icons";
import FloatingChatBox from "./floatingChatBox";

export default function FloatingPanel({
	active,
	setActive,
	lawyer_contact,
}: any) {
	const [url, setUrl] = useState("");

	const message = `Hello!`; // Replace this with your desired message

	const openWhatsAppWeb = () => {
		const phoneNumber = lawyer_contact; // Replace this with the phone number you want to send the message to

		const link = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
			message
		)}`;

		setUrl(link);
	};

	const [contactShare, setContactShare] = useState(false);

	const handleContactShare = () => {
		setContactShare(!contactShare);
	};

	useEffect(() => {
		openWhatsAppWeb();
	}, []);

	return (
		<>
			<section
				id='contactInfo_popUp'
				className={active ? "popup active" : "popup"}
				x-placement='bottom-start'
			>
				<FloatingChatBox
					active={contactShare}
					setActive={handleContactShare}
					lawyer_contact={"+14158359000"}
				/>
				<div className='overlay' onClick={setActive}></div>
				<div className='table_dv'>
					<div className='table_cell'>
						<div className='contain'>
							<div className='row justify-content-end'>
								<div style={{ width: "15%" }}>
									<div
										style={{
											bottom: "22%",
											right: "1%",
											position: "fixed",
											zIndex: 2,
										}}
									>
										<h6 className='text-center mb-5 mt-5'>
											<img
												src={wa}
												alt=''
												className='dropimg'
											/>
											<a href={url} target='_blank'>
												WhatsApp
											</a>
										</h6>
										<div
											style={{ cursor: "pointer" }}
											onClick={handleContactShare}
										>
											<h6 className='text-center'>
												<img
													src={wa2}
													alt=''
													className='dropimg'
												/>
												{/* <a href="https://web.wechat.com/" target="_blank">Support</a> */}
												Support
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
