import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AddNewHandbookLink from "../../containers/Admin/SuperAdmin/MediaLibrary/AddNewHandbookLink";
import { PdfIcon } from "../../icons";
import { useDeleteMediaLibraryMutation } from "../../redux/services/Firm/FirmLawyer";
import DeleteConfirmation from "../DeleteConfirmationModal/DeleteConfirmationModal";
import Checkbox from "@mui/material/Checkbox";

const HandbookTiles = ({ item, deleted, pdf, isSelected, onSelect }: any) => {
	const [
		deleteMedia,
		{ isSuccess: deletedSuccessfully, isError: deletionError },
	] = useDeleteMediaLibraryMutation();
	const dateToConvert = item?.laws?.created_at
		? item?.laws?.created_at
		: item?.created_at;
	const date = new Date(dateToConvert).toDateString();
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [deletedHandbook, setDeletedHandbook] = useState(false);
	const [updateHandbook, setUpdateHandbook] = useState(false);

	const handleDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	useEffect(() => {
		if (deletedSuccessfully === true) {
			setDeletePopUp(!deletePopUp);
			toast.success(`Handbook deleted successfully`);
			deleted(true);
		}
		if (deletionError) {
			toast.error(`Can not delete Handbook Please try again`);
		}
	}, [deletedSuccessfully, deletionError]);

	useEffect(() => {
		if (deletedHandbook) {
			deleteMedia({
				id: item.laws ? [item?.laws?.id] : [item.state_id],
				toDelete: "handbook",
			});
			setDeletedHandbook(false);
		}
	}, [deletedHandbook]);

	const handleUpdatePDFLink = () => {
		setUpdateHandbook(!updateHandbook);
	};

	const handleSelect = () => {
		onSelect(item?.state_id);
	};

	return (
		<div className="resource_card">
			<div style={{ display: "flex" }}>
				<Checkbox
					checked={isSelected}
					onClick={() => handleSelect()}
					classes={{ root: "custom-checkbox-root" }}
					style={{marginRight: '5rem'}}
				/>
				<div className="img_blk my-4">
					<PdfIcon width={40} height={40} />
				</div>
				<div
					style={{
						marginTop: "1.5rem",
						marginLeft: "7rem",
						minWidth: "32rem",
					}}
				>
					<h5 className="fw_500 transform my-4">
						{item?.state_name ? item?.state_name : ""}
					</h5>
				</div>
			</div>
			<div
				style={{
					marginTop: "1.5rem",
					marginLeft: "7rem",
					width: "30%",
				}}
			>
				<h5 className="fw_500 transform my-4">{date}</h5>
			</div>
			<div
				style={{
					width: "15%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
					marginTop: "2rem",
				}}
			>
				{!pdf ? (
					<Link
						to={`/firm/create-handbook`}
						state={{ item: item, update: true }}
					>
						<div style={{ cursor: "pointer" }}>
							<BorderColorIcon
								fontSize="large"
								sx={{
									height: "2em",
									width: "2em",
									color: "#63A744",
								}}
							/>
						</div>
					</Link>
				) : (
					<div
						onClick={handleUpdatePDFLink}
						style={{ cursor: "pointer" }}
					>
						<BorderColorIcon
							fontSize="large"
							sx={{
								height: "2em",
								width: "2em",
								color: "#63A744",
							}}
						/>
					</div>
				)}

				<div onClick={handleDelete} style={{ cursor: "pointer" }}>
					<DeleteIcon
						fontSize="large"
						sx={{ height: "2em", width: "5em", color: "red" }}
					/>
				</div>
			</div>
			<DeleteConfirmation
				showModal={deletePopUp}
				message={`Are you sure you want to delete this Handbook`}
				handleDelete={setDeletedHandbook}
				hideModal={handleDelete}
			/>
			<AddNewHandbookLink
				active={updateHandbook}
				setActive={handleUpdatePDFLink}
				isUpdate={true}
				setRefetch={deleted}
				state={item}
			/>
		</div>
	);
};

export default HandbookTiles;
