import { isEmptyArray } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import filters from "../../../assets/images/filters.svg";
import search from "../../../assets/images/search.svg";
import Layout from "../../../components/Layout";
import AdminResourcesFilter from "../../../components/Popup/AdminResourcesFilter";
import ResourcesCard from "../../../components/ResourcesCard";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import { useGetRole } from "../../../redux/selectors/Common";
import { useGetSearchedResourcesMutation } from "../../../redux/services/Client/ClientDashboard";
import {
	useGetGeneralSearchMutation,
	useGetNewHandbooksQuery,
} from "../../../redux/services/Firm/MediaLibrary";
import { getResourcesList } from "./Selector/resources.selector";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";
import { Button } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import axios from 'axios';

const Resources = () => {
	const role = useGetRole();
	const [filter, setFilter] = useState(false);
	const [searchText, setSearchText] = useState("");
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const [resourcesArr, setResourcesArr] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);

	const [
		searchingResources,
		{ data: searchData, isError, isLoading: Loading },
	] = useGetSearchedResourcesMutation();

	const { data: newHandbooks, refetch, isLoading } = useGetNewHandbooksQuery({ rowsPerPage, pageNumber });

	const [generalSearch, { data: handBooks, isLoading: searchLoading }] =
		useGetGeneralSearchMutation();

	const handleFilter = () => {
		setFilter(!filter);
		setSearchText("");
	};

	useEffect(() => {
		if (searchData) {
			setResourcesArr(getResourcesList({ resources: searchData }));
		} else if (handBooks) {
			setResourcesArr(getResourcesList({ resources: handBooks }));
		} else if (newHandbooks) {
			setResourcesArr(getResourcesList({ resources: newHandbooks }));
		}
		if (isError) {
			toast.error("Something went wrong");
		}
	}, [searchData, isError, handBooks]);

	useEffect(() => {
		refetch();
		if (newHandbooks) {
			setResourcesArr(getResourcesList({ resources: newHandbooks }));
		}
	}, [newHandbooks, pageNumber, rowsPerPage]);

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				generalSearch({ search });
			} else {
				setResourcesArr(getResourcesList({ resources: newHandbooks }));
			}
		}, 1000);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(
				resourcesArr?.map((item: any) => item.pdf_link)
			);
		}

		setSelectAll(!selectAll);
	};

	

	const handleButtonClick = async () => {

		try {
			for (const pdfLink of selectedItems) {

				const response = await axios.get(pdfLink, {
					responseType: 'arraybuffer'
				});

				const blob = new Blob([response.data], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = pdfLink.split('/').pop();
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a)

			}
		} catch (error) {
			console.error('Error occurred:', error);
		}
	};

	return (
		<Layout>
			<section id="resources" className="sec_pad_btm">
				<div className="contain sm">
					<div className="Top_blk">
						<TopBlock title="Handbooks" url="dashboard" />
						<div className="left_blk">
							<div className="search_blk">
								<div className="img_blk">
									<img src={search} alt="" />
								</div>
								<input
									type="search"
									value={searchText}
									placeholder="Search"
									onChange={(e) => {
										handleSearch(e.target.value);
									}}
								/>
							</div>
							<div
								className="filter_blk pop_btn"
								onClick={handleFilter}
							>
								<img src={filters} alt="" />
							</div>
						</div>
					</div>
					{role !== "client" ? (
						<ul>
							<li>
								<Link
									to={`/${role}/resources`}
									className="active"
								>
									Search Handbook
								</Link>
							</li>
							<li>
								<Link to={`/${role}/all-media`}>
									Media Library
								</Link>
							</li>
						</ul>
					) : null}

					<h3 className="mt-5 text-center">
						{isEmptyArray(resourcesArr)
							? !Loading && !searchLoading && "Handbooks"
							: "Lawyers Handbook"}
					</h3>
					<div
							className='search_container'
							style={{
								justifyContent: "space-between",
								backgroundColor: "#63a744",
								alignItems: "center",
								padding: "1rem",
								borderRadius: "1rem",
								marginTop: "2rem"
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "0.5rem",
									}}
								>
									<Checkbox
										checked={selectAll}
										classes={{ root: "custom-checkbox-root" }}
										onClick={() => {
											handleSelectAllChange();
										}}
									/>
									<p
										style={{
											fontSize: "2rem",
											lineHeight: "7rem",
											marginLeft: "0.5rem"
										}}
									>
										Select All
									</p>
								</div>
							</div>
							<div>
								{selectedItems?.length > 1 && (
									<Button
										variant='primary'
										onClick={handleButtonClick}
										style={{
											fontSize: "2rem",
											width: "15rem",
											height: "5rem",
											marginRight: "2rem",
										}}
									>
										Download
									</Button>
								)}
							</div>
						</div>
					<ScreenLoader show={searchLoading || Loading} />
					{resourcesArr?.map((item: any, index) => {
						return (
							<ResourcesCard
								item={item}
								key={index + `${item.title}`}
								isSelected={selectedItems.includes(item.pdf_link)}
								onSelect={handleItemSelect}
							/>
						);
					})}
					{!isLoading && !searchLoading && (
						<ReactPagination
							setRowsPerPage={setRowsPerPage}
							setPages={setPageNumber}
							totalRecords={newHandbooks?.total_records}
						/>
					)}
				</div>
			</section>
			<AdminResourcesFilter
				active={filter}
				setActive={handleFilter}
				searchingResources={searchingResources}
			/>
		</Layout>
	);
};

export default Resources;
