import { useState } from "react";
import Modal from "../../../components/Modal";
import ForgotForm from "./forgotPass";

export default function ForgotPass() {
	const [modalShow, setModalShow]: any = useState(false);
	const handleModal = () => {
		setModalShow(!modalShow);
	};
	return (
		<>
			{/* <Button label="Login" onClick={handleModal} /> */}
			<ForgotForm />
			<Modal children={<ForgotForm />} show={modalShow} onHide={handleModal} />
		</>
	);
}
