import { Link, useLocation } from "react-router-dom";
import { useGetRole } from "../../redux/selectors/Common";

const MediaHeader = () => {
	const role = useGetRole();
	const { pathname } = useLocation();
	return (
		<ul className="tabs">
			<li>
				<Link to={`/${role}/all-media`}>
					<button
						type="button"
						className={
							pathname === `/${role}/all-media`
								? "active site_btn"
								: "site_btn"
						}
					>
						All
					</button>
				</Link>
			</li>
			<li>
				<Link to={`/${role}/webinar`}>
					<button
						type="button"
						className={
							pathname === `/${role}/webinar`
								? "active site_btn"
								: "site_btn"
						}
					>
						Webinars
					</button>
				</Link>
			</li>
			<li>
				<Link to={`/${role}/media-library`}>
					<button
						className={
							pathname === `/${role}/media-library`
								? "active site_btn"
								: "site_btn"
						}
					>
						Podcasts
					</button>
				</Link>
			</li>
			{role !== 'client' && <li>
				<Link to={`/${role}/events`}>
					<button
						className={
							pathname === `/${role}/events`
								? "active site_btn"
								: "site_btn"
						}
					>
						Events
					</button>
				</Link>
			</li>}
			<li>
				<Link to={`/${role}/news`}>
					<button
						className={
							pathname === `/${role}/news`
								? "active site_btn"
								: "site_btn"
						}
					>
						News & Articles
					</button>
				</Link>
			</li>
		</ul>
	);
};

export default MediaHeader;
