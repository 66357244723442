import { authApiSlice } from "../../authApiSlice";

export interface IClientDirectory {
	id: number;
	name: string;
	email: string;
	member_type: string;
	jurisdiction: string;
	firm_code: string;
	firm_name: string;
	about_me: string;
	phone: string;
	is_active: boolean;
	is_visible: boolean;
	profile_image: string;
}

export interface IClientState {
	id: number;
	name: string;
	created_at: string;
	updated_at: string;
	status: boolean;
}

export interface IClientDirectoryArray {
	lawyers: IClientDirectory[];
}

interface IClientDirectorySearch {
	title: string;
	data: IClientDirectory[];
}

interface IClientDirectorySearchArray {
	lawyers: IClientDirectorySearch[];
}

interface IClientStates {
	states: IClientState[];
}

const clientDashboardApiSlice = authApiSlice.injectEndpoints({
	endpoints: (build) => ({
		getClientDirectory: build.query<IClientDirectoryArray, null>({
			query: () => "client_lawyer_directory",
		}),

		getClientSearchDirectory: build.mutation<any, any>({
			query: (data) => {
				return {
					url: "client_lawyer_search",
					method: "POST",
					body: data,
				};
			},
		}),
		getJurisdiction: build.query<IClientStates, null>({
			query: () => "states",
		}),

		getSearchedResources: build.mutation<any, any>({
			query: (data) => {
				return {
					url: "laws",
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useGetClientDirectoryQuery,
	useGetClientSearchDirectoryMutation,
	useGetJurisdictionQuery,
	useGetSearchedResourcesMutation,
} = clientDashboardApiSlice;
export default clientDashboardApiSlice;
