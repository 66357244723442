import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import NoImage from "../../../assets/images/noImage.png";
import { ArrowLeft, Lock } from "../../../icons";
import { IFirms } from "../../../interfaces/Admin";
import { useGetJurisdictionQuery } from "../../../redux/services/Client/ClientDashboard";
import { useCreateNewFirmMutation } from "../../../redux/services/Firm/FirmLawyer";
import { addFirmLawyerSchema } from "../../../utills/YupValidationSchemas";
import ErrorText from "../../Shared/ErrorText";
import ScreenLoader from "../../Shared/ScreenLoader";
import TextInput from "../../TextInput";
import "./AddNewFirm.scss";
import { Locations } from "../../../utills/Const/const";

interface AddFirmLawyer {
	active: boolean;
	setActive: any;
	firm?: IFirms;
	isUpdate: boolean;
	isSuperAdmin?: boolean;
	disable?: boolean;
}

export default function AddNewFirm(props: AddFirmLawyer) {
	const fileInput = useRef<HTMLInputElement>(null);
	let [data, setData] = useState<any>(null);
	const creationDate = new Date(
		props?.firm?.created_at || new Date()
	).toDateString();
	const updationDate = new Date(
		props?.firm?.updated_at || new Date()
	).toDateString();
	const [formImage, setFormImage] = useState<any>(
		props?.isUpdate ? props?.firm?.profile_image : ""
	);
	const [dp, setDp] = useState<any>(
		props?.isUpdate ? props?.firm?.profile_image : ""
	);
	const [formImageError, setFormImageError] = useState<string>("");
	const [addFirm, { data: addNewFirm, isSuccess, isError, isLoading }] =
		useCreateNewFirmMutation();
	const handleAddFirm = (values: IFirms) => {
		if (props.isUpdate) {
			addFirm({
				...values,
				firm_id: props?.firm?.id,
				profile_image: formImage,
			});
		} else {
			addFirm({ ...values, profile_image: formImage });
		}
	};

	const { data: jurisdiction } = useGetJurisdictionQuery(null);

	useEffect(() => {
		let payload: any = jurisdiction;
		var sortedJurisdictions = _.sortBy(payload?.states, "name");
		setData({ states: sortedJurisdictions });
	}, [jurisdiction]);

	useEffect(() => {
		if (
			addNewFirm?.message === "firm is created successfully." ||
			addNewFirm?.message === "firm is updated successfully."
		) {
			setDp(NoImage);
			resetForm();
			handleClose();
			if (
				addNewFirm?.message === "firm is created successfully." &&
				props.isSuperAdmin
			) {
				toast.success("Super Admin is Created Successfully");
			} else if (
				addNewFirm?.message === "firm is updated successfully." &&
				props.isSuperAdmin
			) {
				toast.success("Super Admin is Updated Successfully");
			} else {
				toast.success(addNewFirm?.message);
			}
		} else if (isError) {
			toast.error(
				"Error! Firm not created Successfully, Please try Again"
			);
		}
	}, [addNewFirm]);

	const handleClose = () => {
		props.setActive();
	};

	const initialValues: IFirms = {
		email: props?.firm?.email ? props.firm.email : "",
		password: "",
		name: props?.firm?.name ? props?.firm.name : "",
		phone: props?.firm?.phone ? props?.firm.phone : "",
		description: props?.firm?.description ? props?.firm.description : "",
		role: props.isSuperAdmin ? "Super Admin" : "Admin",
		profile_image: props?.firm?.profile_image
			? props?.firm?.profile_image
			: "",
		jurisdiction: props.isUpdate ? props.firm?.jurisdiction : "",
		region: props.isUpdate ? props.firm?.region : "",
		firm_url: props.firm?.firm_url ? props.firm?.firm_url : "",
		referral_email: props.firm?.referral_email ? props.firm?.referral_email : ""
	};

	const {
		values,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		touched,
		resetForm,
	} = useFormik({
		validationSchema: addFirmLawyerSchema,
		initialValues,
		onSubmit: (values) => {
			handleAddFirm(values);
		},
	});

	const handleFileUpload = (e: any) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}

		const file = e.target.files[0];

		if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
			return;
		}

		setFormImage(file);
		setFormImageError("");

		const reader = new FileReader();

		reader.addEventListener(
			"load",
			(e: ProgressEvent<FileReader>) => {
				if (!e?.target?.result) {
					return;
				}
				const result = reader.result as string;
				setDp(result);
			},
			false
		);
		reader.readAsDataURL(file);
	};

	useEffect(() => {
		if (props?.firm?.profile_image !== "" && props.isUpdate === true) {
			setFormImage(props?.firm?.profile_image || "");
		}
	}, [props?.firm]);

	const handleHeading = () => {
		if (props.disable){
			return 'Firm Detail'
		}
		else if (props.isSuperAdmin && !props.isUpdate) {
			return "Add New Super Admin";
		} else if (props.isSuperAdmin && props.isUpdate) {
			return "Update Super Admin";
		} else if (props.isUpdate && !props.isSuperAdmin) {
			return "Update Firm";
		} else {
			return "Add New Firm";
		}
	};

	return (
		<section
			id="add_lawyer_popup"
			className={props.active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="inner_div">
									<button
										type="button"
										className="back_btn"
										onClick={props.setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">
										{handleHeading()}
									</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();
											if (formImage) {
												handleSubmit();
											} else {
												setFormImageError(
													"Image is Required"
												);
											}
										}}
									>
										<div className="ico fill round dp dp-image ">
											<img
												src={
													props.firm?.profile_image
														? props.firm
																?.profile_image
														: dp || NoImage
												}
												alt="form_image"
											/>
											{!props.disable && (
												<div
													className="edit-icon"
													onClick={() =>
														fileInput?.current?.click()
													}
												>
													<CameraAltIcon className="dp-icon" />
												</div>
											)}
											<input
												ref={fileInput}
												type="file"
												hidden
												onChange={handleFileUpload}
											/>
										</div>
										<div className="text-center">
											<h5
												style={{
													color: "red",
												}}
											>
												{formImageError}
											</h5>
										</div>
										{/* ------------------------------------------------------------------------------------------------------------------
                                        Name And Email Row
------------------------------------------------------------------------------------------------------------------ */}

										<div
											className="row"
											style={{
												justifyContent: "center",
												marginTop: 25,
											}}
										>
											<div className="form_blk_container">
												<div className="input_field_div">
													<h5 className="fw_500 require">
														Name
													</h5>
													<div className="form_blk">
														<TextInput
															disabled={
																props.disable
															}
															placeholder="eg: John Wick"
															type="text"
															name="name"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.name}
														/>
													</div>
													<ErrorText
														text={errors.name}
														isTouched={touched.name}
													/>
												</div>
												<div className="input_field_div">
													<h5 className="fw_500 require">
														Email Address
													</h5>
													<div className="form_blk">
														<TextInput
															disabled={
																props.disable
															}
															placeholder="eg: sample@gmail.com"
															type="email"
															name="email"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.email}
														/>
													</div>
													<ErrorText
														text={errors.email}
														isTouched={
															touched.email
														}
													/>
												</div>
											</div>
											{/* ------------------------------------------------------------------------------------------------------------------
                                        Password And Confirm Password Row
------------------------------------------------------------------------------------------------------------------ */}
											<>
												{!props.disable ? (
													<div className="form_blk_container">
														<div className="input_field_div">
															<h5 className="fw_500 require ">
																Password
															</h5>
															<div className="form_blk">
																<Lock />
																<TextInput
																	disabled={
																		props.disable
																	}
																	type="password"
																	name="password"
																	handleChange={
																		handleChange
																	}
																	handleBlur={
																		handleBlur
																	}
																	value={
																		values.password
																	}
																	className="input"
																	placeholder="eg: PassLogin%7"
																/>
															</div>
															<ErrorText
																text={
																	errors.password
																}
																isTouched={
																	touched.password
																}
															/>
														</div>
														{!props.isUpdate && (
															<div className="input_field_div">
																<h5 className="fw_500 require">
																	Confirm
																	Password
																</h5>
																<div className="form_blk">
																	<Lock />
																	<TextInput
																		type="password"
																		name="password_confirmation"
																		handleChange={
																			handleChange
																		}
																		handleBlur={
																			handleBlur
																		}
																		value={
																			values.password_confirmation
																		}
																		className="input"
																		placeholder="eg: PassLogin%7"
																	/>
																</div>
																<ErrorText
																	text={
																		errors.password_confirmation
																	}
																	isTouched={
																		touched.password_confirmation
																	}
																/>
															</div>
														)}
													</div>
												) : (
													<div className="form_blk_container">
														<div className="input_field_div mb-4">
															<h5 className="fw_500 require ">
																Created At
															</h5>
															<div className="form_blk">
																<TextInput
																	disabled={
																		props.disable
																	}
																	type="text"
																	value={
																		creationDate
																	}
																	className="input"
																	placeholder="22 Wed Feb 2023"
																/>
															</div>
														</div>
														<div className="input_field_div mb-4">
															<h5 className="fw_500 require">
																Updated At
															</h5>
															<div className="form_blk">
																<TextInput
																	disabled
																	type="text"
																	value={
																		updationDate
																	}
																	className="input"
																	placeholder="22 Wed Feb 2023"
																/>
															</div>
														</div>
													</div>
												)}
											</>
											{/* ------------------------------------------------------------------------------------------------------------------
                                            Jurisdiction
------------------------------------------------------------------------------------------------------------------ */}
											{!props.isSuperAdmin && (
												<div className="form_blk_container">
													<div className="input_field_div">
														<h5 className="fw_500 require">
															Jurisdiction
														</h5>
														<Select
															disabled={
																props.disable
															}
															value={
																values.jurisdiction
															}
															onChange={(
																event: SelectChangeEvent
															) => {
																setFieldValue(
																	"jurisdiction",
																	event.target
																		.value
																);
															}}
															style={{
																width: "100%",
																lineHeight:
																	"3rem",
															}}
															displayEmpty
															renderValue={() =>
																values.jurisdiction
																	? values.jurisdiction
																	: "Select Jurisdiction"
															}
														>
															{data?.states &&
																data?.states?.map(
																	(
																		item: any
																	) => (
																		<MenuItem
																			key={
																				item.id
																			}
																			value={
																				item.name
																			}
																			style={{
																				fontSize: 17,
																				alignSelf:
																					"center",
																			}}
																		>
																			{
																				item.name
																			}
																		</MenuItem>
																	)
																)}
														</Select>
														<ErrorText
															text={
																errors.jurisdiction
															}
															isTouched={
																touched.jurisdiction
															}
														/>
													</div>
													<div className="input_field_div">
														<h5 className="fw_500 require">
															Region
														</h5>
														<div className="form_blk">
															<Select
																disabled={
																	props.disable
																}
																value={
																	values.region
																}
																onChange={(
																	event: SelectChangeEvent
																) => {
																	setFieldValue(
																		"region",
																		event
																			.target
																			.value
																	);
																}}
																style={{
																	width: "100%",
																	lineHeight:
																		"3rem",
																}}
																displayEmpty
																renderValue={() =>
																	values.region
																		? values.region
																		: "Select Region"
																}
															>
																{Locations?.map(
																	(
																		item: any
																	) => (
																		<MenuItem
																			key={
																				item.id
																			}
																			value={
																				item.name
																			}
																			style={{
																				fontSize: 17,
																				alignSelf:
																					"center",
																			}}
																		>
																			{
																				item.name
																			}
																		</MenuItem>
																	)
																)}
															</Select>
														</div>
													</div>
												</div>
											)}

											{/* ------------------------------------------------------------------------------------------------------------------
                                            Phone Number
------------------------------------------------------------------------------------------------------------------ */}
											<div className="form_blk_container">
												<div className="col-12">
													<h5 className="fw_500 require my-4">
														Phone
													</h5>
													<div className="form_blk">
														<PhoneInput
															disabled={
																props.disable
															}
															enableAreaCodeStretch
															prefix="+"
															autoFormat
															country={"us"}
															value={values.phone}
															onChange={(
																value
															) => {
																setFieldValue(
																	"phone",
																	value
																);
															}}
														/>
														<ErrorText
															text={errors.phone}
															isTouched={
																touched.phone
															}
														/>
													</div>
												</div>
											</div>
											{/* ------------------------------------------------------------------------------------------------------------------
                                           Link
------------------------------------------------------------------------------------------------------------------ */}

											{!props.isSuperAdmin && (
												<div className="form_blk_container my-4">
													<div className="col-12">
														<h5 className="fw_500 require">
															Firm Url
														</h5>
														<div className="form_blk">
															<TextInput
																disabled={
																	props.disable
																}
																type="text"
																name="firm_url"
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																value={
																	values.firm_url
																}
																className="input"
																placeholder="http://"
															/>
														</div>
													</div>
												</div>
											)}
											{/* ------------------------------------------------------------------------------------------------------------------
                                            Firm Referral Email
------------------------------------------------------------------------------------------------------------------ */}
											{!props.isSuperAdmin && (
												<div className="form_blk_container my-4">
													<div className="col-12">
														<h5 className="fw_500 require">
															Firm Referral CC Email
														</h5>
														<div className="form_blk">
															<TextInput
																disabled={
																	props.disable
																}
																type="text"
																name="referral_email"
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																value={
																	values.referral_email
																}
																className="input"
																placeholder="eg: sample@gmail.com"
															/>
														</div>
													</div>
												</div>
											)}
											{/* ------------------------------------------------------------------------------------------------------------------
                                            Role
------------------------------------------------------------------------------------------------------------------ */}
											<div className="col-11 ">
												<h5 className="fw_500">Role</h5>
												<div className="form_blk">
													<TextInput
														placeholder="eg: Admin"
														type="text"
														name="role"
														disabled
														handleChange={
															handleChange
														}
														handleBlur={handleBlur}
														value={
															props.isSuperAdmin
																? "Super Admin"
																: "Admin"
														}
													/>
												</div>
											</div>
										</div>
										{!props.disable && (
											<div className="btn_blk mt-5 pt-3">
												<button
													className="site_btn w-100"
													type="submit"
												>
													{isLoading ? (
														<ScreenLoader
															show={isLoading}
														/>
													) : (
														handleHeading()
													)}
												</button>
											</div>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
