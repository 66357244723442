import { FC } from "react";
import { Spinner } from "react-bootstrap";

interface InputProps {
	show?: boolean;
	message?: string;
}
const ScreenLoader: FC<InputProps> = ({ show, message }) => {
	return (
		<>
			{show && (
				<div className='loader_container'>
					{message && <h3>{message}</h3>}
					<Spinner animation='border' />
				</div>
			)}
		</>
	);
};

export default ScreenLoader;
