import React, { FC } from "react";
interface InputProps {
	label: string;
	name?: string;
	options?: any;
	className?: string;
	handleChange?: any;
	handleBlur?: any;
	value?: any;
	defaultValue?: string;
}
const Select: FC<InputProps> = ({
	label,
	name,
	options,
	className,
	handleChange,
	handleBlur,
	value,
	defaultValue
}) => {
	return (
		<>
			<select
				className={`${className ? className : ""} form-control`}
				aria-label='form-select-lg example'
				defaultValue={defaultValue || ''}
				onChange={(e) => handleChange(e.target.value)}
				onBlur={handleBlur}
				name={name}
				value={value}
			>
				<option value='' hidden disabled>
					{label}
				</option>
				{options?.map((data: any, index: number) => {
					return (
						<option key={index} value={data.value}>
							{data.key}
						</option>
					);
				})}
			</select>
		</>
	);
};
export default Select;
