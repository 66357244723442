import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { Lock } from "../../../icons";

export default function Reset() {
    const handleSubmit = () => {
        alert("submiited!");
    };
    return (
        <>
            <section id="logon">
                <div className="contain">
                    <div className="flex_blk">
                        <form action="" method="POST" className="auth-form">
                            <div className="log_blk">
                                <div className="inner">
                                    <div className="txt text-center mb-5 pb-3">
                                        <h2>Create New Password</h2>
                                        <p className="fs_5 opacity-50">Your new password must be different from your previously used passwords</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="fw_500 require">Password</h5>
                                            <div className="form_blk mb-2">
                                                <Lock />
                                                <TextInput placeholder="eg: PassLogin%7" type="password" />
                                            </div>
                                            <p className="color fs_6">Must be atleast 8 characters</p>
                                        </div>
                                        <div className="col-12">
                                            <h5 className="fw_500 require">Confirm Password</h5>
                                            <div className="form_blk mb-2">
                                                <Lock />
                                                <TextInput placeholder="eg: PassLogin%7" type="password" />
                                            </div>
                                            <p className="color fs_6">Both password must match</p>
                                        </div>
                                    </div>
                                    <div className="btn_blk justify-content-center mt-5 pt-3">
                                        <Button label="Confirm" type="submit" onClick={handleSubmit} className="site_btn w-100" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
