import { IFirmDashboard } from "../../../../interfaces/Admin";
import { IAction } from "../../../../interfaces/Common";
import { FirmDashboardActionTypes as ActionTypes } from "../../../action/Admin/Dashboard";

const initialState: IFirmDashboard = {
	firm_image: undefined,
	firm_name: undefined,
	incoming_referral: undefined,
	outgoing_referral: undefined,
};
const dashboardReducer = (
	state = initialState,
	action: IAction<IFirmDashboard>
) => {
	switch (action.type) {
		case ActionTypes.SET_FIRM_DASHBOARD_DATA:
			return {
				...state,
				firm_image: action.payload?.firm_image,
				firm_name: action.payload?.firm_name,
				incoming_referral: action.payload?.incoming_referral,
				outgoing_referral: action.payload?.outgoing_referral,
			};
		case ActionTypes.RESET_FIRM_DASHBOARD_DATA:
			return {
				...state,
				firm_image: undefined,
				firm_name: undefined,
				incoming_referral: undefined,
				outgoing_referral: undefined,
			};
		default:
			return state;
	}
};

export default dashboardReducer;
