import React, { useEffect, useRef, useState } from "react";
import NoImage from "../../assets/images/noImage.png";
import PopOver from "../../components/Popover";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import SendIcon from "@mui/icons-material/Send";
import {
	useCreateMessageMutation,
	useDeleteConversationMutation,
	useGetConversationMessagesQuery,
} from "../../redux/services/Lawyer/DashboardApi";
import ScreenLoader from "../Shared/ScreenLoader";
import { GetLoginUserData } from "../../redux/selectors/Common";
import DeleteIcon from "@mui/icons-material/Delete";
import Pusher from "pusher-js";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { Box } from "@mui/material";
import FileUpload from "../Popup/FileUpload";
import AttachmentConfirmation from "./AttchmentConfirmationModal";
import DeleteConfirmation from "../DeleteConfirmationModal/DeleteConfirmationModal";
import { toast } from "react-toastify";
import axios from 'axios';


const RightChatScreen = ({
	receiver,
	conversationId,
	channelName,
	refetchList,
	setReceiver,
	setShowRightChat,
	currentUserName,
}: any) => {
	const [messages, setMessages]: any = useState([]);
	const [count, setCount]: any = useState(false);
	const [attachment, setAttachment]: any = useState(null);
	const [newMessage, setNewMessage]: any = useState("");
	const [showUpload, setShowUpload]: any = useState(false);
	// const [fileName, setFileName]: any = useState("");
	const inputFile = useRef<HTMLInputElement>(null);
	const chatBoxRef = useRef<HTMLDivElement | null>(null);
	const [attachmentPopUp, setAttachmentPopUp] = useState(false);
	const [confirmAttachment, setConfirmAttachment] = useState(false);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [deletedHandbook, setDeletedHandbook] = useState(false);

	const [
		deleteConversation,
		{ isSuccess: deletedSuccessfully, isError: deletionError },
	] = useDeleteConversationMutation();

	useEffect(() => {
		if (confirmAttachment) {
			handleMessageSend();

			setConfirmAttachment(false);
		}
	}, [confirmAttachment]);

	const handleDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	useEffect(() => {
		if (deletedSuccessfully === true) {
			setDeletePopUp(!deletePopUp);
			toast.success(`Conversation deleted successfully`);
			setReceiver(null);
			refetchList();
			setShowRightChat(false);
		}
		if (deletionError) {
			toast.error(`Can not delete Conversation. Please try again`);
		}
	}, [deletedSuccessfully, deletionError]);

	useEffect(() => {
		if (deletedHandbook) {
			deleteConversation({
				id: [conversationId],
			});
			setDeletedHandbook(false);
		}
	}, [deletedHandbook]);

	const handleAttachmentClick = () => {
		setAttachmentPopUp(!attachmentPopUp);
		setTimeout(() => {
			setAttachment(null);
		}, 2000);
	};

	let userData: any = GetLoginUserData();
	const userId = userData?.lawyer?.id;

	const { data, isLoading, isSuccess, refetch } =
		useGetConversationMessagesQuery({
			id: conversationId,
		});

	const createDate = (date: any) => {
		const inputDate = new Date(date);

		const formattedDate = inputDate.toLocaleString(undefined, {
			weekday: "short",
			hour: "numeric",
			minute: "numeric",
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});

		return formattedDate;
	};

	const handleAttachment = (e: any) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}

		const file = e.target.files[0];

		// if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
		// 	return;
		// }

		setAttachment(file);
		handleAttachmentClick();
	};

	function downloadAttachment(attachment: any) {

		const { filename, content_type, data } = attachment;
	  
		// Decode Base64 content
		const byteCharacters = atob(data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
	  
		// Create Blob from decoded content
		const blob = new Blob([byteArray], { type: content_type });
	  
		// Create a download link
		const aTag = document.createElement('a');
		aTag.href = URL.createObjectURL(blob);
		aTag.download = filename;
	  
		// Append the link to the document
		document.body.appendChild(aTag);
	  
		// Trigger a click on the link to start the download
		aTag.click();
	  
		// Remove the link from the document
		document.body.removeChild(aTag);
	  }

	const [
		createnewMessage,
		{
			data: newMessageData,
			isLoading: newMessageLoading,
			isSuccess: isNewMessageSuccess,
			isError,
		},
	] = useCreateMessageMutation();

	const handleMessageSend = () => {
		createnewMessage({
			conversation_id: conversationId,
			message_content: newMessage,
			has_attachment: attachment ? true : false,
			attachment,
			sender_id: userId,
		});
		setNewMessage("");
		setAttachment(null);
	};

	useEffect(() => {
		setMessages(data?.messages);
	}, [data]);

	useEffect(() => {
		setNewMessage("");
		refetch();
	}, [receiver]);

	useEffect(() => {
		pusher();
	}, []);

	useEffect(() => {
		refetch();
	}, [messages]);

	const pusher = () => {
		const pusher = new Pusher(`${process.env.REACT_APP_KEY}`, {
			cluster: `${process.env.REACT_APP_CLUSTER}`,
		});

		const channel = pusher.subscribe(channelName);

		channel.bind(
			`${process.env.REACT_APP_EVENT_NAME}`,
			(message: any) => {
				setMessages((prevMessages: any) => [...prevMessages, message]);
				return () => {
					channel.unbind_all();
					channel.unsubscribe();
				};
			},
			[]
		);
		refetch();
	};

	useEffect(() => {
		if (chatBoxRef) {
			// @ts-ignore
			chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
		}
	}, [data]);

	const image =
		currentUserName !== receiver?.receiver_name
			? receiver?.receiver_profile_image
			: receiver?.sender_profile_image;

	// function check(index: number) {

	// 	console.log('--=-=-=-=-=-=-=-=-', messages[index ].message.created_at, messages[index+1]?.message?.created_at);
	// 	if (messages[index+1]?.message?.created_at == messages[index].message.created_at) {
	// 		return false;
	// 	}

	// 	return true;
	// }

	return (
		<div className="right_blk_chat">
			<ScreenLoader show={isLoading} />
			<div className="top_blk">
				<div className="content-block">
					<div className="img_blk">
						<img
							src={image ? image : NoImage}
							alt="Image Container"
						/>
					</div>
					<div className="text_blk">
						<p>
							{currentUserName !== receiver?.receiver_name
								? receiver?.receiver_name
								: receiver?.sender_name}
						</p>
					</div>
				</div>
				<div className="actions-block" onClick={handleDelete}>
					<DeleteIcon style={{ cursor: "pointer", height: "3rem", width: "3rem" }} />
				</div>
			</div>
			<div className="chat_area" ref={chatBoxRef}>
				{messages?.map((msg: any, index: number) => (
					<>
						{msg?.message?.message_content ? (
							<>
								<div
									className={
										userId === msg?.message?.sender_id
											? "senderMessage "
											: "receiverMessage"
									}
									key={msg.message.conversation_id}
								>
									{msg?.message?.message_content}
								</div>
								{
									<p
										style={{
											fontSize: "smaller",
											margin: `${
												userId ===
												msg?.message?.sender_id
													? " 0 auto 0 auto"
													: "0 auto 0 auto"
											}`,
										}}
									>
										{createDate(msg?.message?.created_at)}
									</p>
								}
							</>
						) : (
							" "
						)}
						{msg?.message?.has_attachment ? (
							<>
								<div
									className={
										userId === msg?.message?.sender_id
											? "senderMessage"
											: "receiverMessage"
									}
								>
									{msg?.attachment?.filename}

									<Box
										sx={{
											display: "inline",
											cursor: "pointer",
											margin: "0 1rem",
										}}
										onClick={() =>
											downloadAttachment(msg.attachment)
										}
									>
										<DownloadingIcon style={{ cursor: "pointer", height: "3rem", width: "3rem" }}/>
									</Box>
								</div>
								<p
									style={{
										fontSize: "smaller",
										margin: `${
											userId === msg?.message?.sender_id
												? " 0 auto 0 auto"
												: "0 auto 0 auto"
										}`,
									}}
								>
									{createDate(msg?.message?.created_at)}
								</p>
							</>
						) : (
							""
						)}
					</>
				))}
			</div>
			<div className="bottom_blk">
				<div className="new_message_block">
					<input
						type="text"
						value={newMessage}
						placeholder="Write Something"
						onChange={(e) => {
							setNewMessage(e.target.value);
						}}
						disabled={attachment ? true : false}
					/>
					<PopOver setNewMessage={setNewMessage} />
					<input
						type="file"
						ref={inputFile}
						hidden
						onChange={handleAttachment}
					/>
					{!newMessage && (
						<InsertLinkIcon
							sx={{
								width: "3rem",
								height: "3rem",
								cursor: "pointer",
								mx: "1rem",
							}}
							onClick={() => {
								inputFile?.current?.click();
							}}
						/>
					)}
					<SendIcon
						onClick={handleMessageSend}
						sx={{
							color: "#114d96",
							height: "2em",
							width: "2em",
							mx: "1rem",
							cursor: "pointer",
						}}
					/>
				</div>
			</div>

			<AttachmentConfirmation
				showModal={attachmentPopUp}
				message={`Click the send button to send you attachment!`}
				handleDelete={setConfirmAttachment}
				hideModal={handleAttachmentClick}
			/>
			<DeleteConfirmation
				showModal={deletePopUp}
				message={`Are you sure you want to delete this conversation?`}
				handleDelete={setDeletedHandbook}
				hideModal={handleDelete}
			/>
		</div>
	);
};

export default RightChatScreen;
