import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import AutoComplete from "../../components/MuiSelect";
import { ArrowLeft } from "../../icons";

import _ from "lodash";
import { useGetJurisdictionQuery } from "../../redux/services/Client/ClientDashboard/index";
import { useGetAllFirmsQuery } from "../../redux/services/Firm/FirmLawyer";
import { Locations } from "../../utills/Const/const";
import TextInput from "../TextInput";

export default function SuperAdminFilter({
	active,
	setActive,
	searchingResources,
	lawyer,
	mediaType,
}: any) {
	const [firmName, setFirmName] = useState<any>("");
	const [createdAt, setCreatedAt] = useState<any>(null);
	const [updatedAt, setUpdatedAt] = useState<any>(null);
	const [mediaDate, setMediaDate] = useState<any>(null);
	let [data, setData] = useState<any>(null);
	const { data: firms } = useGetAllFirmsQuery(null);

	const { data: jurisdiction } = useGetJurisdictionQuery(null);
	useEffect(() => {
		let payload: any = jurisdiction;
		var sortedJurisdictions = _.sortBy(payload?.states, "name");
		setData({ states: sortedJurisdictions });
	}, [jurisdiction]);

	const handleSubmit = (values: any) => {
		let filterObj: any = {};
		if (values?.jurisdiction?.length) {
			filterObj.jurisdiction = values.jurisdiction;
		}
		if (firmName && !lawyer) {
			filterObj.name = firmName.name;
		}

		if (firmName && lawyer) {
			filterObj.firm_name = firmName.name;
		}

		if (createdAt) {
			const selectedDate =
				createdAt &&
				new Date(
					createdAt.getFullYear(),
					createdAt.getMonth(),
					createdAt.getDate() + 1
				);
			filterObj.created_at = selectedDate.toISOString();
		}
		if (updatedAt) {
			const selectedDate =
				updatedAt &&
				new Date(
					updatedAt.getFullYear(),
					updatedAt.getMonth(),
					updatedAt.getDate() + 1
				);
			filterObj.updated_at = selectedDate.toISOString();
		}
		if (mediaDate) {
			const selectedDate =
				mediaDate &&
				new Date(
					mediaDate.getFullYear(),
					mediaDate.getMonth(),
					mediaDate.getDate() + 1
				);
			if (mediaType === "News") filterObj.news_date = selectedDate.toISOString();
			else if (mediaType === "Podcast")
				filterObj.pod_cast_date = selectedDate.toISOString();
			else if (mediaType === "Webinar")
				filterObj.webnars_date = selectedDate.toISOString();
			else filterObj.event_date = selectedDate.toISOString();
		}
		if (values.link !== "") {
			filterObj.link = values.link;
		}
		if (values.address !== "") {
			filterObj.firm_Address = values.address;
		}
		if (values.region !== "") {
			filterObj.region = values.region;
		}
		if (values.firm_url !== "") {
			filterObj.firm_url = values.firm_url;
		}
		if (values.lawyer_url !== "") {
			filterObj.lawyer_url = values.lawyer_url;
		}

		setActive(false);
		searchingResources(filterObj);
	};

	return (
		<section
			id="filter_popup"
			className={active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={setActive}></div>

			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="_inner">
									<button
										type="button"
										className="back_btn"
										onClick={setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">Filter</h2>

									{/* Filter Form */}
									<div>
										<Formik
											initialValues={{
												jurisdiction: [],
												region: "",
												firm_url: "",
												lawyer_url: "",
												link: "",
												address: "",
											}}
											onSubmit={(values) =>
												handleSubmit(values)
											}
										>
											{({
												values,
												handleSubmit,
												setFieldValue,
												handleChange,
											}) => (
												<form
													onSubmit={handleSubmit}
													className=""
												>
													{mediaType === "" && (
														<div className="row">
															{/* Jurisdiction */}

															<div className="col-12">
																<div
																	className=""
																	style={{
																		width: "100% !important",
																	}}
																>
																	<h5 className="fw_500">
																		Jurisdiction
																	</h5>
																	<AutoComplete
																		options={
																			data?.states
																				? data?.states
																				: []
																		}
																		multiple={
																			true
																		}
																		field="jurisdiction"
																		placeholder="Jurisdiction(s)"
																		handleChange={(
																			value: any
																		) => {
																			let selectedJurisdictions: any =
																				value?.map(
																					(
																						i: any
																					) =>
																						i.name
																				);

																			setFieldValue(
																				"jurisdiction",
																				selectedJurisdictions
																			);
																		}}
																	/>
																</div>
															</div>
														</div>
													)}
													{mediaType === "" && (
														<div>
															<h5 className="fw_500 mt-4 mb-0">
																Firm
															</h5>
															<Select
																value={
																	firmName?.name
																		? firmName?.name
																		: ""
																}
																onChange={(
																	event: SelectChangeEvent
																) => {
																	setFirmName(
																		event
																			.target
																			?.value
																	);
																}}
																style={{
																	width: "100%",
																	lineHeight:
																		"3rem",
																	height: "6.5rem",
																	marginTop:
																		"2rem",
																}}
																displayEmpty
																renderValue={() =>
																	firmName?.name
																		? firmName?.name
																		: "Select Firm"
																}
															>
																{firms &&
																	firms.map(
																		(
																			item: any
																		) => (
																			<MenuItem
																				key={
																					item.id
																				}
																				value={
																					item
																				}
																				style={{
																					fontSize: 17,
																					alignSelf:
																						"center",
																				}}
																			>
																				{
																					item.name
																				}
																			</MenuItem>
																		)
																	)}
															</Select>
														</div>
													)}
													{!lawyer &&
														mediaType === "" && (
															<div className="form_blk_container my-4">
																<div
																	style={{
																		flex: "0 0 auto",
																		width: "111%",
																	}}
																>
																	<h5 className="fw_500 ">
																		Firm Url
																	</h5>
																	<div className="form_blk">
																		<TextInput
																			type="text"
																			name="firm_url"
																			handleChange={
																				handleChange
																			}
																			value={
																				values.firm_url
																			}
																			className="input"
																			placeholder="http://"
																		/>
																	</div>
																</div>
															</div>
														)}
													{mediaType === "" &&
														!lawyer && (
															<div className="form_blk_container my-4">
																<div
																	style={{
																		flex: "0 0 auto",
																		width: "111%",
																	}}
																>
																	<h5 className="fw_500 ">
																		Firm Address
																	</h5>
																	<div className="form_blk">
																		<TextInput
																			type="text"
																			name="address"
																			handleChange={
																				handleChange
																			}
																			value={
																				values.address
																			}
																			className="input"
																			placeholder="Ground Floor, 1 Bains Singh Avenue"
																		/>
																	</div>
																</div>
															</div>
														)}

													{lawyer && (
														<div className="form_blk_container my-4">
															<div
																style={{
																	flex: "0 0 auto",
																	width: "111%",
																}}
															>
																<h5 className="fw_500 ">
																	Lawyer Url
																</h5>
																<div className="form_blk">
																	<TextInput
																		type="text"
																		name="lawyer_url"
																		handleChange={
																			handleChange
																		}
																		value={
																			values.lawyer_url
																		}
																		className="input"
																		placeholder="http://"
																	/>
																</div>
															</div>
														</div>
													)}
													{mediaType !== "" && (
														<div className="form_blk_container my-4">
															<div
																style={{
																	flex: "0 0 auto",
																	width: "111%",
																}}
															>
																<h5 className="fw_500 ">
																	Link
																</h5>
																<div className="form_blk">
																	<TextInput
																		type="text"
																		name="link"
																		handleChange={
																			handleChange
																		}
																		value={
																			values.link
																		}
																		className="input"
																		placeholder="http://"
																	/>
																</div>
															</div>
														</div>
													)}
													{!lawyer &&
														mediaType === "" && (
															<div className="input_field_div">
																<h5 className="fw_500 my-4">
																	Region
																</h5>
																<div className="form_blk">
																	<Select
																		value={
																			values.region
																		}
																		onChange={(
																			event: SelectChangeEvent
																		) => {
																			setFieldValue(
																				"region",
																				event
																					.target
																					.value
																			);
																		}}
																		style={{
																			width: "100%",
																			lineHeight:
																				"3rem",
																		}}
																		displayEmpty
																		renderValue={() =>
																			values.region
																				? values.region
																				: "Select Region"
																		}
																	>
																		{Locations?.map(
																			(
																				item: any
																			) => (
																				<MenuItem
																					key={
																						item.id
																					}
																					value={
																						item.name
																					}
																					style={{
																						fontSize: 17,
																						alignSelf:
																							"center",
																					}}
																				>
																					{
																						item.name
																					}
																				</MenuItem>
																			)
																		)}
																	</Select>
																</div>
															</div>
														)}
													<div className="input_field_div my-4">
														<h5 className="fw_500 require  ">
															Created At
														</h5>
														<div className="form_blk">
															<DatePicker
																format="yyyy-MM-dd"
																// defaultValue={
																// 	undefined
																// }
																className="input"
																onChange={(
																	date: any
																) => {
																	setCreatedAt(
																		date ||
																			null
																	);
																}}
																value={
																	createdAt
																}
															/>
														</div>
													</div>
													<div className="input_field_div my-4">
														<h5 className="fw_500 require  ">
															Updated At
														</h5>
														<div className="form_blk">
															<DatePicker
																format="dd-MM-yyyy"
																className="input"
																onChange={(
																	date: any
																) => {
																	const selectedDate =
																		date &&
																		new Date(
																			date?.getFullYear(),
																			date?.getMonth(),
																			date?.getDate() +
																				1
																		);
																	setUpdatedAt(
																		selectedDate ||
																			null
																	);
																}}
																value={
																	updatedAt
																}
															/>
														</div>
													</div>
													{mediaType !== "" && (
														<div className="input_field_div my-4">
															<h5 className="fw_500 require  ">
																{`${mediaType} Date`}
															</h5>
															<div className="form_blk">
																<DatePicker
																	format="dd-MM-yyyy"
																	className="input"
																	onChange={(
																		date: any
																	) => {
																		const selectedDate =
																			date &&
																			new Date(
																				date?.getFullYear(),
																				date?.getMonth(),
																				date?.getDate() +
																					1
																			);
																		setMediaDate(
																			selectedDate ||
																				null
																		);
																	}}
																	value={
																		mediaDate
																	}
																/>
															</div>
														</div>
													)}

													{/* Submit */}
													<div className="btn_blk mt-5 pt-3">
														<button
															className="site_btn w-100"
															type="submit"
														>
															Search
														</button>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
