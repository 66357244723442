import Modal from "../../../components/Modal";
import React, { useState } from "react";
import Button from "../../../components/Button";
import VerificationForm from "./verification";
export default function Login() {
  const [modalShow, setModalShow]: any = useState(false);
  const handleModal = () => {
    setModalShow(!modalShow);
  };
  return (
    <>
      {/* <Button label="Login" onClick={handleModal} /> */}
      <VerificationForm />
      <Modal children={<VerificationForm />} show={modalShow} onHide={handleModal} />
    </>
  );
}
