import { useState } from "react";
import Modal from "../../../components/Modal";
import SignUpForm from "./signUp";

export default function SignUp() {
	const [modalShow, setModalShow]: any = useState(false);
	const handleModal = () => {
		setModalShow(!modalShow);
	};
	return (
		<>
			{/* <Button label="Login" onClick={handleModal} /> */}
			< SignUpForm />
			<Modal children={<SignUpForm />} show={modalShow} onHide={handleModal} />
		</>
	);
}
