import { Formik } from "formik";
import { useEffect, useState } from "react";
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "../../../components/Select";
import ErrorText from "../../../components/Shared/ErrorText";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TextInput from "../../../components/TextInput";
import { Apple, ArrowLeft, Envelope, Google, Lock, User } from "../../../icons";
import { ILoginInput } from "../../../interfaces/Common";
import { setData, setRole } from "../../../redux/reducers/Auth";
import { useGetRole } from "../../../redux/selectors/Common";
import { useLoginMutation } from "../../../redux/services/Auth";
import { useAppDispatch } from "../../../redux/store";
import { setLocalStorage } from "../../../utills/common";
import { UserTypes } from "../../../utills/Const/const";
import { loginSchema } from "../../../utills/YupValidationSchemas";
import hidden from "../../../assets/images/closed-eye.png";
import show from "../../../assets/images/eye.png";


const Login = () => {
	const role = useGetRole();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const [userType, setUserType] = useState('')
	const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
		console.log(res)
	}
	const onFailure = (res: any) => {
		console.log(res)
	}
	const { signIn, loaded } = useGoogleLogin({ onSuccess, onFailure, cookiePolicy: "single_host_origin", clientId: "590047446339-7a4ddorpmgvcdksvgjnbbf65dckt4qup.apps.googleusercontent.com" });

	const [firmLogin, { isLoading, data: loginData, isSuccess, isError }] =
		useLoginMutation();

	const handleSubmit = (values: ILoginInput) => {
		setUserType(values.userType)
		firmLogin(values)
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		if (isSuccess && loginData) {
			setLocalStorage('token', loginData.token)
			if (userType === 'superAdmin' && loginData?.firm?.role === 'Super Admin') {
				dispatch(
					setRole({ userRole: 'firm' })
				);
				dispatch(setData(loginData))
				navigate(`/${role}/dashboard`);
			} else if (userType === 'firm' && loginData?.firm?.role === 'Super Admin' || userType === 'superAdmin' && loginData?.firm?.role === 'Admin') {
				toast.error(`Invalid Email or password related ${userType === 'superAdmin' ? 'Super Admin' : userType}`)
			} else {
				dispatch(
					setRole({ userRole: userType })
				);
				dispatch(setData(loginData))
				navigate(`/${role}/dashboard`);
			}

		}
		if (isError) {
			toast.error(`Invalid Email or Password related ${userType}`)
		}
	}, [isSuccess, loginData, isError, role, dispatch, navigate])


	return (
		<>
			<section id="logon">
				<div className="contain">
					<div className="flex_blk">
						<Formik
							initialValues={{
								userType: 'client',
								email: "",
								password: "",
							}}
							validationSchema={loginSchema}
							onSubmit={(values) => handleSubmit(values)}
						>
							{({
								values,
								handleChange,
								handleSubmit,
								handleBlur,
								setFieldValue,
								errors,
								touched,
							}) => {
								return (
									<form
										onSubmit={handleSubmit}
										className="auth-form"
									>
										<div className="log_blk">
											<Link to="/" className="back_btn">
												<ArrowLeft />
											</Link>
											<div className="inner">
												<div className="txt text-center mb-5 pb-3">
													<h2>Welcome Back!</h2>
													<p className="fs_5">
														Log in to your existent
														account of ELA
													</p>
												</div>
												<div className="row">
													<div className="col-12">
														<h5 className="fw_500">
															Select User
														</h5>
														<div className="form_blk">
															<User />
															<Select
																label="Select User Type"
																className="input"
																options={
																	UserTypes
																}
																handleChange={(value: string) => {
																	setFieldValue("userType", value);
																}}
																handleBlur={
																	handleBlur
																}
																defaultValue={values.userType}
																name="userType"
															/>
														</div>
														<ErrorText
															text={
																errors.userType
															}
															isTouched={
																touched.userType
															}
														/>
													</div>
													<div className="col-12">
														<h5 className="fw_500 require">
															Email Address
														</h5>
														<div className="form_blk">
															<Envelope />
															<TextInput
																placeholder="eg: sample@gmail.com"
																type="email"
																name="email"
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																value={
																	values.email
																}
															/>
														</div>
														<ErrorText
															text={errors.email}
															isTouched={
																touched.email
															}
														/>
													</div>
													<div className="col-12">
														<h5 className="fw_500 require">
															Password
														</h5>
														<div className="password_wrapper">
															<div className="form_blk" style={{ display: "flex", width: '93%', marginBottom: 0 }}>
																<Lock />
																<input
																	type={showPassword ? 'text' : 'password'}
																	name="password"
																	onChange={
																		handleChange
																	}
																	onBlur={
																		handleBlur
																	}
																	value={
																		values.password
																	}
																	className="input"
																	placeholder="eg: PassLogin%7"
																	style={{ border: 'none' }}
																/>
															</div>
															<div onClick={togglePasswordVisibility}>
																{showPassword ? <img src={show} alt="" /> : <img src={hidden} alt="" />}
															</div>
														</div>
														<ErrorText
															text={
																errors.password
															}
															isTouched={
																touched.password
															}
														/>
													</div>
													<div className="col-12">
														<div className="form_blk forgot_btn text-end">
															<Link
																to="/forgot-password"
																className="fw_500"
															>
																Forgot Your
																Password?
															</Link>
														</div>
													</div>
												</div>
												<div className="btn_blk justify-content-center mt-5 pt-3">
													<button
														type="submit"
														className="site_btn w-100"
													>
														Login
													</button>
												</div>
												<div className="OR">OR</div>
												<div className="btn_blk social_btn_blk">
													<button
														type="button"
														className="site_btn apple_btn flex-grow-1"
													>
														<Apple /> Apple
													</button>
													<button
														type="button"
														className="site_btn google_btn flex-grow-1"
														onClick={signIn}
													>
														<Google /> Google
													</button>
												</div>
												<div className="already_acc text-center">
													Don't have an account?&nbsp;
													<Link
														to="/signup"
														className="fw_500"
													>
														Sign up
													</Link>
												</div>
											</div>
										</div>
									</form>
								);
							}}
						</Formik>
					</div>
				</div>
			</section>
			<ScreenLoader
				show={isLoading}
			/>
		</>
	);
};

export default Login;
