import {
	IEventsArray,
	INewsArray,
	INotifications,
	IPodcastArray,
	IWebinarsArray,
} from "../../../../interfaces/Common";
import { authApiSlice } from "../../authApiSlice";

const MediaLibraryApiSlice = authApiSlice.injectEndpoints({
	endpoints: (build) => ({
		getWebinars: build.query<IWebinarsArray, any>({
			query: (data) => {
				return {
					url: `media_library/webnars?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`,
				};
			},
			keepUnusedDataFor: 0,
		}),
		getEvents: build.query<IEventsArray, any>({
			query: (data) => {
				return {
					url: `media_library/events?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`,
				};
			},
			keepUnusedDataFor: 0,
		}),

		getNews: build.query<INewsArray, any>({
			query: (data) => {
				return {
					url: `media_library/news?records_per_page=${data.rowsPerPage}&page_number=${data.pageNumber}`,
				};
			},
			keepUnusedDataFor: 0,
		}),
		getPodcasts: build.query<IPodcastArray, any>({
			query: (data) => {
				return {
					url: `media_library/podcasts?records_per_page=${
						data.rowsPerPage
					}&page_number=${data.pageNumber}`,
				};
			},
			keepUnusedDataFor: 0,
		}),
		getNotifications: build.query<INotifications[], any>({
			query: () => {
				return {
					url: "/user_notification",
				};
			},
			keepUnusedDataFor: 0,
		}),
		deleteNotifications: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `notification/${data}`,
					method: "DELETE",
				};
			},
		}),
		deleteAllNotifications: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `notification/delete_all?id=${data}`,
					method: "DELETE",
				};
			},
		}),

		seenNotifications: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `/mark_as_seen?id=${data}`,
					method: "POST",
				};
			},
		}),

		getGeneralSearch: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `search_content?general_search=${data.search}`,
				};
			},
		}),

		getNewHandbooks: build.query<any, any>({
			query: (data) => {
				return {
					url: `get_handbooks?page=${data?.pageNumber}&records_per_page=${data.rowsPerPage}`,
				};
			},
		}),

		getHandbooks: build.mutation<any, any>({
			query: (data) => {
				return {
					url: `handbook_list?page=${data?.pageNumber}&records_per_page=${data.rowsPerPage}`,
				};
			},
		}),

		getMediaSearch: build.query<any, any>({
			query: (data) => {
				return {
					url: `/media_library/media_library_search?query=${data}`,
				};
			},
			keepUnusedDataFor: 0,
		}),
	}),
});

export const {
	useGetHandbooksMutation,
	useGetPodcastsQuery,
	useGetNotificationsQuery,
	useGetGeneralSearchMutation,
	useGetMediaSearchQuery,
	useDeleteNotificationsMutation,
	useDeleteAllNotificationsMutation,
	useGetEventsQuery,
	useGetNewsQuery,
	useGetWebinarsQuery,
	useGetNewHandbooksQuery,
	useSeenNotificationsMutation,
} = MediaLibraryApiSlice;
export default MediaLibraryApiSlice;
