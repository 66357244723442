import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="#BCB9B9"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M5.96774 6.0074C5.96774 2.68961 8.66848 0 12 0C15.3315 0 18.0323 2.68961 18.0323 6.0074C18.0323 9.3252 15.3315 12.0148 12 12.0148C8.66848 12.0148 5.96774 9.3252 5.96774 6.0074ZM12 2.12026C9.84431 2.12026 8.09677 3.86059 8.09677 6.0074C8.09677 8.15421 9.84431 9.89454 12 9.89454C14.1557 9.89454 15.9032 8.15421 15.9032 6.0074C15.9032 3.86059 14.1557 2.12026 12 2.12026Z"
			strokeWidth={0}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.32258 16.2553C4.55883 16.2553 3.12903 17.6792 3.12903 19.4357V21.1154C3.12903 21.1409 3.14764 21.1627 3.17299 21.1669C9.01896 22.1174 14.981 22.1174 20.827 21.1669C20.8524 21.1627 20.871 21.1409 20.871 21.1154V19.4357C20.871 17.6792 19.4412 16.2553 17.6774 16.2553H17.1936C17.1562 16.2553 17.119 16.2612 17.0835 16.2728L15.855 16.6723C13.3501 17.4868 10.6499 17.4868 8.14501 16.6723L6.91654 16.2728C6.88098 16.2612 6.8438 16.2553 6.80639 16.2553H6.32258ZM1 19.4357C1 16.5082 3.383 14.1351 6.32258 14.1351H6.80639C7.06825 14.1351 7.32847 14.1763 7.57739 14.2572L8.80586 14.6567C10.8814 15.3316 13.1186 15.3316 15.1941 14.6567L16.4226 14.2572C16.6715 14.1763 16.9317 14.1351 17.1936 14.1351H17.6774C20.617 14.1351 23 16.5082 23 19.4357V21.1154C23 22.1801 22.2252 23.0879 21.1701 23.2594C15.0969 24.2469 8.90311 24.2469 2.82993 23.2594C1.7748 23.0879 1 22.18 1 21.1154V19.4357Z"
			strokeWidth={0}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default SvgComponent;
