import { Navigate } from "react-router-dom";
import { useGetRole } from "../../redux/selectors/Common";

export const PublicRoute = ({ children }: { children: JSX.Element }) => {
	const userRole = useGetRole();
	const token = localStorage.getItem("token");

	if (token && userRole) {
		return <Navigate to={`/${userRole}/dashboard`} replace />;
	}

	return children;
};
