import React, { useState, useEffect } from "react";
import { GetReferral } from "../../interfaces/Admin";
import ReferralInfoModal from "../Popup/ReferralInfoModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "../DeleteConfirmationModal/DeleteConfirmationModal";
import { useDeleteReferralMutation } from "../../redux/services/Lawyer/DashboardApi";
import { Checkbox } from "@mui/material";
import { toast } from "react-toastify";

type Props = {
	item: GetReferral;
	refetch?: any;
	admin?: boolean;
	manageReferrals?: boolean;
	checkbox?: boolean;
	handleSelect?: any;
	isSelected?: boolean;
	retchAnalyticsData?: any;
};

const ReferalCard: React.FC<Props> = ({
	item,
	refetch,
	admin = false,
	checkbox,
	handleSelect,
	isSelected,
	retchAnalyticsData
}) => {
	const [referralModal, setReferralModal] = useState(false);
	const [deleteReferralModal, setDeleteReferralModal] = useState(false);
	const [deleteReferral, { data, isSuccess, isError }] =
		useDeleteReferralMutation();

	useEffect(() => {
		if (isSuccess) {
			refetch();
			retchAnalyticsData()
			toast.success("Referral Deleted Successfully");
		}
		if (isError) {
			toast.error("Error! Can not delete the Referral. Please try Again");
		}
	}, [isSuccess, isError]);

	const handleSetActive = () => {
		setReferralModal(!referralModal);
	};
	const date = new Date(item?.created_at).toDateString();
	const handleReferralModal = () => {
		setReferralModal(true);
	};
	const handleDeleteReferal = () => {
		setDeleteReferralModal(!deleteReferralModal);
	};
	const handleDeleteReferralModal = () => {
		
			deleteReferral({ id: item?.id,admin:admin });
		
		
		setDeleteReferralModal(false);
	};

	return (
		<>
			<div className="referal_Card">
				{checkbox && <Checkbox
					checked={isSelected}
					style={{
						marginLeft: "-6rem",
					}}
					classes={{
						root: "custom-checkbox-root",
					}}
					onClick={() => {handleSelect(item?.id)}}
				/>}
				<div className="referal_by" style={{
					marginLeft: "2rem",
				}}>
					<h5 className="fw_500">Referred By:</h5>
					<p>{ item.referred_by_firm ? item.referred_by_firm_name : item.referred_by}</p>
				</div>
				<div className="referal_to">
					<h5 className="fw_500">From Jurisdiction:</h5>
					<p>{item.from_jurisdiction}</p>
				</div>
				<div className="referal_to">
					<h5 className="fw_500">From Firm:</h5>
					<p>{item.from_firm}</p>
				</div>
				<div className="referal_to">
					<h5 className="fw_500">To Jurisdiction:</h5>
					<p>{item.to_jurisdiction}</p>
				</div>
				<div className="referal_to">
					<h5 className="fw_500">To Firm:</h5>
					<p>{item.to_firm}</p>
				</div>
				<div className="referal_to">
					<h5 className="fw_500">Referred To:</h5>
					<p>{item.referred_to}</p>
				</div>
				<div className="referal_to">
					<h5 className="fw_500">Referred Date:</h5>
					<p>{date}</p>
				</div>
				<div className="actions">
					<h5 className="fw_500">Action</h5>
					{refetch ? (
						<div style={{ display: "flex" }}>
							<button
								type="button"
								className="icon_blk"
								style={{
									cursor: "pointer",
									border: "none",
									background: "transparent",
								}}
								onClick={handleReferralModal}
							>
								<VisibilityIcon
									sx={{
										color: "grey",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
							<button
								type="button"
								className="icon_blk"
								style={{
									cursor: "pointer",
									border: "none",
									background: "transparent",
								}}
								onClick={handleDeleteReferal}
							>
								<DeleteIcon
									sx={{
										color: "red",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</div>
					) : (
						<div
							className="referral_info"
							onClick={handleReferralModal}
						>
							{item.action ? item.action : "Referral Info"}
						</div>
					)}
				</div>
			</div>
			<ReferralInfoModal
				active={referralModal}
				setActive={handleSetActive}
				item={item}
			/>
			<DeleteConfirmation
				message={"Are you sure you want to delete this Referal ?"}
				showModal={deleteReferralModal}
				handleDelete={handleDeleteReferralModal}
				hideModal={handleDeleteReferal}
			/>
		</>
	);
};

export default ReferalCard;
