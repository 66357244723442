import { IClient } from "../../../../interfaces/Client";
import { IAction } from "../../../../interfaces/Common";
import { LawyerActionTypes as ActionTypes } from "../../../action/Lawyer/User";

const initialState: IClient = {
	id: undefined,
	name: undefined,
	email: undefined,
	phone: undefined,
	profile_image: undefined,
};

const clientReducer = (state = initialState, action: IAction<IClient>) => {
	switch (action.type) {
		case ActionTypes.SET_LAWYER:
			return {
				...state,
				id: action.payload?.id,
				name: action.payload?.name,
				email: action.payload?.email,
				phone: action.payload?.phone,
				profile_image: action.payload?.profile_image,
			};
		case ActionTypes.RESET_LAWYER:
			return {
				...state,
				id: undefined,
				name: undefined,
				email: undefined,
				phone: undefined,
				profile_image: undefined,
			};
		default:
			return state;
	}
};

export default clientReducer;
