import { useEffect } from "react";
import { Link } from "react-router-dom";
import { GetLoginUserData, useGetRole } from "../../redux/selectors/Common";
import { useDeleteAllNotificationsMutation } from "../../redux/services/Firm/MediaLibrary";
import useOutsideClick from "../ClickOutside/ClickOutSideHook";
import NotificationsCard from "../NotificationsCard";
import "./NotificationPopUp.scss";

const NotificationMenu = ({
	notifications,
	setDeleted,
	closeNotification,
}: any) => {
	const role = useGetRole();
	const [deleteNotification, { data }] = useDeleteAllNotificationsMutation();
	const handleClickOutside = () => {
		closeNotification();
	};
	const ref = useOutsideClick(handleClickOutside);
	let userData: any = GetLoginUserData();
	const idToDelete = userData?.lawyer?.id
		? userData?.lawyer?.id
		: userData?.client?.id
		? userData?.client?.id
		: userData?.firm?.id;

	const handleDeleteAll = () => {
		deleteNotification(idToDelete);
	};

	useEffect(() => {
		if (data?.message === "All Deleted") {
			setDeleted();
		}
	}, [data]);

	return (
		<section id="notification_popUP" ref={ref}>
			<div className="popup_notification">
				<div className="notification_container">
					<span className="triangle"></span>
					<div className="notification_text">
						<Link
							className="view_all"
							to={`/${role}/notifications`}
						>
							View all
						</Link>
						<p className="notification_heading">
							Notifications{" "}
							<p
								style={{
									color: "white",
								}}
							>
								{notifications.length
									? notifications.length
									: 0}
							</p>
						</p>
						<p className="mark_all" onClick={handleDeleteAll}>
							Clear all
						</p>
					</div>
				</div>
				{notifications && Object.keys(notifications).length !== 0 ? (
					<div className="scroll">
						{notifications.map((item: any) => {
							return (
								<NotificationsCard
									key={item?.id}
									item={item}
									setDeleted={setDeleted}
									setCloseNotification={closeNotification}
								/>
							);
						})}
					</div>
				) : (
					<div className="no_notification">
						No Notification to show
					</div>
				)}
			</div>
		</section>
	);
};
export default NotificationMenu;
