import appStore from "../../../assets/images/downloadAppStore.png";
import googlePlay from "../../../assets/images/downloadGooglePlay.png";
import globalEmpl from "../../../assets/images/globalEmpl.png";
import mobile from "../../../assets/images/mobile.png";
import submitImage from "../../../assets/images/submitImage.png";
import Footer from "../../../components/Footer";
import Header from "../../../components/Layout/PublicHeader";
import TopBar from "../../../components/Layout/TopBar";
import PodCastCard from "../../../components/PodCastCard";

const Home = () => {
	return (
		<>
			<section id="home">
				<TopBar />
				<Header />

				<div className="main">
					<div className="feature_img">
						<div className="content_blk">
							<div className="heading">
								Global HR Legal Solutions
							</div>
							<div className="text">
								The Employment Law Alliance is a comprehensive
								global network of local labor, employment and
								immigration attorneys that delivers timely
								solutions to employment law matters wherever
								business takes place. HR executives and
								Corporate Counsel can access exceptional legal
								service from a trusted law firm network with our
								Find a Lawyer tool below. Use the drop down menu
								to select a single or multiple jurisdictions to
								begin your search.
							</div>
						</div>
					</div>
					{/* <div className="content">
						<div className="handbook_containe">
							<div className="handbook_text_container flex_1">
								<div className="heading">
									Global Employer Handbook
								</div>
								<div className="text">
									The ELA's Global Employer Handbook is an
									exclusive online resource that provides
									critical information on labor, employment
									and immigration law in 170+ jurisdictions
									worldwide.
								</div>
								<div className="access_button">Get Access Now</div>
							</div>
							<div className="flex_1">
								<img
									className="image"
									src={globalEmpl}
									alt="global employer handbook"
								/>
							</div>
						</div>
						<div className="video_section">
							<iframe
								style={{width: "100%", height: "60rem"}}
								src="https://player.vimeo.com/video/516349770?autoplay=1&loop=1&autopause=0&muted=1"
								allow="autoplay"
								title="Global labor and employment lawyer network for HR professionals and Corporate Counsel video"
							></iframe>
						</div>
						<div className="download_app">
							<div className="flex_1">
								<img className="image" src={mobile} alt="mobile" />
							</div>
							<div className="download_buttons flex_1">
								<div className="heading">Get ELA App</div>
								<div className="text">
									90% of lawyer firms are ranked in Chambers
									and Partners and other prestigious
									organizations.
								</div>
								<div className="download_links">
									<img
										className="image"
										src={appStore}
										alt="Download from App Store"
									/>
									<img
										className="image"
										src={googlePlay}
										alt="Download from Google Play"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="feedback">
						<div className="submit_question">
							<div className="flex_1">
								<img
									className="image"
									src={submitImage}
									alt="submit"
								/>
							</div>
							<div className="submit_text flex_1">
								<div className=" heading">
									Submit Your Question
								</div>
								<div className="selection_section">
									<div className="block">
										<div className="flex_1 text">
											My company is located in
										</div>
										<div className="flex_1">
											<select className="select_input">
												<option>Select Location</option>
												<option>Two</option>
												<option>Three</option>
											</select>
										</div>
									</div>
									<div className="block">
										<div className="flex_1 text">
											I need information regarding
										</div>
										<div className="flex_1">
											<select className="select_input">
												<option>Select Location</option>
												<option>Two</option>
												<option>Three</option>
											</select>
										</div>
									</div>
								</div>
								<div className="submit_button">Submit Now</div>
							</div>
						</div>
						<div className="podcast">
							<div className="heading">Podcasts</div>
							<div className="cards">
								<div className="card_container">
									<PodCastCard />
								</div>
								<div className="card_container">
									<PodCastCard />
								</div>
								<div className="card_container">
									<PodCastCard />
								</div>
							</div>
						</div>
					</div> */}
				</div>
				<Footer />
			</section>
		</>
	);
};

export default Home;
