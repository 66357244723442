import React, { FC } from 'react';
interface InputProps {
	name?: string;
	placeholder: string;
	type: string;
	readOnly?: boolean;
	className?: string;
	handleChange?: any;
	handleBlur?: any;
	value?: string;
	onKeyPress?: any;
	style?: any;
	disabled?: boolean;
	onClick?: any;
	onKeyDown?: any;
}

const TextInput: FC<InputProps> = ({
	placeholder,
	type,
	className,
	handleChange,
	readOnly,
	handleBlur,
	name,
	value,
	onKeyPress,
	style,
	disabled,
	onClick,
	onKeyDown,
}) => {
	return (
		<input
			type={type}
			readOnly={readOnly}
			className={className ? className : 'input'}
			placeholder={placeholder}
			onChange={handleChange}
			onBlur={handleBlur}
			name={name}
			value={value}
			onKeyPress={onKeyPress}
			style={style}
			disabled={disabled}
			onClick={onClick}
			onKeyDown={onKeyDown}
		/>
	);
};
export default TextInput;
