import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import NoImage from "../../assets/images/noImage.png";
import { IFirmLawyers } from "../../interfaces/Admin";
import { useUpdateFirmLawyerMutation } from "../../redux/services/Firm/FirmLawyer";
import LawyerInfo from "../Popup/LawyerInfo";
import Image from "material-ui-image";

type Props = {
	item: IFirmLawyers;
};

const ManageLawyersCard: React.FC<Props> = ({ item }) => {
	const location = useLocation();
	const lawyerManage = location.state;
	const [lawyerInfo, setLawyerInfo] = useState(false);
	const [isActive, setIsActive] = useState(item.is_active);
	const [isVisible, setIsVisible] = useState(item.is_visible);
	const [updateFirmLawyer, { data, isSuccess, isError }] =
		useUpdateFirmLawyerMutation();

	const handleLawyerInfo = () => {
		setLawyerInfo(!lawyerInfo);
	};

	const handlerVisibleChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsVisible(event.target.checked);
		updateFirmLawyer({
			id: item.id,
			isActive,
			isVisible: event.target.checked,
		});
	};

	const handlerActiveChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsActive(event.target.checked);
		updateFirmLawyer({
			id: item.id,
			isActive: event.target.checked,
			isVisible,
		});
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success("Lawyer Status Updated");
		}
		if (isError) {
			toast.error("Lawyer Status Not Updated");
		}
	}, [isSuccess, isError, data]);

	return (
		<>
			{lawyerManage ? (
				<div className="lawyer_card lawyer_firm">
					<div className="profile_blk">
						<div className="img_blk">
							<Image src={item.profile_image || NoImage} alt="" />
						</div>
						<div className="text_blk">
							<h5 className="fs_5 fw_500">{item.name}</h5>
							<p className="fw_400">{item.member_type}</p>
						</div>
					</div>

					<div className="phone">
						<h5 className="fs_5 fw_500">Phone no</h5>
						<p className="fw_400">{item.phone}</p>
					</div>
					<div className="phone_email">
						<div className="email">
							<h5 className="fs_5 fw_500">Email</h5>
							<p className="fw_400">{item.email}</p>
						</div>
					</div>
				</div>
			) : (
				<div className="lawyer_card">
					<div className="profile_blk">
						<div className="img_blk">
							<img
								src={item.profile_image || NoImage}
								alt=""
								loading="lazy"
							/>
						</div>
						<div className="text_blk">
							<h5 className="fs_5 fw_500">{item.name}</h5>
							<p className="fw_400">{item.member_type}</p>
						</div>
					</div>

					<div className="phone_email">
						<div className="phone">
							<h5 className="fs_5 fw_500">Phone no</h5>
							<p className="fw_400">{item.phone}</p>
						</div>
						<div className="email">
							<h5 className="fs_5 fw_500">Email</h5>
							<p className="fw_400">{item.email}</p>
						</div>
					</div>

					<div className="mob_toggle_blk">
						<div className="toggle1">
							<div className="switch switch2">
								<input
									type="checkbox"
									name=""
									defaultChecked={isVisible}
									onChange={handlerVisibleChange}
								/>
								<em></em>
								<span></span>
							</div>
						</div>
						<div className="toggle2">
							<div className="switch">
								<input
									type="checkbox"
									name=""
									defaultChecked={isActive}
									onChange={handlerActiveChange}
								/>
								<em></em>
								<span></span>
							</div>
						</div>
					</div>
					<div className="pop_btn" onClick={handleLawyerInfo}></div>
				</div>
			)}
			{lawyerInfo && (
				<LawyerInfo
					active={lawyerInfo}
					setActive={handleLawyerInfo}
					lawyerId={item.id}
				/>
			)}
		</>
	);
};

export default ManageLawyersCard;
