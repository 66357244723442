import { combineReducers } from "redux";
import { RESET } from "../action/resetReduxStore";
import { authApiSlice } from "../services/authApiSlice";
import { noAuthApiSlice } from "../services/noAuthApiSlice";

//latest implementation
import adminReducer from "./Admin";
import authReducer from "./Auth";
import clientsReducer from "./Client";
import lawyersReducer from "./Lawyer";

export const appReducers = combineReducers({
	[noAuthApiSlice.reducerPath]: noAuthApiSlice.reducer,
	[authApiSlice.reducerPath]: authApiSlice.reducer,
	adminReducer,
	lawyersReducer,
	clientsReducer,
	authReducer,
});


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: any) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === RESET) {
		localStorage.removeItem("persist:root");
		localStorage.removeItem("token");
		state = undefined;
	}

	return appReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
