import { useEffect, useState, useRef } from "react";
import chat from "../../assets/images/chat.png";
import wa from "../../assets/images/whatsapp.png";
import wa2 from "../../assets/images/wechat.png";
import { ArrowLeft } from "../../icons";
import TextInput from "../TextInput";
import { Button } from "react-bootstrap";
import "./floatingChat.scss";
import ContactForm from "../MuiForm";
import SendIcon from "@mui/icons-material/Send";

const responses = [
	{ index: 0, text: "Welcome! How can I assist you today?" },
	{
		index: 1,
		text: "Please share your query, we will be happy to assist you!",
	},
];

export default function FloatingChatBox({
	active,
	setActive,
	lawyer_contact,
	lawyer_name,
}: any) {
	const [messages, setMessages] = useState<string[]>([]);
	const [inputText, setInputText] = useState("");
	const chatBoxRef = useRef<HTMLDivElement | null>(null);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputText(event.target.value);
	};

	const handleSendMessage = () => {
		if (inputText.trim() === "") {
			return; // Don't send empty messages
		}

		setMessages([...messages, inputText]);
		setInputText("");
	};

	// Use useEffect to scroll to the bottom when messages change
	useEffect(() => {
		if (chatBoxRef.current) {
			chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
		}
	}, [messages]);

	return (
		<>
			<section
				id='contactInfo_popUp'
				className={active ? "popup active" : "popup"}
				x-placement='bottom-start'
			>
				<div className='overlay' onClick={setActive}></div>
				<div className='table_dv'>
					<div className='table_cell'>
						<div className='contain'>
							<div className='row justify-content-end'>
								<div style={{ width: "30%" }}>
									<div
										className='_innerChat'
										style={{
											bottom: "0%",
											right: "15%",
										}}
									>
										<button
											type='button'
											className='back_btn'
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<h4 className='text-center mb-5 mt-5'>
											Chat with support
										</h4>

										<div
											className='_innerChatBoxDiv'
											ref={chatBoxRef}
										>
											{messages.length === 0 ? (
												<p className='text-center text-muted  mt-5 '>
													How can i help you?
												</p>
											) : (
												" "
											)}
											{messages.map((message, index) => (
												<div key={index}>
													<div className='text-left mb-4 mt-4 leftMessage'>
														{message}
													</div>
													<div className='text-end mb-5 mt-5'>
														{index === 2 ? (
															<ContactForm />
														) : (
															responses[index]
																?.text
														)}
													</div>
												</div>
											))}
										</div>

										<div style={{ display: "flex" }}>
											<TextInput
												placeholder='Type the message...'
												type='text'
												name='text'
												handleChange={handleInputChange}
												value={inputText}
												style={{
													height: "5.5rem",
													borderRadius:
														" 5px 0px 0px 5px",
												}}
												onKeyDown={(e: any) => {
													if (e.key === "Enter") {
														e.preventDefault();
														handleSendMessage();
													}
												}}
											/>
											<Button
												type='button'
												variant='primary'
												style={{
													paddingLeft: "2rem",
													paddingRight: "2rem",
													width: "20%",
													borderRadius:
														" 0px 5px 5px 0px",
												}}
												onClick={handleSendMessage}
											>
												<SendIcon
													style={{
														width: "2rem",
														height: "2rem",
													}}
												/>
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
