import { useEffect, useRef, useState } from "react";
import filters from "../../../assets/images/filters.svg";
import search from "../../../assets/images/search.svg";
import Layout from "../../../components/Layout";
import PaginatedItems from "../../../components/Paginator";
import DirectoryFilter from "../../../components/Popup/DirectoryFilter/index";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import {
	useGetClientDirectoryQuery,
	useGetClientSearchDirectoryMutation
} from "../../../redux/services/Client/ClientDashboard";

const Directory = () => {
	const { data, isLoading } = useGetClientDirectoryQuery(null);
	const [searching, { data: searchData, isLoading: searchLoading }] =
		useGetClientSearchDirectoryMutation();

	const [searchingResources, { data: filterData, isLoading: filterLoading }] =
		useGetClientSearchDirectoryMutation();

	const [lawyer, setLawyer] = useState<any | null>(null);
	const [text, setText] = useState<any | null>("");
	const [directory, setDirectory] = useState(false);
	const handleDirectory = () => {
		setDirectory(!directory);
	};
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searching({ search });
			} else {
				setLawyer(data?.lawyers);
			}
		}, 1000);
	};

	useEffect(() => {
		if (filterData) {
			setLawyer(filterData?.lawyers?.data);
		}
	}, [filterData]);

	useEffect(() => {
		if (searchData?.lawyers) {
			setLawyer(searchData?.lawyers?.data);
		} else {
			setLawyer(data?.lawyers);
		}
	}, [searchData,data]);

	const handlerSearchResources = (data: any) => {
		searchingResources({ ...data, member_type: data?.member_type[0] });
		handleDirectory();
	};

	if (filterLoading || searchLoading) {
		return <ScreenLoader show={true} />;
	}
	return (
		<Layout>
			<section id="directory" className="sec_pad_btm">
				<div className="contain sm">
					<div className="Top_blk">
						<TopBlock title="Directory" />
						<div className="left_blk">
							<div className="search_blk">
								<div className="img_blk">
									<img src={search} alt="" />
								</div>
								<input
									type="search"
									placeholder="Search"
									value={text}
									onChange={(e) =>
										handleSearch(e.target.value)
									}
								/>
							</div>
							<div
								className="filter_blk pop_btn"
								onClick={handleDirectory}
							>
								<img src={filters} alt="" />
							</div>
						</div>
					</div>

					{isLoading && <ScreenLoader show={true} />}
					{lawyer && lawyer.length > 0 ? (
						<PaginatedItems
							itemsPerPage={10}
							items={lawyer}
							directory={true}
						/>
					) : (
						!isLoading && !searchLoading && !filterLoading && <div>
							<h3>No Records Found</h3>
						</div>
					)}
				</div>
			</section>
			<DirectoryFilter
				active={directory}
				setActive={handleDirectory}
				handlerSearchResources={handlerSearchResources}
			/>
		</Layout>
	);
};

export default Directory;
