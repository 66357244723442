import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { FormikState, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import NoImage from "../../assets/images/noImage.png";
import { ArrowLeft } from "../../icons";
import { IAddLawyer } from "../../interfaces/Admin";
import {
	useAddFirmLawyerMutation,
	useGenerateFirmCodeMutation,
} from "../../redux/services/Firm/FirmLawyer";
import { useAppSelector } from "../../redux/store";
import { addLawyerSchema } from "../../utills/YupValidationSchemas";
import ErrorText from "../Shared/ErrorText";
import TextInput from "../TextInput";
import Image from "material-ui-image";

export default function AddLawyers({ active, setActive }: any) {
	const userInfo = useAppSelector((state: any) => state.authReducer.data);
	const fileInput = useRef<HTMLInputElement>(null);
	const firmId = userInfo?.firm?.id || userInfo?.customer?.id;
	const [
		generateCode,
		{ data: generateCodeData, isSuccess: generateCodeSuccess },
	] = useGenerateFirmCodeMutation();
	const [addFirmLawyer, { data: addFirmLawyerData }] =
		useAddFirmLawyerMutation();
	const [formImage, setFormImage] = useState<string>("");
	const [dp, setDp] = useState<string>("");
	const [formImageError, setFormImageError] = useState<string>("");

	const handleAddLawyer = (
		values: IAddLawyer,
		resetForm: {
			(nextState?: Partial<FormikState<IAddLawyer>> | undefined): void;
			(): void;
		}
	) => {
		addFirmLawyer({ ...values, profile_image: formImage });
		setDp(NoImage);
		resetForm();
		handleClose();
	};

	const handleClose = () => {
		setActive();
	};

	useEffect(() => {
		if (addFirmLawyerData?.message === "Invitation created") {
			toast.success(addFirmLawyerData.message);
		}
	}, [addFirmLawyerData]);

	const {
		values,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		touched,
		resetForm,
	} = useFormik({
		validationSchema: addLawyerSchema,
		enableReinitialize: true,
		initialValues: {
			email: "",
			firm_code: "",
			firm_id: firmId,
			name: "",
			profile_image: "",
		},
		onSubmit: (values) => {
			handleAddLawyer(values, resetForm);
		},
	});

	useEffect(() => {
		if (generateCodeSuccess && generateCodeData) {
			setFieldValue("firm_code", generateCodeData?.code);
		}
	}, [generateCodeSuccess, generateCodeData]);

	const handleFileUpload = (e: any) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}

		const file = e.target.files[0];

		if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
			return;
		}

		setFormImage(file);
		setFormImageError("");

		const reader = new FileReader();

		reader.addEventListener(
			"load",
			(e: ProgressEvent<FileReader>) => {
				if (!e?.target?.result) {
					return;
				}
				const result = reader.result as string;
				setDp(result);
			},
			false
		);

		reader.readAsDataURL(file);
	};

	return (
		<section
			id="add_lawyer_popup"
			className={active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="_inner">
									<button
										type="button"
										className="back_btn"
										onClick={setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">Add Lawyer</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();
											if (formImage) {
												handleSubmit();
											} else {
												setFormImageError(
													"Image is Required"
												);
											}
										}}
									>
										<div className="ico fill round dp dp-image">
											<Image src={dp ? dp : NoImage} />
											<div
												className="edit-icon"
												onClick={() =>
													fileInput?.current?.click()
												}
											>
												<CameraAltIcon className="dp-icon" />
											</div>
											<input
												ref={fileInput}
												type="file"
												hidden
												onChange={handleFileUpload}
											/>
										</div>
										<div className="text-center">
											<h5
												style={{
													color: "red",
												}}
											>
												{formImageError}
											</h5>
										</div>
										<div className="row">
											<div className="col-12">
												<h5 className="fw_500 require">
													Name
												</h5>
												<div className="form_blk">
													<TextInput
														placeholder="eg: John Wick"
														type="text"
														name="name"
														handleChange={
															handleChange
														}
														handleBlur={handleBlur}
														value={values.name}
													/>
												</div>
												<ErrorText
													text={errors.name}
													isTouched={touched.name}
												/>
											</div>
											<div className="col-12">
												<h5 className="fw_500 require">
													Email Address
												</h5>
												<div className="form_blk">
													<TextInput
														placeholder="eg: sample@gmail.com"
														type="email"
														name="email"
														handleChange={
															handleChange
														}
														handleBlur={handleBlur}
														value={values.email}
													/>
												</div>
												<ErrorText
													text={errors.email}
													isTouched={touched.email}
												/>
											</div>
											<div className="col-12">
												<h5 className="fw_500 require">
													Firm Code
												</h5>
												<div className="row">
													<div className="col-6">
														<div className="form_blk">
															<TextInput
																placeholder="eg: DXR6"
																name="firm_code"
																type="text"
																handleChange={
																	handleChange
																}
																handleBlur={
																	handleBlur
																}
																value={
																	values.firm_code
																}
															/>
														</div>
														<ErrorText
															text={
																errors.firm_code
															}
															isTouched={
																touched.firm_code
															}
														/>
													</div>
													<div className="col-6">
														<div className="btn_blk">
															<button
																type="button"
																className="site_btn w-100"
																onClick={
																	generateCode
																}
															>
																Generate
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="btn_blk mt-5 pt-3">
											<button
												className="site_btn w-100"
												type="submit"
											>
												Add Lawyer
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
