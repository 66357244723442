import { Formik } from "formik";
import { Button } from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import TextInput from "../../../components/TextInput";
import { LogoIcon } from "../../../icons";
import ErrorText from "../../../components/Shared/ErrorText";

const DeleteAccount = () => {
	const handleSubmit = (values: any) => {
		axios
			.post("https://elalaw.app/delete_account", values)
			.then(function () {
				toast.success("Your request has been successfully submitted!");
			})
			.catch(function (error) {
				toast.error("An error has been occurred!");
			});
	};

	return (
		<section>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<div
					style={{
						background: "#fff",
						width: "40%",
						marginTop: "120px",
						padding: "50px",
					}}
				>
					<Formik
						initialValues={{ email: "" }}
						onSubmit={(values) => handleSubmit(values)}
						validationSchema={Yup.object().shape({
							email: Yup.string()
								.email()
								.required("Email is a required field"),
						})}
					>
						{({
							values,
							handleSubmit,
							handleChange,
							errors,
							touched,
						}) => {
							return (
								<form onSubmit={handleSubmit}>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<div
											style={{
												margin: "auto",
												fontSize: "20px",
											}}
										>
											ELA - Employment Law Alliance®
										</div>
										<div
											style={{
												height: "90px",
												width: "90px",
												margin: "auto",
											}}
										>
											<LogoIcon />
										</div>
										<TextInput
											name="email"
											placeholder="Please enter your email"
											type="email"
											handleChange={handleChange}
											value={values.email}
											style={{
												marginBottom: "10px",
												marginTop: "5px",
											}}
										/>
										<ErrorText
											text={errors.email}
											isTouched={touched.email}
										/>
										<Button
											sx={{
												fontSize: "14px",
												width: "30%",
												margin: "auto",
											}}
											size="large"
											variant="contained"
											type="submit"
										>
											Submit
										</Button>
									</div>
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
		</section>
	);
};

export default DeleteAccount;
