import * as Yup from "yup";
import { emailRegExp, phoneRegExp, name } from "../common";

export const loginSchema = Yup.object().shape({
	userType: Yup.string().required("Select User is a required field"),
	email: Yup.string()
		.matches(emailRegExp, "Invalid email format!")
		.required("Email is a required field"),
	password: Yup.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is a required field"),
});

export const referralMessageSchema = Yup.object().shape({
	message: Yup.string().required("Referral information is required"),
});

export const lawyersFilterSchema = Yup.object().shape({
	jurisdiction: Yup.array()
		.min(1, "Please select atleast one jurisdiction")
		.required("required"),
	member_type: Yup.array().max(1, "You cannot select more than one"),
	alphabet: Yup.string(),
});

export const resourcesFilterSchema = Yup.object().shape({
	jurisdiction: Yup.array()
		.min(1, "Please select atleast one Jurisdiction")
		.required("required")
});

export const signUpSchema = Yup.object().shape({
	userType: Yup.string().required("Select User is a required field"),
	email: Yup.string()
		.matches(emailRegExp, "Invalid email format!")
		.required("Email is a required field"),
	phone: Yup.string()
		.matches(phoneRegExp, "Phone number is not valid")
		.required("Phone No is required"),
	password: Yup.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	password_confirmation: Yup.string().oneOf(
		[Yup.ref("password"), null],
		"Passwords must match"
	),
	firm_code: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Firm Code is required"),
	}),
	name: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string()
			.matches(name, "Please enter valid Name")
			.required("Name is required"),
	}),
	member_type: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Member Type is required"),
	}),
	jurisdiction: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Jurisdiction is required"),
	}),
});
export const addFirmLawyerSchema = Yup.object().shape({
	email: Yup.string()
		.matches(emailRegExp, "Invalid email format!")
		.required("Email is a required field"),
	phone: Yup.string()
		.matches(phoneRegExp, "Phone number is not valid")
		.required("Phone No is required"),
	password: Yup.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	password_confirmation: Yup.string().oneOf(
		[Yup.ref("password"), null],
		"Passwords must match"
	),
	firm_code: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Firm Code is required"),
	}),
	name: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string()
			.matches(name, "Please enter valid Name")
			.required("Name is required"),
	}),
	member_type: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Member Type is required"),
	}),
	jurisdiction: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Jurisdiction is required"),
	}),
});
export const addContactSchema = Yup.object().shape({
	email: Yup.string()
		.matches(emailRegExp, "Invalid email format!")
		.required("Email is a required field"),
	phone: Yup.string()
		.matches(phoneRegExp, "Phone number is not valid")
		.required("Phone No is required"),
	name: Yup.string()
		.matches(name, "Please enter valid Name")
		.required("Name is required"),
	// lawyer_id: Yup.number().when("userType", {
	// 	is: (userType) => userType !== "lawyer",
	// 	then: Yup.number().required("Please select lawyer name"),
	// })
		
});
export const updateFirmLawyerSchema = Yup.object().shape({
	email: Yup.string()
		.matches(emailRegExp, "Invalid email format!")
		.required("Email is a required field"),
	phone: Yup.string()
		.matches(phoneRegExp, "Phone number is not valid")
		.required("Phone No is required"),
	firm_code: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Firm Code is required"),
	}),
	name: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string()
			.matches(name, "Please enter valid Name")
			.required("Name is required"),
	}),
	member_type: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Member Type is required"),
	}),
	jurisdiction: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Jurisdiction is required"),
	}),
});

export const addLawyerSchema = Yup.object().shape({
	name: Yup.string()
		.matches(name, "Please enter valid Name")
		.required("Name is required"),
	email: Yup.string()
		.matches(emailRegExp, "Invalid email format!")
		.required("Email is a required field"),
	firm_id: Yup.string().required("Firm Id is required"),
	firm_code: Yup.string().required("Firm Id is required"),
});

export const resetPassSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	password_confirmation: Yup.string().oneOf(
		[Yup.ref("password"), null],
		"Passwords must match"
	),
});

export const settingSchema = Yup.object().shape({
	email: Yup.string()
		.matches(emailRegExp, "Invalid email format!")
		.required("Email is a required field"),
	phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
	about: Yup.string().required("About is a required field"),
	firmInfo: Yup.string().required("FirmInfo is a required field"),
});

export const addPodcastSchema = Yup.object().shape({
	name: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string()
			.matches(name, "Please enter valid Name")
			.required("Name is required"),
	}),
	location: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Member Type is required"),
	}),
	category: Yup.string().when("userType", {
		is: (userType) => userType === "lawyer",
		then: Yup.string().required("Jurisdiction is required"),
	}),
});