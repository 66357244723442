import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.6799 9.98322C18.7008 10.114 18.7204 10.245 18.7388 10.3761C18.7563 10.501 18.8492 10.6014 18.9704 10.6363C19.3282 10.7393 19.6625 10.8979 19.9633 11.1021C20.1138 11.2043 20.3347 11.0911 20.3155 10.9102C20.2744 10.5215 20.2229 10.1335 20.1612 9.74679L20.0971 9.34535C19.8912 8.05533 18.7785 7.10612 17.4721 7.10612L9.15768 7.10612C8.95216 6.04846 8.0209 5.25 6.90314 5.25H4.61158C3.25529 5.25 2.11289 6.26343 1.95118 7.61004L1.6787 9.87915C1.38904 12.2913 1.46379 14.7333 1.90046 17.1232C2.11598 18.3027 3.10248 19.1869 4.29851 19.2725L5.81252 19.3808C7.99325 19.5368 10.1792 19.5943 12.3639 19.5533C12.5027 19.5507 12.6203 19.4524 12.6567 19.3183C12.7479 18.9821 12.8772 18.6615 13.0395 18.3615C13.1166 18.219 13.014 18.0378 12.8521 18.042C10.5407 18.1022 8.22711 18.0497 5.91956 17.8846L4.40555 17.7763C3.89208 17.7396 3.46856 17.36 3.37603 16.8536C2.9664 14.6116 2.89627 12.3208 3.168 10.058L3.44048 7.78888C3.51167 7.19611 4.01455 6.75 4.61158 6.75H6.90314C7.34319 6.75 7.69991 7.10672 7.69991 7.54677C7.69991 8.13183 8.1742 8.60612 8.75926 8.60612H17.4721C18.0413 8.60612 18.5261 9.0197 18.6159 9.58178L18.6799 9.98322Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.9999 12C16.8953 12 15.9999 12.8954 15.9999 14C15.9999 15.1046 16.8953 16 17.9999 16C19.1045 16 19.9999 15.1046 19.9999 14C19.9999 12.8954 19.1045 12 17.9999 12Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.9999 20.5C13.9999 18.8431 15.3431 17.5 16.9999 17.5H18.9999C20.6568 17.5 21.9999 18.8431 21.9999 20.5C21.9999 21.0523 21.5522 21.5 20.9999 21.5H14.9999C14.4476 21.5 13.9999 21.0523 13.9999 20.5Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent
