import { useEffect } from "react";
import { Link } from "react-router-dom";
import icon1 from "../../../assets/images/icon2.svg";
import icon2 from "../../../assets/images/icon4.svg";
import icon3 from "../../../assets/images/lawyerIcon3.svg";
import icon4 from "../../../assets/images/lawyerIcon4.svg";
import icon5 from "../../../assets/images/icon5.svg";
import Layout from "../../../components/Layout";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import { setDashboardData } from "../../../redux/action/Lawyer/Dashboard";
import { useGetLawyerData } from "../../../redux/selectors/Lawyer";
import { useGetLawyerDashboardDataQuery } from "../../../redux/services/Lawyer/DashboardApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import NoImage from "../../../assets/images/noImage.png";
import Image from "material-ui-image";
import FloatingChat from "../../../components/FloatingChat";

const Dashboard = () => {
	const { data, isLoading } = useGetLawyerDashboardDataQuery(null, {
		refetchOnMountOrArgChange: true,
	});
	const userInfo = useAppSelector((state: any) => state.authReducer.data);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (data) {
			const lawyerDashboardData = {
				firm_image: data?.firm_image,
				firm_name: data?.firm_name,
				member_type: data?.member_type,
				incoming_referral: data?.incoming_referel,
				outgoing_referral: data?.outgoing_referel,
			};
			dispatch(setDashboardData(lawyerDashboardData));
		}
	}, [data]);

	if (isLoading) {
		return <ScreenLoader show={true} />;
	}
	return (
		<>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<div
					style={{
						position: "fixed",
						bottom: "10%",
						right: "2%",
						zIndex: 20,
					}}
				>
					<FloatingChat />
					<strong>Contact Us</strong>
				</div>
				<Layout>
					<section id='dashboard' className='sec_pad_sec'>
						<div className='lawyer_board_content'>
							<div className='contain sm'>
								<div className='top_blk'>
									<div className='img_blk'>
										<Image
											animationDuration={1000}
											src={
												data?.firm_image
													? data?.firm_image
													: NoImage
											}
											alt='Image Container'
											style={{
												height: "120px",
												width: "120px",
												borderRadius: "120px",
											}}
										/>
									</div>
									<div className='text_blk'>
										<p>{data?.firm_name}</p>
										<p>
											Jurisdiction:{" "}
											<span className='notBold'>
												{userInfo?.lawyer?.jurisdiction}
											</span>
										</p>
										<p>
											Firm:{" "}
											<span className='notBold'>
												{userInfo?.firm_name}
											</span>
										</p>
										{data?.referral_email && (
											<p>
												Referral Email:{" "}
												<span className='notBold'>
													{data?.referral_email}
												</span>
											</p>
										)}
									</div>
								</div>
								<div className='row'>
									<div className='col-md-6 col-sm-12'>
										<Link
											to='/lawyer/referals'
											className='img_blk'
										>
											<div className='dashboard_card'>
												<div className='img_blk'>
													<img
														src={icon1}
														alt='icon 2'
													/>
												</div>
												<p className='fw_500'>
													Incoming Referrals:
													<strong className='color'>
														{data?.incoming_referel}
													</strong>
												</p>
												<p className='fw_500'>
													Outgoing Referrals:
													<strong className='color'>
														{data?.outgoing_referel}
													</strong>
												</p>
											</div>
										</Link>
									</div>
									<div className='col-md-6 col-sm-12'>
										<Link to='/lawyer/resources'>
											<div className='dashboard_card'>
												<div className='img_blk'>
													<img
														src={icon2}
														alt='icon 4'
													/>
												</div>
												<p className='fw_500'>
													GEH & Resources / Media
													Library
												</p>
											</div>
										</Link>
									</div>
									<div className='col-md-6 col-sm-12'>
										<Link to='/lawyer/lawyers'>
											<div className='dashboard_card'>
												<div className='img_blk'>
													<img
														src={icon3}
														alt='icon 3'
													/>
												</div>
												<p className='fw_500'>
													ELA Lawyer Directory
												</p>
											</div>
										</Link>
									</div>
									<div className='col-md-6 col-sm-12'>
										<Link to='/lawyer/settings'>
											<div className='dashboard_card'>
												<div className='img_blk'>
													<img
														src={icon4}
														alt='icon 4'
													/>
												</div>
												<p className='fw_500'>
													Settings
												</p>
											</div>
										</Link>
									</div>
									<div className='col-md-6 col-sm-12'>
										<Link to='/lawyer/contacts'>
											<div className='dashboard_card'>
												<div className='img_blk'>
													<img
														src={icon1}
														alt='icon 4'
													/>
												</div>
												<p className='fw_500'>
													My Contacts
												</p>
											</div>
										</Link>
									</div>
									<div className='col-md-6 col-sm-12'>
										<Link to='/lawyer/chat'>
											<div className='dashboard_card'>
												<div className='img_blk'>
													<img
														src={icon5}
														alt='icon 5'
													/>
												</div>
												<p className='fw_500'>Chat</p>
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default Dashboard;
