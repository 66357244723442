import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import NoImage from "../../../assets/images/noImage.png";
import { ArrowLeft, Lock } from "../../../icons";
import { IClient } from "../../../interfaces/Client";
import { useCreateNewClientMutation, useCreateNewFirmMutation } from "../../../redux/services/Firm/FirmLawyer";
import { addFirmLawyerSchema } from "../../../utills/YupValidationSchemas";
import ErrorText from "../../Shared/ErrorText";
import ScreenLoader from "../../Shared/ScreenLoader";
import TextInput from "../../TextInput";
import "./AddNewClient.scss";


interface AddFirmLawyer {
    active: boolean,
    setActive: any,
    client?: IClient,
    isUpdate: boolean,
    setUpdateRefetch?: any,
}

export default function AddNewClient(props: AddFirmLawyer) {

    const fileInput = useRef<HTMLInputElement>(null);
    const [addClient, { data: addNewClient, isSuccess, isError, isLoading }] =
        useCreateNewClientMutation();

    const [formImage, setFormImage] = useState<string>("");
    const [dp, setDp] = useState<any>(props?.isUpdate ? props?.client?.profile_image : '');
    const [formImageError, setFormImageError] = useState<string>("");

    const handleAddFirm = (values: any) => {
        if (props.isUpdate) {
            addClient({ ...values, client_id: props?.client?.id, profile_image: formImage })
        } else {
            addClient({ ...values, profile_image: formImage });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            setDp(NoImage);
            resetForm();
            handleClose();
            if (isSuccess && props.isUpdate) {
                toast.success('Client Updated Successfully')
                props.setUpdateRefetch(true)
            } else if (isSuccess && !props.isUpdate) {
                toast.success('Client created Successfully')
            } else {
                toast.success(addNewClient?.message)
            }
        }
        else if (isError && !props.isUpdate) {
            toast.error('Error! Client not created Successfully, Please try Again')
        } else if (isError && props.isUpdate) {
            toast.error('Error! Could not update the client at the moment, Please try Again')

        }
    }, [isSuccess, isError])

    const handleClose = () => {
        props.setActive();
    };


    const initialValues = {
        email: props?.client?.email ? props.client.email : '',
        password: "",
        name: props?.client?.name ? props?.client.name : '',
        phone: props?.client?.phone ? props?.client.phone : '',
        profile_image: props?.client?.profile_image ? props?.client?.profile_image : "",
        password_confirmation: '',
    }

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        touched,
        resetForm
    } = useFormik({
        validationSchema: addFirmLawyerSchema,
        initialValues,
        onSubmit: (values) => {
            handleAddFirm(values);
        },
    });

    const handleFileUpload = (e: any) => {
        if (!e.target.files || e.target.files.length !== 1) {
            return;
        }

        const file = e.target.files[0];

        if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
            return;
        }

        setFormImage(file);
        setFormImageError("");

        const reader = new FileReader();

        reader.addEventListener(
            "load",
            (e: ProgressEvent<FileReader>) => {
                if (!e?.target?.result) {
                    return;
                }
                const result = reader.result as string;
                setDp(result);
            },
            false
        );

        reader.readAsDataURL(file);
    };

    useEffect(() => {
        if (props?.client?.profile_image !== '' && props.isUpdate === true) {
            setFormImage(props?.client?.profile_image || '')
        }
    }, [props?.client])

    const handleHeading = () => {
        if (props.isUpdate) {
            return 'Update Client'
        } else {
            return 'Create Client'
        }
    }

    return (
        <section
            id="add_lawyer_popup"
            className={props.active ? "popup active" : "popup"}
        >
            <div className="overlay" onClick={handleClose}></div>
            <div className="table_dv">
                <div className="table_cell">
                    <div className="contain">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8 col-sm-10">
                                <div className="inner_div">
                                    <button
                                        type="button"
                                        className="back_btn"
                                        onClick={props.setActive}
                                    >
                                        <ArrowLeft />
                                    </button>
                                    <h2 className="text-center">{handleHeading()}</h2>

                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();


                                            if (formImage) {
                                                handleSubmit();
                                            } else {
                                                setFormImageError(
                                                    "Image is Required"
                                                );
                                            }
                                        }}
                                    >
                                        <div className="ico fill round dp dp-image ">
                                            <img src={props.client?.profile_image ? props.client?.profile_image : dp || NoImage} />
                                            <div
                                                className="edit-icon"
                                                onClick={() =>
                                                    fileInput?.current?.click()
                                                }
                                            >
                                                <CameraAltIcon className="dp-icon" />
                                            </div>
                                            <input
                                                ref={fileInput}
                                                type="file"
                                                hidden
                                                onChange={handleFileUpload}
                                            />
                                        </div>
                                        <div className="text-center">
                                            <h5
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                {formImageError}
                                            </h5>
                                        </div>
                                        {/* ------------------------------------------------------------------------------------------------------------------
                                        Name And Email Row
------------------------------------------------------------------------------------------------------------------ */}

                                        <div className="row" style={{ justifyContent: 'center', marginTop: 25 }}>
                                            <div className="form_blk_container">
                                                <div className="input_field_div">
                                                    <h5 className="fw_500 require">
                                                        Name
                                                    </h5>
                                                    <div className="form_blk">
                                                        <TextInput
                                                            placeholder="eg: John Wick"
                                                            type="text"
                                                            name="name"
                                                            handleChange={
                                                                handleChange
                                                            }
                                                            handleBlur={handleBlur}
                                                            value={values.name}
                                                        />
                                                    </div>
                                                    <ErrorText
                                                        text={errors.name}
                                                        isTouched={touched.name}
                                                    />
                                                </div>
                                                <div className="input_field_div">
                                                    <h5 className="fw_500 require">
                                                        Email Address
                                                    </h5>
                                                    <div className="form_blk">
                                                        <TextInput
                                                            placeholder="eg: sample@gmail.com"
                                                            type="email"
                                                            name="email"
                                                            handleChange={
                                                                handleChange
                                                            }
                                                            handleBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                    </div>
                                                    <ErrorText
                                                        text={errors.email}
                                                        isTouched={touched.email}
                                                    />
                                                </div>
                                            </div>
                                            {/* ------------------------------------------------------------------------------------------------------------------
                                        Password And Confirm Password Row
------------------------------------------------------------------------------------------------------------------ */}
                                            <div className="form_blk_container">
                                                <div className="input_field_div my-4">
                                                    <h5 className='fw_500 require  '>
                                                        Password
                                                    </h5>
                                                    <div className="form_blk">
                                                        <Lock />
                                                        <TextInput
                                                            type="password"
                                                            name="password"
                                                            handleChange={
                                                                handleChange
                                                            }
                                                            handleBlur={
                                                                handleBlur
                                                            }
                                                            value={
                                                                values.password
                                                            }
                                                            className="input"
                                                            placeholder="eg: PassLogin%7"
                                                        />
                                                    </div>
                                                    <ErrorText
                                                        text={errors.password}
                                                        isTouched={
                                                            touched.password
                                                        }
                                                    />
                                                </div>
                                                {!props?.isUpdate && <div className="input_field_div">
                                                    <h5 className='fw_500 require'>
                                                        Confirm Password
                                                    </h5>
                                                    <div className="form_blk">
                                                        <Lock />
                                                        <TextInput
                                                            type="password"
                                                            name="password_confirmation"
                                                            handleChange={
                                                                handleChange
                                                            }
                                                            handleBlur={
                                                                handleBlur
                                                            }
                                                            value={
                                                                values.password_confirmation
                                                            }
                                                            className="input"
                                                            placeholder="eg: PassLogin%7"
                                                        />
                                                    </div>
                                                    <ErrorText
                                                        text={errors.password_confirmation}
                                                        isTouched={
                                                            touched.password_confirmation
                                                        }
                                                    />
                                                </div>}
                                            </div>

                                            {/* ------------------------------------------------------------------------------------------------------------------
                                            Phone Number
------------------------------------------------------------------------------------------------------------------ */}
                                            <div className="form_blk_container">
                                                <div className="col-12">
                                                    <h5 className="fw_500 require">
                                                        Phone
                                                    </h5>
                                                    <div className="form_blk">
                                                        <PhoneInput
                                                            enableAreaCodeStretch
                                                            prefix="+"
                                                            autoFormat
                                                            country={"us"}
                                                            value={
                                                                values.phone
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    "phone",
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                        <ErrorText
                                                            text={
                                                                errors.phone
                                                            }
                                                            isTouched={
                                                                touched.phone
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn_blk mt-5 pt-3">
                                            <button
                                                className="site_btn w-100"
                                                type="submit"
                                            >
                                                {isLoading ? <ScreenLoader show={isLoading} /> : handleHeading()}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
