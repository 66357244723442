import Layout from "../../../components/Layout";
import NotificationsCard from "../../../components/NotificationsCard";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import { INotifications } from "../../../interfaces/Common";
import { useGetNotificationsQuery } from "../../../redux/services/Firm/MediaLibrary";

const Notifications = () => {
	const { data, isLoading } = useGetNotificationsQuery({
		refetchOnMountOrArgChange: true,
	});

	return (
		<Layout>
			<div id="notification" className="sec_pad_btm">
				<div className="contain sm">
					<TopBlock title="Notifications" url="dashboard" />
					{isLoading && <ScreenLoader show={true} />}
					{data?.length
						? data?.map((item: INotifications) => {
								return (
									<NotificationsCard
										item={item}
										key={item.id}
									/>
								);
						  })
						: "No Notifications"}
				</div>
			</div>
		</Layout>
	);
};

export default Notifications;
