import React, { FC } from "react";
interface ButtonProps {
  label: string;
  onClick: () => void;
  type?: any;
  className?: string;
}
const Button: FC<ButtonProps> = ({ label, onClick, type, className }) => {
  return (
    <button onClick={onClick} type={type ?? "button"} className={className ? className : ""}>
      {label}
    </button>
  );
};
export default Button;
