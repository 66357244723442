import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bell from "../../../assets/images/bell.svg";
import logo from "../../../assets/images/logo.svg";
import { useGetNotificationsQuery } from "../../../redux/services/Firm/MediaLibrary";
import NotificationMenu from "../../NotificationPopUp";
import DropdownButton from "../../ProfileDropDown/ProfileDropDown";

const Header = () => {
	const { data, isLoading, refetch } = useGetNotificationsQuery(null);
	const [deletedNotification, setDeletedNotification] = useState(false);

	const handleNotificationReload = () => {
		setDeletedNotification(!deletedNotification);
	};
	const [showNotification, setShowNotification] = useState(false);
	const handleShowNotification = () => {
		setShowNotification(!showNotification);
	};

	useEffect(() => {
		refetch();
	}, [deletedNotification]);

	const count = data?.reduce((acc, obj) => {
		if (obj.seen === false) {
			return acc + 1;
		} else {
			return acc;
		}
	}, 0);

	return (
		<>
			<div id="header">
				<div className="contain">
					<div className="header_blk">
						<Link to="/firm" className="logo_blk">
							<img src={logo} alt="Logo" />
						</Link>
						<h3 className="fw_600">My Dashboard</h3>
						<ul style={{ marginRight: "2rem" }}>
							<li>
								<div
									style={{
										display: "flex",
										flexDirection: "row-reverse",
									}}
									onClick={handleShowNotification}
								>
									<span
										style={{
											position: "absolute",
											minWidth: "20px",
											minHeight: "20px",
											borderRadius: "2rem",
											backgroundColor: "#63a744",
										}}
									>
										<p
											style={{
												textAlign: "center",
												marginTop: "2px",
												fontSize: "11px",
												fontWeight: "600",
												color: "white",
											}}
										>
											{count}
										</p>
									</span>
									<img src={bell} alt="bell icon" />
								</div>
								{showNotification && (
									<NotificationMenu
										notifications={data}
										setDeleted={handleNotificationReload}
										closeNotification={
											handleShowNotification
										}
									/>
								)}
							</li>
							<li>
								<DropdownButton />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
