import React, { FC } from "react";
import { Link } from "react-router-dom";
import iconUp from "../../assets/images/icon_up.png";
import iconDown from "../../assets/images/icon_down.png";
interface AnalyticsTileProps {
	title: string;
	icon: string;
	path: string;
	count: number;
	percentage: number;
	currentMonth: number;
	mobileRegistered?: number;
	mobileUnregistered?: number;
}
const AnalyticsTile: FC<AnalyticsTileProps> = ({
	title,
	icon,
	path,
	count,
	percentage,
	currentMonth,
	mobileRegistered,
	mobileUnregistered,
}) => {
	let isPercentageDown = percentage <= 0;
	let isNumberNegative = percentage <= 0;
	return (
		<div className="col-md-6 col-sm-12">
			<Link to={path} state={{ lawyerManage: true }}>
				<div className="dashboard_card">
					<div className="d-flex justify-content-between align-items-center">
						<div>
							<p className="fw_500 text-secondary">{title}</p>
							<p>{count}</p>
						</div>
						<div className="img_blk">
							<img src={icon} alt="icon 4" />
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-between mt-5">
						<Link
							to={path + "?this_month=true"}
							state={{ lawyerManage: true }}
						>
							<div className="d-flex flex-column">
								<div
									className={`percentage-container ${
										isNumberNegative
											? "analytics-danger"
											: "analytics-success"
									}`}
								>
									<p
										className={`percentage-container ${
											isNumberNegative
												? "analytics-text-danger"
												: "analytics-text-success"
										}`}
									>
										{currentMonth}
									</p>
									<img
										src={
											isNumberNegative ? iconDown : iconUp
										}
									/>
								</div>
								<small className="text-sm text-muted">
									This month
								</small>
							</div>
						</Link>

						<div className="d-flex flex-column align-items-end">
							<div
								className={`percentage-container ${
									isPercentageDown
										? "analytics-danger"
										: "analytics-success"
								}`}
							>
								<p
									className={`percentage-container ${
										isPercentageDown
											? "analytics-text-danger"
											: "analytics-text-success"
									}`}
								>
									{percentage}%
								</p>
								<img
									src={isPercentageDown ? iconDown : iconUp}
								/>
							</div>
							<small className="text-sm text-muted">
								Since last month
							</small>
						</div>
					</div>

					{(mobileRegistered || mobileUnregistered) && (
						<div className="d-flex align-items-center justify-content-between mt-5">
							<Link
								to={path + "?registered=true"}
								state={{ lawyerManage: true }}
							>
								<div className="d-flex flex-column">
									<div
										className={`percentage-container analytics-success`}
									>
										<p
											className={`percentage-container analytics-text-success`}
										>
											{mobileRegistered}
										</p>
									</div>
									<small className="text-sm text-muted">
										Registered on mobile
									</small>
								</div>
							</Link>
							<Link
								to={path + "?registered=false"}
								state={{ lawyerManage: true }}
							>
								<div className="d-flex flex-column align-items-end">
									<div
										className={`percentage-container analytics-danger`}
									>
										<p
											className={`percentage-container analytics-text-danger`}
										>
											{mobileUnregistered}
										</p>
									</div>
									<small className="text-sm text-muted">
										Unregistered on mobile
									</small>
								</div>
							</Link>
						</div>
					)}
				</div>
			</Link>
		</div>
	);
};
export default AnalyticsTile;
