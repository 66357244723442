import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ArrowLeft } from "../../../icons";
import { usePapaParse } from "react-papaparse";
import {
	useGetAllFirmsQuery,
	useUploadDataInBulkMutation,
	useUploadMediaDataInBulkMutation,
	useDeleteDataFromCsvMutation,
} from "../../../redux/services/Firm/FirmLawyer";
import ScreenLoader from "../../Shared/ScreenLoader";
import "../AddNewClient/AddNewClient.scss";

interface ImportFirmLawyers {
	active: boolean;
	setActive: any;
	toImport?: string;
	firm_id?: string;
	refetch?: any;
	firm?: boolean;
	toDelete?: string;
}

export default function ImportFromCSV(props: ImportFirmLawyers) {
	const { jsonToCSV } = usePapaParse();
	const { data: firms } = useGetAllFirmsQuery(null);

	const [uploadData, { data, isSuccess, isError, isLoading }] =
		useUploadDataInBulkMutation();
	const [
		deleteData,
		{
			data: deleteResult,
			isSuccess: isDeleteSuccess,
			isError: isDeleteError,
			isLoading: isDeleteLoading,
		},
	] = useDeleteDataFromCsvMutation();
	const [
		uploadMediaData,
		{
			data: uploadCsvResponse,
			isSuccess: MediaIsSuccess,
			isError: MediaIsError,
			isLoading: loadingMedia,
		},
	] = useUploadMediaDataInBulkMutation();
	const [loading, setLoading] = useState(false);
	const [csvFile, setCsvFile] = useState<File | null>(null);

	const handleClose = () => {
		props.setActive();
	};
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files?.[0];
		if (
			selectedFile &&
			(selectedFile.type === "text/csv" ||
				selectedFile.type === "application/vnd.ms-excel" ||
				selectedFile.type ===
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
		) {
			setCsvFile(selectedFile);
		} else {
			setCsvFile(null);
		}
	};
	const handleImportHeading = () => {
		if (props.toImport === "lawyers") {
			return "Import Lawyers";
		} else if (props.toImport === "firm") {
			return "Import Firms";
		} else if (props.toImport === "pdf") {
			return "Import Handbook PDF";
		} else if (props.toImport === "webinar") {
			return "Import Webinars";
		} else if (props.toImport === "podcast") {
			return "Import Podcasts";
		} else if (props.toImport === "news") {
			return "Import News";
		} else if (props.toImport === "handbook") {
			return "Import Handbook";
		} else {
			return "Import Events";
		}
	};
	const handleDeleteHeading = () => {
		if (props.toDelete === "lawyers") {
			return "Delete Lawyers";
		} else if (props.toDelete === "firm") {
			return "Delete Firms";
		} else if (props.toDelete === "pdf") {
			return "Delete Handbook PDF";
		} else if (props.toDelete === "webinar") {
			return "Delete Webinars";
		} else if (props.toDelete === "podcast") {
			return "Delete Podcasts";
		} else if (props.toDelete === "news") {
			return "Delete News";
		} else if (props.toDelete === "handbook") {
			return "Delete Handbook";
		} else {
			return "Delete Events";
		}
	};

	const downloadCSV = () => {
		let csvData;
		if (props.toImport === "lawyers" && !props?.firm) {
			csvData =
				"name,email,phone,lawyer_url,password,jurisdiction,member_type,profile_url\nJohn,john@example.com,+123232323,https://example-lawyer-url,ela@1234,Alabama,Employment Lawyer,https://www.ela.law/Templates/media/files/Bennett_A_Dean_gallery.jpg";
		} else if (props.toImport === "firm") {
			csvData =
				"name,email,phone,firm_url,password,jurisdiction,region,firm_address,firm_image\nJohn,john@example.com,+123232323,https://example-firm-url,ela@1234,Alabama,Asia,This is firm address,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg";
		} else if (props.toImport === "pdf") {
			csvData = "state,pdf_link\nAlabama,https://example-pdf-link";
		} else if (props.toImport === "news") {
			csvData =
				"name,location,description,featured,category,link,news_date,news_image\njohn,Asia Pacific,News description Here,true,Data Privacy,News Link Here,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg\njohn,Asia Pacific,News description Here,false,Data Privacy,News Link Here,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg";
		} else if (props.toImport === "webinar") {
			csvData =
				"name,location,description,featured,category,link,webnar_type,webnars_date,webinar_image\njohn,Asia Pacific,Webinar description Here,true,Data Privacy,webinar Link Here,on_demand,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg\njohn,Asia Pacific,Webinar description Here,false,Data Privacy,webinar Link Here,upcoming,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg";
		} else if (props.toImport === "podcast") {
			csvData =
				"name,location,description,featured,category,link,pod_cast_date,podcast_image\njohn,Asia Pacific,podcast description Here,true,Data Privacy,podcast Link Here,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg\njohn,Asia Pacific,podcast description Here,false,Data Privacy,podcast Link Here,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg";
		} else if (props.toImport === "handbook") {
			csvData =
				"state_id,additional_information,compensation,discrimination_and_harassment,introduction,employee_injurie_and_workers_compensation,health_and_safety,hiring,immigration_labor_migration,layoffs_workforce_reductions_redundancies_collective_dismissals,personnel_administration,privacy,termination_dismissal_issues,trade_union_industrial_relations,off_leaves_of_absence,unemployment_compensation,unfair_competition_covenants_not_to_compete,contact";
		} else if (props.toImport === "lawyers" && props?.firm) {
			csvData =
				"name,email,phone,firm_id,lawyer_url,password,jurisdiction,member_type,profile_url\nJohn,john@example.com,+123232323,20,https://example-lawyer-url,ela@1234,Alabama,Employment Lawyer,https://www.ela.law/Templates/media/files/Bennett_A_Dean_gallery.jpg";
		} else if (props.toDelete) {
			csvData = "name,\n2023 ELA Annual Global Membership Conference";
		} else {
			csvData =
				"name,location,description,status,category,link,event_date,event_image\njohn,Asia Pacific,event description Here,Created,event Link Here,2023-01-24\njohn,Asia Pacific,event description Here,Confirmed,Data Privacy,event Link Here,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg\njohn,Asia Pacific,event description Here,Open,Data Privacy,event Link Here,2023-01-24,https://www.ela.law/Templates/media/images/1_MEMBER_HEADSHOTS/Are-Fagerhaug_headshot.jpg";
		}
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "example.csv";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const handleUploadCSV = () => {
		setLoading(true);
		if (props.toImport === "lawyers" || props.toImport === "firm") {
			uploadData({
				csvFile,
				type: props.toImport === "lawyers" ? "lawyer" : "firm",
				firm_id: props.firm_id,
			});
		} else {
			uploadMediaData({
				csvFile,
				type: props.toImport,
			});
		}
	};

	const handleDeleteFromCsv = () => {
		deleteData({ csvFile, type: props.toDelete });
	};

	useEffect(() => {
		if (isSuccess || MediaIsSuccess || isDeleteSuccess) {
			setLoading(false);
			if (
				data?.message.includes("Validation error") ||
				data?.message.includes("Firm with id")
			) {
				toast.error(data?.message);
			} else if (deleteResult?.message?.error) {
				toast.error(deleteResult?.message?.error);
			} else if(uploadCsvResponse?.params){
				toast.error(uploadCsvResponse?.message);
			} else {
				toast.success(
					props.toImport === "lawyers"
						? "Lawyers Added Successfully"
						: props.toImport === "firm"
						? "Firms Added Successfully"
						: props.toImport === "news"
						? "News Added Successfully"
						: props.toImport === "podcast"
						? "Podcast Added Successfully"
						: props.toImport === "webinar"
						? "Webinars Added Successfully"
						: props.toImport === "handbook"
						? "Handbooks Added Successfully"
						: props.toDelete
						? "Deleted Successfully"
						: "Events Added Successfully"
				);
				props.refetch();
			}
			handleClose();
		}
		if (isError || MediaIsError || isDeleteError) {
			setLoading(false);
			toast.error(isError);
			handleClose();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isError,
		isSuccess,
		MediaIsError,
		MediaIsSuccess,
		isDeleteError,
		isDeleteSuccess,
	]);

	const downloadFirmCSV = () => {
		const csvData = jsonToCSV(firms);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = "FirmID's.csv";
		link.href = url;
		link.click();
	};

	return (
		<section
			id="add_lawyer_popup"
			className={props.active ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<ScreenLoader
							show={
								isLoading ||
								loading ||
								loadingMedia ||
								isDeleteLoading
							}
							message="This may take a while"
						/>
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="inner_div">
									<button
										type="button"
										className="back_btn"
										onClick={props.setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">
										{props.toImport
											? handleImportHeading()
											: handleDeleteHeading()}
									</h2>
									<div
										style={{
											marginTop: "7rem",
											marginLeft: "3rem",
										}}
									>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<button
												onClick={downloadCSV}
												className="site_btn"
											>
												Download Example File
											</button>
											{props?.firm && (
												<button
													onClick={downloadFirmCSV}
													className="site_btn"
												>
													Download Firm ID's
												</button>
											)}
										</div>
										<h5 style={{ marginTop: "2rem" }}>
											Please do not change first row/
											'variables' in csv/xlsx while
											uploading.
										</h5>
									</div>

									<div
										style={{
											alignSelf: "flex-end",
											display: "flex",
											justifyContent: "space-around",
											marginTop: "10rem",
										}}
									>
										<div
											style={{
												alignSelf: "flex-end",
												display: "flex",
											}}
										>
											<input
												type="file"
												accept=".csv, .xlsx"
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>
												) => handleFileChange(e)}
											/>
										</div>
										<button
											onClick={() => {
												props.toDelete
													? handleDeleteFromCsv()
													: handleUploadCSV();
											}}
											className="site_btn"
											disabled={!csvFile}
										>
											Upload File
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
