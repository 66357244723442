import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.58 0C16.7215 2.17938 15.7472 3.79969 13.9833 5.00119C13.3229 5.45252 12.5476 5.58263 11.6943 5.51554C11.7012 5.11494 11.7321 4.71509 11.7866 4.3181C11.9978 3.25135 12.529 2.27266 13.3107 1.51016C14.0924 0.747659 15.0882 0.236891 16.1677 0.0447261C16.2121 0.0337926 16.2553 0.0188203 16.2969 0L16.58 0Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.6477 17.6151C21.498 17.9973 21.3688 18.3632 21.2129 18.719C20.5749 20.148 19.7283 21.4762 18.7004 22.661C18.1671 23.2872 17.5333 23.7832 16.6862 23.9194C15.9947 24.0015 15.2936 23.9041 14.6516 23.6369C14.186 23.4701 13.7245 23.2872 13.2486 23.153C12.3814 22.9301 11.4654 22.982 10.6294 23.3014C10.0285 23.5047 9.42956 23.7202 8.8204 23.9011C8.46782 24.0089 8.09377 24.0282 7.73183 23.9572C7.36989 23.8862 7.0314 23.7272 6.74677 23.4945C6.25102 23.1247 5.79837 22.7012 5.39718 22.232C3.60861 20.1136 2.44589 17.5462 2.03754 14.8136C1.80167 13.2706 1.79141 11.7336 2.30623 10.2333C3.00359 8.20025 4.29371 6.73039 6.40835 6.06153C7.40829 5.74805 8.48463 5.7702 9.47058 6.12455C10.0654 6.32785 10.652 6.55148 11.2406 6.76901C11.7096 6.92531 12.2199 6.90724 12.6764 6.71819C13.544 6.4173 14.4034 6.03103 15.2997 5.90499C17.5559 5.57361 19.4921 6.18554 20.9073 8.05997C20.936 8.09657 20.9586 8.13723 20.9975 8.19415C19.1782 9.33467 18.2573 10.9346 18.4173 13.1018C18.5773 15.269 19.7136 16.6921 21.6477 17.6151Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent
