import React from "react";
import { Link } from "react-router-dom";
import { useGetRole } from "../../redux/selectors/Common";
import Image from "material-ui-image";

type Props = {
	item: any;
};

const NewsCard: React.FC<Props> = ({ item }) => {
	const date: any = new Date(item.created_at)
	const role = useGetRole();
	return (
		<div className="event_card">
			<Link
				to={`/${role}/news-details`}
				state={{ NewsDetails: item }}
				className="img_blk"
			>
				<Image src={item.news_image} aspectRatio={5 / 4} />
			</Link>
			<div className="content">
				<Link
					to={`/${role}/news-details`}
					state={{ NewsDetails: item }}
				>
					<h5>{item.name}</h5>
				</Link>
				<a href={item.link} style={{ color: '#114d96' }} >
					<h6>{date.toDateString()}</h6>
				</a>
			</div>
		</div>
	);
};

export default NewsCard;
