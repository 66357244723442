import Checkbox from '@mui/material/Checkbox';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TermsAndConditionsText from './TermsandConditionText';

export default function TermsAndConditions(redirect: any) {
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(false)
    const handleCheckBox = () => {
        setChecked(!checked);
    }
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const handleContinue = () => {
        if (checked) {
            localStorage.setItem('termsAndCondition', 'true');
            if (redirect.redirect === 'login') {
                navigate('/login')
            } else {
                navigate('/signup')
            }
        }

    }
    return (
        <>
            <section
                id="terms_and_conditions_popUp"
                className={"popup active"}
            >
                <div className="overlay"></div>
                <div className="table_dv">
                    <div className="termsAndConditionsContainer">
                        <div className="termsAndConditionText">
                            END USER LICENSE AGREEMENT
                        </div>
                        <div className="justify-center flex">
                            <div className="termsAndConditionContent">
                                <div className="text">
                                    <TermsAndConditionsText />
                                </div>
                            </div>
                        </div>
                        <div className="checkBoxContainer">
                            <div className="checkboxText" onClick={handleCheckBox}>
                                <Checkbox checked={checked}   classes={{root: 'custom-checkbox-root'}} />
                                I agree to terms and Conditions
                            </div>
                            <div className="continueButton" onClick={handleContinue} style={{ backgroundColor: !checked ? '#dddddd' : '' }} >
                                Continue
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}





