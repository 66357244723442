import { Button, Image, Modal } from "react-bootstrap";
import ELALOGO from "../../assets/images/elaLogo.png";

const AttachmentConfirmation = ({
	showModal,
	hideModal,
	message,
	handleDelete,
}: any) => {
	return (
		<Modal
			show={showModal}
			onHide={hideModal}
			centered
			onBackdropClick={hideModal}
		>
			<Image
				src={ELALOGO}
				style={{
					height: "8rem",
					width: "8rem",
					alignSelf: "center",
					marginTop: "1rem",
				}}
			/>
			<Modal.Header>
				<Modal.Title className="text-center w-100">
					Attachment Confirmation
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="alert alert-primary">{message}</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="default"
					onClick={hideModal}
					style={{ fontSize: "2rem" }}
				>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						handleDelete(true);
						hideModal();
					}}
					style={{ fontSize: "2rem" }}
				>
					Send
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AttachmentConfirmation;
