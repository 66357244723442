import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.36412 20.6359C3.84977 21.1214 4.637 21.1214 5.12265 20.6359L12.0075 13.751L18.8924 20.6359C19.3804 21.1072 20.156 21.1004 20.6357 20.6207C21.1155 20.141 21.1222 19.3653 20.6509 18.8774L13.766 11.9925L20.6509 5.10763C21.1222 4.61965 21.1155 3.84398 20.6357 3.36426C20.156 2.88454 19.3804 2.8778 18.8924 3.34911L12.0075 10.234L5.12265 3.34911C4.63467 2.8778 3.85899 2.88454 3.37927 3.36426C2.89956 3.84398 2.89281 4.61965 3.36412 5.10763L10.249 11.9925L3.36412 18.8774C2.87863 19.363 2.87863 20.1502 3.36412 20.6359V20.6359Z"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent
