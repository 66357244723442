import { useEffect } from "react";
import { Link } from "react-router-dom";
import icon1 from "../../../assets/images/icon1.svg";
import icon2 from "../../../assets/images/icon2.svg";
import icon3 from "../../../assets/images/icon3.svg";
import icon4 from "../../../assets/images/icon4.svg";
import icon5 from "../../../assets/images/lawyerIcon3.svg";
import NoImage from "../../../assets/images/noImage.png";
import Layout from "../../../components/Layout";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import { setFirmDashboardData } from "../../../redux/action/Admin/Dashboard";
import { GetLoginUserData, useGetRole } from "../../../redux/selectors/Common";
import { useGetDashboardDataQuery, useGetAnalyticsDataQuery } from "../../../redux/services/Dashboard";
import { useAppDispatch } from "../../../redux/store";
import FloatingChat from "../../../components/FloatingChat";
import AnalyticsTile from "../../../components/AnalyticsTile";

const Dashboard = () => {
	const role = useGetRole();
	let userData = GetLoginUserData();
	let user_data: any = userData;
	const { data, isLoading, isSuccess } = useGetDashboardDataQuery(role, {
		refetchOnMountOrArgChange: true,
	});
	const { data: analyticsData , isLoading: isAnalyticsLoading, isSuccess: isAnalyticsSuccess } = useGetAnalyticsDataQuery( {
		refetchOnMountOrArgChange: true,
	});
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (isSuccess && data) dispatch(setFirmDashboardData(data));
	}, [isSuccess, data, dispatch]);

	return (
		<Layout>
			<section id="dashboard" className="sec_pad_btm">
				<div className="board_content">
					<div className="contain sm">
						{isLoading && <ScreenLoader show={true} />}
						<div className="top_blk">
							<div className="img_blk">
								<img
									src={
										data?.firm_image
											? data?.firm_image
											: NoImage
									}
									alt="Profile_image"
									className="img"
								/>
							</div>
							<div className="text_blk">
								<p>{data?.firm_name}</p>
								{role === "firm" && user_data?.firm?.role != "Super Admin" && data?.referral_email && <p> <strong>Referral Email:</strong> {data?.referral_email}</p>}
							</div>
						</div>
						<div className="row">
							{user_data?.firm?.role === "Admin" && (
								<>
									<div className="col-md-6 col-sm-12">
										<Link
											to="/firm/lawyers"
											className="img_blk"
										>
											<div className="dashboard_card">
												<div className="img_blk">
													<img
														src={icon3}
														alt="icon 3"
													/>
												</div>
												<p className="fw_500">
													Manage Lawyers
												</p>
											</div>
										</Link>
									</div>
									{/* <div className="col-md-6 col-sm-12">
										<Link
											to="/firm/lawyers"
											state={{ lawyerManage: true }}
											className="img_blk"
										>
											<div className="dashboard_card">
												<div className="img_blk">
													<img
														src={icon1}
														alt="icon 1"
													/>
												</div>
												<p className="fw_500">
													Manage Firm Content
												</p>
											</div>
										</Link>
									</div> */}

									<div className="col-md-6 col-sm-12">
										<Link
											to="/firm/referals"
											className="img_blk"
										>
											<div className="dashboard_card">
												<div className="img_blk">
													<img
														src={icon2}
														alt="icon 2"
													/>
												</div>
												<p className="fw_500">
													Incoming Referrals:
													<strong className="color">
														{data?.incoming_referel}
													</strong>
												</p>
												<p className="fw_500">
													Outgoing Referrals:
													<strong className="color">
														{data?.outgoing_referel}
													</strong>
												</p>
											</div>
										</Link>
									</div>
									{/* <div className="col-md-6 col-sm-12">
										<Link
											to="/firm/lawyers"
											className="img_blk"
										>
											<div className="dashboard_card">
												<div className="img_blk">
													<img
														src={icon3}
														alt="icon 3"
													/>
												</div>
												<p className="fw_500">
													Manage Lawyers
												</p>
											</div>
										</Link>
									</div> */}
									<div className="col-md-6 col-sm-12">
										<Link to="/firm/ela-lawyer-directory">
											<div className="dashboard_card">
												<div className="img_blk">
													<img src={icon1} alt="icon 3" />
												</div>
												<p className="fw_500">ELA Lawyer Directory</p>
											</div>
										</Link>
									</div>
									<div className="col-md-6 col-sm-12">
										<Link to="/firm/resources">
											<div className="dashboard_card">
												<div className="img_blk">
													<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
														<img
															src={icon4}
															alt="icon 4"
														/>
														<img
															style={{marginLeft: '1rem'}}
															src={icon5}
															alt="icon 4"
														/>
													</div>
												</div>
												<p className="fw_500">
													GEH & Resources | Media Library
												</p>
											</div>
										</Link>
									</div>
								</>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<AnalyticsTile 
								title="ELA Members" 
								icon={icon2}  
								path="/firm/super-admin-manage-lawyers" 
								count={analyticsData?.ela_members?.total_count} 
								currentMonth={analyticsData?.ela_members?.current_month_count}
								percentage={analyticsData?.ela_members?.percentage_difference}
								mobileRegistered={analyticsData?.ela_members?.registered_on_mobile_count}
								mobileUnregistered={analyticsData?.ela_members?.unregistered_on_mobile_count}
								/>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<AnalyticsTile 
								title="Firms" 
								icon={icon3}  
								path="/firm/registered-firms" 
								count={analyticsData?.firms?.total_count} 
								currentMonth={analyticsData?.firms?.current_month_count}
								percentage={analyticsData?.firms?.percentage_difference}
								mobileRegistered={analyticsData?.firms?.registered_on_mobile_count}
								mobileUnregistered={analyticsData?.firms?.unregistered_on_mobile_count}
								/>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<AnalyticsTile 
								title="Client / Guest" 
								icon={icon1}  
								path="/firm/super-admin-manage-client" 
								count={analyticsData?.clients?.total_count} 
								currentMonth={analyticsData?.clients?.current_month_count}
								percentage={analyticsData?.clients?.percentage_difference}
								mobileRegistered={analyticsData?.clients?.registered_on_mobile_count}
								mobileUnregistered={analyticsData?.clients?.unregistered_on_mobile_count}
								/>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<AnalyticsTile 
								title="Referrals" 
								icon={icon4}  
								path="/firm/super-admin-manage-referrals" 
								count={analyticsData?.referrals?.total_count} 
								currentMonth={analyticsData?.referrals?.current_month_count}
								percentage={analyticsData?.referrals?.percentage_difference}/>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/registered-firms"
										state={{ lawyerManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon3} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage Registered Firms
											</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon2} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage Super Admins
											</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin-manage-lawyers"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon2} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage All Lawyers
											</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin-manage-client"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon2} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage Clients
											</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin-media-library-handbooks"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon4} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage Media Library
											</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin-jurisdictions"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon4} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage Jurisdictions
											</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin-manage-referrals"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon4} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage Referrals
											</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin-manage-contacts"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon1} alt="icon 1" />
											</div>
											<p className="fw_500">Manage All Contacts</p>
										</div>
									</Link>
								</div>
							)}
							{user_data?.firm?.role == "Super Admin" && (
								<div className="col-md-6 col-sm-12">
									<Link
										to="/firm/super-admin-manage-messages"
										state={{ superAdminManage: true }}
									>
										<div className="dashboard_card">
											<div className="img_blk">
												<img src={icon4} alt="icon 4" />
											</div>
											<p className="fw_500">
												Manage Messages
											</p>
										</div>
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			{user_data?.firm?.role === "Admin" && (
			<div style={{position: 'fixed', bottom: "10%", right: "2%", zIndex: 20}}>
					<FloatingChat />
					<strong>Contact Us</strong>
				</div>
				)}
		</Layout>
	);
};

export default Dashboard;
