import React from "react";

const Support = () => {
	return (
		<div style={{ padding: "30px"}}>
			At the Employment Law Alliance (ELA), we provide excellent support,
			resources, tools, and legal contacts through our application. Our
			mission is to be the go-to platform for addressing all your firms
			legal, labor, and employment law needs. With a global reach and a
			dedicated membership of the best lawyers from the top law firms
			around the globe, we strive to empower business leaders and legal
			and HR professionals with the tools and knowledge necessary to
			navigate complex employment matters as they arise.
			<br />
			One of the key features of our application is comprehensive access
			to our Global Employers Handbook which provides in depth chapters
			that address the labor and employment laws and regulations for each
			jurisdiction. The ELA has member firms in all 50 US states, Canadian
			provinces, and 100 countries worldwide. This means that whether you
			are HR or legal matter is related to hiring, termination,
			discrimination, data privacy, non-competition, or any other related
			employment law matter, you can access the relevant information and
			connect with the firm that authored the chapter within a few clicks.
			Our Global Employer Handbook database is regularly updated to ensure
			it contains most current and accurate information at your
			fingertips.
			<br />
			To further enhance your experience, we offer a wide range of Content
			and Media resources that were previously unavailable in an app
			platform. Whether you are drafting an employment agreement,
			conducting workplace investigations, or implementing diversity and
			inclusion initiatives, the ELA resources will provide valuable
			support.
			<br />
			One unique aspect of our application is the direct access to our
			global network of member firms that feature the top employment law
			professionals from each jurisdiction. . Navigating across multiple
			cross border jurisdictions can be very complex, and usually requires
			access to local counsel to address these legal matters. The ELA's
			global team of experts are readily accessible to provide
			information, guidance and support. Through our application, you can
			access this network, and collaborate with the best legal resources
			in each jurisdiction, who have deep knowledge and relationships to
			assist you with local laws and regulations.
			<br />
			To help you stay up to date with the latest developments in the
			field of employment law, the Media Library provides regular updates
			and news alerts with the application. . This ensures that you are
			informed about changes in regulations, landmark cases, and emerging
			trends that may impact your organization. By staying ahead of the
			curve, you can proactively address potential risks and make informed
			decisions.
			<br />
			The ELA App has been designed with user-friendliness in mind. An
			intuitive interface allows for easy navigation and quick retrieval
			of information. Whether you are accessing the application on your
			computer, tablet, or mobile device, users can seamlessly search,
			view, or stream the resources and tools. At ELA, we are committed to
			providing exceptional support to our users. As we continuously
			strive to enhance our application with future updates, we greatly
			value user feedback and evolving industry needs.
			<br />
			As the world's largest and most prestigious network of labor and
			employment lawyers from the best law firms in the world, the
			Employment Law Alliance application will continue to grow and help
			our members support our clients and guest users in addressing their
			important legal matters. Download the Employment Law Alliance App
			today.
		</div>
	);
};
export default Support;
