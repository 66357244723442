import { IJurisdictionTopic, IMemberType } from "../../interfaces/Common";

export const UserRole = {
	FIRM: "FIRM",
	LAWYER: "LAWYER",
	CLIENT: "CLIENT",
};

export const UserTypes: { key: string; value: string }[] = [
	{
		key: "ELA Member",
		value: "lawyer",
	},
	{
		key: "Client/Guest",
		value: "client",
	},
	{
		key: "Firm",
		value: "firm",
	},
	{
		key: "Super Admin",
		value: "superAdmin",
	},
];

export const basePaths: Record<string, string> = {
	firm: "firms",
	lawyer: "lawyers",
	client: "clients",
};

export const dashboardTypes: Record<string, string> = {
	firm: "firm_dashboard",
	lawyer: "lawyer_dashboard",
	client: "client_dashboard",
};

export const member_type: IMemberType[] = [
	{
		id: 0,
		memberType: "Employment Lawyer",
		status: false,
	},
	{
		id: 1,
		memberType: "Immigration Lawyer",
		status: false,
	},
	{
		id: 2,
		memberType: "Primary Lawyer",
		status: false,
	},
	{
		id: 3,
		memberType: "SPA Member",
		status: false,
	},
	{
		id: 4,
		memberType: "All Lawyer Types",
		status: false,
	},
];

export const Alphabets = [
	{ id: 1, value: "A" },
	{ id: 2, value: "B" },
	{ id: 3, value: "C" },
	{ id: 4, value: "D" },
	{ id: 5, value: "E" },

	{ id: 6, value: "F" },
	{ id: 7, value: "G" },
	{ id: 8, value: "H" },
	{ id: 9, value: "I" },
	{ id: 10, value: "J" },

	{ id: 11, value: "K" },
	{ id: 12, value: "L" },
	{ id: 13, value: "M" },
	{ id: 14, value: "N" },
	{ id: 15, value: "O" },

	{ id: 16, value: "P" },
	{ id: 17, value: "Q" },
	{ id: 18, value: "R" },
	{ id: 19, value: "S" },
	{ id: 20, value: "T" },

	{ id: 21, value: "U" },
	{ id: 22, value: "V" },
	{ id: 23, value: "W" },
	{ id: 24, value: "X" },
	{ id: 25, value: "Y" },
	{ id: 26, value: "Z" },
];
export const JurisdictionTopic: IJurisdictionTopic[] = [
	{
		id: 0,
		memberType: "Introduction",
		key: "introduction",
		status: false,
	},
	{
		id: 1,
		memberType: "Compensation",
		key: "compensation",
		status: false,
	},
	{
		id: 2,
		memberType: "Discrimination and Harassment",
		key: "discrimination_and_harassment",
		status: false,
	},
	{
		id: 3,
		memberType: "Employee Injuries and Workers Compensation",
		key: "employee_injurie_and_workers_compensation",
		status: false,
	},
	{
		id: 4,
		memberType: "Health and Safety",
		key: "health_and_safety",
		status: false,
	},
	{
		id: 5,
		memberType: "Immigration/Labor Migration",
		key: "immigration_labor_migration",
		status: false,
	},
	{
		id: 6,
		memberType:
			"Layoffs/Work Force Reductions/Redundancies/Collective Dismissals",
		key: "layoffs_workforce_reductions_redundancies_collective_dismissals",
		status: false,
	},
	{
		id: 7,
		memberType: "Personnel Administration",
		key: "personnel_administration",
		status: false,
	},
	{
		id: 8,
		memberType: "Privacy",
		key: "privacy",
		status: false,
	},
	{
		id: 9,
		memberType: "Additional Information",
		key: "additional_information",
		status: false,
	},
];

export const Locations = [

	{ id: 0, name: 'Africa & Middle East', status: false },
	{ id: 1, name: 'Asia Pacific', status: false },
	{ id: 2, name: 'Europe', status: false },
	{ id: 3, name: 'Latin America', status: false },
	{ id: 4, name: 'North America', status: false },

]
export const Category = [

	{ id: 0, name: 'Benefits & Compensation', status: false },
	{ id: 1, name: 'Data Privacy', status: false },
	{ id: 2, name: 'Health & Safety', status: false },
	{ id: 3, name: 'Higher Education', status: false },
	{ id: 4, name: 'Immigration', status: false },
	{ id: 5, name: 'Government Affairs- US', status: false },
	{ id: 6, name: 'Labor/Trade Unions', status: false },
	{ id: 7, name: 'Employment Laws/Others', status: false },

]
export const Webinars = [

	{ id: 0, values: 'on_demand', name: 'On Demand' },
	{ id: 1, values: 'upcoming', name: 'Up Coming' },

]
