import { Navigate } from "react-router-dom";
import { useGetRole } from "../../redux/selectors/Common";

export const PrivateRoute = ({
	role,
	children,
}: {
	role: string;
	children: JSX.Element;
}) => {
	const token = localStorage.getItem("token");
	const userRole = useGetRole();
	if (userRole.toUpperCase() === role && token) {
		return children;
	}
	return <Navigate to='/login' replace />;
};
