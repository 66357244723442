import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import Image from "material-ui-image";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NoImage from "../../assets/images/noImage.png";
import AddNewMediaLibrary from "../../containers/Admin/SuperAdmin/MediaLibrary/AddNewMediaLibrary";
import { useDeleteMediaLibraryMutation } from "../../redux/services/Firm/FirmLawyer";
import DeleteConfirmation from "../DeleteConfirmationModal/DeleteConfirmationModal";

type Props = {
	item: any;
	isDeleted: any;
	setRefetch?: any;
	toDelete?: any;
	isSelected?: any;
	onSelect?: any;
};

const MediaLibraryCard: React.FC<Props> = ({
	item,
	isDeleted,
	setRefetch,
	toDelete,
	onSelect,
	isSelected,
}) => {
	const date = new Date(item?.created_at).toDateString();
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [deletedPodcast, setDeletedPodcast] = useState(false);
	const [updatePodcast, setUpdatePodcast] = useState(false);

	const [
		deleteMedia,
		{ isSuccess: deletedSuccessfully, isError: deletionError },
	] = useDeleteMediaLibraryMutation();

	const handleUpdate = () => {
		setUpdatePodcast(!updatePodcast);
	};

	const handleDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	useEffect(() => {
		if (deletedSuccessfully === true) {
			setDeletePopUp(!deletePopUp);
			toast.success(`${toDelete} deleted successfully`);
			isDeleted(true);
		}
		if (deletionError) {
			toast.error(`Can not delete ${toDelete}, Please try again`);
		}
	}, [deletedSuccessfully, deletionError]);

	useEffect(() => {
		if (deletedPodcast) {
			deleteMedia({ selectedItems: item?.id, toDelete: toDelete });
			setDeletedPodcast(false);
		}
	}, [deletedPodcast]);

	const handleSelect = () => {
		onSelect(item.id);
	};

	return (
		<div className="lawyer_card lawyer_firm">
			<Checkbox checked={isSelected} classes={{root: 'custom-checkbox-root'}} onClick={() => handleSelect()} />
			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					width: "100%",
				}}
			>
				<div className="profile_blk">
					<div className="img_blk">
						<Image
							src={item?.image || item?.image || NoImage}
							alt=""
						/>
					</div>
					<div
						className="text_blk"
						style={{
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							overflow: "hidden",
						}}
					>
						<h5
							className="fs_5 fw_500 "
							style={{ display: "inline" }}
						>
							{item.name}
						</h5>
					</div>
				</div>
				<div className="phone_email">
					<div className="phone">
						<h5 className="fs_5 fw_500">Featured</h5>
						<p className="fw_400">
							{item.featured === true ? "True" : "False"}
						</p>
					</div>
				</div>
				<div className="phone_email">
					<div className="email">
						<h5 className="fs_5 fw_500">Date</h5>
						<p className="fw_400">{date}</p>
					</div>
				</div>
			</div>
			<div
				style={{
					width: "15%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
					marginTop: "2rem",
				}}
			>
				<div onClick={handleUpdate} style={{ cursor: "pointer" }}>
					<BorderColorIcon
						fontSize="large"
						sx={{ height: "2em", width: "2em", color: "#63A744" }}
					/>
				</div>
				<div onClick={handleDelete} style={{ cursor: "pointer" }}>
					<DeleteIcon
						fontSize="large"
						sx={{ height: "2em", width: "5em", color: "red" }}
					/>
				</div>
			</div>
			<AddNewMediaLibrary
				active={updatePodcast}
				setActive={handleUpdate}
				isUpdate={true}
				toUpdate={toDelete}
				item={item}
				setRefetch={setRefetch}
			/>
			<DeleteConfirmation
				showModal={deletePopUp}
				message={`Are you sure you want to delete this ${toDelete}`}
				handleDelete={setDeletedPodcast}
				hideModal={handleDelete}
			/>
		</div>
	);
};

export default MediaLibraryCard;
