import { Link } from "react-router-dom";
import Icon from "../assets/images/logo_icon.png";

const LogoIcon = () => {
    return (
        <>
            <div className="logo_icon">
                <Link to="/"><img src={Icon} alt="Logo" /></Link>
            </div>
        </>
    );
};

export default LogoIcon;
