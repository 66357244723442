import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Box from "@mui/material/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "./style.css";


const useStyles = makeStyles({
	customTextField: {
		"& input::placeholder": {
			fontSize: "16px"
		}
	}
});

const icon = (
	<CheckBoxOutlineBlankIcon
		fontSize="large"
		sx={{ zoom: "2", height: "12px" }}
	/>
);
const checkedIcon = (
	<CheckBoxIcon fontSize="large" sx={{ zoom: "2", height: "12px" }} />
);

interface IMuiSelect {
	options: any;
	multiple: boolean;
	field?: string;
	handleChange: any;
	placeholder: string;
	IconStart?: any;
	className?: any;
	placeHolderStyle?: any;
	value?: any
}

export default function CheckboxesTags({
	options,
	multiple,
	handleChange,
	placeholder,
	IconStart,
	className,
	value,
}: IMuiSelect) {
	const classes = useStyles();
	return (
		<div style={{ position: "relative" }}>
			{IconStart && (
				<div>
					<IconStart className="icon-start" />
				</div>
			)}
			<Autocomplete
				className={className}
				id="checkboxes-tags-demo"
				multiple={multiple}
				options={options}
				inputValue={value}
				disableCloseOnSelect
				onChange={(event, newValue) => {
					handleChange(newValue);
				}}
				getOptionLabel={(option: any) =>
					option?.memberType || option?.name
				}
				renderOption={(props, option, { selected }) => (
					<li {...props} key={option?.id}>
						<Checkbox
							icon={icon}
							checkedIcon={checkedIcon}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
						<Box sx={{ fontSize: "10px" }}>
							{option?.memberType || option?.name}{" "}
						</Box>
					</li>
				)}
				renderInput={(params) => (
					<TextField classes={{ root: classes.customTextField }} {...params} placeholder={placeholder} />
				)}
			/>
		</div>
	);
}
