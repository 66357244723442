import { SVGProps } from "react";

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="30"
		height="30"
		viewBox="0 0 48 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="24" cy="24" r="24" fill="white" />
		<circle cx="24.0001" cy="24.0001" r="20.129" fill="#114D96" />
		<path
			d="M25.7227 32.3995H34.2802"
			stroke="white"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M29.3769 12.7398V12.7398C27.5733 11.3871 25.0156 11.7521 23.6629 13.5543C23.6629 13.5543 16.9358 22.5158 14.6022 25.625C12.2685 28.7357 14.476 32.5897 14.476 32.5897C14.476 32.5897 18.8293 33.5908 21.1293 30.5245C23.4308 27.4595 30.1901 18.4537 30.1901 18.4537C31.5428 16.6515 31.1791 14.0924 29.3769 12.7398Z"
			stroke="white"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.9219 15.8955L28.4491 20.7949"
			stroke="white"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default EditIcon;
