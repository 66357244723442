import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ErrorText from "../../../components/Shared/ErrorText";
import TextInput from "../../../components/TextInput";
import { Envelope, User } from "../../../icons";
import { useGetRole } from "../../../redux/selectors/Common";
import { useForgetPasswordMutation } from "../../../redux/services/Auth";
import { emailRegExp } from "../../../utills/common";
import Select from "../../../components/Select";
import { UserTypes } from "../../../utills/Const/const";

export default function Forgot() {
	const role = useGetRole();
	const navigate = useNavigate();

	const [forgetPassword, { data: forgetData, isSuccess, isError }] = useForgetPasswordMutation();


	const handleResetPass = (values: any) => {

		const resetData = values;
		forgetPassword(resetData);
	};

	const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched } = useFormik({
		initialValues: {
			email: "",
			userType: role
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.matches(emailRegExp, "Invalid email format!")
				.required("Email is a required field"),
		}),
		onSubmit: (values) => {
			handleResetPass(values);
		},
	});

	useEffect(() => {
		if (isSuccess && forgetData?.otp) {
			navigate("/verification", { state: { email: values.email, otp: forgetData?.otp, userType: values.userType } });
		}
	}, [isSuccess, forgetData, isError]);

	return (
		<>
			<section id="logon">
				<div className="contain">
					<div className="flex_blk">
						<form onSubmit={handleSubmit} className="auth-form">
							<div className="log_blk">
								<div className="inner">
									<div className="txt text-center mb-5 pb-3">
										<h2>Reset Password</h2>
										<p className="fs_6 opacity-50">
											Enter the email address associated
											with your account and we’ll send a
											verification code
										</p>
									</div>
									<div className='form_blk'>
										<User />
										<Select
											label='Select User Type'
											className='input'
											options={UserTypes}
											handleChange={(value: string) => {
												setFieldValue(
													"userType",
													value
												);
											}}
											handleBlur={handleBlur}
											defaultValue={values.userType}
											name='userType'
										/>
									</div>
									<div className="row">
										<div className="col-12">
											<h5 className="fw_500 require">
												Email Address
											</h5>
											<div className="form_blk">
												<Envelope />
												<TextInput
													placeholder="eg: sample@gmail.com"
													type="email"
													name="email"
													handleChange={handleChange}
													handleBlur={handleBlur}
													value={values.email}
												/>
											</div>
											<ErrorText
												text={errors.email}
												isTouched={touched.email}
											/>
										</div>
									</div>
									<div className="btn_blk justify-content-center mt-5 pt-3">
										<button
											type="submit"
											className="site_btn w-100"
										>
											Send Verification Code
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	);
}
