import Modal from "../../../components/Modal";
import { useState } from "react";
import LoginForm from "./login";

export default function Login() {
	const [modalShow, setModalShow]: any = useState(false);
	const handleModal = () => {
		setModalShow(!modalShow);
	};
	return (
		<>
			<LoginForm />
			<Modal children={<LoginForm />} show={modalShow} onHide={handleModal} />
		</>
	);
}
