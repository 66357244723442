const ErrorText = ({
	text,
	isTouched,
}: {
	text?: string | undefined | string[];
	isTouched?: boolean | undefined | string[];
}) => {
	return (
		<p
			style={{
				color: "red",
			}}
		>
			{text && isTouched && text}
		</p>
	);
};

export default ErrorText;
