import fbFooterLogo from "../../assets/images/fbFooterLogo.png";
import twitterFooterLogo from "../../assets/images/twitterFooterLogo.png";
import instaFooterLogo from "../../assets/images/instaFooterLogo.png";
import waFooterLogo from "../../assets/images/waFooterLogo.png";
import spotifyFooterLogo from "../../assets/images/spotifyFooterLogo.png";
import ytFooterLogo from "../../assets/images/ytFooterLogo.png";
import appleLogo from "../../assets/images/appleLogo.png";
import googlePlayLogo from "../../assets/images/googlePlayLogo.png";
import appLogoWhite from "../../assets/images/appLogoWhite.png";
import linkedIn from "../../assets/images/Linkedin.svg";
import vimeo from "../../assets/images/vimeo.svg";
import { useEffect, useState } from "react";

const Footer = () => {
	const [url, setUrl] = useState("");

	const message = `Hello!`; // Replace this with your desired message

	const openWhatsAppWeb = () => {
		const link = `https://web.whatsapp.com/send?phone=+12157689936&text=${encodeURIComponent(
			message
		)}`;

		setUrl(link);
	};

	useEffect(() => {
		openWhatsAppWeb();
	}, []);

	return (
		<div className="footer">
			<div className="data_container">
				<div className="footer_heading">Global HR Legal Solutions</div>
				<div className="text">
					Comprehensive labor, employment and immigration law services
					for employers in virtually every jurisdiction in the world.
				</div>
				<div className="links_container">
					<div className="page_links">
						<div className="page_link">Home</div>
						<div>
							<a
								style={{
									color: "white",
									fontSize: "2.8rem",
									fontWeight: "500",
								}}
								href="https://www.ela.law/news"
							>
								News
							</a>
						</div>
						{/* <div className="page_link">Podcast</div>
						<div className="page_link">About Us</div> */}
						<div>
							<a
								style={{
									color: "white",
									fontSize: "2.8rem",
									fontWeight: "500",
								}}
								href="https://www.ela.law/contact"
							>
								Contact
							</a>
						</div>
					</div>
					<div className="social_links">
						<div className="social_image">
							<a href="https://twitter.com/elaglobal">
								<img
									className="image"
									src={twitterFooterLogo}
									alt="Twitter Logo"
								/>
							</a>
						</div>
						{/* <div className="social_image">
						<a href="https://www.youtube.com/@EmploymentlawallianceELA">

							<img
								className="image"
								src={vimeo}
								alt="Youtube Logo"
							/>
						</a>

						</div> */}
						<div className="social_image">
							<a href="https://www.youtube.com/@EmploymentlawallianceELA">
								<img
									className="image"
									src={ytFooterLogo}
									alt="Youtube Logo"
								/>
							</a>
						</div>
						{/* <div className="social_image">
						<a href="https://vimeo.com/employmentlawalliance">

							<img
								className="image"
								src={linkedIn}
								alt="Instagram Logo"
							/>
						</a>
						</div> */}
						{/* <div className="social_image">
							<img
								className="image"
								src={spotifyFooterLogo}
								alt="Spotify Logo"
							/>
						</div> */}
						<div className="social_image">
							<a href={url} target="_blank">
								<img
									className="image"
									src={waFooterLogo}
									alt="Whatsapp Logo"
								/>
							</a>
						</div>
						{/* <div className="social_image">
							<img
								className="image"
								src={fbFooterLogo}
								alt="Facebook Logo"
							/>
						</div> */}
					</div>
					<hr />
					<div className="copyright">
						©2022 Employment Law Alliance
					</div>
				</div>
			</div>
			<div className="logo_container">
				<div className="app_logo">
					<img
						style={{ height: "100%" }}
						src={appLogoWhite}
						alt="App Logo"
					/>
				</div>
				<div className="available">Available on</div>
				<div className="app_links">
					<div>
						<a href="https://apps.apple.com/us/app/employment-law-alliance-app/id6443846440">
							<img
								className="logo_image"
								src={appleLogo}
								alt="Apple Logo"
							/>
						</a>
					</div>
					<div>
						<a href="https://play.google.com/store/apps/details?id=com.reactnative.elalawapp&pli=1">
							<img
								className="logo_image"
								src={googlePlayLogo}
								alt="Playstore Logo"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
