import React, { useCallback, useEffect, useRef, useState } from "react";

import JoditEditor from "jodit-react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { ArrowLeft } from "../../icons";
import ScreenLoader from "../Shared/ScreenLoader";
import TextInput from "../TextInput";

interface EmailSendPropsType {
	showEmailModal: boolean;
	handleEmailModalClose: () => void;
	emails: any;
	handleSendEmail: any;
	isDisable?: boolean;
}

function EmailSend({
	showEmailModal,
	handleEmailModalClose,
	emails,
	handleSendEmail,
	isDisable,
}: EmailSendPropsType) {
	const editor = useRef(null);
	const [toEmail, setToEmail] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const { values, setFieldValue, handleSubmit, resetForm } = useFormik({
		initialValues: {
			content: "",
			subject: "",
			emails: "",
		},
		onSubmit: (values) => {
			sendButtonClicked(values);
		},
	});

	const sendButtonClicked = useCallback(
		(values: any) => {
			if (!isValid) {
				handleSendEmail({
					emails: emails.length > 0 ? emails : toEmail.split(","),
					content: values?.content,
					subject: values?.subject,
				});
				setToEmail("");
				resetForm();
			} else {
				toast.error("Error! Please add valid emails");
			}
		},
		[isValid, emails, toast]
	);

	useEffect(() => {
		let emails = toEmail.split(",");
		emails.forEach((email: any) => {
			if (!isValidEmail(email) && email !== null && email !== "") {
				setIsValid(true);
				setErrorMessage(`Please enter a valid email: ${email}`);
			} else {
				setIsValid(false);
				setErrorMessage("");
			}
		});
	}, [toEmail]);

	// Basic email validation function
	const isValidEmail = useCallback(
		(email: any) => {
			// Regular expression for basic email validation
			let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailRegex.test(email);
		},
		[]
	);

	return (
		<section
			id="add_lawyer_popup"
			className={showEmailModal ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleEmailModalClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="inner_div">
									<button
										type="button"
										className="back_btn"
										onClick={handleEmailModalClose}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">Email</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
									>
										<div
											className="row"
											style={{
												justifyContent: "center",
												marginTop: 25,
											}}
										>
											<div className="form_blk_container">
												<div className="col-12 mb-5">
													<h5 className="fw_500 require">
														To
													</h5>

													<div className="form_blk">
														{isDisable ? (
															<TextInput
																placeholder="Email"
																type="text"
																name="to"
																value={emails}
																disabled={
																	isDisable
																}
															/>
														) : (
															<TextInput
																placeholder="Emails"
																type="text"
																name="to"
																value={toEmail}
																handleChange={(
																	e: any
																) =>
																	setToEmail(
																		e.target.value.trim()
																	)
																}
															/>
														)}

														{isValid && (
															<small>
																{errorMessage}
															</small>
														)}
													</div>
												</div>
											</div>
											<div className="form_blk_container">
												<div className="col-12 mb-5">
													<h5 className="fw_500 require">
														Subject
													</h5>

													<div className="form_blk">
														<TextInput
															placeholder="Subject"
															type="text"
															name="subject"
															value={
																values?.subject ||
																""
															}
															handleChange={(
																e: any
															) => {
																setFieldValue(
																	"subject",
																	e.target
																		.value
																);
															}}
														/>
													</div>
												</div>
											</div>

											<div className="form_blk_container">
												<div className="col-12">
													<div className="form_blk">
														<JoditEditor
															ref={editor}
															value={
																values?.content ||
																""
															}
															className="editor-class"
															onBlur={(
																newContent
															) => {
																setFieldValue(
																	"content",
																	newContent
																);
															}} // preferred to use only this option to update the content for performance reasons
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="btn_blk mt-5 pt-3">
											<button
												className="site_btn w-100"
												type="submit"
											>
												{false ? (
													<ScreenLoader
														show={false}
													/>
												) : (
													"Send Email"
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default EmailSend;
