import { Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import AutoComplete from "../../../components/MuiSelect";
import { MapMarker, User } from "../../../icons";
import KeyboardIcon from "../../../icons/KeyboardIcon";
import { useGetJurisdictionQuery } from "../../../redux/services/Client/ClientDashboard";
import { Alphabets, member_type } from "../../../utills/Const/const";
import { lawyersFilterSchema } from "../../../utills/YupValidationSchemas";
import ErrorText from "../../Shared/ErrorText";
import TextInput from "../../TextInput";
import "./style.css";

const DirectoryForm = ({ handlerSearchResources }: any) => {
	const [alphabet, setAlphabet] = useState<any>({ id: null, value: "" });
	const [showKeyboard, setShowKeyboard] = useState(false);
	const [data, setData] = useState<any>(null);
	const { data: jurisdiction } = useGetJurisdictionQuery(null);

	const toogleShowKeyboard = () => setShowKeyboard(!showKeyboard);
	const selectAlphabetHandler = (value: any) => { };

	useEffect(() => {
		let payload: any = jurisdiction
		var sortedJurisdictions = _.sortBy(payload?.states, 'name');
		setData({ states: sortedJurisdictions })

	}, [jurisdiction])

	return (
		<>
			<Formik
				initialValues={{
					jurisdiction: [],
					name: "",
					member_type: [],
				}}
				validationSchema={lawyersFilterSchema}
				onSubmit={(values) => {
					handlerSearchResources(values);
				}}
			>
				{({ values, errors, touched, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit} className="">
						<div className="row">
							{/* Jurisdiction  */}
							<div className="col-12">
								<div
									className=""
									style={{
										width: "100% !important",
									}}
								>
									<AutoComplete
										options={
											data?.states
												? data?.states
												: []
										}
										multiple={true}
										field="jurisdiction"
										placeholder="Jurisdiction(s)"
										handleChange={(value: any) => {
											let selectedJurisdictions: any =
												value?.map((i: any) => i.name);

											setFieldValue(
												"jurisdiction",
												selectedJurisdictions
											);
										}}
									/>
								</div>
								<ErrorText
									text={errors?.jurisdiction}
									isTouched={touched?.jurisdiction}
								/>
							</div>

							{/* Member Type  */}
							<div className="col-12">
								<div
									className=""
									style={{
										width: "100% !important",
									}}
								>
									<AutoComplete
										options={member_type}
										multiple={true}
										field="member_type"
										placeholder="Member Type"
										handleChange={(value: any) => {
											let selectedTopics: any =
												value?.map((i: any) => i.id);

											setFieldValue(
												"member_type",
												selectedTopics
											);
										}}
									/>
								</div>
								<ErrorText
									text={errors?.member_type}
									isTouched={touched?.member_type}
								/>
							</div>

							{/* Select Alphabet */}
							<div className="col-12" id="keyboard">
								<div id="keyboard-icon">
									<KeyboardIcon />
								</div>

								<TextInput
									style={{
										height: "50px",
										paddingLeft: "50px",
										position: "relative",
									}}
									placeholder="Keyword Search"
									type="text"
									value={alphabet?.value}
									handleChange={(e: any) =>
										selectAlphabetHandler(e.target.value)
									}
									onClick={(e: any) => toogleShowKeyboard()}
								/>
							</div>

							{showKeyboard && (
								<div className="col-12">
									<div className="form_blk dropdown user_dropdown">
										<div className="dropdown-menu w-100 scrollbar show p-4">
											<h5 className="text-center">
												Select Alphabetically
											</h5>
											<ul className="alpha_list">
												{Alphabets?.map(
													(obj, index) => {
														return (
															<li key={index}>
																<button
																	type="button"
																	onClick={() => {
																		setAlphabet(
																			obj
																		);
																		setFieldValue(
																			"name",
																			obj.value
																		);
																	}}
																	className={
																		alphabet?.id ===
																			obj?.id
																			? "active"
																			: ""
																	}
																>
																	{obj?.value}
																</button>
															</li>
														);
													}
												)}
											</ul>
											<div className="btn_blk mt-4">
												<button
													className="site_btn w-100"
													onClick={() =>
														toogleShowKeyboard()
													}
												>
													Confirm
												</button>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="btn_blk mt-5 pt-3">
							<button className="site_btn w-100" type="submit">
								Search
							</button>
						</div>
					</form>
				)}
			</Formik>
		</>
	);
};

export default DirectoryForm;
