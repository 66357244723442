import { combineReducers } from "redux";
import lawyerReducer from "./User";
import dashboardReducer from "./Dashboard";

const lawyersReducer = combineReducers({
	lawyerReducer,
	dashboardReducer,
});

export default lawyersReducer;
