import { Checkbox, FormControlLabel } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MuiSelect from "../../../components/MuiSelect";
import Select from "../../../components/Select";
import ErrorText from "../../../components/Shared/ErrorText";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TextInput from "../../../components/TextInput";
import {
	ArrowLeft,
	Envelope, FolderUser,
	Lock,
	LogoIcon, User
} from "../../../icons";
import { ISignUp } from "../../../interfaces/Common";
import { setData, setRole } from "../../../redux/reducers/Auth";
import { useGetRole } from "../../../redux/selectors/Common";
import { useSignUpMutation } from "../../../redux/services/Auth";
import { useGetStatesQuery } from "../../../redux/services/Jurisdiction";
import { useAppDispatch } from "../../../redux/store";
import { member_type, UserTypes } from "../../../utills/Const/const";
import { signUpSchema } from "../../../utills/YupValidationSchemas";
export default function SignUpAdmin() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const role = useGetRole();
	const [podcast, setPodcast] = useState(false);
	const [webinar, setWebinar] = useState(false);
	const [news, setNews] = useState(false);
	const [referral, setReferral] = useState(false);

	const { data: jurisdiction, isLoading: jurisdictionLoading } =
		useGetStatesQuery();

	const [signUp, { isLoading, isSuccess, data: signUpData }] = useSignUpMutation();

	const handleSubmit = (values: ISignUp) => {
		signUp(values);
	};

	useEffect(() => {
		if (isSuccess && signUpData) {
			if (signUpData?.error) {
				toast.error(signUpData?.error[0])
			}
			else {
				dispatch(setData(signUpData));
				navigate('/login');
			}
		}
	}, [isSuccess, signUpData]);

	return (
		<>
			<section id="logon">
				<div className="contain">
					<div className="flex_blk">
						<Formik
							initialValues={{
								email: "",
								phone: "",
								password: "",
								password_confirmation: "",
								name: "",
								firm_code: "",
								jurisdiction: "",
								member_type: "",
								is_webinar: webinar,
								is_podcast: podcast,
								is_news: news,
								is_referral: referral,
								userType: role,
							}}
							validationSchema={signUpSchema}
							onSubmit={(values) => handleSubmit(values)}
						>
							{({
								values,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
								errors,
								touched,
							}) => (
								<form
									onSubmit={handleSubmit}
									className="auth-form"
								>
									<div className="log_blk">
										<Link to="/" className="back_btn">
											<ArrowLeft />
										</Link>
										<div className="inner">
											<LogoIcon />
											<div className="txt text-center mb-5 pb-3">
												<h2>Create Account</h2>
												<p className="fs_5">
													Create an account to access
													ELA
												</p>
											</div>
											<div className="row">
												<div className="col-12">
													<h5 className="fw_500">
														Select User
													</h5>
													<div className="form_blk">
														<User />
														<Select
															label="Select User Type"
															className="input"
															options={UserTypes}
															handleChange={(
																value: string
															) => {
																setFieldValue(
																	"userType",
																	value
																);
																dispatch(
																	setRole({
																		userRole:
																			value,
																	})
																);
															}}
															handleBlur={
																handleBlur
															}
															value={
																values.userType
															}
															name="userType"
														/>
													</div>
												</div>
												{role === "lawyer" && (
													<>
														<div className="col-12">
															<h5 className="fw_500 require">
																Name
															</h5>
															<div className="form_blk">
																<User />
																<TextInput
																	placeholder="eg: John Wick"
																	type="text"
																	name="name"
																	handleChange={
																		handleChange
																	}
																	handleBlur={
																		handleBlur
																	}
																	value={
																		values.name
																	}
																/>
															</div>
															<ErrorText
																text={errors.name}
																isTouched={
																	touched.name
																}
															/>
														</div>
														<div className="col-12">
															<h5 className="fw_500 require">
																Member Type
															</h5>
															<div className="form_blk">
																<MuiSelect

																	options={
																		member_type
																	}
																	multiple={
																		false
																	}
																	field="memberType"
																	placeholder="Select Member Type"

																	handleChange={(
																		value: any
																	) => {
																		setFieldValue(
																			"member_type",
																			value?.memberType
																		);
																	}}
																/>
															</div>
															<ErrorText
																text={
																	errors.member_type
																}
																isTouched={
																	touched.member_type
																}
															/>
														</div>

														<div className="col-12">
															<h5 className="fw_500 require">
																Jurisdiction
															</h5>
															{jurisdiction?.[
																"states"
															].length > 0 && (
																	<MuiSelect
																		options={
																			jurisdiction?.[
																			"states"
																			]
																		}
																		multiple={
																			true
																		}
																		placeholder="Select jurisdiction"
																		handleChange={(
																			value: any
																		) => {
																			let locations =
																				value?.map(
																					(
																						i: any
																					) =>
																						i.name
																				);
																			setFieldValue(
																				"jurisdiction",
																				locations?.toString()
																			);
																		}}
																	/>
																)}
															<ErrorText
																text={
																	errors.jurisdiction
																}
																isTouched={
																	touched.jurisdiction
																}
															/>
															<div className="form_blk">
																{/* <MapMarker />
																<Select
																	label="Select"
																	className="input"
																/> */}
															</div>
															<div className="col-12">
																<h5 className="fw_500 require">
																	Firm Code
																</h5>
																<div className="form_blk">
																	<FolderUser />
																	<TextInput
																		placeholder="eg: X99809"
																		type="string"
																		name="firm_code"
																		handleChange={
																			handleChange
																		}
																		handleBlur={
																			handleBlur
																		}
																		value={
																			values.firm_code
																		}
																	/>
																</div>
																<ErrorText
																	text={errors.firm_code}
																	isTouched={
																		touched.firm_code
																	}
																/>
															</div>
														</div>
													</>
												)}
												<div className="col-12">
													<h5 className="fw_500 require">
														Email Address
													</h5>
													<div className="form_blk">
														<Envelope />
														<TextInput
															placeholder="eg: sample@gmail.com"
															type="email"
															name="email"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.email}
														/>
													</div>
													<ErrorText
														text={errors.email}
														isTouched={
															touched.email
														}
													/>
												</div>
												<div className="col-12">
													<h5 className="fw_500 require">
														Phone
													</h5>
													<div className="row">
														<div className="col-4 col-sm-3">
															<PhoneInput
																country={"us"}
																value={
																	values.phone
																}
																onChange={(
																	value
																) => {
																	setFieldValue(
																		"phone",
																		value
																	);
																}}
															/>
														</div>
														<ErrorText
															text={
																errors.phone
															}
															isTouched={
																touched.phone
															}
														/>
													</div>
												</div>
												<div className="col-12">
													<h5 className="fw_500 require">
														Password
													</h5>
													<div className="form_blk">
														<Lock />
														<TextInput
															type="password"
															name="password"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={
																values.password
															}
															className="input"
															placeholder="eg: PassLogin%7"
														/>
													</div>
													<ErrorText
														text={errors.password}
														isTouched={
															touched.password
														}
													/>
												</div>
												<div className="col-12">
													<h5 className="fw_500 require">
														Confirm Password
													</h5>
													<div className="form_blk">
														<Lock />
														<TextInput
															type="password"
															name="password_confirmation"
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={
																values.password_confirmation
															}
															className="input"
															placeholder="eg: PassLogin%7"
														/>
													</div>
													<ErrorText
														text={
															errors.password_confirmation
														}
														isTouched={
															touched.password_confirmation
														}
													/>
												</div>
												<div style={{ display: "flex", flexDirection: "column" }}>
													<h5 className="fw_500 require">
														Notifications
													</h5>
													<p>Yes, I want to be notified about:</p>
													{role === 'lawyer' && <FormControlLabel control={<Checkbox checked={referral} onChange={() => {
														setReferral(!referral);
														setFieldValue('is_referral', !referral)
													}} />} label={<span style={{ fontSize: '2rem' }} >New ELA Referrals</span>} style={{ fontSize: "3rem" }} />}
													<FormControlLabel control={<Checkbox checked={podcast} onChange={() => {
														setPodcast(!podcast);
														setFieldValue('is_podcast', !podcast)

													}} />} label={<span style={{ fontSize: '2rem' }} >New Podcast</span>} style={{ fontSize: "3rem" }} />
													<FormControlLabel control={<Checkbox checked={webinar} onChange={() => {
														setWebinar(!webinar);
														setFieldValue('is_webinar', !webinar)
													}} />} label={<span style={{ fontSize: '2rem' }} >Upcoming Webinars</span>} style={{ fontSize: "3rem" }} />
													<FormControlLabel control={<Checkbox checked={news} onChange={() => {
														setNews(!news);
														setFieldValue('is_news', !news)
													}} />} label={<span style={{ fontSize: '2rem' }} >ELA News & Events</span>} style={{ fontSize: "3rem" }} />

												</div>
											</div>
											<div className="btn_blk justify-content-center mt-5 pt-3">
												<button
													type="submit"
													className="site_btn w-100"
												>
													Create
												</button>
											</div>
											<div className="already_acc text-center">
												Already have an account?&nbsp;
												<Link
													to="/login"
													className="fw_500"
												>
													Login
												</Link>
											</div>
										</div>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</section>
			<ScreenLoader show={isLoading || jurisdictionLoading} />
		</>
	);
}
