import { useCallback, useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import {
	useGetContactsQuery,
	useGetSearchedContactsMutation,
} from "../../../redux/services/Lawyer/DashboardApi";

import { Checkbox } from "@mui/material";
import { isEmptyArray } from "formik";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import LawyerDirectoryCard from "../../../components/LawyerDirectoryCard";
import AddContact from "../../../components/Popup/AddNewContact/AddContact";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";
import { GetLoginUserData, useGetRole } from "../../../redux/selectors/Common";
import { useDeleteContactMutation, useDeleteAllContactMutation } from "../../../redux/services/Firm/FirmLawyer";

const MyContacts = () => {
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [contacts, setContacts]: any = useState([]);
	const [search, setSearch]: any = useState([]);
	const [deleteContact, setDeleteContact] = useState(false);
	const [contactUpdated, setContactUpdated] = useState(false);
	const [contactAdded, setContactAdded] = useState(false);
	const [addContact, setAddContact] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [deleteReferralCall, setDeleteReferralCall] = useState(false);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [isDeleteAll, setIsDeleteAll] = useState(false);

	const {
		data: contactsData,
		isLoading,
		refetch,
	} = useGetContactsQuery({ rowsPerPage, pageNumber });

	const [
		searchingResources,
		{ data: searchData, isLoading: searchLoading, isSuccess },
	] = useGetSearchedContactsMutation();

	const [
		deleteContactCall,
		{ isSuccess: contactSuccess, isError: contactError },
	] = useDeleteContactMutation();

	const [
		deleteAllContactCall,
		{ isSuccess: contactAllSuccess, isError: contactAllError },
	] = useDeleteAllContactMutation();

	const role: any = useGetRole();
	const userData: any = GetLoginUserData();

	useEffect(() => {
		if (contactsData) {
			setContacts(contactsData?.contacts || []);
		}
	}, [contactsData]);

	useEffect(() => {
		refetch();
		setContacts(contactsData?.contacts);
	}, [contactAdded, deleteContact, contactUpdated]);

	useEffect(() => {
		if (isSuccess && searchData) {
			setContacts(searchData?.contacts || []);
		}
	}, [isSuccess, searchData]);

	const handleDelete = () => {
		setDeleteContact(!deleteContact);
	};

	const handleUpdate = () => {
		setContactUpdated(!contactUpdated);
	};

	const handleAdd = () => {
		setContactAdded(!contactAdded);
	};

	const handleAddModal = () => {
		setAddContact(!addContact);
	};

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearch(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searchingResources({ search });
			} else {
				setContacts(contactsData?.contacts);
			}
		}, 1000);
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(contacts?.map((item: { id: any }) => item.id));
		}

		setSelectAll(!selectAll);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	useEffect(() => {
		if (selectedItems.length === 0) {
			setSelectAll(false);
		}
	}, [selectedItems]);

	useEffect(() => {
		if (deleteReferralCall) {
			if (isDeleteAll) {
				deleteAllContactCall({ lawyer_id: userData?.lawyer?.id });
			} else {
				deleteContactCall({ id: selectedItems });
			}
			setDeleteReferralCall(false);
		}

		if (contactSuccess || contactAllSuccess) {
			refetch();
			setSelectedItems([]);
			toast.success("Contacts Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
		}

		if (contactError || contactAllError) {
			toast.error(
				"Could not delete contacts at the moment, Please try again"
			);
		}

		setDeletePopUp(false);
	}, [deleteReferralCall, contactSuccess, contactError, contactAllSuccess, contactAllError]);

	return (
		<>
			<Layout>
				<section id="manage_lawyers" className=" sec_pad_btm ">
					<div className="contain sm">
						<div className="Top_blk">
							<TopBlock
								title={
									role === "lawyer"
										? "My Contacts"
										: "Manage All Contacts"
								}
								url="dashboard"
							/>
							<div className="left_blk">
								<div className="search_blk">
									<div className="img_blk">
										<img src={search} alt="" />
									</div>
									<input
										type="search"
										value={search}
										placeholder="Search"
										onChange={(e) => {
											handleSearch(e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
						<ScreenLoader show={isLoading || searchLoading} />

						<div
							className="search_container"
							style={{
								justifyContent: "space-between",
								backgroundColor: "#63a744",
								alignItems: "center",
								padding: "1rem",
								borderRadius: "1rem",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "0.5rem",
									}}
								>
									<Checkbox
										checked={selectAll}
										classes={{
											root: "custom-checkbox-root",
										}}
										onClick={() => {
											handleSelectAllChange();
										}}
									/>
									<p
										style={{
											fontSize: "2rem",
											lineHeight: "7rem",
											marginLeft: "0.5rem",
										}}
									>
										Select All
									</p>
								</div>
							</div>
							<div style={{ display: 'flex' }}>
								{selectedItems?.length > 1 && (
									<Button
										variant="danger"
										onClick={handleBulkDelete}
										style={{
											fontSize: "2rem",
											width: "15rem",
											height: "5rem",
											marginRight: "2rem",
										}}
									>
										Delete
									</Button>
								)}

								<div>
									<Button
										variant="danger"
										onClick={useCallback(() => {
											setIsDeleteAll(true);
											handleBulkDelete();
										}, [])}
										style={{
											fontSize: "2rem",
											width: "15rem",
											height: "5rem",
											marginRight: "2rem",
										}}
									>
										Delete All
									</Button>

									<Button
										variant="primary"
										onClick={handleAddModal}
										style={{
											fontSize: "2rem",
											width: "22rem",
											height: "5rem",
											marginRight: "2rem",
										}}
									>
										Add New Contact
									</Button>
								</div>
							</div>
						</div>

						{!isEmptyArray(contacts)
							? contacts?.map((item: any) => {
									return (
										<LawyerDirectoryCard
											item={item}
											key={item.id}
											totalRecords={
												contactsData?.total_records
											}
											checkbox={true}
											firm={true}
											isContact={true}
											setDelete={handleDelete}
											setUpdate={handleUpdate}
											showAddModal={addContact}
											setShowAddModel={handleAddModal}
											hadnleContactAdded={handleAdd}
											isSelected={selectedItems.includes(
												item.id
											)}
											onSelect={handleItemSelect}
										/>
									);
							  })
							: !isLoading &&
							  !searchLoading && (
									<div className="text-center mt-5">
										<h3>No Records Found</h3>
									</div>
							  )}
						{!isLoading && !searchLoading && (
							<ReactPagination
								setRowsPerPage={(rows: number) => {
									setRowsPerPage(rows);
									refetch();
								}}
								setPages={(page: number) => {
									setPageNumber(page + 1);
									refetch();
								}}
								totalRecords={contactsData?.total_records}
							/>
						)}
						<AddContact
							active={addContact}
							setActive={handleAddModal}
							updateContact={false}
							setUpdateContact={handleAdd}
						/>
						<DeleteConfirmation
							showModal={deletePopUp}
							message={
								isDeleteAll ? "Are you sure you want to delete all the Contacts?" : "Are you sure you want to delete the selected Contacts?"
							}
							handleDelete={setDeleteReferralCall}
							hideModal={handleBulkDelete}
						/>
					</div>
				</section>
			</Layout>
		</>
	);
};

export default MyContacts;
