import Layout from "../../../components/Layout";
import TopBlock from "../../../components/TopBlock";
import { FIRMLAWYER_DATA } from "../../../utills/DashboardData";

const FirmLawyers = () => {
  return (
    <>
      <Layout>
        <div id="firm_lawyer" className="sec_pad_btm">
          <div className="contain sm">
            <TopBlock title="Firm Lawyers" />
            {FIRMLAWYER_DATA.map((item) => {
              return (
                <>
                  {/* <ManageLawyersCard item={item} key={item.id} /> */}
                </>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FirmLawyers;
