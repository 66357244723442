import React from "react";
import { Link } from "react-router-dom";
import rightArrow from "../../assets/images/rightArrow.svg";
import { IEvents } from "../../interfaces/Common";
import { useGetRole } from "../../redux/selectors/Common";
import Image from "material-ui-image";

type Props = {
	item: IEvents;
};

const EventCard: React.FC<Props> = ({ item }) => {
	const date: any = new Date(item.event_date)
	const role = useGetRole();
	return (
		<div className="event_card">
			<Link
				to={`/${role}/event-details`}
				state={{ eventDetails: item }}
				className="img_blk"
			>
				<Image
					src={item.image}
					alt="Event_Image"
					aspectRatio={5 / 4}
				/>
			</Link>
			<div className="content">
				<Link
					to={`/${role}/event-details`}
					state={{ eventDetails: item }}
				>
					<h5>{item.name}</h5>
				</Link>
				<a href={item.link} style={{ color: '#114d96' }} >
					<h6>{date.toDateString()}</h6>
				</a>
				<a href={item.link} className="register">
					Register
					<img src={rightArrow} alt="Event_Image" />
				</a>
			</div>
		</div>
	);
};

export default EventCard;
