import { isEmptyArray } from "formik";
import { ArrowLeft } from "../../../icons";
import {
	useCreateConversationMutation,
	useGetLawyersListQuery,
} from "../../../redux/services/Lawyer/DashboardApi";
import ScreenLoader from "../../Shared/ScreenLoader";
import "../../LawyerContactCard/ContactCard.scss";
import LawyerContactCard from "../../LawyerContactCard";
import { GetLoginUserData } from "../../../redux/selectors/Common";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import searchImg from "../../../assets/images/search.svg";

export default function LawyersContactModal({
	active,
	setActive,
	setReceiver,
	refetch,
	setChannelName,
}: any) {

	const [search, setSearch]: any = useState();
	const [lawyerList, setLwayers]: any = useState();

	const { data: lawyers, isLoading } = useGetLawyersListQuery(null, {
		refetchOnMountOrArgChange: true,
	});

	const [
		addConversation,
		{ data, isLoading: conversationLoading, isSuccess, isError },
	] = useCreateConversationMutation();

	const userData: any = GetLoginUserData();

	const handleChatSelect = (item: any) => {
		var currentDate = new Date();
		let channelName = userData?.lawyer?.name || userData?.firm?.name;
		channelName =
			channelName.split(" ")?.pop()?.toUpperCase() +
			item?.name?.split(" ")?.pop()?.toUpperCase() +
			currentDate.getSeconds();

		if (channelName) {
			setChannelName(channelName);
		}

		addConversation({
			sender_lawyer_id: userData?.lawyer?.id,
			receiver_lawyer_id: item?.id,
			channel_name: channelName,
			seen: true,
		});
	};

	useEffect(() => {
		if (isSuccess) {
			if (data?.error) {
				toast.error("You can not create duplicate conversation! ");
			} else {
				toast.success("New Conversation created");
				setActive(false);
				refetch();
				setReceiver(data);
			}
		}
	}, [isSuccess, isError]);

	useEffect(() => {
		if (search) {
			const searchResult = lawyers?.data.filter(
				(item: any) => {

					// if (item.receiver_name !== userData?.lawyer?.name) {
						return item?.name?.toLowerCase()?.includes(search.toLowerCase());
					// }
				}
			);
			setLwayers(searchResult);
		} else {
			setLwayers(lawyers?.data);
		}
	}, [search]);

	useEffect(() => {
		setLwayers(lawyers?.data);
	}, [lawyers]);

	if (isLoading) {
		return <ScreenLoader show={true} />;
	}

	return (
		<>
			<section
				id="filter_popup"
				className={active ? "popup active" : "popup"}
			>
				<div className="overlay" onClick={setActive}></div>
				<div className="table_dv">
					<div className="table_cell">
						<div className="contain">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-8 col-sm-10">
									<div className="_contact_modal">
										<button
											type="button"
											className="back_btn"
											onClick={()=> {setActive(); setSearch("");}}
										>
											<ArrowLeft />
										</button>
										<h2 className="text-center mb-5">
											Lawyers List
										</h2>
										<h5 className="text-center mb-5">
											Total contacts:{" "}
											{lawyers?.data?.length}
										</h5>
										<div style={{padding: "2rem"}}>
										<div className="search_blk_chat">
											<div className="img_blk">
												<img src={searchImg} alt="" />
											</div>
											<input
												type="search"
												value={search}
												placeholder="Search Lawyers"
												onChange={(e) => {
													setSearch(e.target.value);
												}}
											/>
										</div>
										</div>
										{false && <ScreenLoader show={true} />}
										<div style={{ width: "100%" }}>
											{!isEmptyArray(lawyers)
												? lawyerList?.map(
														(item: any) => {
															return (
																<LawyerContactCard
																	item={item}
																	key={
																		item.id
																	}
																	handleChatSelect={
																		handleChatSelect
																	}
																/>
															);
														}
												  )
												: !isLoading && (
														<div className="text-center mt-5">
															<h3>
																No Records Found
															</h3>
														</div>
												  )}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
