import Modal from "react-bootstrap/Modal";
import { FC } from "react";

interface ModalProps {
  show: boolean;
  onHide: () => void; 
  children: any;
}
const LoginModal: FC<ModalProps> = ({ show, onHide, children }) => {
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
export default LoginModal;
