import dayjs from "dayjs";
import ArrowLeft from "../../icons/ArrowLeft";
import TextArea from "../TextArea";
import TextInput from "../TextInput";

export default function MessageInfoModal({ active, setActive, item }: any) {
	return (
		<>
			<section
				id="contactInfo_popUp"
				className={active ? "popup active" : "popup"}
			>
				<div className="overlay" onClick={setActive}></div>
				<div className="table_dv">
					<div className="table_cell">
						<div className="contain">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-8 col-sm-10">
									<div className="_inner">
										<button
											type="button"
											className="back_btn"
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<div className="row mb-5">
											<div className="col-12">
												<h5>Name</h5>
												<div className="form_blk">
													<TextInput
														placeholder=""
														type="text"
														handleChange={() => {}}
														handleBlur={() => {}}
														value={item?.name || ""}
														readOnly
													/>
												</div>
											</div>
										</div>
										<div className="row mb-5">
											<div className="col-12">
												<h5>Email</h5>
												<div className="form_blk">
													<TextInput
														placeholder=""
														type="text"
														handleChange={() => {}}
														handleBlur={() => {}}
														value={
															item?.email ||
															""
														}
														readOnly
													/>
												</div>
											</div>
										</div>
										<div className="row mb-5">
											<div className="col-12">
												<h5>Phone</h5>
												<div className="form_blk">
													<TextInput
														placeholder=""
														type="tel"
														handleChange={() => {}}
														handleBlur={() => {}}
														value={item?.phone || ""}
														readOnly
													/>
												</div>
											</div>
										</div>
										<div className="row mb-5">
											<div className="col-12">
												<h5>DateTime</h5>
												<div className="form_blk">
													<TextInput
														placeholder=""
														type="text"
														handleChange={() => {}}
														handleBlur={() => {}}
														value={dayjs(item?.created_at).format('MM/DD/YYYY , h:mm:ss A') || ""}
														readOnly
													/>
												</div>
											</div>
										</div>
										<h5>Message</h5>
											<TextArea
												placeholder="Message"
												value={item?.message}
												type={""}
												readOnly
											/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
